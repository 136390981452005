import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {
  Avatar,
  Icon,
  IconVerifiedBadge,
  ListItemSelect,
  Menu,
  Paragraph,
} from 'common/components'
import {formatRelativeDateDiff, useHistory} from 'utils'
import {useTranslation} from 'i18n'
import {useSelector} from 'lib/redux'
import {FototreeGlobalChatItemContentProps} from './FototreeGlobalChatProps'

interface StyledContainerProps {
  isPreview: boolean
}

const StyledChatContainer = styled.div`
  display: flex;
  flex: 1;
  column-gap: ${convertUnit(12)};
`

const StyledItemHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledItemChatContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  word-break: break-word;
  word-wrap: break-word;
  overflow: ${({isPreview}) => (isPreview ? 'hidden' : undefined)};
`

const StyledItemChat = styled(Paragraph)<StyledContainerProps>`
  ${({isPreview}) => ({
    whiteSpace: isPreview ? 'nowrap' : undefined,
    overflow: isPreview ? 'hidden' : undefined,
    textOverflow: isPreview ? 'ellipsis' : undefined,
  })};
`

const StyledMenu = styled(Menu)`
  cursor: pointer;
`

const StyledAvatar = styled(Avatar)`
  cursor: pointer;
`

const StyledDate = styled(Paragraph)`
  margin-left: auto;
`

const StyledIconVerified = styled(IconVerifiedBadge)`
  margin-left: ${convertUnit(4)};
`

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${convertUnit(16)};
`

export default function FototreeGlobalChatItemContent({
  data,
  overlay,
  userType,
  showReply,
  stateDeleteModal,
  stateRecipient,
  onReply,
}: FototreeGlobalChatItemContentProps) {
  const history = useHistory()
  const {translate} = useTranslation()
  const {id: userId} = useSelector('user') || {}
  const stateActionToggle = useState(false)
  const setDeleteModal = stateDeleteModal[1]
  const setRecipient = stateRecipient[1]
  const setActionToggle = stateActionToggle[1]

  const canDelete = useMemo(
    () =>
      (userType !== 'visitor' || userId === data.member.id) &&
      overlay === 'full',
    [data.member.id, overlay, userId, userType],
  )

  const handleNavigateProfile = useCallback(() => {
    if (overlay !== 'full') return
    history.push(
      'giftshop_profile',
      {
        self: data.member.id === userId,
        postData: {
          member_id: data.member.id,
          username: data.member.username,
          photo: data.member.photo,
        },
      },
      data.member.username,
    )
  }, [
    data.member.id,
    data.member.photo,
    data.member.username,
    history,
    overlay,
    userId,
  ])

  const handleRenderActionOption = useMemo(
    () =>
      canDelete && (
        <ListItemSelect
          title={translate('forkygram:deletePostComment')}
          onClick={() => {
            setActionToggle((prev) => !prev)
            setDeleteModal(true)
            setRecipient({username: '', comment_id: data.id})
            onReply && onReply()
          }}
        />
      ),
    [
      canDelete,
      data.id,
      onReply,
      setActionToggle,
      setDeleteModal,
      setRecipient,
      translate,
    ],
  )

  const handleRenderActionMenu = useMemo(
    () =>
      canDelete && (
        <StyledMenu
          stateToggle={stateActionToggle}
          renderContent={handleRenderActionOption}
          contentContainerStyle={{
            right: 0,
            top: convertUnit(-50),
            minWidth: convertUnit(185),
            borderRadius: convertUnit(8),
          }}>
          <Icon
            size={16}
            color="gray_5"
            type="more-filled"
            onClick={() => setActionToggle(true)}
          />
        </StyledMenu>
      ),
    [canDelete, handleRenderActionOption, setActionToggle, stateActionToggle],
  )

  return (
    <StyledChatContainer>
      <StyledAvatar
        alt={data.member.username}
        src={data.member.photo}
        size={convertUnit(overlay === 'full' ? 40 : 28)}
        onClick={handleNavigateProfile}
      />
      <StyledItemChatContainer isPreview={overlay !== 'full'}>
        <StyledItemHeaderContainer>
          <Paragraph
            className="pressable"
            fontWeight="bold"
            color="white_1"
            fontSize={overlay === 'full' ? 's' : 'xs'}
            onClick={handleNavigateProfile}>
            {data.member.username}
          </Paragraph>
          {data.member.creator_status === 'verified' && <StyledIconVerified />}
          {overlay === 'full' && (
            <StyledDate color="gray_5">
              {formatRelativeDateDiff(data.created_at)}
            </StyledDate>
          )}
        </StyledItemHeaderContainer>
        <StyledItemChat
          isPreview={overlay !== 'full'}
          fontSize={overlay === 'full' ? 's' : 'xs'}
          color="white_1">
          {data.comment}
        </StyledItemChat>
        {overlay === 'full' && (
          <StyledFooter>
            {showReply && (
              <Paragraph
                fontWeight="bold"
                color="gray_5"
                style={{cursor: 'pointer'}}
                onClick={() =>
                  setRecipient({
                    comment_id: data.id,
                    username: data.member.username,
                  })
                }>
                {translate('forkygram:reply')}
              </Paragraph>
            )}
            {handleRenderActionMenu}
          </StyledFooter>
        )}
      </StyledItemChatContainer>
    </StyledChatContainer>
  )
}
