import {lazy} from 'react'

const AuthLoginScreen = lazy(
  () => import(/* webpackChunkName: 'AuthLogin' */ './AuthLoginScreen'),
)

const AuthLoginUnderageScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'AuthLoginUnderage' */ './AuthLoginUnderageScreen'
    ),
)

export * from './AuthLoginScreen'
export {AuthLoginScreen}

export * from './AuthLoginUnderageScreen'
export {AuthLoginUnderageScreen}
