import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {GiftShopTemplateUploadForm} from 'pages'
import {useGiftshopUploadProperties} from 'utils'
import convertUnit from 'lib/unit'
import {GiftShopUploadContentItemVideoProps} from './GiftShopUploadContentItemVideoProps'
import GiftShopUploadContentItemVideoImage from './GiftShopUploadContentItemVideoImage'

const StyledOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${convertUnit(20)};
  height: 100%;
  background-color: ${({theme}) => theme.white_2};
  overflow-y: scroll;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_1};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    flex-direction: column;
  }
`

const StyledFormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export default function GiftShopUploadContentItemVideo({
  videoData,
  defaultValues: initialValues,
  renderUploadButton,
  sendToFaceSrc,
  onLoadFaceFile,
  onAddLocation,
  onFormUpdate,
  toggleModal,
}: GiftShopUploadContentItemVideoProps) {
  const priceState = useState(0)
  const {defaultValues, select} = useGiftshopUploadProperties({
    initialValues,
    data: videoData,
    uploadType: 'video',
  })
  const {
    height: videoHeight,
    width: videoWidth,
    size: videoSize,
    src: videoSrc,
    file: videoFile,
  } = select

  const handleRenderContentImage = useMemo(
    () => (
      <GiftShopUploadContentItemVideoImage
        videoHeight={videoHeight}
        videoSize={videoSize}
        videoTitle={videoFile.name}
        videoSrc={videoSrc}
        videoWidth={videoWidth}
        sendToFaceSrc={sendToFaceSrc}
        onLoadFaceFile={onLoadFaceFile}
      />
    ),
    [
      onLoadFaceFile,
      sendToFaceSrc,
      videoFile.name,
      videoHeight,
      videoSize,
      videoSrc,
      videoWidth,
    ],
  )

  const handleRenderForm = useMemo(
    () => (
      <GiftShopTemplateUploadForm
        defaultValues={defaultValues}
        priceState={priceState}
        toggleModal={toggleModal}
        uploadType="video"
        onFormUpdate={onFormUpdate}
        onAddLocation={onAddLocation}
        showDeleteButton={false}
      />
    ),
    [defaultValues, onAddLocation, onFormUpdate, priceState, toggleModal],
  )

  return (
    <StyledOuterContainer>
      <StyledContainer>
        {handleRenderContentImage}
        <StyledFormContainer>
          {handleRenderForm}
          {renderUploadButton}
        </StyledFormContainer>
      </StyledContainer>
    </StyledOuterContainer>
  )
}
