import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {useDidMount, useHistory} from 'utils'
import {Button, Icon, Image, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopExploreSearchModalProps} from './GiftShopExploreSearchModalProps'

const StyledModal = styled(Modal)`
  max-width: ${convertUnit(295)};
  width: 100%;
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${convertUnit(20)};
  align-items: center;
  text-align: center;
`

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(20)};
  width: inherit;
`

const StyledRowButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  width: 100%;
`

const StyledButton = styled.div`
  cursor: pointer;
  background-color: ${({theme}) => theme.info_1};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${convertUnit(12)} 0;
  border-radius: ${convertUnit(8)};
  width: 100%;
  flex: 1;
`

const StyledCancelButton = styled(Button)`
  width: 100%;
`

const StyledFilterIconContainer = styled.div`
  position: relative;
  width: fit-content;
`

const StyledFilterIcon = styled(Icon)`
  position: relative;
`

const StyledFilterBadge = styled.div`
  width: ${convertUnit(8)};
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: ${({theme}) => theme.primary_5};
  border-width: ${convertUnit(2)};
  border-style: solid;
  border-color: ${({theme}) => theme.white_1};
  position: absolute;
  top: 0;
  box-sizing: border-box;
  bottom: ${convertUnit(20)};
  left: ${convertUnit(3)};
`

const StyledTemporaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(12)};
  justify-content: center;
  align-items: center;
`

export default function GiftShopExploreSearchModal({
  visible,
  isFiltered,
  onClickFilter,
  toggleModal,
}: GiftShopExploreSearchModalProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const temporaryImage = IMAGE_ASSET('giftshop', 'robopet-offer.png')
  const [searchReady, setSearchReady] = useState<boolean | null>(null)

  const handleFetchSearchStatus = useCallback(() => {
    requestData('giftshop_get_explore_filter_status', {
      onRequestSuccess: ({status}) => {
        if (status === 200) setSearchReady(true)
        else if (status === 503) setSearchReady(false)
      },
    })
  }, [])

  const handleRenderFilterButton = useMemo(
    () => (
      <StyledButton onClick={onClickFilter}>
        <StyledFilterIconContainer>
          {isFiltered && <StyledFilterBadge />}
          <StyledFilterIcon
            type="filter"
            color="primary_5"
            size={convertUnit(16)}
          />
        </StyledFilterIconContainer>
        <Paragraph fontWeight="bold" color="primary_5">
          {translate('global:filter')}
        </Paragraph>
      </StyledButton>
    ),
    [isFiltered, onClickFilter, translate],
  )

  const handleRenderEventsButton = useMemo(
    () => (
      <StyledButton onClick={() => history.push('tree_events', {})}>
        <Icon type="calendar-event" color="primary_5" size={convertUnit(16)} />
        <Paragraph fontWeight="bold" color="primary_5">
          {translate('global:events')}
        </Paragraph>
      </StyledButton>
    ),
    [history, translate],
  )

  const handleRenderCreatorButton = useMemo(
    () => (
      <StyledButton
        onClick={() => history.push('giftshop_search_by_creator', {})}>
        <Icon type="people" color="primary_5" size={convertUnit(16)} />
        <Paragraph fontWeight="bold" color="primary_5">
          {translate('global:creators')}
        </Paragraph>
      </StyledButton>
    ),
    [history, translate],
  )

  const handleRenderCancelButton = useMemo(
    () => (
      <StyledCancelButton
        label={translate(searchReady ? 'global:cancel' : 'global:close')}
        backgroundColor="white_2"
        color="primary_5"
        onClick={toggleModal}
      />
    ),
    [searchReady, toggleModal, translate],
  )

  const handleRenderTemporary = useMemo(
    () => (
      <StyledTemporaryContainer>
        <Image
          src={temporaryImage}
          alt={translate('giftShop:exploreSearchTemporary')}
          width={200}
          height={200}
        />
        <Paragraph fontSize="l" fontWeight="bold" color="primary_5">
          {translate('giftShop:exploreSearchTemporary')}
        </Paragraph>
      </StyledTemporaryContainer>
    ),
    [temporaryImage, translate],
  )

  useDidMount(handleFetchSearchStatus)

  return (
    <StyledModal visible={visible}>
      <Paragraph fontSize="l" fontWeight="bold">
        {translate('giftShop:exploreSearchTitle')}
      </Paragraph>
      {searchReady === null ? (
        <></>
      ) : searchReady ? (
        <StyledButtonsContainer>
          <StyledRowButtonsContainer>
            {handleRenderEventsButton}
            {handleRenderCreatorButton}
          </StyledRowButtonsContainer>
          {handleRenderFilterButton}
        </StyledButtonsContainer>
      ) : (
        handleRenderTemporary
      )}

      {handleRenderCancelButton}
    </StyledModal>
  )
}
