import {TranslationKeyTree} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_TREE_ID: TranslationResource<TranslationKeyTree> = {
  translation: {
    'tree:addFarmer': 'Tambah Petani',
    'tree:addFarmerExceedLimit':
      'Maksimal farmer yang dapat diundang hanya {{count}}',
    'tree:addFarmerInputPlaceholder': 'Cari berdasarkan username',
    'tree:addFarmerNotFound': 'Username tidak ditemukan',
    'tree:addFarmerSuccess': 'Berhasil!',
    'tree:addFarmerSuccessDescription': 'Undangan kamu berhasil dikirim.',
    'tree:addHostInfoCreatorPriceTitle': 'Atur Harga Kreator',
    'tree:addHostInfoCreatorPriceSubtitle':
      'Kamu dapat mengubahnya nanti di menu kelola.',
    'tree:addHostInfoErrorHashtagContainSpecialChar':
      'Nama host tidak bisa mengandung karakter spesial kecuali (_) underscore',
    'tree:addHostInfoErrorHashtagAlreadyExist':
      'Nama ini sudah dipakai oleh host lain, coba gunakan nama lainnya',
    'tree:addHostInfoErrorHostNameContainSpecialChar':
      'Nama host tidak bisa mengandung karakter spesial kecuali (.) titik',
    'tree:addHostInfoErrorInvalidFileFormat':
      'Format file tidak sesuai. Silahkan coba lagi',
    'tree:addHostInfoErrorMaxFee': 'Biaya maksimum adalah {{fee}}%',
    'tree:addHostInfoErrorMaxFileSize': 'Ukuran file maksimum adalah 50 MB',
    'tree:addHostInfoErrorMaxPrice': 'Harga maksimum adalah {{price}}',
    'tree:addHostInfoErrorMaxPriceMoreThanMinPrice':
      'Harga maksimum harus lebih dari harga minimum',
    'tree:addHostInfoErrorMinPriceLessThanMaxPrice':
      'Harga minimum tidak boleh melebihi harga maksimum',
    'tree:addHostInfoFirstBuyChecked': 'Konten First Buy sekarang gratis',
    'tree:addHostInfoFirstBuyDescription':
      'Harga gratis untuk Konten First Buy ',
    'tree:addHostInfoFirstBuySubtitle':
      'Kamu dapat mengubahnya nanti di menu kelola.',
    'tree:addHostInfoFirstBuyTitle': 'Konten First Buy',
    'tree:addHostInfoFirstBuyUnchecked':
      'Konten First Buy sekarang harga normal',
    'tree:addHostInfoHostNameLabel': 'Nama Host',
    'tree:addHostInfoHostNamePlaceholder': 'Masukkan nama host kamu',
    'tree:addHostInfoHostNameSubtitle':
      'Kamu dapat mengubahnya nanti di menu kelola.',
    'tree:addHostInfoHostNameTitle': 'Buat Nama Host',
    'tree:addHostInfoHeader': 'Tambahkan Informasi Host',
    'tree:addHostInfoHostFeeIndependentLabel':
      'Biaya Host untuk Kreator INDEPENDEN',
    'tree:addHostInfoHostFeeInternalLabel': 'Biaya Host untuk Kreator INTERNAL',
    'tree:addHostInfoHostFeeLabel': 'Biaya Host',
    'tree:addHostInfoHostFeePlaceholder': 'Masukkan biaya host kamu',
    'tree:addHostInfoHostFeeSubtitle':
      'Kamu dapat mengubahnya nanti di menu kelola.',
    'tree:addHostInfoHostFeeTitle': 'Atur Biaya Host',
    'tree:addHostInfoLogoChange': 'Ubah Logo',
    'tree:addHostInfoLogoSubtitle': 'Tambahkan logo perusahaan kamu di sini.',
    'tree:addHostInfoLogoTitle': 'Tambahkan Gambar Logo',
    'tree:addHostInfoMaxPriceLabel': 'Harga Maksimum',
    'tree:addHostInfoMaxPricePlaceholder': 'Masukkan harga maksimum',
    'tree:addHostInfoMinPriceLabel': 'Harga Minimum',
    'tree:addHostInfoMinPricePlaceholder': 'Masukkan harga minimum',
    'tree:availableBalanceLabel': 'Saldo #Host tersedia',
    'tree:beAHost': 'Jadilah Host',
    'tree:beAHostModalHashtagDesc':
      'Klaim #Nama_Host_Nama_Bisnis milikmu pribadi',
    'tree:beAHostModalPlaceDesc':
      'Host bisa berupa tempat wisata, taman hiburan, mal, hotel, ballroom, kafe, restoran, sportclub, atau sebuah acara',
    'tree:beAHostModalRevenueDesc':
      'Ciptakan sumber pendapatan baru dengan bisnismu saat ini',
    'tree:beAHostNotCreatorSnackbarMessage':
      'Kamu harus menjadi kreator terlebih dahulu.',
    'tree:bio': 'Bio Tree',
    'tree:bioPlaceholder': 'Deskripsi Tree',
    'tree:changePhotoButton': 'Ubah Foto',
    'tree:changeBioPlaceholder': 'Ceritakan tentang FotoTree kamu',
    'tree:changeLocationButton': 'Ubah Lokasi',
    'tree:chooseLocationButton': 'Pilih Lokasi',
    'tree:creatorViewCameraLive': 'Kamera Live',
    'tree:creatorViewCameraLiveAction':
      'Kamera Live sampai <date>{{date}}</date>',
    'tree:creatorViewCameraLiveDescription':
      'Atur durasi pengambilan fotomu di lokasi ini',
    'tree:creatorViewCameraLiveInputError': 'Maks. waktu adalah 24 jam.',
    'tree:creatorViewCameraLiveSnackbarMessage':
      'Untuk menggunakan fitur "Kamera Live", harap tingkatkan akun kamu menjadi kreator terverifikasi.',
    'tree:deleteVideoConfirmation': 'Apakah kamu yakin ingin menghapus video?',
    'tree:editHostChangeBioHeader': 'Ubah Bio',
    'tree:editHostChangeHostFeeHeader': 'Ubah Biaya Host',
    'tree:editHostChangeHostNameHeader': 'Ubah Nama Host',
    'tree:editHostChangeMaxPriceHeader': 'Ubah Harga Max.',
    'tree:editHostChangeMinPriceHeader': 'Ubah Harga Min.',
    'tree:editHostFeeDisclaimer': 'Nilai bawaan adalah 20% dari Harga Kreator',
    'tree:editHostFirstBuyModalDescription':
      'Apakah kamu yakin ingin membuat perubahan?',
    'tree:editHostFormLabelBio': 'Bio',
    'tree:editHostFormLabelHostFee': 'Biaya Host',
    'tree:editHostFormLabelHostFeeIndependent':
      'Biaya Host untuk Kreator INDEPENDEN',
    'tree:editHostFormLabelHostFeeInternal':
      'Biaya Host untuk Kreator INTERNAL',
    'tree:editHostFormLabelHostName': 'Nama Host',
    'tree:editHostFormLabelMaxPrice': 'Harga Max.',
    'tree:editHostFormLabelMinPrice': 'Harga Min.',
    'tree:editHostFormPlaceholderBio': 'Ceritakan diri kamu',
    'tree:editHostFormPlaceholderHostFee': 'Masukkan biaya host',
    'tree:editHostFormPlaceholderHostName': 'Masukkan nama host kamu',
    'tree:editHostFormPlaceholderMaxPrice': 'Masukkan harga max.',
    'tree:editHostFormPlaceholderMinPrice': 'Masukkan harga min.',
    'tree:editHostImageChangePicture': 'Ganti Foto',
    'tree:editHostIndependentOfficialCreatorTitle': 'Kreator Independen',
    'tree:editHostInternalOfficialCreatorTitle': 'Kreator Internal',
    'tree:editHostProfileBioEmpty': 'Tidak ada bio',
    'tree:editHostProfileBioTitle': 'Bio (Opsional)',
    'tree:editHostProfileHeader': 'Edit Host',
    'tree:editHostProfileHostFeeTitle': 'Biaya Host',
    'tree:editHostProfileMaxPriceTitle': 'Harga Max.',
    'tree:editHostProfileMinPriceTitle': 'Harga Min.',
    'tree:editHostProfileSettingPriceTitle': 'Pengaturan Harga Kreator',
    'tree:editTree': 'Ubah Bio FotoTree',
    'tree:enableGPS': 'Aktifkan GPS',
    'tree:eventBIBContentSaveSuccessMessage': 'File tersimpan di tab "FotoYu"',
    'tree:eventBIBEmptyMessageFirstHalf':
      'Ya Bos, RoboYu sedang mencari konten kamu.',
    'tree:eventBIBEmptyMessageSecondHalf':
      'RoboYu akan mengirim notifikasi setelah menemukannya!',
    'tree:eventBIBSearchErrorNumeric': 'Masukkan angka saja.',
    'tree:eventBIBSearchPlaceholder': 'Ketik nomor BIB kamu',
    'tree:eventDate': 'Tanggal Acara Tree',
    'tree:eventDateEnd': 'Tanggal Berakhir',
    'tree:eventDateEndErrorLessThanStartDate':
      'Tanggal berakhir tidak boleh diatur sebelum tanggal mulai.',
    'tree:eventDateEndErrorMoreThanMaxDate':
      'Tanggal berakhir tidak boleh melebihi {{max}} hari dari tanggal mulai.',
    'tree:eventDateStart': 'Tanggal Mulai',
    'tree:eventSearchEmptySubtitle':
      'Silakan coba lagi menggunakan kata kunci lain.',
    'tree:eventSearchEmptyTitle':
      'Maaf! Kami tidak dapat menemukan Acara / FotoTree yang kamu cari',
    'tree:eventSearchPlaceholder': 'Cari berdasarkan nama acara',
    'tree:footprints': 'Jejak Kaki',
    'tree:fototreeFootprints': 'Jejak Kaki FotoTree',
    'tree:fototreeFootprintsTreeCount': '{{count}} pohon',
    'tree:fototreeFootprintsTreeCount_plural': '{{count}} pohon',
    'tree:fototreeFootprintsLeafCount': '{{count}} daun memori',
    'tree:fototreeName': 'Nama FotoTree',
    'tree:history': 'Riwayat Transaksi',
    'tree:historyDescription':
      'Lihat semua riwayat transaksi host dalam satu tempat',
    'tree:hostCreatorType': '',
    'tree:hostCreatorType_independent': 'Independen',
    'tree:hostCreatorType_internal': 'Internal',
    'tree:hostCancelInvitationDescription':
      'Kamu yakin ingin membatalkan undangan ini?',
    'tree:hostCancelInvitationSuccess': 'Undangan dibatalkan.',
    'tree:hostCancelInvitationTitle': 'Batalkan Undangan',
    'tree:hostManageAdminAddAdmin': 'Tambah Admin',
    'tree:hostManageAdminAddAdminErrorAlreadyInAnotherHost':
      'Pengguna sudah didaftarkan sebagai admin host oleh host lain.',
    'tree:hostManageAdminCaution':
      'Maksimal host admin yang dapat diundang hanya 5',
    'tree:hostManageAdminDemoteAdmin': 'Turunkan Admin',
    'tree:hostManageAdminDemoteAdminContent':
      'Apakah kamu yakin ingin menurunkan admin ini?',
    'tree:hostManageAdminNoAdmin': 'Kamu belum punya admin',
    'tree:hostManageAdminUsernameNotFound': 'Username tidak ditemukan',
    'tree:hostManageAdminSuccessInviteSubtitle':
      'Promosi kamu berhasil dikirimkan.',
    'tree:hostManageAdminPeopleSelected': '{{qty}} Orang Dipilih',
    'tree:hostManageCreatorCaution':
      'Kamu hanya dapat mengundang 5 kreator internal dalam satu waktu',
    'tree:hostManageCreatorAddCreator': 'Tambah Kreator Internal',
    'tree:hostManageCreatorAddCreatorErrorAlreadyInAnotherHost':
      'Pengguna sudah didaftarkan sebagai kreator internal oleh host lain.',
    'tree:hostManageCreatorAddCreatorIndependent': 'Tambah Kreator Independen',
    'tree:hostManageCreatorAddCreatorIndependentHeader':
      'Tambah Kreator Independen',
    'tree:hostManageCreatorAddCreatorInternal': 'Tambah Kreator Internal',
    'tree:hostManageCreatorAddCreatorInternalHeader': 'Tambah Kreator Internal',
    'tree:hostManageCreatorDemoteCreator': 'Turunkan Kreator Internal',
    'tree:hostManageCreatorDemoteCreatorContent':
      'Apakah kamu yakin ingin menurunkan kreator internal ini?',
    'tree:hostManageCreatorSuccessInviteSubtitle':
      'Undangan kamu berhasil dikirimkan.',
    'tree:hostManageCreatorNoCreator': 'Kamu belum punya kreator internal',
    'tree:hostNotification': 'Notifikasi Host',
    'tree:hostNotificationEmptyInfo': 'Lihat notifikasi dari host di sini.',
    'tree:hostNotificationAdmin': 'host admin.',
    'tree:hostNotificationAdminInvitation': 'Ingin mempromosikan kamu sebagai ',
    'tree:hostNotificationInvitationConfirmationAccept':
      'Apakah kamu yakin ingin menerima undangan? Kamu hanya dapat menerima satu undangan.',
    'tree:hostNotificationInvitationConfirmationReject':
      'Apakah kamu yakin ingin menolak undangan?',
    'tree:hostNotificationOfficialCreatorIndependent': 'kreator independen.',
    'tree:hostNotificationOfficialCreatorIndependentInvitation':
      'Ingin mempromosikan kamu sebagai ',
    'tree:hostNotificationOfficialCreatorInternal': 'kreator internal.',
    'tree:hostNotificationOfficialCreatorInternalInvitation':
      'Ingin mempromosikan kamu sebagai ',
    'tree:hostProfileAdminCount': 'Admin',
    'tree:hostProfileCreatorCount': 'Kreator',
    'tree:hostProfileSellCount': 'Jual',
    'tree:hostRegistrationFormAccountHolderLabel': 'Nama Pemilik Akun',
    'tree:hostRegistrationFormAccountHolderPlaceholder':
      'Tambahkan Nama Pemilik Akun',
    'tree:hostRegistrationFormAccountNumberLabel': 'Nomor Rekening',
    'tree:hostRegistrationFormAccountNumberPlaceholder':
      'Tambahkan Nomor Rekening',
    'tree:hostRegistrationFormAddressNumberLabel': 'Nomor',
    'tree:hostRegistrationFormAddressNumberPlaceholder':
      'Tambahkan Nomor Alamat Kamu',
    'tree:hostRegistrationFormBankNameLabel': 'Nama Bank',
    'tree:hostRegistrationFormBankNamePlaceholder': 'Tambahkan Nama Bank',
    'tree:hostRegistrationFormBusinessFieldLabel': 'Bidang Usaha',
    'tree:hostRegistrationFormBusinessFieldPlaceholder':
      'Tambahkan Bidang Usaha',
    'tree:hostRegistrationFormCompanyLabel': 'Nama Perusahaan',
    'tree:hostRegistrationFormCompanyPlaceholder': 'PT. Perusahaan Kamu',
    'tree:hostRegistrationFormCountryLabel': 'Negara',
    'tree:hostRegistrationFormCountryPlaceholder': 'Indonesia',
    'tree:hostRegistrationFormDistrictLabel': 'Kecamatan',
    'tree:hostRegistrationFormDistrictPlaceholder': 'Tambahkan Kecamatan Kamu',
    'tree:hostRegistrationFormPostalCodeLabel': 'Kode Pos',
    'tree:hostRegistrationFormPostalCodePlaceholder': 'Tambahkan Kode Pos Kamu',
    'tree:hostRegistrationFormProvinceLabel': 'Provinsi',
    'tree:hostRegistrationFormProvincePlaceholder': 'Tambahkan Provinsi Kamu',
    'tree:hostRegistrationFormRegencyLabel': 'Kabupaten/Kota',
    'tree:hostRegistrationFormRegencyPlaceholder':
      'Tambahkan Kabupaten/Kota Kamu',
    'tree:hostRegistrationFormStreetLabel': 'Jalan',
    'tree:hostRegistrationFormStreetPlaceholder': 'Tambahkan Nama Jalan Kamu',
    'tree:hostRegistrationFormSubDistrictLabel': 'Kelurahan',
    'tree:hostRegistrationFormSubDistrictPlaceholder':
      'Tambahkan Kelurahan Kamu',
    'tree:hostRegistrationFormSubtitle':
      'Isi formulir di bawah ini dengan informasi perusahaan kamu, sehingga kami dapat memverifikasi identitas perusahaan kamu',
    'tree:hostRegistrationFormTitle': 'Formulir pendaftaran',
    'tree:hostRegistrationHeader': 'Registrasi Host',
    'tree:hostRegistrationInvalidAccountHolderMustAlphabet':
      'Nama pemilik akun harus huruf',
    'tree:hostRegistrationInvalidCompanyAlreadyExist':
      'Nama perusahaan sudah digunakan',
    'tree:hostRegistrationInvalidCompanyNameSpecialChar':
      'Nama perusahaan tidak bisa mengandung karakter spesial kecuali (.) titik',
    'tree:hostRegistrationInvalidDocumentSizeErrorMessage':
      'Ukuran file maksimum adalah 5 Mb',
    'tree:hostRegistrationInvalidDocumentTypeErrorMessage':
      'Format file harus .pdf atau .docx',
    'tree:hostRegistrationProcessModalMessage':
      'Harap tunggu sementara kami memeriksa informasi kamu. Kami akan memberi tahu kamu setelah proses selesai.',
    'tree:hostRegistrationProcessModalTitle': 'Terima Kasih!',
    'tree:hostRegistrationSelectLocationButton': 'Pilih Lokasi',
    'tree:hostRegistrationSelectLocationDescription': 'Lokasi terpilih: ',
    'tree:hostRegistrationUploadDocumentButton': 'Unggah Dokumen',
    'tree:hostRegistrationUploadDocumentDescription': 'Dokumen terunggah',
    'tree:hostRegistrationUploadDocumentPlaceholder':
      'Belum ada file yang diunggah',
    'tree:hostRegistrationUploadDocumentSubtitle':
      'File .pdf atau .docx. Ukuran maksimum adalah 5MB',
    'tree:hostRegistrationUploadDocumentTitle': 'Unggah Dokumen Perusahaan',
    'tree:hostValidateMaxCharacter':
      'Jumlah karakter maksimum adalah {{count}}',
    'tree:hostValidateMinCharacter': 'Jumlah karakter minimum adalah {{count}}',
    'tree:independent': 'Independen',
    'tree:internal': 'Internal',
    'tree:invisible': 'Hanya terlihat oleh kamu',
    'tree:leafAMemory': 'Tinggalkan Daun Kenangan',
    'tree:leafAMemoryHeader': 'Meninggalkan Memori',
    'tree:leafCountIncludePrivate': 'Jumlah daun termasuk daun privat.',
    'tree:leafEmpty': 'Belum ada konten.',
    'tree:locPermissionModalContent':
      'Kamu harus mengaktifkan layanan GPS untuk melanjutkan.',
    'tree:location': 'Lokasi Tree',
    'tree:locationPlaceholder': 'Silahkan pilih lokasi',
    'tree:manageFarmer': 'Kelola Petani',
    'tree:manageFarmerInfo':
      'Maksimal petani yang dapat kamu tambahkan adalah 5',
    'tree:manageFarmerEmpty': 'Kamu belum mempunyai petani saat ini',
    'tree:messageError_alreadyRegister':
      'Pengguna sudah terdaftar sebagai kreator internal atau host admin oleh host lain',
    'tree:name': 'Nama Tree',
    'tree:namePlaceholder': 'Masukkan nama tree',
    'tree:nearestFototree': 'FotoTree Terdekat',
    'tree:plantFototree': 'Tanam FotoTree',
    'tree:plantFototreeInvalid':
      'Kamu sudah memiliki FotoTree, tidak dapat menanam lebih dari 1 FotoTree.',
    'tree:plantFototreeInvalid_verified':
      'Untuk menanam FotoTree, harap tingkatkan akun kamu menjadi kreator terverifikasi.',
    'tree:plantFototreeSuccess':
      'Terima kasih telah menanam bibit FotoTree! Harap menunggu hingga akarnya tumbuh.',
    'tree:plantFototreeVideoDuration':
      'Durasi video harus antara 3 detik - 15 detik.',
    'tree:plantFototreeVideoExtension': 'MP4, MPEG4, MOV, HEIV',
    'tree:plantFototreeVideoInvalidFormat':
      'File video tidak valid atau tidak diizinkan. Hanya file dengan ekstensi berikut yang diizinkan: MP4, MPEG4, MOV, HEIV.',
    'tree:plantFototreeVideoMaxSize': 'Ukuran maks 30MB',
    'tree:plantFototreeVideoMaxWidth': 'Lebar maks 1080',
    'tree:postMemory': 'Posting memori kamu',
    'tree:previewQrCode': 'Pratinjau Kode QR',
    'tree:previewQrCodeDownload': 'Unduh Kode QR',
    'tree:previewQrCodeTitle': 'Scan Fotomu di',
    'tree:previewQrCodeDescription': '',
    'tree:previewQrCodeDescription_1': 'Buka kamera kamu.',
    'tree:previewQrCodeDescription_2': 'Arahkan kamera ke QR Code.',
    'tree:previewQrCodeDescription_3':
      'Buka link yang muncul pada ponsel kamu.',
    'tree:previewQrCodeDescription_4': 'Buat akun dan dapatkan fotomu.',
    'tree:profileEmptyBio': 'Belum ada bio.',
    'tree:profileHeader': '#Host',
    'tree:profileManageAdmin': 'Kelola Admin',
    'tree:profileManageButton': 'Kelola',
    'tree:profileManageEditHost': 'Sunting Host',
    'tree:profileManageHostBalance': 'Saldo Host',
    'tree:profileManageOfficialCreator': 'Kelola Kreator Internal',
    'tree:profilePicture': 'Foto Profil Tree',
    'tree:profilePictureEmpty': 'Belum ada file terunggah.',
    'tree:readFurther': 'Baca lebih lanjut',
    'tree:removeFarmer': 'Hapus Petani',
    'tree:removeFarmerDescription': 'Kamu yakin ingin menghapus petani ini?',
    'tree:searchFototree': 'Cari FotoTree',
    'tree:searchFototreeEmptyMessage':
      'Ups, tidak ada FotoTree dengan nama itu, silahkan periksa kata kunci, atau coba lagi dengan kata kunci yang berbeda.',
    'tree:searchFototreeInputPlaceholder': 'Masukkan nama FotoTree',
    'tree:searchTransactionInputPlaceholder': 'Cari Riwayat Transaksi',
    'tree:selected': 'Terpilih ({{count}})',
    'tree:selectedFarmer': '{{count}} Petani Dipilih',
    'tree:shareFototree': 'Bagikan FotoTree',
    'tree:similarFototreeName': 'Nama Pohon Serupa:',
    'tree:tallestTree': 'Pohon Tertinggi',
    'tree:tellYourStory': 'Bagikan ceritamu',
    'tree:treeName': 'Nama Pohon',
    'tree:treeTreatment': 'Perawatan Tree',
    'tree:treeType_community': 'Komunitas',
    'tree:treeType_event': 'Acara',
    'tree:treeType_place': 'Tempat',
    'tree:trimmingLeaf': 'Pangkas Dedaunan',
    'tree:trimmingLeafDescription':
      'Kamu yakin akan pangkas dedaunan ini? Konten yang terhapus tidak dapat dikembalikan',
    'tree:type': 'Jenis Tree',
    'tree:uploadRequirement': 'JPG, JPEG, PNG, HEIC. (Maks. 15MB)',
    'tree:whereToPlantFotoTree': 'Di mana kamu ingin menanam FotoTree kamu?',
    'tree:withdraw': 'Penarikan',
    'tree:withdrawDescription': 'Tarik saldo menuju akun bank kamu',
    'tree:withdrawConfirmationDisclaimer':
      'Penarikan akan di proses dalam jangka waktu 7 hari.',
    'tree:withdrawDisclaimer': 'Kamu hanya dapat menarik 1x/bulan',
    'tree:withdrawMaximumWarning':
      'Kamu telah mencapai batas penarikan. (Penarikan dapat dilakukan 1x setiap bulan)',
    'tree:validationRequired': 'Bagian ini wajib diisi',
    'tree:validationWithdrawField':
      'Harap masukkan jumlah penarikan yang valid',
    'tree:viewLocation': 'Lihat Lokasi',
    'tree:visitedFototree':
      'Kamu telah kunjungi FotoTree <name>{{name}}</name>.',
  },
}
