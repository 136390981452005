import React from 'react'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload} from 'types'
import {getCurrencyValue, parseDate} from 'utils'
import {Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'

export interface GiftShopMessageRoomContentItemNotificationWithdrawProps {
  payload: FirebaseMessageRoomItemPayload['notification_withdraw']
}

export default function GiftShopMessageRoomContentItemNotificationWithdraw({
  payload: {withdraw_amount, withdraw_date, withdraw_id},
}: GiftShopMessageRoomContentItemNotificationWithdrawProps) {
  const {translate} = useTranslation()
  const {username} = useSelector('user')!

  return (
    <Paragraph fontSize="m">
      {translate('messages:notificationWithdraw', {
        username,
        withdraw_id,
        withdraw_amount: getCurrencyValue(withdraw_amount),
        withdraw_date: parseDate(withdraw_date, 'D MMMM YYYY, HH:mm:ss'),
      })}
    </Paragraph>
  )
}
