import React, {useCallback, useMemo, useState} from 'react'
import {
  SERVICE_CANCELLATION_SEARCH_FOTOTREE,
  SERVICE_CANCELLED_RESPONSE,
} from 'consts'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {useTheme} from 'themes'
import {TreeSearchFototreeResponse} from 'types'
import {getBoxShadow, useDebounce, useHistory} from 'utils'
import {Icon, Input, ListLazy, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch} from 'lib/redux'
import {TreeFototreeTemplateListTree} from '../../../template'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(4)};
  flex: 1;
  position: relative;
`

const StyledInput = styled(Input)`
  width: 100%;
`

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  flex-wrap: wrap;
  height: ${convertUnit(400)};
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_1};
  box-shadow: ${({theme}) =>
    getBoxShadow(theme, {
      horizontalOffset: 1,
      verticalOffset: 2,
      blurRadius: 8,
      opacity: 0.15,
    })};
  overflow: hidden;
`

const StyledEmptyContainer = styled(StyledContentContainer)`
  text-align: center;
  padding: ${convertUnit(8)} ${convertUnit(16)};
`

export default function TreeSearchInput() {
  const theme = useTheme()
  const debounce = useDebounce()
  const history = useHistory()
  const {update} = useDispatch()
  const [search, setSearch] = useState('')
  const {translate} = useTranslation()

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('tree_search_fototree', {
        useDefaultMessage: true,
        cancelId: SERVICE_CANCELLATION_SEARCH_FOTOTREE,
        params: {name: search, page, limit},
        actionType: 'fetch',
      })

      if (
        typeof response === 'string' &&
        response === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof response !== 'string' && response.status === 200
        ? response.data.result
        : []
    },
    [search],
  )

  const handleRenderInput = useMemo(
    () => (
      <StyledInput
        onChangeText={(text) => {
          debounce(() => setSearch(text))
        }}
        leftIcon={<Icon type="search" size={16} />}
        placeholder={translate('tree:searchFototreeInputPlaceholder')}
        rightIcon="delete"
        onRightIconClick={() => setSearch('')}
        inputContainerStyle={{
          boxShadow: getBoxShadow(theme, {
            horizontalOffset: 1,
            verticalOffset: 2,
            blurRadius: 8,
            opacity: 0.15,
          }),
        }}
      />
    ),
    [debounce, theme, translate],
  )

  const handleRenderItem = useCallback(
    (item: TreeSearchFototreeResponse) => (
      <TreeFototreeTemplateListTree
        data={item}
        onClick={() => {
          update('lastTreeState', {videoIntro: true})
          history.push(
            'tree_fototree_detail',
            {
              treeId: item.id,
              treeAlias: item.alias,
            },
            item.alias,
          )
        }}
      />
    ),
    [history, update],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <StyledEmptyContainer>
        <Paragraph fontSize="m" color="gray_5">
          {translate('tree:searchFototreeEmptyMessage')}
        </Paragraph>
      </StyledEmptyContainer>
    ),
    [translate],
  )

  const handleRenderContent = useMemo(
    () =>
      search && (
        <StyledContentContainer>
          <ListLazy
            keyExtractor={(item) => item.id}
            loadData={handleLoadData}
            search={search}
            renderItem={handleRenderItem}
            listEmptyElement={handleRenderEmpty}
          />
        </StyledContentContainer>
      ),
    [handleLoadData, handleRenderEmpty, handleRenderItem, search],
  )

  return (
    <StyledContainer>
      {handleRenderInput}
      {handleRenderContent}
    </StyledContainer>
  )
}
