import React, {useCallback, useMemo, useRef, useState} from 'react'
import {UseFormMethods} from 'react-hook-form'
import styled from 'styled-components'
import {
  PAYMENT_CURRENCY_ID_DEFAULT,
  PAYMENT_CURRENCY_NAME_DEFAULT,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData, requestDataPayload} from 'services'
import {
  GiftShopUploadFormData,
  TransactionCurrencyData,
  WindowModeType,
} from 'types'
import {
  getContentURL,
  getGiftshopLocationName,
  showSnackbar,
  useDidMount,
} from 'utils'
import {useWindowMode} from 'windows'
import {
  Button,
  ButtonIcon,
  ConfirmationModal,
  Label,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateDetailContentEditProps} from './GiftShopTemplateDetailContentEditProps'
import {GiftShopTemplateUploadForm} from '../UploadForm'
import {GiftShopTemplateZeroPriceModal} from '../ZeroPriceModal'
import {
  GiftShopLocationModal,
  GiftShopUploadContentItemImage,
} from '../../../upload'
import {GiftShopTemplateHostFeeModal} from '../HostFeeConfirmation'

interface StyledTitleContainerProps {
  mode: WindowModeType
}

const StyledEditContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.white_2};
  padding: ${convertUnit(25)};
  z-index: 1;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_3};
    width: ${convertUnit(25)};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_3};
    border-radius: ${convertUnit(16)};
  }
`

const StyledEditContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  border-radius: ${convertUnit(8)};
  padding-bottom: ${convertUnit(25)};
  background-color: ${({theme}) => theme.white_1};
`

const StyledSectionButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 ${convertUnit(25)};
`

const StyleButtonEditSave = styled(Button)`
  flex: 1;
  box-sizing: border-box;
  margin-left: ${convertUnit(25)};
`

const StyledConfirmationModal = styled(ConfirmationModal)`
  width: ${convertUnit(275)};
`

const StyledTitleContainer = styled.div<StyledTitleContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: ${({mode}) => mode !== 'tablet' && convertUnit(12)};
  margin-left: ${({mode}) => mode === 'tablet' && convertUnit(20)};
`

export default function GiftShopTemplateDetailContentEdit({
  stateEdit,
  stateData,
  onDeleteCreation,
}: GiftShopTemplateDetailContentEditProps) {
  const {translate} = useTranslation()
  const form = useRef<GiftShopUploadFormData>()
  const [currentMap, setCurrentMap] = useState<
    UseFormMethods<GiftShopUploadFormData>
  >()
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = stateEdit
  const [currencyData, setCurrencyData] = useState<TransactionCurrencyData[]>()
  const setData = stateData[1]
  const priceState = useState(0)
  const userPrice = priceState[0]
  const [modal, setModal] = useState(true)
  const mode = useWindowMode()
  const [confirmModal, setConfirmModal] = useState<
    'delete' | 'zero-price' | 'hostfee'
  >()
  const [disabledSave, setDisabledSave] = useState(
    !form.current?.location || form.current.location === '',
  )

  const handleLoadCurrencyData = useCallback(async () => {
    const response = await requestDataPayload('giftshop_get_currency', {
      useDefaultMessage: true,
      actionType: 'fetch',
    })
    typeof response !== 'string' &&
      response?.status === 200 &&
      setCurrencyData(response.data.result)
  }, [])

  const handleToggleConfirmModal = useCallback(
    () => setConfirmModal(undefined),
    [],
  )

  const handleDelete = useCallback(() => {
    onDeleteCreation && onDeleteCreation()
  }, [onDeleteCreation])

  const handleSave = useCallback(() => {
    if (edit && form.current) {
      const {
        description,
        price,
        date,
        time,
        tags,
        currencyId,
        location,
        peopleTag,
      } = form.current

      const tagNicknames = {}
      if (peopleTag) {
        peopleTag.forEach((item) => {
          tagNicknames[item.nickname] = item.id
        })
      }

      if (location !== '') {
        const dateTime = new Date(
          date.concat('T').concat(time).concat(':00.000+07:00'),
        ).toISOString()
        setLoading(true)
        requestData('giftshop_edit_content', {
          actionType: 'execute',
          useDefaultMessage: true,
          data: {
            content_id: edit.content_id,
            currency_id: currencyId,
            nickname: tagNicknames,
            description,
            location,
            original_at: dateTime,
            price: +price,
            tag_ids: tags.map((tag) => tag.id),
            title: edit.title,
          },
          onRequestReceived: () => setLoading(false),
          onRequestSuccess: ({status, data: {result}}) => {
            if (status === 200) {
              setEdit(undefined)
              setData((previous) =>
                previous.map((item) =>
                  item.content_id === edit.content_id
                    ? {
                        ...item,
                        description,
                        tags,
                        location: JSON.parse(location),
                        price: price === '0' ? 0 : +userPrice,
                        original_price: +price,
                        original_at: dateTime,
                        nickname: peopleTag
                          .map((val) => val.nickname)
                          .join(','),
                        tag_nicknames: [
                          ...peopleTag,
                          ...(result?.tag_nicknames || []),
                        ].filter((tag) => tag.id !== null),
                      }
                    : item,
                ),
              )
              showSnackbar(
                translate('giftShop:creationSingleEditMessageSuccess'),
              )
            }
          },
        })
      }
    }
  }, [edit, setData, setEdit, translate, userPrice])

  const handleCheckZeroPriceAndHostFee = useCallback(() => {
    if (!!form.current?.price && parseInt(form.current?.price, 10) === 0) {
      setConfirmModal('zero-price')
      return
    }
    if (!!form.current?.tags[0] && form.current?.tags[0].host_fee) {
      setConfirmModal('hostfee')
      return
    }

    handleSave()
  }, [handleSave])

  const handleRenderTitle = useMemo(
    () => (
      <StyledTitleContainer mode={mode}>
        <Label
          containerStyle={{width: 'inherit'}}
          label={translate('giftShop:uploadTitleLabel')}>
          <Paragraph fontSize="m" color="gray_4">
            {edit?.title}
          </Paragraph>
        </Label>
      </StyledTitleContainer>
    ),
    [edit?.title, mode, translate],
  )

  const handleRenderContentForm = useMemo(
    () =>
      edit &&
      typeof edit.original_at === 'string' && (
        <GiftShopTemplateUploadForm
          isEdit
          defaultValues={{
            id: edit.content_id,
            peopleTag: edit.tag_nicknames,
            title: edit.title,
            tags: edit.tags,
            description: edit.description,
            price: edit.original_price.toString(),
            currencyId: edit.currency?.id ?? PAYMENT_CURRENCY_ID_DEFAULT,
            currencyName:
              edit.currency?.currency ?? PAYMENT_CURRENCY_NAME_DEFAULT,
            location: getGiftshopLocationName({
              latitude: edit.location?.latitude.toString(),
              longitude: edit.location?.longitude.toString(),
              name: edit.location?.name,
            }),
            originalAt: edit.original_at,
            size: edit.size,
            width: 0,
            height: 0,
          }}
          showDeleteButton={false}
          currencyData={currencyData}
          priceState={priceState}
          onAddLocation={setCurrentMap}
          onFormUpdate={(values) => {
            form.current = values
            setDisabledSave(values.location === '' || !values.location)
          }}
          uploadType="single"
          quickFill={{
            description: true,
            location: true,
            price: true,
          }}
          renderImage={
            <GiftShopUploadContentItemImage
              id={edit.content_id}
              src={getContentURL({url: edit.url, type: edit.type})}
              height={edit.resolution.height}
              width={edit.resolution.width}
              size={edit.size}
              renderFooter={handleRenderTitle}
            />
          }
          toggleModal={() => setModal(true)}
        />
      ),
    [currencyData, edit, handleRenderTitle, priceState],
  )

  const handleRenderLocation = useMemo(
    () => (
      <GiftShopLocationModal
        mapForm={currentMap}
        showField="giftshop"
        toggleModal={() => setModal((prev) => !prev)}
        visible={modal}
        onSave={() => setCurrentMap(undefined)}
      />
    ),
    [currentMap, modal],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledEditContainer>
        <StyledEditContentContainer>
          {handleRenderContentForm}
          <StyledSectionButton>
            <ButtonIcon
              iconType="trash"
              iconColor="gray_3"
              hasShadow={false}
              backgroundColor="white_1"
              backgroundHoverColor="white_1"
              onClick={() => setConfirmModal('delete')}
            />
            <StyleButtonEditSave
              label={translate('global:save')}
              isLoading={loading}
              onClick={handleCheckZeroPriceAndHostFee}
              disabled={disabledSave}
            />
          </StyledSectionButton>
        </StyledEditContentContainer>
      </StyledEditContainer>
    ),
    [
      handleRenderContentForm,
      translate,
      loading,
      handleCheckZeroPriceAndHostFee,
      disabledSave,
    ],
  )

  const handleRenderDeleteConfirmationModal = useMemo(
    () => (
      <StyledConfirmationModal
        visible
        title={translate('giftShop:uploadDeleteContent')}
        text={translate('giftShop:contentDetailEditDeleteDescription')}
        onConfirm={handleDelete}
        toggleModal={handleToggleConfirmModal}
        onBackdropPress={handleToggleConfirmModal}
      />
    ),
    [handleDelete, handleToggleConfirmModal, translate],
  )

  const handleRenderZeroPriceModal = useMemo(
    () => (
      <GiftShopTemplateZeroPriceModal
        visible
        onConfirm={handleSave}
        toggleModal={handleToggleConfirmModal}
      />
    ),
    [handleSave, handleToggleConfirmModal],
  )

  const handleRenderHostFeeConfirmationModal = useMemo(
    () => (
      <GiftShopTemplateHostFeeModal
        visible
        onConfirm={() => handleSave()}
        toggleModal={handleToggleConfirmModal}
      />
    ),
    [handleSave, handleToggleConfirmModal],
  )

  const handleRenderConfirmModal = useMemo(() => {
    switch (confirmModal) {
      case 'delete':
        return handleRenderDeleteConfirmationModal
      case 'hostfee':
        return handleRenderHostFeeConfirmationModal
      case 'zero-price':
        return handleRenderZeroPriceModal
    }
    return <></>
  }, [
    confirmModal,
    handleRenderDeleteConfirmationModal,
    handleRenderHostFeeConfirmationModal,
    handleRenderZeroPriceModal,
  ])

  useDidMount(() => {
    handleLoadCurrencyData()
  })

  return edit ? (
    <>
      {handleRenderConfirmModal}
      {handleRenderLocation}
      {handleRenderContent}
    </>
  ) : null
}
