import React from 'react'
import {translate} from 'i18n'
import {GiftShopRootScreen} from '../../../gift-shop'
import {ForkygramSearchFriends} from '../containers'

export default function ForkygramSearchFriendsScreen() {
  return (
    <GiftShopRootScreen 
      headerTitle={translate('forkygram:searchFriends')}
      hideBottomNavbar
      showBadgeIcon={false}
      onBackButtonClicked={() => history.go(-1)}>
        <ForkygramSearchFriends />
    </GiftShopRootScreen>
  )
}
