import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {Button, Icon, Input, Modal, Paragraph} from 'common/components'
import {REGEX_NUMBER} from 'consts'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {requestData} from 'services'
import {TreeCreatorLiveDuration} from 'types'
import {formatHours} from 'utils'
import {FototreeMapCreatorCameraLiveModalProps} from './FototreeMapCreatorProps'

const StyledModal = styled(Modal)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  max-width: ${convertUnit(335)};
  row-gap: ${convertUnit(20)};
  padding: ${convertUnit(20)};
`

const StyledInputWrapper = styled.div`
  display: flex;
  text-align: left;
  gap: ${convertUnit(20)};
`

const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${convertUnit(20)};
`

const StyledError = styled(Paragraph)`
  margin-top: ${convertUnit(-10)};
`

export default function FototreeMapCreatorCameraLiveModal({
  stateCreatorLiveExpired,
  visible,
  location,
  creatorLiveCountdown,
  isCreatorLive,
  toggleModal,
}: FototreeMapCreatorCameraLiveModalProps) {
  const {translate} = useTranslation()
  const {hours, minutes, seconds} = creatorLiveCountdown
  const [expiredAt, setExpiredAt] = stateCreatorLiveExpired
  const [duration, setDuration] = useState<TreeCreatorLiveDuration>({
    minute: '',
    hour: '',
  })
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const isDisabled = useMemo(() => {
    const {hour, minute} = duration

    const isDurationEmpty = hour === '' && minute === ''
    const isHourError = hour !== '' && error
    const isMinuteError = minute !== '' && error

    return isCreatorLive
      ? !expiredAt || loading
      : isDurationEmpty || isHourError || isMinuteError || loading
  }, [duration, error, expiredAt, isCreatorLive, loading])

  const handleStartLive = useCallback(() => {
    const {minute, hour} = duration
    setLoading(true)
    requestData('giftshop_post_live_location', {
      useDefaultMessage: true,
      data: {
        duration: hour ? `${hour}h${minute || 0}m` : `${minute}m`,
        lat: location.latitude!,
        long: location.longitude!,
      },
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status, data}) => {
        if (status === 201) {
          setExpiredAt(new Date(data.result.expired_at).getTime())
          setDuration({minute: '', hour: ''})
        }
      },
    })
  }, [duration, location.latitude, location.longitude, setExpiredAt])

  const handleStopLive = useCallback(() => {
    setLoading(true)
    requestData('giftshop_delete_live_location', {
      useDefaultMessage: true,
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status}) => {
        if (status === 200) setExpiredAt(null)
      },
    })
  }, [setExpiredAt])

  const handleRenderHeader = useMemo(
    () => (
      <div>
        <Paragraph fontSize="l" fontWeight="bold" lineHeight={31}>
          {translate('tree:creatorViewCameraLive')}
        </Paragraph>
        <Paragraph fontSize="m" fontWeight="medium" lineHeight={26}>
          {translate('tree:creatorViewCameraLiveDescription')}
        </Paragraph>
      </div>
    ),
    [translate],
  )

  const handleRenderInput = useMemo(
    () => (
      <>
        <StyledInputWrapper>
          <Input
            type="text"
            value={duration.hour}
            label={translate('global:hour')}
            placeholder="00"
            allowedCharacters={REGEX_NUMBER}
            autoComplete={false}
            maxLength={2}
            containerStyle={{
              width: '50%',
              display: 'block',
            }}
            rightIcon="delete"
            onRightIconClick={() => {
              setDuration({...duration, hour: ''})
              setError(false)
            }}
            onChangeText={(text) => {
              setError(false)
              setDuration({...duration, hour: text})
              if (text.length > 1 && text.startsWith('0')) {
                setDuration({...duration, hour: text.slice(1)})
              }
              if (parseInt(text, 10) > 24) {
                setDuration({...duration, hour: '24'})
              }
            }}
          />
          <Input
            type="text"
            label={translate('global:minute')}
            value={duration.minute}
            placeholder="00"
            allowedCharacters={REGEX_NUMBER}
            maxLength={2}
            autoComplete={false}
            containerStyle={{
              width: '50%',
              display: 'block',
            }}
            rightIcon="delete"
            onRightIconClick={() => {
              setDuration({...duration, minute: ''})
              setError(false)
            }}
            onChangeText={(text) => {
              setError(false)
              setDuration({...duration, minute: text})
              if (text.length > 1 && text.startsWith('0')) {
                setDuration({...duration, minute: text.slice(1)})
              }
              if (parseInt(text, 10) > 59) {
                setDuration({...duration, minute: '59'})
              }
            }}
          />
        </StyledInputWrapper>
        {error && (
          <StyledError fontWeight="medium" color="danger_5">
            {translate('tree:creatorViewCameraLiveInputError')}
          </StyledError>
        )}
      </>
    ),
    [duration, error, translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButtonWrapper>
        <Button
          label={translate(isCreatorLive ? 'global:stop' : 'global:start')}
          leftIcon={
            <Icon
              size={convertUnit(16)}
              type={isCreatorLive ? 'stop' : 'play'}
              color={isDisabled ? 'gray_3' : 'white_1'}
            />
          }
          disabled={isDisabled}
          onClick={isCreatorLive ? handleStopLive : handleStartLive}
        />
        <Button
          label={translate('global:close')}
          backgroundColor="white_3"
          color="primary_5"
          onClick={toggleModal}
        />
      </StyledButtonWrapper>
    ),
    [
      handleStartLive,
      handleStopLive,
      isCreatorLive,
      isDisabled,
      toggleModal,
      translate,
    ],
  )

  useEffect(() => {
    const {hour, minute} = duration
    if (parseInt(hour, 10) === 24 && parseInt(minute, 10) > 0) {
      setError(true)
    }
  }, [duration])

  return (
    <StyledModal visible={visible}>
      {handleRenderHeader}
      {isCreatorLive ? (
        <Paragraph fontSize="xxl" lineHeight={53}>
          {formatHours(hours, minutes, seconds).replace(/:/g, ' : ')}
        </Paragraph>
      ) : (
        handleRenderInput
      )}
      {handleRenderButton}
    </StyledModal>
  )
}
