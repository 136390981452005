import React, {useCallback, useMemo, useRef, useState} from 'react'
import {translate} from 'i18n'
import {requestData} from 'services'
import {LIST_LOAD_LIMIT} from 'consts'
import {TreeFotoTreeLeafFootprintTree} from 'types'
import {useHistory, useLocation} from 'utils'
import {useWindowMode} from 'windows'
import {ListLazy} from 'common/components'
import {GiftShopRootScreen} from 'pages/gift-shop'
import {
  ForkygramListGridItem,
  ForkygramTemplateListGridContent,
} from '../containers'

export default function ForkygramFootprintDetailScreen() {
  const mode = useWindowMode()
  const history = useHistory()
  const {memberId, treeId, treeName, treeAlias} = useLocation(
    'forkygram_footprint_detail',
  ).state
  const lastPage = useRef(1)
  const stateData = useState<ReadonlyArray<TreeFotoTreeLeafFootprintTree>>([])
  const data = stateData[0]
  const [count, setCount] = useState(0)
  const column = mode === 'website' ? 5 : 4

  const handleNavigateFotoTreeDetail = useCallback(
    (item: TreeFotoTreeLeafFootprintTree) => {
      const index = data.findIndex((post) => post.post?.id === item.post?.id)
      lastPage.current = Math.floor(index / LIST_LOAD_LIMIT) + 1
      history.push(
        'tree_fototree_feed',
        {
          data: [],
          dataTag: {
            id: '',
            alias: item.alias,
            name: item.tree_name,
            tree_id: item.tree_id,
          },
          selectedItemId: item.post?.id,
          memberId,
          isPastMemory: true,
          initialPage: lastPage.current,
        },
        item.alias,
      )
    },
    [data, history, memberId],
  )

  const handleClickHeader = useCallback(() => {
    history.push('tree_fototree_detail', {treeId, treeAlias}, treeAlias)
  }, [history, treeAlias, treeId])

  const handleRenderItem = useCallback(
    (item: TreeFotoTreeLeafFootprintTree) => (
      <ForkygramListGridItem
        borderColor="black"
        column={column}
        data={{
          contentType: item.post?.content_type || 'photo',
          url: item.url,
          thumbnail: item.url,
          isHidden: item.is_hidden,
        }}
        onClickItem={() => handleNavigateFotoTreeDetail(item)}
      />
    ),
    [column, handleNavigateFotoTreeDetail],
  )

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('tree_get_leaf_footprints', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {page, limit, member_id: memberId, tree_id: treeId},
        onRequestSuccess: ({status, data: {result}}) => {
          if (status === 200) {
            setCount(result.count)
          }
        },
      })
      return typeof response !== 'string' && response.status === 200
        ? response.data.result.footprints
        : []
    },
    [memberId, treeId],
  )

  const handleRenderContent = useMemo(
    () => (
      <ForkygramTemplateListGridContent
        innerMargin={0}
        header={false}
        backgroundColor="black"
        containerHeight="100%">
        <ListLazy
          renderItem={handleRenderItem}
          stateData={stateData}
          loadData={handleLoadData}
          keyExtractor={(item: TreeFotoTreeLeafFootprintTree) => item.url}
          numColumns={column}
          scrollbar={false}
        />
      </ForkygramTemplateListGridContent>
    ),
    [column, handleLoadData, handleRenderItem, stateData],
  )
  return (
    <GiftShopRootScreen
      showBackIcon
      showBadgeIcon={false}
      darkBar
      darkHeader
      headerTitle={treeName}
      headerSubtitle={translate('tree:fototreeFootprintsLeafCount', {count})}
      onClickHeader={handleClickHeader}>
      {handleRenderContent}
    </GiftShopRootScreen>
  )
}
