import React, {useMemo} from 'react'
import styled from 'styled-components'
import {Button, Image, Modal, Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {IMAGE_ASSET} from 'consts'
import {getFontFamily} from 'utils'
import {parseShape} from 'types'
import {TreeCongratulationModalProps} from './TreeFototreeModalProps'

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: ${convertUnit(335)};
  row-gap: ${convertUnit(12)};
  padding: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(8)};
`

const StyledBold = styled.span`
  font-family: ${getFontFamily('medium')};
`

export default function TreeCongratulationModal({
  footprintCount,
  treeName,
  onClose,
}: TreeCongratulationModalProps) {
  const {translate} = useTranslation()

  const patterns: parseShape[] = useMemo(
    () => [
      {
        pattern: /<name>.*?<\/name>/g,
        style: {fontFamily: getFontFamily('medium')},
        renderText: (text: string) => text.replace(/<name>|<\/name>/g, ''),
      },
    ],
    [],
  )

  return (
    <StyledModal visible>
      <Paragraph fontSize="xl" fontWeight="bold" lineHeight={42}>
        {translate('global:congratulations')}
      </Paragraph>
      <Paragraph fontSize="l" lineHeight={31}>
        <ParsedText parse={patterns}>
          {translate('tree:visitedFototree', {
            name: treeName,
          })}
        </ParsedText>
      </Paragraph>
      <Image
        alt="tree-congratulations"
        src={IMAGE_ASSET('giftshop', 'tree-square.png')}
        width={268}
        height={254}
      />
      <Paragraph fontSize="m" color="fotoyu_logo_1">
        {translate('tree:fototreeFootprints')} :{' '}
        <StyledBold>{footprintCount}</StyledBold>
      </Paragraph>
      <StyledButton label={translate('global:ok')} onClick={onClose} />
    </StyledModal>
  )
}
