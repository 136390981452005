import React from 'react'
import {useTranslation} from 'i18n'
import {Paragraph} from 'common/components'
import {GiftShopRobopetActivationTemplate} from '../ActivationTemplate'

export default function GiftShopRobopetActivationVerified() {
  const {translate} = useTranslation()

  return (
    <GiftShopRobopetActivationTemplate
      fullWidth
      backgroundColor="primary_5"
      robopetImg="robopet-fly.png"
      title={translate('giftShop:roboyuActivationVerifiedHeader')}
      titleColor="white_1">
      <Paragraph fontSize="m" fontWeight="regular" color="white_1">
        {translate('giftShop:roboyuActivationVerifiedDescription')}
      </Paragraph>
    </GiftShopRobopetActivationTemplate>
  )
}
