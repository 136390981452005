import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {Icon} from '../Icon'
import {RadioItemProps} from './RadioItemProps'
import {Paragraph} from '../Paragraph'

interface StyledIconContainerProps extends RadioItemProps {
  size: number
  hover: boolean
}

const StyledParagraph = styled(Paragraph)`
  ${({theme}) => ({
    ':hover': {color: theme.black},
  })}
  transition: color 0.15s ease-out;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
`

const StyledIconContainer = styled.div<StyledIconContainerProps>`
  ${({isSelected, size, theme, hover}) => ({
    backgroundColor: isSelected ? theme.primary_5 : theme.white_1,
    width: convertUnit(size),
    height: convertUnit(size),
    ...(!isSelected && {
      borderStyle: 'solid',
      borderWidth: convertUnit(1),
      borderColor: hover ? theme.gray_5 : theme.gray_3,
    }),
  })}
  display: flex;
  box-sizing: border-box;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease-out;
  cursor: pointer;
`

const StyledDescriptionContainer = styled.div`
  flex: 1;
  padding: 0 ${convertUnit(8)};
  user-select: none;
  cursor: pointer;
`

export default function RadioItem({
  isSelected,
  size = 24,
  containerStyle,
  description,
  fontSize,
  fontWeight,
  color,
  onClick,
  onMouseEnter,
  onMouseLeave,
  ...props
}: RadioItemProps) {
  const [hover, setHover] = useState(false)

  const handleMouseEnter = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onMouseEnter && onMouseEnter(event)
      setHover(true)
    },
    [onMouseEnter],
  )

  const handleMouseLeave = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onMouseLeave && onMouseLeave(event)
      setHover(false)
    },
    [onMouseLeave],
  )

  const handleRenderIcon = useMemo(
    () =>
      isSelected && <Icon size={(size * 2) / 3} type="check" color="white_1" />,
    [isSelected, size],
  )

  const handleRenderDescription = useMemo(
    () =>
      description ? (
        <StyledDescriptionContainer>
          {typeof description === 'string' ? (
            <StyledParagraph
              color={color || 'gray_5'}
              fontWeight={fontWeight || 'regular'}
              fontSize={fontSize || 's'}>
              {description}
            </StyledParagraph>
          ) : (
            description
          )}
        </StyledDescriptionContainer>
      ) : null,
    [description, color, fontSize, fontWeight],
  )

  return (
    <StyledContainer
      data-testid="radioItem"
      style={containerStyle}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <StyledIconContainer
        {...props}
        size={size}
        isSelected={isSelected}
        hover={hover}>
        {handleRenderIcon}
      </StyledIconContainer>
      {handleRenderDescription}
    </StyledContainer>
  )
}
