import React from 'react'
import {AuthTemplateScreen} from '../../template'
import {AuthLoginForm} from '../containers'

export default function AuthLoginScreen() {
  return (
    <AuthTemplateScreen>
      <AuthLoginForm />
    </AuthTemplateScreen>
  )
}
