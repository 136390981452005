import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_HEADER_HEIGHT, GIFT_SHOP_ROOT_HEADER_PADDING} from 'consts'
import {translate} from 'i18n'
import {requestData} from 'services'
import {FirebaseMessageRoomType, WindowModeType} from 'types'
import {
  useFirebaseMessageRoomMember,
  useFirebaseUser,
  useHistory,
  useLocation,
} from 'utils'
import {useWindowMode} from 'windows'
import {
  Avatar,
  ConfirmationModal,
  Icon,
  IconVerifiedBadge,
  ListItemSelect,
  Menu,
  Paragraph,
} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledSectionHeader = styled.div`
  ${({theme}) => ({borderColor: theme.gray_1})}
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: ${convertUnit(GIFT_SHOP_HEADER_HEIGHT)};
  width: 100%;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: ${convertUnit(1)};
`
const StyledUsername = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`
const StyledIconContainer = styled.div<StyledContainerProps>`
  padding: ${({mode}) =>
    mode === 'website' ? convertUnit(12) : convertUnit(20)};
  margin-right: ${convertUnit(GIFT_SHOP_ROOT_HEADER_PADDING / 2)};
  cursor: pointer;
`
const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: ${convertUnit(8)};
`
const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  margin-right: ${convertUnit(4)};
  line-height: ${convertUnit(16)};
  margin-left: ${convertUnit(4)};
`
const StyledMenu = styled(Menu)`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
`
const StyledMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledAvatar = styled(Avatar)`
  cursor: pointer;
`

export interface GiftShopMessageRoomHeaderGiftshopProps {
  participantId: string
  roomId: string
  roomType?: FirebaseMessageRoomType
  onBackButtonClicked?(): void
}
export default function GiftShopMessageRoomHeaderGiftshop({
  participantId,
  roomId,
  onBackButtonClicked,
  roomType = 'giftshop',
}: GiftShopMessageRoomHeaderGiftshopProps) {
  const mode = useWindowMode()
  const history = useHistory()
  const user = useSelector('user')!
  const {roomName = '-', roomPhoto = ''} = useLocation(
    'giftshop_message_room',
  ).state
  const stateToggle = useState(false)
  const setToggle = stateToggle[1]
  const [modalVisible, setModalVisible] = useState(false)
  const {members} = useFirebaseMessageRoomMember({roomId, roomType})
  const {data} = useFirebaseUser({id: participantId}) || {}
  const {photo = roomPhoto, username = roomName, nickname} = data || {}
  const role = useMemo(
    () => members.find((item) => item.id === participantId)?.role,
    [members, participantId],
  )
  const giftshopName =
    (role && role === 'creator' ? username : nickname) || roomName
  const participantName = useMemo(
    () => (roomType === 'chat' ? username : giftshopName),
    [giftshopName, roomType, username],
  )
  const userAlias = useMemo(
    () =>
      role ? (role === 'creator' ? user.nickname : user.username) : undefined,
    [role, user.nickname, user.username],
  )

  const handleDeleteRoom = useCallback(
    () =>
      roomType === 'chat'
        ? requestData('forkygram_dm_delete_room', {
            params: {id: roomId},
            onRequestSuccess: () => history.goBack(),
          })
        : requestData('giftshop_dm_delete_room', {
            params: {id: roomId},
            onRequestSuccess: () => history.goBack(),
          }),
    [history, roomId, roomType],
  )

  const handleRedirectUser = useCallback(() => {
    history.push('giftshop_profile', {self: false}, username)
  }, [history, username])

  const handleRenderDeleteModal = useMemo(
    () => (
      <ConfirmationModal
        visible={modalVisible}
        title={translate('global:delete')}
        text={translate('messages:deleteMessageConfirmation')}
        onConfirm={handleDeleteRoom}
        toggleModal={() => setModalVisible(false)}
        cancelText={translate('global:cancel')}
        confirmText={translate('global:yesImSure')}
      />
    ),
    [handleDeleteRoom, modalVisible],
  )

  const handleRenderBadge = useMemo(
    () =>
      data &&
      data?.creator_status === 'verified' && <StyledIconVerifiedBadge />,
    [data],
  )

  const handleRenderMenu = useMemo(
    () => (
      <StyledMenuContainer>
        <ListItemSelect
          title={translate('messages:deleteMessage')}
          onClick={() => {
            setToggle(false)
            setModalVisible(true)
          }}
        />
      </StyledMenuContainer>
    ),
    [setToggle],
  )

  return (
    <StyledSectionHeader>
      {handleRenderDeleteModal}
      <StyledIconContainer
        mode={mode}
        onClick={onBackButtonClicked || history.goBack}>
        <Icon
          type="back"
          color="primary_5"
          size={mode === 'mobile' ? convertUnit(20) : convertUnit(24)}
        />
      </StyledIconContainer>
      <StyledAvatar
        src={photo}
        alt={participantName}
        onClick={handleRedirectUser}
      />
      <StyledHeader>
        <StyledUsername onClick={handleRedirectUser}>
          <Paragraph fontSize="l" fontWeight="bold">
            {participantName}
          </Paragraph>
          {handleRenderBadge}
        </StyledUsername>
        <Paragraph color="gray_3">
          {translate('global:with')} <strong>{userAlias}</strong>
        </Paragraph>
      </StyledHeader>
      <StyledMenu
        stateToggle={stateToggle}
        renderContent={handleRenderMenu}
        contentContainerStyle={{
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          width: '100%',
          minWidth: convertUnit(200),
          right: 25,
          borderRadius: convertUnit(8),
        }}>
        <StyledIconContainer mode={mode}>
          <Icon
            type="ellipsis-v"
            color="primary_5"
            size={mode === 'mobile' ? convertUnit(20) : convertUnit(24)}
            onClick={() => setToggle((prev) => !prev)}
          />
        </StyledIconContainer>
      </StyledMenu>
    </StyledSectionHeader>
  )
}
