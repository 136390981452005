import React from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import convertUnit from 'lib/unit'
import {Image, Paragraph} from 'common/components'
import {getFileSize} from 'utils'
import {WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import {GiftShopUploadContentItemImageProps} from './GiftShopUploadContentItemImageProps'

interface StyledImageSectionProps {
  mode: WindowModeType
  hasFooter: boolean
}

interface StyledImageContainerProps {
  mode: WindowModeType
}

const StyledImageSection = styled.div<StyledImageSectionProps>`
  display: flex;
  width: ${({mode}) => (mode === 'website' ? '20%' : undefined)};
  flex-direction: ${({mode}) => (mode === 'tablet' ? 'row' : 'column')};
  justify-content: ${({mode, hasFooter}) =>
    mode === 'tablet' && !hasFooter ? 'center' : undefined};
`

const StyledContentImageContainer = styled.div`
  width: ${convertUnit(230)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledImageContainer = styled.div<StyledImageContainerProps>`
  ${({mode}) => ({
    margin: mode === 'website' ? `${convertUnit(10)} 0px` : undefined,
    marginBottom: convertUnit(10),
  })}
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  justify-content: space-evenly;
`

const StyledImage = styled(Image)`
  box-sizing: border-box;
  width: 100%;
  object-fit: cover;
  border-radius: ${convertUnit(8)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    display: flex;
    justify-content: center;
  }
`

export default function GiftShopUploadContentItemImage({
  id,
  src,
  width,
  height,
  size,
  renderHeader,
  renderFooter,
}: GiftShopUploadContentItemImageProps) {
  const mode = useWindowMode()

  return (
    <StyledImageSection mode={mode} hasFooter={renderFooter !== undefined}>
      {renderHeader}
      <StyledContentImageContainer>
        <StyledImageContainer mode={mode}>
          <StyledImage alt={id} src={src} />
        </StyledImageContainer>
        <Paragraph fontSize="m" fontWeight="medium" color="gray_3">
          {width} x {height}, {getFileSize(size)}
        </Paragraph>
      </StyledContentImageContainer>
      {renderFooter}
    </StyledImageSection>
  )
}
