import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_UPLOAD_MAX_PRICE,
  GIFT_SHOP_UPLOAD_MIN_PRICE,
  GIFT_SHOP_UPLOAD_YUSER_MAX_PRICE,
  REGEX_NUMBER,
} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopUploadPriceLimitModal} from 'pages'
import {TransactionCurrencyData} from 'types'
import {useHistory} from 'utils'
import {
  DropdownItem,
  Input,
  Label,
  List,
  ListItemSelect,
  Menu,
  Paragraph,
} from 'common/components'
import {VALIDATION_GIFTSHOP_UPLOAD_REQUIRED_PRICE} from 'common/validations'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {GiftShopTemplateUploadFormPriceProps} from './GiftShopTemplateUploadFormPriceProps'

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  margin-top: ${convertUnit(25)};
`

const StyledCurrencyDropdownContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
`

const StyledError = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${convertUnit(4)};
  gap: ${convertUnit(4)};
  margin-bottom: ${convertUnit(20)};
`

export default function GiftShopTemplateUploadFormPrice({
  currencyName,
  form,
  stateInputPrice,
  currencyData,
}: GiftShopTemplateUploadFormPriceProps) {
  const history = useHistory()
  const {creatorStatus, kycStatus} = useSelector('yuserActivationState')
  const {translate} = useTranslation()
  const {watch, setValue} = form
  const {price} = watch()
  const [inputPrice, setInputPrice] = stateInputPrice
  const currencyStateToggle = useState(false)
  const {maximumPrice, minimumPrice} = useSelector('hostState')
  const [toggle, setToggle] = currencyStateToggle
  const [modal, setModal] = useState(false)

  const notVerifCreator =
    (kycStatus === 'verified' || kycStatus === 'verified_check') &&
    creatorStatus !== 'verified'

  const maximum_price = notVerifCreator
    ? Infinity
    : maximumPrice === 0
    ? 0
    : maximumPrice || GIFT_SHOP_UPLOAD_MAX_PRICE
  const minimum_price = minimumPrice || GIFT_SHOP_UPLOAD_MIN_PRICE

  const handleSelectCurrency = useCallback(
    (item: TransactionCurrencyData) => {
      setValue('currencyName', item.currency)
      setValue('currencyId', item.id)
    },
    [setValue],
  )

  const handleRenderCurrencyDropdownItem = useCallback(
    (item: TransactionCurrencyData) => (
      <ListItemSelect
        title={item.currency}
        onClick={() => handleSelectCurrency(item)}
      />
    ),
    [handleSelectCurrency],
  )

  const handleRenderPriceLimitModal = useMemo(
    () => (
      <GiftShopUploadPriceLimitModal
        visible={modal}
        isUpload
        toggleModal={() => setModal((prev) => !prev)}
        continueAction={() => history.push('giftshop_upgrade_creator', {})}
      />
    ),
    [history, modal],
  )

  const handleRenderPriceLimitError = useMemo(
    () =>
      notVerifCreator &&
      parseInt(price, 10) > GIFT_SHOP_UPLOAD_YUSER_MAX_PRICE ? (
        <StyledError>
          <Paragraph fontSize="s" fontWeight="bold" color="danger_5">
            {translate('giftShop:creatorPriceLimitErrorMessage')}
          </Paragraph>
          <Paragraph
            fontSize="s"
            fontWeight="bold"
            color="primary_5"
            className="pressable"
            onClick={() => setModal((prev) => !prev)}>
            {translate('giftShop:creatorPriceLimitErrorMessageContinue')}
          </Paragraph>
        </StyledError>
      ) : (
        <></>
      ),
    [notVerifCreator, price, translate],
  )

  useEffect(() => {
    if (price.toString().startsWith('0')) {
      setValue('price', '0')
      setInputPrice('0')
    }
    if (
      price.length >= 2 &&
      price.toString().charAt(0) === '0' &&
      price.toString().charAt(1) !== '0'
    ) {
      setValue('price', price.toString().charAt(1))
      setInputPrice(price.toString().charAt(1))
    }
    if (inputPrice === '') {
      setInputPrice('0')
    }
  }, [inputPrice, price, setInputPrice, setValue])

  return (
    <>
      <StyledPriceContainer>
        {handleRenderPriceLimitModal}
        <Label
          label={translate('global:currency')}
          containerStyle={{height: convertUnit(27.4)}}>
          <StyledCurrencyDropdownContainer>
            <Menu
              stateToggle={currencyStateToggle}
              contentContainerStyle={{
                height: convertUnit(96),
                borderRadius: convertUnit(8),
              }}
              renderContent={
                <List
                  data={currencyData}
                  keyExtractor={(item) => item.id}
                  renderItem={handleRenderCurrencyDropdownItem}
                />
              }>
              <DropdownItem
                label={currencyName}
                toggle={toggle}
                onClick={(e) => {
                  e.preventDefault()
                  setToggle((previous) => !previous)
                }}
              />
            </Menu>
          </StyledCurrencyDropdownContainer>
        </Label>
        <Input
          name="price"
          type="number"
          min={minimum_price}
          max={maximum_price}
          defaultValue={price}
          form={form}
          formRules={VALIDATION_GIFTSHOP_UPLOAD_REQUIRED_PRICE(
            minimum_price,
            maximum_price,
          )}
          label={translate('global:basicPrice')}
          allowedCharacters={REGEX_NUMBER}
          autoComplete={false}
          placeholder={translate('giftShop:uploadInputPricePlaceholder')}
          containerStyle={{
            marginLeft: '25px',
            flex: 1,
          }}
          rightIcon="delete"
          onRightIconClick={() => {
            setInputPrice('0')
          }}
          onChangeText={setInputPrice}
        />
      </StyledPriceContainer>
      {handleRenderPriceLimitError}
    </>
  )
}
