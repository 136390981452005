import React, {CSSProperties, useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {AuthTemplateScreen, TemplateAuthAccessResendOtpModal} from 'pages'
import {requestData} from 'services'
import {maskPhone, showSnackbar, useHistory, useTimer} from 'utils'
import {IMAGE_ASSET, OTP_COOLDOWN} from 'consts'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'
import {
  Paragraph,
  Image,
  Button,
  Input,
  Icon,
} from 'common/components'
import {AuthPhoneResendMethod} from 'types'
import {AuthVerificationFormProps} from './AuthVerificationFormProps'

interface StyledResendProps {
  cursor: CSSProperties['cursor']
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
`

const StyledContentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 0 ${convertUnit(20)};
`

const StyledHeaderDescription = styled(Paragraph)`
  margin-top: ${convertUnit(10)};
  text-align: center;
`

const StyledResend = styled(Paragraph)<StyledResendProps>`
  display: flex;
  cursor: ${({cursor}) => cursor};
`

const StyledExpired = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: ${convertUnit(4)};
`

const StyledImgContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${convertUnit(20)} 0;
`

const StyledIllustration = styled(Image)`
  max-width: ${convertUnit(325)};
  width: 100%;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(25)};
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledIconContainer = styled.div`
  padding: ${convertUnit(20)} 0;
  display: flex;
  align-items: center;
`

const StyledBackIconCursor = styled.div`
  cursor: pointer;
  width: fit-content;
`

const StyledSectionResend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${convertUnit(5)};
  margin-top: ${convertUnit(10)};
`

export default function AuthVerificationForm({
  isExpired,
  phoneCode,
  phoneNumber,
  method,
}: AuthVerificationFormProps) {
  const {translate} = useTranslation()
  const history = useHistory('auth_verification_phone')
  const form = useForm<{code: string}>()
  const {errors, setError, reset, clearErrors, watch} = form
  const values = watch()
  const {code} = values
  const {phoneOTPTime} = useSelector('lastUserState')
  const {update} = useDispatch()
  const mobileConfirmImage = IMAGE_ASSET('giftshop', 'mobile-confirm.png')
  const whatsappConfirmImage = IMAGE_ASSET('giftshop', 'whatsapp-confirm.png')
  const [expired, setExpired] = useState(isExpired)
  const [isLimit, setIsLimit] = useState(false)
  const stateSelectedMethod = useState(method)
  const selectedMethod = stateSelectedMethod[0]
  const [isVisible, setIsVisible] = useState(false)
  const [selectedMethodImage, setSelectedMethodImage] = useState(method);
  const {countdown, reset: resetTimer} = useTimer({
    duration: phoneOTPTime && (new Date().getTime() / 1000) - (phoneOTPTime / 1000) <= OTP_COOLDOWN ? OTP_COOLDOWN - (phoneOTPTime && new Date().getTime() / 1000 - (phoneOTPTime / 1000)) : OTP_COOLDOWN,
    countOnStart: !expired ?? true,
  })

  const countDownMinute = Math.floor(countdown / 60)
  const countDownSecond = countdown - 60 * countDownMinute
  const handleVerify = useCallback(
    () =>
      requestData('auth_verify_phone', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {
        code: values.code,
        phone_code: phoneCode,
        phone_number: phoneNumber,
      },
      onRequestReceived: () => reset({code: ''}),
      onRequestFailed: (reason) =>
        setError('code', {
          type: reason,
          message: translate('global:messageError', {
            context: reason,
          }),
        }),
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          history.replace('auth_verification_phone_confirm', {})
          return
        }
         setError('code', {
          type: 'validation',
          message: translate('auth:validationVerificationOTPInvalid'),
        })
      },
    }),
  [history, phoneCode, phoneNumber, reset, setError, translate, values.code],
  )

  const handleResend = useCallback(
    () => {
      requestData('auth_resend_phone', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {phone_code: phoneCode, phone_number: phoneNumber, method: selectedMethod},
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            update('lastUserState', {phoneOTPTime: new Date().getTime()})
            resetTimer(OTP_COOLDOWN)
            setExpired(false)
            history.replace('auth_verification_phone', {
              lastSent: new Date(),
              isExpired: false,
              phoneCode,
              phoneNumber,
              method: selectedMethod,
            })
          } else if (status === 429) {
            setIsLimit(true)
            showSnackbar(translate('auth:registerLimitReached'))
          } else {
            showSnackbar(translate('global:messageError'))
          }
        },
      })},
    [history, phoneCode, phoneNumber, resetTimer, selectedMethod, translate, update],
  )

  const handleFocus = useCallback(() => clearErrors('code'), [clearErrors])

  const handleRenderBackIcon = useMemo(
    () => (
      <StyledIconContainer>
        <StyledBackIconCursor onClick={() => history.push('auth_login', {})}>
          <Icon type="back" size={convertUnit(20)} color="primary_5" />
        </StyledBackIconCursor>
      </StyledIconContainer>
    ),
    [history],
  )

  const handleRenderHeader = useMemo(
    () => (
      <>
        <StyledParagraph fontSize="xl" fontWeight="bold">
          {maskPhone(phoneCode, phoneNumber)}
        </StyledParagraph>
        <StyledHeaderDescription fontSize="m" color="gray_5">
          {translate('auth:verificationPhoneHeaderDescription', {context: selectedMethodImage})}
        </StyledHeaderDescription>
      </>
    ),
    [phoneCode, phoneNumber, selectedMethodImage, translate],
  )

  const handleRenderMidSection = useMemo(
    () => (
      <>
        <StyledImgContainer>
          <StyledIllustration src={selectedMethodImage === 'phone' ? mobileConfirmImage : whatsappConfirmImage} alt="Illustration" />
        </StyledImgContainer>
        <StyledExpired>
          <Paragraph fontSize="s" color="gray_3">
            {translate('auth:verificationPhoneCaution')}
          </Paragraph>
          <Paragraph fontSize="s" fontWeight="bold" color="gray_3">
            {translate('auth:verificationPhoneCautionTime')}
          </Paragraph>
        </StyledExpired>
      </>
    ),
    [mobileConfirmImage, selectedMethodImage, translate, whatsappConfirmImage],
  )

  const handleRenderInput = useMemo(
    () => (
      <Input
        name="code"
        form={form}
        autoComplete={false}
        placeholder={translate('auth:verificationPhoneInputPlaceholder')}
        containerStyle={{marginTop: convertUnit(25)}}
      />
    ),
    [form, translate],
  )

  const handleSelectResendMethod = useCallback(
    (selected: AuthPhoneResendMethod) => {
      setSelectedMethodImage(selected)
      setIsVisible(false)
      handleResend()
    },
    [handleResend],
  )

  const handleRenderTimer = useMemo(
    () => (
      <StyledSectionResend>
        <Paragraph fontSize="s" color="gray_5">
          {translate('auth:verificationPhoneDidNotGet')}
        </Paragraph>
        <StyledResend
          color={countdown === 0 && !isLimit ? 'primary_5' : 'gray_3'}
          fontWeight="medium"
          cursor={countdown === 0 && !isLimit ? 'pointer' : 'default'}
          onClick={() => countdown === 0 && !isLimit && setIsVisible(true)}>
          {countdown === 0
            ? translate('auth:forgotPasswordPhoneResend')
            : translate('auth:forgotPasswordPhoneResendCountdown', {
                countdown:
                  countdown > 60
                    ? `${countDownMinute}m${countDownSecond}`
                    : `${countDownSecond}`,
              })}
        </StyledResend>
      </StyledSectionResend>
    ),
    [translate, countdown, isLimit, countDownMinute, countDownSecond],
  )

  return (
    <AuthTemplateScreen>
      <TemplateAuthAccessResendOtpModal
        isVisible={isVisible}
        stateSelectedMethod={stateSelectedMethod}
        onConfirm={handleSelectResendMethod}
        onCancel={() => setIsVisible(false)}
        userInfo={{phoneCode, phoneNumber}}
      />
      <StyledContainer>
        {handleRenderBackIcon}
        <StyledContentContainer>
          {handleRenderHeader}
          {handleRenderMidSection}
          {handleRenderInput}
          {handleRenderTimer}
          <StyledButton
            disabled={
              Object.keys(errors).length > 0 ||
              Object.keys(values).length === 0 ||
              code.length === 0
            }
            label={translate('global:continue')}
            onFocus={handleFocus}
            onClick={handleVerify}
          />
        </StyledContentContainer>
      </StyledContainer>
    </AuthTemplateScreen>
  )
}
