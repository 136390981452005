import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {MaintenanceDate, ModalMaintenanceHandler} from 'types'
import {setModalMaintenanceHandler} from 'utils'
import {ModalMaintenanceProviderProps} from './ModalMaintenanceProps'
import {ModalMaintenanceContext} from './ModalMaintenanceContext'
import ModalMaintenanceItem from './ModalMaintenanceItem'

export default function ModalMaintenanceProvider({
  children,
}: ModalMaintenanceProviderProps) {
  const [visible, setVisible] = useState(false)
  const [maintenanceDate, setMaintenanceDate] = useState<MaintenanceDate>()

  const handleShowModal = useCallback(
    (modalVisible: boolean, startDate: string, endDate: string) => {
      setVisible(modalVisible)
      setMaintenanceDate({start_date: startDate, end_date: endDate})
    },
    [],
  )

  const handleClickOk = useCallback(() => setVisible((prev) => !prev), [])

  const handleRenderModalMaintenance = useMemo(
    () => (
      <ModalMaintenanceItem
        visible={visible}
        maintenanceDate={maintenanceDate}
        onClick={handleClickOk}
      />
    ),
    [handleClickOk, maintenanceDate, visible],
  )

  const handler = useMemo<ModalMaintenanceHandler>(
    () => ({
      visible,
      showModal: handleShowModal,
    }),
    [handleShowModal, visible],
  )

  useEffect(() => setModalMaintenanceHandler(handler), [handler])

  return (
    <ModalMaintenanceContext.Provider value={handler}>
      {handleRenderModalMaintenance}
      {children}
    </ModalMaintenanceContext.Provider>
  )
}
