import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {
  GiftShopProfileInformationContentItem,
  TreeEditProfileVideo,
} from 'pages'
import {
  TreeEditEventDateForm,
  TreeEditFotoTreeData,
  TreePlantFotoTreePictureForm,
  TreePlantFotoTreeVideoForm,
} from 'types'
import {
  formatDateRange,
  formatTimestampToDate,
  useDidMount,
  useHistory,
  usePatchTree,
} from 'utils'
import {ModalLoading} from 'common/components'
import convertUnit from 'lib/unit'
import {REGEX_MULTIPLE_NEWLINES} from 'consts'
import {TreeEditTreeFormProps} from './TreeEditFormProps'
import TreeEditProfileImage from './TreeEditProfileImage'
import {FototreeEditProfileEventDateModal} from '../Modal'

const StyledContainer = styled.div`
  width: 100%;
  max-width: ${convertUnit(521)};
  display: flex;
  align-self: center;
  box-sizing: border-box;
  padding: ${convertUnit(20)};
  flex-direction: column;
  overflow-y: scroll;
  gap: ${convertUnit(20)};
  margin: 0 auto;

  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
  }
`

const StyledSubContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`

export default function TreeEditTreeForm({
  stateTreeInfo,
  stateActiveContent,
}: TreeEditTreeFormProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const stateLoading = useState(false)
  const stateImageBroken = useState(false)
  const stateEventDateModal = useState(false)
  const imageForm = useForm<TreePlantFotoTreePictureForm>()
  const videoForm = useForm<TreePlantFotoTreeVideoForm>()

  const treeInfo = stateTreeInfo[0]
  const loading = stateLoading[0]
  const setActiveContent = stateActiveContent[1]
  const setEventDateModal = stateEventDateModal[1]
  const [isImageBroken, setImageBroken] = stateImageBroken

  const {register: registerImg} = imageForm

  const defaultValuesEventDate = useMemo(
    () =>
      treeInfo?.event_start_date && treeInfo?.event_end_date
        ? {
            eventEndDate: formatTimestampToDate(treeInfo.event_end_date),
            eventStartDate: formatTimestampToDate(treeInfo.event_start_date),
          }
        : {
            eventEndDate: '',
            eventStartDate: '',
          },
    [treeInfo.event_end_date, treeInfo.event_start_date],
  )

  const eventDateForm = useForm<TreeEditEventDateForm>({
    defaultValues: defaultValuesEventDate,
  })
  const {watch: watchEvent} = eventDateForm
  const {eventStartDate, eventEndDate} = watchEvent()

  const {
    handleDeleteVideo,
    handleSubmitVideo,
    handleSubmitImage,
    handleUpdateEventDate,
  } = usePatchTree({
    imageForm,
    videoForm,
    eventDateForm,
    stateTreeInfo,
    stateLoading,
  })

  const onSubmitImage = useCallback(async () => {
    const data: TreeEditFotoTreeData = {
      ...treeInfo,
      url: '',
    }
    const {status} = await handleSubmitImage()
    if (status === 200) {
      if (isImageBroken) setImageBroken(false)
      history.replace(
        'tree_fototree_edit',
        {treeId: treeInfo.id, data},
        treeInfo.alias,
      )
    }
  }, [handleSubmitImage, history, isImageBroken, setImageBroken, treeInfo])

  const onSubmitVideo = useCallback(
    async (file) => {
      const data: TreeEditFotoTreeData = {
        ...treeInfo,
        video_intro_url: '',
      }
      const {status} = await handleSubmitVideo(file)
      if (status === 200) {
        history.replace(
          'tree_fototree_edit',
          {treeId: treeInfo.id, data},
          treeInfo.alias,
        )
        location.reload()
      }
    },
    [handleSubmitVideo, history, treeInfo],
  )

  const onDeleteVideo = useCallback(async () => {
    const data: TreeEditFotoTreeData = {
      ...treeInfo,
      video_intro_url: '',
    }
    const {status} = await handleDeleteVideo()
    if (status === 200) {
      stateTreeInfo[1](data)
      history.replace(
        'tree_fototree_edit',
        {treeId: treeInfo.id, data},
        treeInfo.alias,
      )
    }
  }, [handleDeleteVideo, history, stateTreeInfo, treeInfo])

  const onSubmitEventDate = useCallback(async () => {
    setEventDateModal((prev) => !prev)
    const data: TreeEditFotoTreeData = {
      ...treeInfo,
      event_start_date: new Date(eventStartDate).getTime() / 1000,
      event_end_date: new Date(eventEndDate).getTime() / 1000,
    }

    const {status} = await handleUpdateEventDate()
    if (status === 200) {
      stateTreeInfo[1](data)
      history.replace(
        'tree_fototree_edit',
        {
          treeId: treeInfo.id,
          data,
        },
        treeInfo.alias,
      )
    }
  }, [
    eventEndDate,
    eventStartDate,
    handleUpdateEventDate,
    history,
    setEventDateModal,
    stateTreeInfo,
    treeInfo,
  ])

  const eventDate = useMemo(
    () =>
      treeInfo?.event_end_date && treeInfo?.event_start_date
        ? formatDateRange(treeInfo.event_start_date, treeInfo.event_end_date)
        : '',
    [treeInfo.event_end_date, treeInfo.event_start_date],
  )

  const handleRenderWeb = useMemo(
    () => (
      <StyledContainer>
        <StyledSubContainer>
          <TreeEditProfileImage
            stateLoading={stateLoading}
            stateImageBroken={stateImageBroken}
            treeInfo={treeInfo}
            imageForm={imageForm}
            onSaveChanges={onSubmitImage}
          />
        </StyledSubContainer>
        <StyledSubContainer>
          <GiftShopProfileInformationContentItem
            label={translate('tree:bio')}
            value={treeInfo.bio.replace(REGEX_MULTIPLE_NEWLINES, '\n')}
            actionText={
              treeInfo.bio
                ? translate('global:change')
                : translate('global:add')
            }
            isFullWidth
            emptyText={translate('giftShop:noBio')}
            onAction={() => setActiveContent('bio')}
          />
        </StyledSubContainer>
        {treeInfo.category_name === 'event' && (
          <StyledSubContainer>
            <GiftShopProfileInformationContentItem
              label={translate('tree:eventDate')}
              value={eventDate}
              actionText={
                treeInfo.event_start_date
                  ? translate('global:change')
                  : translate('global:add')
              }
              isFullWidth
              emptyText="-"
              onAction={() => setEventDateModal((prev) => !prev)}
            />
          </StyledSubContainer>
        )}
        <StyledSubContainer>
          <TreeEditProfileVideo
            stateTreeInfo={stateTreeInfo}
            videoForm={videoForm}
            onFileAccept={onSubmitVideo}
            onDeleteVideo={onDeleteVideo}
          />
        </StyledSubContainer>
      </StyledContainer>
    ),
    [
      eventDate,
      imageForm,
      onDeleteVideo,
      onSubmitImage,
      onSubmitVideo,
      setActiveContent,
      setEventDateModal,
      stateImageBroken,
      stateLoading,
      stateTreeInfo,
      translate,
      treeInfo,
      videoForm,
    ],
  )

  const handleRenderEventDate = useMemo(
    () => (
      <FototreeEditProfileEventDateModal
        form={eventDateForm}
        showState={stateEventDateModal}
        onSubmit={onSubmitEventDate}
      />
    ),
    [eventDateForm, onSubmitEventDate, stateEventDateModal],
  )

  useDidMount(() => registerImg('imgUrl'))

  return (
    <>
      <ModalLoading visible={loading} />
      {handleRenderEventDate}
      {handleRenderWeb}
    </>
  )
}
