import React from 'react'
import {GoogleOAuthProvider} from '@react-oauth/google'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import styled from 'styled-components'
import {RouteStack} from './routes'
import {ThemeProvider} from './themes'
import {getOAuthClientId} from './utils'
import {WindowProvider} from './windows'
import {SnackbarProvider} from './common/components'
import {REDUX_STORE, REDUX_PERSISTOR} from './lib/redux'
import {
  BanModalProvider,
  ModalOnHoldProvider,
  LogoutModalProvider,
  DownTimeModalProvider,
  ModalReprocessKycProvider,
  ModalMaintenanceProvider,
  ModalLimitedAccessProvider,
} from './context'

import(/* webpackChunkName: 'RegisterSW' */ './register-sw')

const StyledRoot = styled.div`
  ${({theme}) => ({backgroundColor: theme.white_1})}
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
`

export default function App() {
  const userLanguage = window.navigator.language.substring(0, 2)

  return (
    <WindowProvider>
      <GoogleOAuthProvider clientId={getOAuthClientId()}>
        <HelmetProvider>
          <Provider store={REDUX_STORE}>
            <PersistGate persistor={REDUX_PERSISTOR}>
              <Helmet>
                {userLanguage === 'id' ? (
                  <meta
                    name="description"
                    content={
                      'Fotoyu adalah platform marketplace foto/konten pribadi, dengan teknologi kecerdasan buatan pencari foto, yang mempertemukan Fotografer (kreator) dengan Yuser.  Fotoyu juga dilengkapi dengan fitur media sosial sebagai tempat berbagi konten kamu dengan teman. Siapapun. Dimanapun, Kapanpun.'
                    }
                  />
                ) : (
                  <meta
                    name="description"
                    content={
                      'FOTOYU, a photo marketplace platform created to capture the beautiful moments of everyone. Photo-finding technology with facial recognition in Fotoyu, connecting Photographer and Yuser. Anyone. Anywhere. Anytime.'
                    }
                  />
                )}
              </Helmet>
              <ThemeProvider>
                <SnackbarProvider>
                  <ModalMaintenanceProvider>
                    <LogoutModalProvider>
                      <BanModalProvider>
                        <ModalOnHoldProvider>
                          <DownTimeModalProvider>
                            <ModalReprocessKycProvider>
                              <ModalLimitedAccessProvider>
                                <StyledRoot>
                                  <RouteStack />
                                </StyledRoot>
                              </ModalLimitedAccessProvider>
                            </ModalReprocessKycProvider>
                          </DownTimeModalProvider>
                        </ModalOnHoldProvider>
                      </BanModalProvider>
                    </LogoutModalProvider>
                  </ModalMaintenanceProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </PersistGate>
          </Provider>
        </HelmetProvider>
      </GoogleOAuthProvider>
    </WindowProvider>
  )
}
