import {REGEX_ALPHABET_AND_SPACE, REGEX_NUMBER} from 'consts'
import {translate} from 'i18n'
import {InputFormRules} from 'types'
import {getValidation} from '../Validation'

export const VALIDATION_GIFTSHOP_ADDBANKACCOUNT_FULLNAME: InputFormRules = {
  required: true,
  messages: {
    required: getValidation('giftShop:validationFullname'),
  },
  validate: {
    rule: (text) =>
      !REGEX_ALPHABET_AND_SPACE.test(text)
        ? translate('giftShop:addBankInputAccountNameErrorMustAlphabet')
        : true,
  },
}

export const VALIDATION_GIFTSHOP_ADDBANKACCOUNT_ACCOUNTNUMBER: InputFormRules = {
  required: true,
  minLength: 10,
  maxLength: 18,
  messages: {
    required: getValidation('giftShop:validationAccountNumber'),
    minLength: getValidation(
      'giftShop:addBankInputAccountNumberErrorMinimalCharacter',
    ),
    maxLength: getValidation('global:validationMaxLength', {
      count: 18,
    }),
  },
  validate: {
    rule: (text) =>
      !REGEX_NUMBER.test(text)
        ? translate('giftShop:addBankInputAccountNumberErrorNumberOnly')
        : true,
  },
}

export const VALIDATION_GIFTSHOP_ADDBANKACCOUNT_BANKNAME: InputFormRules = {
  required: true,
  messages: {
    required: getValidation('giftShop:validationBankName'),
  },
}
