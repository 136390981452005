import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {
  Button,
  ConfirmationModal,
  Icon,
  Image,
  InputPicker,
  Label,
  Paragraph,
} from 'common/components'
import {WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import {GIFTSHOP_UPLOAD_SINGLE_LIMIT, WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {getBorder, showSnackbar} from 'utils'
import {GiftShopUploadSeriesPreviewModal} from 'pages'
import {GiftShopUploadContentItemSeriesProps} from './GiftShopUploadContentItemSeriesProps'
import GiftShopUploadContentItemImage from './GiftShopUploadContentItemImage'

interface StyledImageContainerProps {
  mode: WindowModeType
}

interface StyledSeriesProps {
  selected?: boolean
}

interface StyledSeriesContainerProps {
  mode: WindowModeType
}

interface StyledSeriesImgButtonContainerProps {
  mode: WindowModeType
}

const StyledSeriesMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.primary_5};
  width: 100%;
  aspect-ratio: 1;
  border-radius: ${convertUnit(8)};
  cursor: pointer;
`

const StyledAddSeriesButtonContainer = styled.div<StyledImageContainerProps>`
  margin-top: ${convertUnit(25)};
`

const SeriesImageButtonContainer = styled.div<StyledSeriesImgButtonContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: ${({mode}) => mode !== 'tablet' && convertUnit(12)};
  margin-left: ${({mode}) => mode === 'tablet' && convertUnit(20)};
  position: relative;
`

const StyledSeriesContainer = styled.div<StyledSeriesContainerProps>`
  margin-top: ${convertUnit(10)};
  display: flex;
  flex-direction: column;
`

const StyledParentIconContainer = styled.div`
  position: absolute;
  bottom: ${convertUnit(5)};
  right: ${convertUnit(5)};
  z-index: 5;
`

const StyledSeries = styled(Image)<StyledSeriesProps>`
  border: ${({selected, theme}) =>
    selected && getBorder(2, 'solid', theme.primary_5)};
  width: ${convertUnit(30.83)};
  aspect-ratio: 1;
  border-radius: ${convertUnit(8)};
  box-sizing: border-box;
  cursor: pointer;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
  }
`

const StyledSeriesInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledParagraphButton = styled(Paragraph)`
  cursor: pointer;
`

const StyledSeriesImageContainer = styled.div`
  position: relative;
`

const StyledSeriesVariantContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: ${convertUnit(9)};
  margin: ${convertUnit(8)} 0;
`

const StyledDeleteContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-top: ${convertUnit(25)};
  }
`

const StyledDeleteContent = styled(Paragraph)`
  align-self: left;
  cursor: pointer;
`

const StyledTextWrap = styled(Paragraph)`
  word-break: break-all;
  display: inline-block;
`

export default function GiftShopUploadContentItemSeries({
  data,
  total,
  showDeleteButton,
  select,
  stateSelectId,
  onLoadFileSeries,
  onDeleteVariant,
  onSetParent,
  onDelete,
}: GiftShopUploadContentItemSeriesProps) {
  const mode = useWindowMode()
  const {translate} = useTranslation()
  const {parent} = data
  const [toggleDelete, setToggleDelete] = useState(false)
  const [toggleModal, setToggleModal] = useState(false)
  const setSelectId = stateSelectId[1]

  const seriesRef = useRef<HTMLInputElement>(null)

  const handleToggleDelete = useCallback(
    () => setToggleDelete((previous) => !previous),
    [],
  )

  const handleToggleModal = useCallback(
    () => setToggleModal((previous) => !previous),
    [],
  )

  const handleUploadVariant = useCallback(
    () => seriesRef.current && seriesRef.current.click(),
    [],
  )

  const handleRenderSeriesVariant = useMemo(
    () => (
      <StyledSeriesVariantContainer>
        {data.images.slice(0, data.images.length > 12 ? 11 : 12).map((item) => (
          <StyledSeriesImageContainer key={item.id}>
            {parent === item.id && (
              <StyledParentIconContainer>
                <Icon
                  type="star-solid"
                  color="success_3"
                  size={convertUnit(8)}
                />
              </StyledParentIconContainer>
            )}
            <StyledSeries
              src={item.src}
              alt="series"
              selected={select.id === item.id}
              onClick={() => setSelectId(item.id)}
            />
          </StyledSeriesImageContainer>
        ))}
        {data.images.length > 12 && (
          <StyledSeriesMore onClick={handleToggleModal}>
            <Paragraph
              fontSize={mode === 'website' ? 's' : 'l'}
              fontWeight="bold"
              color="white_1">
              {`${data.images.length - 11}+`}
            </Paragraph>
          </StyledSeriesMore>
        )}
      </StyledSeriesVariantContainer>
    ),
    [data, select, mode, parent, setSelectId, handleToggleModal],
  )

  const handleRenderSeries = useMemo(
    () =>
      data.images.length > 1 && (
        <StyledSeriesContainer mode={mode}>
          <StyledSeriesInfo>
            <Paragraph fontSize="s" fontWeight="medium" color="gray_5">
              {translate('giftShop:uploadedContentCount', {
                count: data.images.length,
              })}
            </Paragraph>
            <StyledParagraphButton
              fontSize="s"
              fontWeight="medium"
              color="primary_5"
              onClick={() => onSetParent(select)}>
              {translate('giftShop:uploadSeriesSetAsParent')}
            </StyledParagraphButton>
          </StyledSeriesInfo>
          {handleRenderSeriesVariant}
          <StyledSeriesInfo>
            <StyledParagraphButton
              fontSize="s"
              fontWeight="medium"
              color="gray_3"
              onClick={() =>
                select.id === parent
                  ? showSnackbar(
                      translate('giftShop:deleteParentSeriesMessage'),
                    )
                  : onDeleteVariant(data, select)
              }>
              {translate('global:remove')}
            </StyledParagraphButton>
            <StyledParagraphButton
              fontSize="s"
              fontWeight="medium"
              color="gray_3"
              onClick={handleToggleDelete}>
              {translate('global:removeAll')}
            </StyledParagraphButton>
          </StyledSeriesInfo>
        </StyledSeriesContainer>
      ),
    [
      data,
      select,
      mode,
      parent,
      handleRenderSeriesVariant,
      translate,
      onSetParent,
      onDeleteVariant,
      handleToggleDelete,
    ],
  )

  const handleRenderAddSeriesButton = useMemo(
    () => (
      <SeriesImageButtonContainer mode={mode}>
        <Label
          containerStyle={{width: 'inherit'}}
          label={translate('giftShop:uploadTitleLabel')}>
          <StyledTextWrap fontSize="m" color="gray_4">
            {select.file.name}
          </StyledTextWrap>
        </Label>
        {handleRenderSeries}
        <StyledAddSeriesButtonContainer mode={mode}>
          <Button
            label={translate('giftShop:uploadAddSeriesButton')}
            color="primary_5"
            backgroundColor="white_3"
            onClick={
              total >= 100
                ? () =>
                    showSnackbar(
                      translate('giftShop:uploadIconMaxSnackbar', {
                        max: GIFTSHOP_UPLOAD_SINGLE_LIMIT,
                      }),
                    )
                : handleUploadVariant
            }
          />
        </StyledAddSeriesButtonContainer>
      </SeriesImageButtonContainer>
    ),
    [
      mode,
      translate,
      select.file.name,
      handleRenderSeries,
      total,
      handleUploadVariant,
    ],
  )

  const handleRenderDeleteButton = useMemo(
    () =>
      mode === 'website' && showDeleteButton ? (
        <StyledDeleteContainer>
          <StyledDeleteContent
            color="gray_3"
            fontSize="s"
            fontWeight="medium"
            onClick={onDelete}>
            {translate('giftShop:uploadDeleteContent')}
          </StyledDeleteContent>
        </StyledDeleteContainer>
      ) : undefined,
    [mode, showDeleteButton, translate, onDelete],
  )

  const handleRenderPicker = useMemo(
    () => (
      <InputPicker
        multiple
        inputRef={seriesRef}
        onLoadFiles={(files) => onLoadFileSeries(files, data.id)}
      />
    ),
    [data.id, onLoadFileSeries],
  )

  const handleRenderContent = useMemo(
    () => (
      <GiftShopUploadContentItemImage
        id={select.id}
        src={select.src}
        height={select.height}
        width={select.width}
        size={select.file.size}
        renderHeader={handleRenderDeleteButton}
        renderFooter={handleRenderAddSeriesButton}
      />
    ),
    [select, handleRenderAddSeriesButton, handleRenderDeleteButton],
  )

  return (
    <>
      <ConfirmationModal
        title={translate('global:removeAll')}
        toggleModal={handleToggleDelete}
        visible={toggleDelete}
        text={translate('giftShop:removeAllSeriesMessage')}
        onConfirm={() => onDeleteVariant(data)}
      />
      <GiftShopUploadSeriesPreviewModal
        visible={toggleModal}
        data={data}
        total={total}
        onClose={handleToggleModal}
        onUpload={handleUploadVariant}
        onSelect={(variant) => setSelectId(variant.id)}
        onDeleteVariant={onDeleteVariant}
        select={select}
        onSetParent={onSetParent}
      />
      {handleRenderPicker}
      {handleRenderContent}
    </>
  )
}
