import {useCallback, useMemo} from 'react'
import {
  GiftshopUploadPublishFormData,
  GiftshopUploadPublishFormMap,
  GiftshopUploadPublishSeriesParam,
  GiftShopUploadSeriesData,
  ImageExtensionType,
} from 'types'
import {requestData} from 'services'
import {GIFT_SHOP_MAX_FILE_NAME} from 'consts'
import {createImageSignatureFromFile} from 'utils/image'
import {useSelector} from 'lib/redux'
import {useGiftshopUploadCompressResendSetting} from './GiftShopUploadCompressResendHooks'
import {useHistory} from '../../../routes'
import {getFileName, handleUploadSingleFileToWasabi} from '../../../files'
import {getGiftshopLocationName} from '../GiftshopHelper'

export function useGiftshopUploadPublishSeries({
  mapRef,
}: GiftshopUploadPublishSeriesParam) {
  const {map, setMapValue, updateMap} = mapRef
  const {username} = useSelector('user') || {}
  const history = useHistory()
  const {resendEnabled} = useGiftshopUploadCompressResendSetting()

  const handleFinished = useCallback(() => {
    const currentEntries = Object.entries(map.current.mapForm)
    const currentTotal = currentEntries.length
    const currentSuccess = currentEntries.filter(
      ([, {status}]) => status === 'success',
    ).length

    if (currentTotal === currentSuccess) {
      setTimeout(
        () => history.push('giftshop_profile', {self: true}, username),
        1000,
      )
    }
  }, [history, map, username])

  const handlePublishGetLink = useCallback(
    async (
      item: GiftshopUploadPublishFormData,
      signature: string,
      totalSize: number,
    ) => {
      const {height, width, size, extension} = item.image
      const imgExtension = extension as ImageExtensionType

      const response = await requestData('giftshop_post_upload_content_link', {
        data: {
          content: {
            size,
            height,
            width,
            extension: imgExtension,
          },
          series: {
            total_size: totalSize,
          },
          signature,
        },
      })

      return typeof response !== 'string' ? response : null
    },
    [],
  )

  const handlePublishToWasabi = useCallback(
    async (item: GiftshopUploadPublishFormData, link: string) => {
      const {file, width, height, extension} = item.image
      const mime = `image/${extension}`

      try {
        return handleUploadSingleFileToWasabi(file, link, mime, {
          'Content-Type': mime,
          'x-amz-meta-imagewidth': width.toString(),
          'x-amz-meta-imageheight': height.toString(),
        })
      } catch (error) {
        return false
      }
    },
    [],
  )

  const handlePublishProperty = useCallback(
    async (
      item: GiftshopUploadPublishFormData,
      link: string,
      isParent: boolean,
      seriesId: string,
      signature: string,
    ) => {
      const {
        image,
        currencyId,
        description,
        location,
        price,
        originalAt,
        tags,
        peopleTag,
      } = item

      const response = await requestData('giftshop_upload_content', {
        useDefaultMessage: true,
        data: {
          resend: resendEnabled,
          title: getFileName(image.file.name, GIFT_SHOP_MAX_FILE_NAME),
          description,
          location:
            location ??
            getGiftshopLocationName({
              latitude: tags[0].location?.latitude.toString(),
              longitude: tags[0].location?.longitude.toString(),
              name: tags[0].name,
            }),
          content: link,
          height: image.height,
          width: image.width,
          size: image.size,
          currency_id: currencyId,
          price: parseInt(price, 10),
          original_at: originalAt,
          unix_original_at: Math.floor(new Date(originalAt).getTime() / 1000),
          tag_ids: tags.map((tag) => tag.id),
          is_parent: isParent,
          series_id: seriesId,
          nickname: peopleTag.map((tag) => tag.nickname).join(','),
          is_bibs: tags.map((tag) => ({
            tag_id: tag.id,
            is_bib: tag.is_bib,
          })),
          signature,
        },
      })

      return typeof response !== 'string' && response.status === 200
    },
    [resendEnabled],
  )

  const handlePublishSingle = useCallback(
    async (
      item: GiftshopUploadPublishFormData,
      isParent: boolean,
      seriesId: string,
      totalSize: number,
    ) => {
      const signature = await createImageSignatureFromFile(item.image.file)

      const response = signature
        ? await handlePublishGetLink(item, signature, totalSize)
        : null

      const link =
        response && response.status === 200
          ? response.data.result.original_link
          : null

      const isDuplicate = response && response.status === 409

      const isSuccess =
        signature &&
        !!link &&
        !isDuplicate &&
        (await handlePublishToWasabi(item, link)) &&
        (await handlePublishProperty(item, link, isParent, seriesId, signature))

      const status = (() => {
        if (isSuccess) return 'success'
        if (isDuplicate) return 'duplicate'
        return 'failed'
      })()

      setMapValue({
        mapForm: {
          ...map.current.mapForm,
          [item.image.id]: {
            item,
            id: item.image.id,
            status,
          },
        },
      })
      updateMap()
      handleFinished()

      return status
    },
    [
      handlePublishGetLink,
      handlePublishToWasabi,
      handlePublishProperty,
      setMapValue,
      map,
      updateMap,
      handleFinished,
    ],
  )

  const handlePublishSeries = useCallback(
    async ({id, parent: parentId}: GiftShopUploadSeriesData) => {
      const variants = Object.values(map.current.mapForm).filter(
        (variant) => variant.item.series === id,
      )
      const parent = variants.find((variant) => variant.id === parentId)
      const totalSize = variants.reduce(
        (prev, curr) => prev + curr.item.image.size,
        0,
      )

      if (parent && parent.status === 'not-started') {
        const status = await handlePublishSingle(
          parent.item,
          true,
          id,
          totalSize,
        )

        if (status !== 'success') {
          setMapValue({
            mapForm: variants.reduce<GiftshopUploadPublishFormMap>(
              (previous, variant): GiftshopUploadPublishFormMap => ({
                ...previous,
                [variant.id]: {...variant, status},
              }),
              map.current.mapForm,
            ),
          })
          updateMap()
          handleFinished()
          return
        }
      }

      const children = variants.filter(
        (child) => child.status === 'not-started' && child.id !== parentId,
      )

      for (const child of children) {
        // eslint-disable-next-line no-await-in-loop
        await handlePublishSingle(child.item, false, id, 0)
      }
    },
    [map, handlePublishSingle, setMapValue, updateMap, handleFinished],
  )

  return useMemo(() => ({publishSeries: handlePublishSeries}), [
    handlePublishSeries,
  ])
}
