import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {requestData} from 'services'
import {
  FirebaseMessageRoomContentData,
  FirebaseMessageRoomType,
  GiftShopChatRoleType,
} from 'types'
import {useDidMount} from 'utils'
import {useDispatch, useSelector} from 'lib/redux'
import GiftShopMessageRoom from './GiftShopMessageRoom'
import GiftShopMessageRoomHeaderGiftshop from './GiftShopMessageRoomHeaderGiftShop'

export interface GiftShopMessageRoomGiftShopProps {
  roomIdDefault?: string
  roomType: FirebaseMessageRoomType
  participantId: string
  isVerifCreator?: boolean
  content?: FirebaseMessageRoomContentData
  selfRole?: GiftShopChatRoleType
}

export default function GiftShopMessageRoomGiftShop({
  roomIdDefault,
  roomType,
  participantId,
  content,
  selfRole = 'buyer',
}: GiftShopMessageRoomGiftShopProps) {
  const [roomId, setRoomId] = useState(roomIdDefault)
  const {update} = useDispatch()
  const messageRoomState = Object.values(useSelector('messageRoomState'))

  const handleLoadData = useCallback(async () => {
    if (!roomId) {
      roomType === 'giftshop'
        ? await requestData('giftshop_dm_post_room', {
            data: {member_id: participantId, type: selfRole},
            onRequestSuccess: ({status, data: {result}}) =>
              status === 201 && setRoomId(result.room_id),
          })
        : await requestData('forkygram_dm_post_room', {
            data: {member_ids: [participantId]},
            onRequestSuccess: ({status, data: {result}}) =>
              status === 201 && setRoomId(result.room_id),
          })
    }
  }, [participantId, roomId, roomType, selfRole])

  const handleSendMessage = useCallback(
    async (
      message: string,
      id: string,
      memberId: string,
      contentId?: string,
      invoiceId?: string,
    ) => {
      const response =
        roomType === 'giftshop'
          ? await requestData('giftshop_dm_post_message', {
              data: {
                payload: {
                  message,
                  content_id: contentId,
                  invoice_id: invoiceId,
                },
                room_id: id,
                member_id: memberId,
                type: !!contentId && !!invoiceId ? 'resend_revision' : 'chat',
              },
            })
          : await requestData('forkygram_dm_post_message', {
              data: {
                message,
                room_id: id,
              },
            })

      return typeof response !== 'string' && response.status === 200
        ? response.data.result
        : null
    },
    [roomType],
  )

  const handleRenderHeader = useMemo(
    () =>
      roomId ? (
        <GiftShopMessageRoomHeaderGiftshop
          roomId={roomId}
          roomType={roomType}
          participantId={participantId}
        />
      ) : null,
    [participantId, roomId, roomType],
  )

  const handleRenderContent = useMemo(
    () =>
      roomId ? (
        <GiftShopMessageRoom
          participantId={participantId}
          roomId={roomId}
          roomType={roomType}
          content={content}
          onSendMessage={handleSendMessage}
        />
      ) : null,
    [roomId, participantId, roomType, content, handleSendMessage],
  )

  useEffect(() => {
    handleLoadData()
    return function cleanup() {
      const currentTime = new Date().toISOString()
      let exist = false
      const newMessageRoomState =
        messageRoomState &&
        messageRoomState.map((obj) => {
          if (obj.room_id === roomId) {
            exist = true
            return {...obj, last_leave: currentTime}
          }
          return obj
        })
      if (exist) {
        update('messageRoomState', newMessageRoomState)
      } else {
        messageRoomState &&
          roomId &&
          update(
            'messageRoomState',
            messageRoomState.concat([
              {room_id: roomId, last_leave: currentTime},
            ]),
          )
      }
    }
  }, [handleLoadData, messageRoomState, roomId, update])

  useDidMount(() => {
    if (roomId)
      requestData('giftshop_dm_room_enter', {
        data: {
          room_id: roomId,
          room_type: roomType,
        },
      })
  })

  return (
    <>
      {handleRenderHeader}
      {handleRenderContent}
    </>
  )
}
