import {
  API_AUTH_CHECK_EMAIL,
  API_AUTH_CHECK_PHONE,
  API_AUTH_FORGOT_PASSWORD,
  API_AUTH_LOGIN,
  API_AUTH_RESEND_EMAIL,
  API_AUTH_RESEND_PHONE,
  API_AUTH_RESET_PASSWORD,
  API_AUTH_VERIFY_EMAIL,
  API_AUTH_VERIFY_PHONE,
  API_GOOGLE_LOGIN_SSO,
  API_MEMBER_SELF_INFO,
  API_PASSWORD_SSO,
  API_AUTH_CHECK_USERNAME,
  API_OAUTH_REGISTER_EMAIL,
  API_OAUTH_REGISTER_PHONE,
  API_AUTH_CHECK_CHANGE_PASSWORD_TOKEN,
  API_MEMBER_PROFILE,
  API_APPLE_LOGIN_SSO,
  API_MEMBER_REGISTER_EMAIL_RESEND,
  API_AUTH_RESEND_FORGOT_TOKEN,
  API_OAUTH_LOGOUT,
  API_AUTH_OAUTH_LOGIN,
  API_EASY_SIGNUP_EMAIL,
  API_EASY_SIGNUP_PHONE,
  API_EASY_SIGNUP_VERIFY,
  API_EASY_SIGNUP_KYC_VERIFICATION,
  API_EASY_SIGNUP_GOOGLE,
  API_EASY_SIGNUP_APPLE,
  API_AUTH_LOGIN_CHECK_ACCOUNT_STATUS,
  API_EASY_SIGNUP_RESEND,
  API_EASY_SIGNUP_RESEND_BY_CODE,
  API_EASY_SIGNUP_KYC_VERIFICATION_LINKS,
  API_EASY_SIGNUP_CODE_STATUS,
} from 'consts'
import {ServiceMap} from 'types'
import {getAPIBaseUrl} from 'utils'
import {ServiceParamAuth} from './ServiceParamAuth'

export const SERVICE_MAP_AUTH: ServiceMap<ServiceParamAuth> = {
  auth_check_change_password_token: {
    url: getAPIBaseUrl() + API_AUTH_CHECK_CHANGE_PASSWORD_TOKEN,
    method: 'GET',
  },
  auth_check_email: {
    url: getAPIBaseUrl() + API_AUTH_CHECK_EMAIL,
    method: 'POST',
  },
  auth_check_phone: {
    url: getAPIBaseUrl() + API_AUTH_CHECK_PHONE,
    method: 'POST',
  },
  auth_check_reset_password_token: {
    url: getAPIBaseUrl() + API_AUTH_FORGOT_PASSWORD,
    method: 'GET',
  },
  auth_check_username: {
    url: getAPIBaseUrl() + API_AUTH_CHECK_USERNAME,
    method: 'POST',
  },
  auth_forgot_password: {
    url: getAPIBaseUrl() + API_AUTH_FORGOT_PASSWORD,
    method: 'POST',
  },
  auth_get_member: {
    url: getAPIBaseUrl() + API_MEMBER_PROFILE,
    method: 'GET',
  },
  auth_get_self: {
    url: getAPIBaseUrl() + API_MEMBER_SELF_INFO,
    method: 'GET',
  },
  auth_logout: {
    url: getAPIBaseUrl() + API_OAUTH_LOGOUT,
    method: 'POST',
  },
  auth_login: {
    url: getAPIBaseUrl() + API_AUTH_LOGIN,
    method: 'POST',
  },
  auth_login_check_account_status: {
    url: getAPIBaseUrl() + API_AUTH_LOGIN_CHECK_ACCOUNT_STATUS,
    method: 'POST',
  },
  auth_login_sso_apple: {
    url: getAPIBaseUrl() + API_APPLE_LOGIN_SSO,
    method: 'POST',
  },
  auth_login_sso_google: {
    url: getAPIBaseUrl() + API_GOOGLE_LOGIN_SSO,
    method: 'POST',
  },
  auth_oauth_login: {
    url: getAPIBaseUrl() + API_AUTH_OAUTH_LOGIN,
    method: 'POST',
  },
  auth_patch_passwordsso: {
    url: getAPIBaseUrl() + API_PASSWORD_SSO,
    method: 'PATCH',
  },
  auth_register_email_resend: {
    url: getAPIBaseUrl() + API_MEMBER_REGISTER_EMAIL_RESEND,
    method: 'PATCH',
  },
  auth_register_oauth_email: {
    url: getAPIBaseUrl() + API_OAUTH_REGISTER_EMAIL,
    method: 'POST',
  },
  auth_register_oauth_phone: {
    url: getAPIBaseUrl() + API_OAUTH_REGISTER_PHONE,
    method: 'POST',
  },
  auth_register_sso_apple: {
    url: getAPIBaseUrl() + API_APPLE_LOGIN_SSO,
    method: 'POST',
  },
  auth_register_sso_google: {
    url: getAPIBaseUrl() + API_GOOGLE_LOGIN_SSO,
    method: 'POST',
  },
  auth_resend_email: {
    url: getAPIBaseUrl() + API_AUTH_RESEND_EMAIL,
    method: 'PATCH',
  },
  auth_resend_forgot_token: {
    url: getAPIBaseUrl() + API_AUTH_RESEND_FORGOT_TOKEN,
    method: 'PATCH',
  },
  auth_resend_phone: {
    url: getAPIBaseUrl() + API_AUTH_RESEND_PHONE,
    method: 'PATCH',
  },
  auth_reset_password: {
    url: getAPIBaseUrl() + API_AUTH_RESET_PASSWORD,
    method: 'PATCH',
  },
  auth_verify_email: {
    url: getAPIBaseUrl() + API_AUTH_VERIFY_EMAIL,
    method: 'GET',
  },
  auth_verify_phone: {
    url: getAPIBaseUrl() + API_AUTH_VERIFY_PHONE,
    method: 'PATCH',
  },
  auth_easy_signup_email: {
    url: getAPIBaseUrl() + API_EASY_SIGNUP_EMAIL,
    method: 'POST',
  },
  auth_easy_signup_phone: {
    url: getAPIBaseUrl() + API_EASY_SIGNUP_PHONE,
    method: 'POST',
  },
  auth_easy_signup_verify: {
    url: getAPIBaseUrl() + API_EASY_SIGNUP_VERIFY,
    method: 'POST',
  },
  auth_easy_signup_kyc_links: {
    url: getAPIBaseUrl() + API_EASY_SIGNUP_KYC_VERIFICATION_LINKS,
    method: 'POST',
  },
  auth_easy_signup_kyc_verif: {
    url: getAPIBaseUrl() + API_EASY_SIGNUP_KYC_VERIFICATION,
    method: 'POST',
  },
  auth_easy_signup_sso_google: {
    url: getAPIBaseUrl() + API_EASY_SIGNUP_GOOGLE,
    method: 'POST',
  },
  auth_easy_signup_login_sso_google: {
    url: getAPIBaseUrl() + API_EASY_SIGNUP_GOOGLE,
    method: 'POST',
  },
  auth_easy_signup_sso_apple: {
    url: getAPIBaseUrl() + API_EASY_SIGNUP_APPLE,
    method: 'POST',
  },
  auth_easy_signup_login_sso_apple: {
    url: getAPIBaseUrl() + API_EASY_SIGNUP_APPLE,
    method: 'POST',
  },
  auth_easy_signup_resend: {
    url: getAPIBaseUrl() + API_EASY_SIGNUP_RESEND,
    method: 'POST',
  },
  auth_easy_signup_resend_by_code: {
    url: getAPIBaseUrl() + API_EASY_SIGNUP_RESEND_BY_CODE,
    method: 'POST',
  },
  auth_easy_signup_check_code_status: {
    url: getAPIBaseUrl() + API_EASY_SIGNUP_CODE_STATUS,
    method: 'GET',
  },
}
