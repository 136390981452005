import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTheme} from 'themes'
import {getFontSize} from 'utils'
import {useTranslation} from 'i18n'
import {MaintenanceDate, parseShape} from 'types'
import {Button, Modal, Paragraph, ParsedText} from 'common/components'
import {useDispatch} from 'lib/redux'
import convertUnit from 'lib/unit'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(20)};
`
const StyledTitle = styled(Paragraph)`
  margin-bottom: ${convertUnit(15)};
`

interface ModalMaintenanceItemProps {
  visible: boolean
  maintenanceDate?: MaintenanceDate
  onClick(): void
}

export default function ModalMaintenanceItem({
  visible,
  maintenanceDate,
  onClick,
}: ModalMaintenanceItemProps) {
  const theme = useTheme()
  const {translate} = useTranslation()
  const {update} = useDispatch()
  const handleRenderText: parseShape[] = useMemo(
    () => [
      {
        pattern: /<date>.*?<\/date>/,
        style: {
          color: theme.primary_5,
          fontFamily: 'roboto-bold',
          fontSize: getFontSize('m'),
        },
        renderText: (text) => text.replace(/<date>|<\/date>/g, ''),
      },
    ],
    [theme.primary_5],
  )
  return (
    <StyledModal visible={visible}>
      <StyledTitle fontSize="l" fontWeight="bold">
        {translate('auth:serverUnderMaintenance')}
      </StyledTitle>
      {maintenanceDate ? (
        <>
          <Paragraph fontSize="m">
            <ParsedText parse={handleRenderText}>
              {translate('global:maintenanceStart', {
                date: maintenanceDate.start_date,
              })}
            </ParsedText>
          </Paragraph>
          <Paragraph fontSize="m">
            <ParsedText parse={handleRenderText}>
              {translate('global:maintenanceEnd', {
                date: maintenanceDate.end_date,
              })}
            </ParsedText>
          </Paragraph>
        </>
      ) : (
        <></>
      )}
      <Paragraph fontSize="m">
        {translate('auth:serverUnderMaintenanceDescSecond')}
      </Paragraph>
      <StyledButton
        onClick={() => {
          update('maintenanceModalStatus', {showMaintenanceModal: false})
          onClick()
        }}>
        {translate('global:ok')}
      </StyledButton>
    </StyledModal>
  )
}
