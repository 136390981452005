import React, {useMemo} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {WindowModeType} from 'types'
import {useHistory, getAppStoreLink, getPlayStoreLink} from 'utils'
import {useWindowMode} from 'windows'
import {Icon, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {AuthTemplateScreen} from '../../template'

interface StyledIconProps {
  mode: WindowModeType
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
`

const StyledContentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`

const StyledHeader = styled(Paragraph)`
  text-align: center;
`

const StyledIconContainer = styled.div<StyledIconProps>`
  padding: ${convertUnit(20)} 0;
  display: flex;
  align-items: center;
  ${({mode}) => ({
    position: mode === 'mobile' ? 'absolute' : undefined,
    top: mode === 'mobile' ? convertUnit(5.5) : undefined,
    left: mode === 'mobile' ? convertUnit(17.5) : undefined,
  })}
`

const StyledBackIconCursor = styled.div`
  cursor: pointer;
  width: fit-content;
`

const StyledImageContainer = styled.div`
  justify-content: center;
  display: flex;
  margin-top: ${convertUnit(30)};
`

const AuthSelfieYuser = styled(Image)`
  object-fit: contain;
  width: ${convertUnit(226)};
  height: ${convertUnit(140)};
  flex-grow: 0;
`

const StyledParagraph = styled(Paragraph)`
  width: ${convertUnit(280)};
  text-align: center;
`

const StyledParagraphContainer = styled.div`
  justify-content: center;
  display: flex;
  margin-top: ${convertUnit(20)};
`

const StyledStoreImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${convertUnit(20)};
`

const StyledImg = styled(Image)`
  margin-right: ${convertUnit(25)};
`

const StyledLink = styled.a`
  text-decoration: none;
`

export default function AuthLoginWefieScreen() {
  const {translate} = useTranslation()
  const history = useHistory()
  const mode = useWindowMode()
  const selfieWefie = IMAGE_ASSET('giftshop', 'wefie-illustration.png')
  const googlePlayImg = IMAGE_ASSET('giftshop', 'google-play-icon.png')
  const appStoreImg = IMAGE_ASSET('giftshop', 'app-store-icon.png')

  const handleRenderContent = useMemo(
    () => (
      <>
        <StyledImageContainer>
          <AuthSelfieYuser src={selfieWefie} alt="SelfieWefie" />
        </StyledImageContainer>
        <StyledParagraphContainer>
          <StyledParagraph
            fontSize="m"
            fontWeight={mode === 'mobile' ? 'medium' : 'bold'}
            color="gray_5">
            {translate('auth:wefieThroughMobileApp')}
          </StyledParagraph>
        </StyledParagraphContainer>
        <StyledStoreImagesContainer>
          <StyledLink href={getPlayStoreLink()} target="_blank">
            <StyledImg src={googlePlayImg} alt="google-play" height={40} />
          </StyledLink>
          <StyledLink href={getAppStoreLink()} target="_blank">
            <Image src={appStoreImg} alt="app-store" height={40} />
          </StyledLink>
        </StyledStoreImagesContainer>
      </>
    ),
    [appStoreImg, googlePlayImg, mode, selfieWefie, translate],
  )

  return (
    <AuthTemplateScreen>
      <StyledContainer>
        <StyledContentContainer>
          <StyledIconContainer mode={mode}>
            <StyledBackIconCursor onClick={history.goBack}>
              <Icon type="back" size={convertUnit(20)} color="primary_5" />
            </StyledBackIconCursor>
          </StyledIconContainer>
          <StyledHeader fontSize="xl" fontWeight="bold">
            {translate('auth:wefieHeader')}
          </StyledHeader>
          {handleRenderContent}
        </StyledContentContainer>
      </StyledContainer>
    </AuthTemplateScreen>
  )
}
