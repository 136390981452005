import React, {useMemo} from 'react'
import {Layer, Source} from 'react-map-gl'
import {AnyPaint} from 'mapbox-gl'
import {
  TREE_LAYER_LAYOUT_CONFIG,
  TREE_LAYER_LAYOUT_CONFIG_CREATOR_LIVE,
  TREE_LAYER_LAYOUT_CONFIG_FOTOTREE,
} from 'consts'
import {useTheme} from 'themes'
import {FototreeMapPinpointProps} from './FototreeMapProps'

export default function FototreeMapPinpoint({
  activeFeatureData,
  cluster,
  creatorCluster,
  overlayFootprint,
  viewMode,
}: FototreeMapPinpointProps) {
  const theme = useTheme()

  const shouldRenderPinpoint = useMemo(
    () =>
      viewMode !== 'creator' ||
      (viewMode === 'creator' && overlayFootprint !== 'hidden'),
    [viewMode, overlayFootprint],
  )

  const shouldRenderPinpointCreatorLive = useMemo(
    () => viewMode !== 'fototree' && overlayFootprint === 'hidden',
    [viewMode, overlayFootprint],
  )

  const mapStylePaint = useMemo<AnyPaint>(
    () => ({
      'text-color': [
        'match',
        ['get', 'category'],
        'event',
        theme.danger_5,
        'community',
        theme.success_7,
        theme.primary_5,
      ],
      'text-halo-width': 2,
      'text-halo-color': theme.white_1,
    }),
    [theme.danger_5, theme.primary_5, theme.success_7, theme.white_1],
  )

  const mapStylePaintCreator = useMemo<AnyPaint>(
    () => ({
      'text-color': theme.primary_7,
      'text-halo-width': 1,
      'text-halo-color': theme.gray_1,
    }),
    [theme.gray_1, theme.primary_7],
  )

  const handleRenderPinpoint = useMemo(
    () => (
      <Source
        id="map-marker"
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: cluster,
        }}>
        <Layer
          id="map-fototree-layer"
          type="symbol"
          layout={{
            ...TREE_LAYER_LAYOUT_CONFIG,
            ...TREE_LAYER_LAYOUT_CONFIG_FOTOTREE,
          }}
          paint={mapStylePaint}
          filter={[
            'all',
            ['!=', 'id', activeFeatureData?.properties.id || ''],
            ['has', 'alias'],
          ]}
        />
      </Source>
    ),
    [activeFeatureData?.properties.id, cluster, mapStylePaint],
  )

  const handleRenderPinpointCreatorLive = useMemo(
    () => (
      <Source
        id="map-creator-marker"
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: creatorCluster,
        }}>
        <Layer
          id="map-creator-layer"
          type="symbol"
          layout={{
            ...TREE_LAYER_LAYOUT_CONFIG,
            ...TREE_LAYER_LAYOUT_CONFIG_CREATOR_LIVE,
          }}
          paint={mapStylePaintCreator}
        />
      </Source>
    ),
    [creatorCluster, mapStylePaintCreator],
  )

  return (
    <>
      {shouldRenderPinpointCreatorLive && handleRenderPinpointCreatorLive}
      {shouldRenderPinpoint && handleRenderPinpoint}
    </>
  )
}
