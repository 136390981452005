import {ModalMaintenanceHandler} from 'types'

let handler: ModalMaintenanceHandler | undefined

export function setModalMaintenanceHandler(instance: ModalMaintenanceHandler) {
  handler = instance
}

export function showModalMaintenance(visible: boolean, startDate: string, endDate: string) {
  handler && handler.showModal(visible, startDate, endDate)
}
