import React, {CSSProperties, useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {IMAGE_ASSET, OTP_COOLDOWN} from 'consts'
import {
  formatCooldownTime,
  formatPhoneNumber,
  maskPhone,
  showSnackbar,
  useLocation,
  useTimer,
} from 'utils'
import {requestData} from 'services'
import {AuthPhoneResendMethod} from 'types'
import {Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'
import {TemplateAuthAccessResendOtpModal} from '../../../template'

interface StyledResendProps {
  cursor: CSSProperties['cursor']
}

const StyledContentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`

const StyledHeaderDescription = styled(Paragraph)`
  margin-top: ${convertUnit(10)};
  text-align: center;
`

const StyledImgContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${convertUnit(20)} 0;
`

const StyledIllustration = styled(Image)`
  max-width: ${convertUnit(325)};
  width: 100%;
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledResendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledResend = styled(Paragraph)<StyledResendProps>`
  margin-left: ${convertUnit(5)};
  cursor: ${({cursor}) => cursor};
`

interface AuthEasySignupVerificationPhoneProps {
  shouldCensor?: boolean
  showResend?: boolean
}
export default function AuthEasySignupVerificationPhone({
  shouldCensor = true,
  showResend = true,
}: AuthEasySignupVerificationPhoneProps) {
  const {translate} = useTranslation()
  const {method, phone_code, phone_number, identifier} = useLocation(
    'auth_easy_signup_verification',
  ).state
  const whatsappConfirmImage = IMAGE_ASSET('giftshop', 'whatsapp-confirm.png')
  const phoneConfirmImage = IMAGE_ASSET('giftshop', 'mobile-confirm.png')
  const {update} = useDispatch()
  const stateSelectedMethod = useState<AuthPhoneResendMethod>(
    method as AuthPhoneResendMethod,
  )
  const selectedMethod = stateSelectedMethod[0]
  const [image, setImage] = useState<AuthPhoneResendMethod>(
    method as AuthPhoneResendMethod,
  )
  const {phoneOTPTime} = useSelector('lastUserState')
  const phoneMask =
    phone_code && phone_number ? maskPhone(phone_code, phone_number) : ''
  const phoneDash =
    phone_code && phone_number
      ? `+${phone_code} ${formatPhoneNumber(phone_number)}`
      : ''
  const [isVisible, setIsVisible] = useState(false)
  const [isLimit, setIsLimit] = useState(false)

  const today = new Date().getTime() / 1000
  const {countdown, reset} = useTimer({
    duration:
      phoneOTPTime && today - phoneOTPTime / 1000 <= OTP_COOLDOWN
        ? OTP_COOLDOWN - (today - phoneOTPTime / 1000)
        : OTP_COOLDOWN,
    countOnStart: true,
  })

  const countDownMinute = Math.floor(countdown / 60)
  const countDownSecond = countdown - 60 * countDownMinute

  const handleResend = useCallback(() => {
    requestData('auth_easy_signup_resend', {
      data: {
        identifier: identifier || `${phone_code}${phone_number}`,
        method: selectedMethod,
      },
      onRequestSuccess: ({status, data}) => {
        if (status === 200) {
          reset(OTP_COOLDOWN)
          update('lastUserState', {
            phoneOTPTime: new Date().getTime(),
          })
        } else if (status === 429) {
          const errorDetail = data.result
          if (errorDetail.is_limit) {
            setIsLimit(true)
          }
          const {hour, minute, second} = formatCooldownTime(
            errorDetail.resend_in,
          )
          showSnackbar(
            translate(
              errorDetail.is_limit
                ? 'auth:registerEasySignupResendLinkLimitSnackbar'
                : 'auth:registerEasySignupResendLinkCooldownSnackbar',
              {
                hour,
                minute,
                second,
              },
            )
              .replace(
                hour === ''
                  ? /<hourStamp>.*?<\/hourStamp>/
                  : /<hourStamp>|<\/hourStamp>/g,
                '',
              )
              .replace(
                minute === ''
                  ? /<minuteStamp>.*?<\/minuteStamp>/
                  : /<minuteStamp>|<\/minuteStamp>/g,
                '',
              )
              .replace(/<secondStamp>|<\/secondStamp>/g, ''),
            [
              {
                pattern: /<timestamp>.*?<\/timestamp>/,
                style: {
                  fontFamily: 'Roboto-Bold',
                },
                renderText: (text) =>
                  text.replace(/<timestamp>|<\/timestamp>/g, ''),
              },
            ],
          )
        } else {
          showSnackbar(
            translate('global:messageError', {context: `code-${status}`}),
          )
        }
      },
    })
  }, [
    identifier,
    phone_code,
    phone_number,
    reset,
    selectedMethod,
    translate,
    update,
  ])

  const handleSelectResendMethod = useCallback(
    (selected: AuthPhoneResendMethod) => {
      setImage(selected)
      setIsVisible(false)
      handleResend()
    },
    [handleResend],
  )

  const handleRenderResend = useMemo(
    () => (
      <StyledResendContainer>
        <Paragraph color="gray_5">
          {translate('auth:verificationWhatsappDidNotGet')}
        </Paragraph>
        <StyledResend
          color={countdown === 0 && !isLimit ? 'primary_5' : 'gray_3'}
          fontWeight="medium"
          cursor={countdown === 0 && !isLimit ? 'pointer' : 'default'}
          onClick={() => countdown === 0 && !isLimit && setIsVisible(true)}>
          {countdown === 0
            ? translate('auth:forgotPasswordPhoneResend')
            : translate('auth:forgotPasswordPhoneResendCountdown', {
                countdown:
                  countdown > 60
                    ? `${countDownMinute}m${countDownSecond}`
                    : `${countDownSecond}`,
              })}
        </StyledResend>
      </StyledResendContainer>
    ),
    [countDownMinute, countDownSecond, countdown, isLimit, translate],
  )

  const handleRenderTitle = useMemo(
    () => (
      <>
        <StyledParagraph fontSize="xl" fontWeight="bold">
          {shouldCensor ? phoneMask : phoneDash}
        </StyledParagraph>
        <StyledHeaderDescription fontSize="m" color="gray_5">
          {translate('auth:registerEasySignupVerifMobileHeaderDescription', {
            context: image,
          })}
        </StyledHeaderDescription>
      </>
    ),
    [image, phoneDash, phoneMask, shouldCensor, translate],
  )

  const handleRenderMidSection = useMemo(
    () => (
      <>
        <StyledImgContainer>
          <StyledIllustration
            src={
              image === 'whatsapp' ? whatsappConfirmImage : phoneConfirmImage
            }
            alt="Illustration"
          />
        </StyledImgContainer>
        {showResend ? handleRenderResend : <></>}
      </>
    ),
    [
      handleRenderResend,
      image,
      phoneConfirmImage,
      showResend,
      whatsappConfirmImage,
    ],
  )

  return (
    <StyledContentContainer>
      <TemplateAuthAccessResendOtpModal
        isVisible={isVisible}
        stateSelectedMethod={stateSelectedMethod}
        onConfirm={handleSelectResendMethod}
        onCancel={() => setIsVisible(false)}
        userInfo={{phoneCode: phone_code!, phoneNumber: phone_number!}}
      />
      {handleRenderTitle}
      {handleRenderMidSection}
    </StyledContentContainer>
  )
}
