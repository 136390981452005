import React, {useMemo, useRef, useState} from 'react'
import AvatarEditor from 'react-avatar-editor'
import styled from 'styled-components'
import {HOST_IMAGE_TYPE, WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {cropImage, useDidMount, useTreeImageFile} from 'utils'
import {
  Button,
  Icon,
  Image,
  ImageCropper,
  ModalLoading,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateProfilePhotoModal} from 'pages/template'
import {TreePlantUploadImageProps} from './TreePlantUploadImageProps'

const StyledUploadImageContainer = styled.div`
  display: flex;
`

const StyledButtonContainer = styled.div`
  margin-top: ${convertUnit(20)};
`

const StyledUploadButton = styled(Button)`
  height: ${convertUnit(32)};
  max-width: ${convertUnit(200)};
  margin-bottom: ${convertUnit(8)};
`

const StyledProfileContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-left: ${convertUnit(40)};

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    margin-left: ${convertUnit(20)};
  }
`

const StyledProfilePicture = styled(Image)`
  aspect-ratio: 0.75;
  width: ${convertUnit(108)};
  border-radius: ${convertUnit(8)};
  object-fit: contain;
  cursor: pointer;
`

const StyledProfilePictureDefault = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({theme}) => theme.white_5};
  aspect-ratio: 0.75;
  width: ${convertUnit(108)};
  border-radius: ${convertUnit(8)};
`

const StyledImageUpload = styled.input`
  display: none;
`

const StyledFileName = styled(Paragraph)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${convertUnit(200)};
`

export default function TreePlantUploadImage({
  imageForm,
}: TreePlantUploadImageProps) {
  const {translate} = useTranslation()
  const inputImageRef = useRef<HTMLInputElement>(null)
  const canvas = useRef<AvatarEditor>(null)
  const stateCrop = useState(false)
  const stateTempImage = useState<File>()
  const stateLoading = useState(false)
  const loading = stateLoading[0]
  const [crop, setCrop] = stateCrop
  const [tempImage, setTempImage] = stateTempImage
  const [previewModal, setPreviewModal] = useState(false)

  const {register: registerImg, watch: watchImg} = imageForm
  const {imgUrl} = watchImg()

  const {handleCrop, handleCheckImage} = useTreeImageFile({
    ref: inputImageRef,
    form: imageForm,
    stateCrop,
    stateTempImage,
    stateLoading,
    isUpload: true,
  })

  const cameraIcon = useMemo(
    () => (
      <Icon
        type="camera"
        color="white_1"
        size={16}
        marginRight={convertUnit(12)}
      />
    ),
    [],
  )

  const handleRenderLoadingModal = useMemo(
    () => <ModalLoading visible={loading} />,
    [loading],
  )

  const handleRenderCropperModal = useMemo(
    () =>
      tempImage ? (
        <ImageCropper
          shape="portrait"
          borderRadius={0}
          visible={crop}
          componentRef={canvas}
          file={tempImage}
          onBackdropPress={() => {
            setTempImage(undefined)
            setCrop(false)
          }}
          onClickSave={() => {
            !loading && cropImage(canvas, 'tree-profile', handleCrop)
          }}
        />
      ) : null,
    [crop, handleCrop, loading, setCrop, setTempImage, tempImage],
  )

  const handleRenderImageModal = useMemo(
    () =>
      previewModal && (
        <TemplateProfilePhotoModal
          src={imgUrl}
          title={translate('tree:profilePicture')}
          toggleModal={() => setPreviewModal((prev) => !prev)}
        />
      ),
    [imgUrl, previewModal, translate],
  )

  const handleRenderImage = useMemo(
    () => (
      <StyledUploadImageContainer>
        {imgUrl ? (
          <StyledProfilePicture
            src={imgUrl}
            alt="tree-profile-picture"
            onClick={() => setPreviewModal(true)}
          />
        ) : (
          <StyledProfilePictureDefault>
            <Icon type="tree-feature" color="gray_5" size={40} />
          </StyledProfilePictureDefault>
        )}
        <StyledProfileContainer>
          <div>
            <Paragraph fontSize="l" fontWeight="medium" lineHeight={31}>
              {translate('tree:profilePicture')}
            </Paragraph>
            <StyledFileName fontSize="m" color="gray_3">
              {imgUrl && inputImageRef.current?.files
                ? inputImageRef.current?.files[0].name
                : translate('tree:profilePictureEmpty')}
            </StyledFileName>
          </div>
          <StyledButtonContainer>
            <StyledUploadButton
              fontSize="s"
              label={translate('tree:changePhotoButton')}
              leftIcon={cameraIcon}
              onClick={() => {
                inputImageRef.current && inputImageRef.current.click()
              }}
            />
            <Paragraph color="gray_3">
              {translate('tree:uploadRequirement')}
            </Paragraph>
          </StyledButtonContainer>
        </StyledProfileContainer>
      </StyledUploadImageContainer>
    ),
    [cameraIcon, imgUrl, translate],
  )

  useDidMount(() => {
    registerImg('imgFile')
    registerImg('imgUrl')
    registerImg('imgExtension')
    registerImg('imgHeight')
    registerImg('imgSize')
    registerImg('imgWidth')
    registerImg('imgMime')
  })

  return (
    <>
      {handleRenderLoadingModal}
      {handleRenderCropperModal}
      {handleRenderImageModal}
      {handleRenderImage}
      <StyledImageUpload
        type="file"
        ref={inputImageRef}
        accept={HOST_IMAGE_TYPE.join(',')}
        onChange={handleCheckImage}
        onClick={() => {
          inputImageRef.current && (inputImageRef.current.value = '')
        }}
      />
    </>
  )
}
