import React from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_CREATION_BADGE_BACKGROUND_COLORS,
  GIFT_SHOP_CREATION_BADGE_ICONS,
} from 'consts'
import {useTranslation} from 'i18n'
import {ThemeColor} from 'themes'
import {getThemeColor} from 'utils'
import {useWindowMode} from 'windows'
import convertUnit from 'lib/unit'
import {Icon, Paragraph} from 'common/components'
import {GiftShopTemplateContentBadgeProps} from './GiftShopTemplateContentBadgeProps'

interface StyledBadgeContainerProps {
  backgroundColor: ThemeColor
}

const StyledBadgeContainer = styled.div<StyledBadgeContainerProps>`
  padding: ${convertUnit(2)} ${convertUnit(8)};
  position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  border-radius: 0 0 ${convertUnit(5)} 0;
  background-color: ${({theme, backgroundColor}) => theme[backgroundColor]};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledUnwishlistBadgeContainer = styled.div`
  padding: ${convertUnit(4)} ${convertUnit(12)};
  position: absolute;
  top: ${convertUnit(8)};
  left: ${convertUnit(8)};
  width: fit-content;
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => getThemeColor(theme, 'black', 0.5)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledLeftIconContainer = styled.div`
  margin-right: ${convertUnit(6)};
`

export default function GiftShopTemplateContentBadge({
  type,
  isDeletable,
}: GiftShopTemplateContentBadgeProps) {
  const mode = useWindowMode()
  const {translate} = useTranslation()

  return type === 'resend' || type === 'sold' || type === 'resendpology' ? (
    <StyledBadgeContainer
      backgroundColor={GIFT_SHOP_CREATION_BADGE_BACKGROUND_COLORS[type]}>
      {(type === 'resend' ||
        type === 'sold' ||
        type === 'resendpology' ||
        isDeletable) && (
        <StyledLeftIconContainer>
          <Icon
            type={GIFT_SHOP_CREATION_BADGE_ICONS[type]}
            size={convertUnit(16)}
            color="white_1"
          />
        </StyledLeftIconContainer>
      )}
      <Paragraph
        fontSize={mode === 'website' ? 'm' : 's'}
        fontWeight="medium"
        color="white_1">
        {translate('giftShop:creationBadge', {context: type})}
      </Paragraph>
    </StyledBadgeContainer>
  ) : (
    <StyledUnwishlistBadgeContainer>
      {isDeletable && (
        <Icon
          type={GIFT_SHOP_CREATION_BADGE_ICONS.unwishlist}
          size={convertUnit(16)}
          color="white_1"
        />
      )}
    </StyledUnwishlistBadgeContainer>
  )
}
