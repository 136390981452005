import React, {useCallback, useMemo} from 'react'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopRootEmpty} from 'pages'
import {requestData} from 'services'
import {GiftShopTemplateDetailContent} from '../../../template'

export default function GiftShopRecoverContent() {
  const {translate} = useTranslation()
  const emptyImg = IMAGE_ASSET('giftshop', 'empty.png')

  const handleLoadData = useCallback(async (page: number, limit: number) => {
    const response = await requestData('giftshop_get_explore_trash', {
      actionType: 'fetch',
      params: {
        page,
        limit,
      },
    })

    if (typeof response !== 'string' && response.status === 200) {
      return response.data.result.filter((item) => item.is_reported !== true)
    }

    return []
  }, [])

  const handleRenderListEmpty = useMemo(
    () => (
      <GiftShopRootEmpty
        image={emptyImg}
        imageAlt={emptyImg}
        title={translate('giftShop:removedContentEmptyTitle')}
        message={translate('giftShop:removedContentEmptySubtitle')}
      />
    ),
    [emptyImg, translate],
  )

  return (
    <GiftShopTemplateDetailContent
      isRemoved
      showPreviewBadge
      redirectCreator
      loadData={handleLoadData}
      listEmptyElement={handleRenderListEmpty}
    />
  )
}
