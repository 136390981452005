import {TranslationKeyForkygram} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_FORKYGRAM_EN: TranslationResource<TranslationKeyForkygram> = {
  translation: {
    'forkygram:addLocation': 'Add Location',
    'forkygram:authAccessDescription':
      'Please log in to be able to access the features of the app, or register if you don’t have an account yet.',
    'forkygram:authAccessHeader': 'Login',
    'forkygram:bannedSnackbar': 'Yuser not found!',
    'forkygram:block': 'Block',
    'forkygram:blockedDescription':
      'You can not see the photos or videos because you have blocked this yuser.',
    'forkygram:blockYuser': 'Block Yuser',
    'forkygram:blockYuserAcc': 'Block Yuser Account',
    'forkygram:blockYuserAccSuccess': 'Account successfully blocked',
    'forkygram:blockYuserAccText': 'Are you sure want to block yuser account?',
    'forkygram:blockYuserProfile': 'Are you sure to block this yuser account?',
    'forkygram:caption': 'Caption',
    'forkygram:captionInputPlaceholder':
      'Add a caption here (Max. 300 characters)',
    'forkygram:comment': 'Comment',
    'forkygram:commentLimitPerDay':
      'Sorry! You can only leave {{comment_limit}} comments/day.',
    'forkygram:commentHeader': 'Comment ({{comment_count}})',
    'forkygram:commentHeader_plural': 'Comments ({{comment_count}})',
    'forkygram:commentPlaceholder': 'comment as {{username}}',
    'forkygram:commentPlaceholder_GUEST':
      'Please log in or register to leave comments.',
    'forkygram:continue': 'Continue',
    'forkygram:count': '{{count}}',
    'forkygram:count_B': '{{count}}B',
    'forkygram:count_K': '{{count}}K',
    'forkygram:count_M': '{{count}}M',
    'forkygram:countUploadDuration': '{{count}} seconds ago',
    'forkygram:countUploadDuration_minute': '{{count}} minutes ago',
    'forkygram:countUploadDuration_hour': '{{count}} hours ago',
    'forkygram:countUploadDuration_day': '{{count}} days ago',
    'forkygram:deletePostMessage': 'Are you sure want to delete this post?',
    'forkygram:deletePostTitle': 'Delete Post',
    'forkygram:deletePostComment': 'Delete Comment',
    'forkygram:deletePostCommentMessage':
      'Are you sure want to delete this comment?',
    'forkygram:emptyComment': "You don't have any comments yet.",
    'forkygram:emptyFeedMessage': 'There is no more content to show for now.',
    'forkygram:emptyFeedTitle': "Oops, looks like you've seen it all!",
    'forkygram:emptyLikeMessage':
      'You can see a list of people who like your post here.',
    'forkygram:emptyLikeTitle': 'People Who Like Your Post',
    'forkygram:follow': 'Follow',
    'forkygram:followerCount': '<follow>{{follow}}</follow> Follower',
    'forkygram:followerCount_plural': '<follow>{{follow}}</follow> Followers',
    'forkygram:followers': 'Followers',
    'forkygram:following': 'Following',
    'forkygram:followingCount': '<follow>{{follow}}</follow> Following',
    'forkygram:fototreeTagMismatch': 'Please select a FotoTree from the list.',
    'forkygram:fototreeTagNotFound': 'We cannot find FotoTree {{tag_name}}',
    'forkygram:giftshop': 'Giftshop',
    'forkygram:home': 'Home',
    'forkygram:hideReply': 'Hide replies',
    'forkygram:leafs': 'Leafs',
    'forkygram:likes': 'Likes',
    'forkygram:likeLimitPerHour': 'Sorry! You can only like 350 post/hour.',
    'forkygram:likeLimitPerDay': 'Sorry! You can only like 1000 post/day.',
    'forkygram:logOutMessage': 'Are you sure you want to log out?',
    'forkygram:message': 'Message',
    'forkygram:myAccount': 'My Account',
    'forkygram:noCollection': 'No collection',
    'forkygram:noPost': 'No Posts Yet',
    'forkygram:noPostDescription':
      "This yuser hasn't published anything here yet",
    'forkygram:notificationSettingHeader': 'Notification Settings',
    'forkygram:optional': '(Optional)',
    'forkygram:post': 'Post',
    'forkygram:postDelete': 'Delete post',
    'forkygram:postEdit': 'Edit post',
    'forkygram:postPin': 'Pin post',
    'forkygram:postPinFailed': 'Maximum pinned content is 9.',
    'forkygram:postPinSuccess': 'Post pinned to your profile.',
    'forkygram:postShare': 'Share post',
    'forkygram:postUnpin': 'Unpin post',
    'forkygram:postUnpinSuccess': 'Post unpinned from your profile.',
    'forkygram:postEmptyButton': 'Upload Now',
    'forkygram:postEmptyDescription':
      "You haven't uploaded your moments yet. We're excited for your amazing moments.",
    'forkygram:postEmptyHeader': 'Your Moments',
    'forkygram:postUpdated': 'Post Updated',
    'forkygram:privateAccount': 'This Account is Private',
    'forkygram:pushNotification': 'Push Notification',
    'forkygram:registerOption': 'Use email or mobile number',
    'forkygram:reply': 'Reply',
    'forkygram:replying': 'Replying to',
    'forkygram:reportComment': 'Report Comment',
    'forkygram:reportComment_HATE SPEECH': 'Hate speech',
    'forkygram:reportComment_SPAM': 'Spam comment',
    'forkygram:reportComment_HARRASMENT': 'Bullying or harrasment',
    'forkygram:reportComment_OTHER': 'Other issues',
    'forkygram:reportCommentCancel': 'Report canceled.',
    'forkygram:reportCommentSuccess':
      'Thank you for reporting. The comment has been successfully reported.',
    'forkygram:reportCommentFailed':
      "There's a problem when reporting the comment.",
    'forkygram:reportCommentAlreadyReported':
      'You already reported this comment.',
    'forkygram:reportCancel': 'Report cancelled.',
    'forkygram:reportOtherPlaceholder': 'Write your reason here...',
    'forkygram:reportPostFailed': "There's a problem when reporting the post.",
    'forkygram:reportPostSuccess':
      'Thank you for reporting. The post has been successfully reported.',
    'forkygram:reportYuser': 'Report Yuser',
    'forkygram:reportYuserFailed':
      "There's a problem when report this account.",
    'forkygram:reportYuserSuccess':
      'Thank you for reporting. This account has been successfully reported.',
    'forkygram:shareYuser': 'Share Yuser',
    'forkygram:searchFollowersPlaceholder': 'Search Followers',
    'forkygram:searchFollowingPlaceholder': 'Search Following',
    'forkygram:searchFriends': 'Search Friends',
    'forkygram:searchFriendsNotFound':
      "Sorry! We cannot find the user you're looking for.",
    'forkygram:searchFriendsNotFoundSubtitle':
      'Please try again using another keyword.',
    'forkygram:tagged': 'Tagged',
    'forkygram:taggedEmptyDescription':
      'When other people tags you in their post, it will be shown here.',
    'forkygram:taggedEmptyHeader': 'No tagged posts',
    'forkygram:treeHeight': 'Tree Height',
    'forkygram:unblock': 'Unblock',
    'forkygram:unblockSuccessMessage': 'Account successfully unblocked',
    'forkygram:uploadHeader': 'Content Info',
    'forkygram:uploadInvalidImageFormat':
      'The image file is invalid or the image type is not allowed. Only files with the following extension are allowed: jpg, jpeg, png, heic, and heif',
    'forkygram:uploadInvalidVideoFormat':
      'The video file is invalid or the video type is not allowed. Only files with the following extension are allowed: mp4(mpeg-4), and mov',
    'forkygram:uploadMaxImageResolutionMessage':
      'Maximum image resolution is 25 megapixels',
    'forkygram:uploadMaxImageSizeMessage': 'Maximum image file size is 5 MB',
    'forkygram:uploadMaxVideoDurationMessage':
      'Maximum video length is 30 seconds',
    'forkygram:uploadMaxVideoSizeMessage': 'Maximum video file size is 30 MB',
    'forkygram:uploadMaxVideoWidthMessage':
      'Maximum video width is 1080 pixels',
    'forkygram:uploadMinImageResolutionMessage':
      'Minimum image resolution is 600x600 pixels',
    'forkygram:uploadPostActivateRoboyuBottomDesc':
      'When your RoboYu is activated, you can come back here to start uploading your content.',
    'forkygram:uploadPostActivateRoboyuUpperDesc':
      'You need to activate your RoboYu first before uploading your first content.',
    'forkygram:uploadSearchLocationPlaceholder': 'Search Location',
    'forkygram:uploadSearchPeoplePlaceholder': 'Search by username',
    'forkygram:uploadSuccessMessage':
      'Congrats! The image has been successfully posted',
    'forkygram:uploadLeafSuccessMessage': 'You have leaf-t a memory',
    'forkygram:uploadTagPeople': 'Tag People ',
    'forkygram:uploadTagPeopleCount': '{{count}} Person Tagged',
    'forkygram:uploadTagPeopleCount_plural': '{{count}} People Tagged',
    'forkygram:viewReply': 'View {{count}} more reply',
    'forkygram:viewReply_plural': 'View {{count}} more replies',
  },
}
