import React, {useCallback, useMemo, useRef, useState} from 'react'
import Lottie from 'lottie-react'
import styled from 'styled-components'
import {Paragraph, Icon, ConfirmationModal} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {requestData} from 'services'
import {ForkygramCommentItemType, TreeFototreeCommentResponse} from 'types'
import {useDidUpdate} from 'utils'
import animationData from 'assets/lottie/activity_indicator.json'
import {TREE_GLOBAL_CHAT_REPLY_LIMIT} from 'consts'
import {FototreeGlobalChatItemProps} from './FototreeGlobalChatProps'
import FototreeGlobalChatItemContent from './FototreeGlobalChatItemContent'

interface StyledContainerProps {
  isPreview: boolean
}

const StyledContainer = styled.div<StyledContainerProps>`
  padding: ${({isPreview}) =>
    `${convertUnit(isPreview ? 4 : 8)} ${convertUnit(isPreview ? 12 : 20)}`};
`

const StyledReplyContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${convertUnit(16)};
  margin-left: ${convertUnit(42)};
`

const StyledViewReplyContainer = styled.div`
  display: flex;
  gap: ${convertUnit(12)};
  cursor: pointer;
  user-select: none;
  width: fit-content;
`

const StyledViewMoreReplyContainer = styled(StyledViewReplyContainer)`
  margin-top: ${convertUnit(-8)};
`

const StyledLottie = styled(Lottie)`
  width: ${convertUnit(10)};
  height: ${convertUnit(10)};
`

export default function FototreeGlobalChatItem({
  data,
  treeId,
  overlay,
  userType,
  stateRecipient,
  stateSearch,
  stateNewReply,
}: FototreeGlobalChatItemProps) {
  const {translate} = useTranslation()
  const currReplyPage = useRef(1)
  const [loading, setLoading] = useState(false)
  const [loadReply, setLoadReply] = useState(false)
  const [replyData, setReplyData] = useState<TreeFototreeCommentResponse[]>([])
  const stateDeleteModal = useState(false)
  const stateCommentType = useState<ForkygramCommentItemType>('comment')
  const recipient = stateRecipient[0]
  const setSearch = stateSearch[1]
  const [newReply, setNewReply] = stateNewReply
  const [deleteModal, setDeleteModal] = stateDeleteModal
  const [commentType, setCommentType] = stateCommentType

  const replyNotViewed = useMemo(
    () => Math.max(0, data.reply_count - replyData.length),
    [data.reply_count, replyData.length],
  )

  const handleLoadReplyData = useCallback(
    async (page: number, limit: number) => {
      setLoading(true)
      const response = await requestData('tree_get_fototree_comment_reply', {
        useDefaultMessage: true,
        params: {
          page,
          limit,
          tree_id: treeId,
          comment_id: data.id,
        },
      })

      if (typeof response !== 'string' && response.status === 200) {
        setReplyData((prev) => [...prev, ...response.data.result])
      }
      setLoading(false)
    },
    [data.id, treeId],
  )

  const handleDeletedReply = useCallback(() => {
    if (replyData.length === 1) {
      setLoadReply(false)
      setReplyData([])
    } else {
      setReplyData((prev) =>
        prev.filter((item) => item.id !== recipient.comment_id),
      )
    }
  }, [recipient.comment_id, replyData.length])

  const handleDeleteComment = useCallback(() => {
    requestData(
      commentType === 'comment'
        ? 'tree_delete_fototree_comment'
        : 'tree_delete_fototree_comment_reply',
      {
        useDefaultMessage: true,
        data: {id: recipient.comment_id},
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setSearch(new Date().getTime())
            if (commentType === 'reply') {
              handleDeletedReply()
            }
          }
        },
      },
    )
  }, [commentType, handleDeletedReply, recipient.comment_id, setSearch])

  const handlePressViewReply = useCallback(() => {
    setNewReply(undefined)
    if (loadReply) {
      currReplyPage.current = 1
      setReplyData([])
      setLoadReply(false)
    } else {
      setLoadReply(true)
    }
  }, [loadReply, setNewReply])

  const handleCloseDeleteModal = useCallback(() => {
    setDeleteModal(false)
    setCommentType('comment')
  }, [setDeleteModal, setCommentType])

  const handleRenderDeleteConfirmation = useMemo(
    () => (
      <ConfirmationModal
        visible={deleteModal}
        cancelText={translate('global:cancel')}
        confirmText={translate('global:yesImSure')}
        title={translate('forkygram:deletePostComment')}
        text={translate('forkygram:deletePostCommentMessage')}
        onConfirm={handleDeleteComment}
        onBackdropPress={handleCloseDeleteModal}
        toggleModal={handleCloseDeleteModal}
      />
    ),
    [deleteModal, handleCloseDeleteModal, handleDeleteComment, translate],
  )

  const handleRenderLoading = useMemo(
    () =>
      loading && (
        <StyledLottie
          animationData={animationData}
          height={convertUnit(10)}
          width={convertUnit(10)}
        />
      ),
    [loading],
  )

  const handleRenderViewReply = useMemo(
    () =>
      data.reply_count > 0 && (
        <StyledViewReplyContainer onClick={handlePressViewReply}>
          <Icon
            type={loadReply ? 'drop-up' : 'drop-down'}
            size={16}
            color="primary_3"
          />
          <Paragraph fontWeight="bold" color="primary_3">
            {loadReply
              ? translate('forkygram:hideReply')
              : translate('forkygram:viewReply', {count: data.reply_count})}
          </Paragraph>
          {currReplyPage.current === 1 && handleRenderLoading}
        </StyledViewReplyContainer>
      ),
    [
      data.reply_count,
      handlePressViewReply,
      handleRenderLoading,
      loadReply,
      translate,
    ],
  )

  const handleRenderViewMoreReply = useMemo(() => {
    if (currReplyPage.current === 1 && loading) return null

    if (loadReply && replyNotViewed) {
      return (
        <StyledViewMoreReplyContainer
          onClick={() => {
            handleLoadReplyData(
              currReplyPage.current + 1,
              TREE_GLOBAL_CHAT_REPLY_LIMIT,
            )
            currReplyPage.current += 1
          }}>
          <Paragraph fontWeight="medium" color="primary_3">
            {translate('forkygram:viewReply', {count: replyNotViewed})}
          </Paragraph>
          {currReplyPage.current > 1 && handleRenderLoading}
        </StyledViewMoreReplyContainer>
      )
    }
    return null
  }, [
    handleLoadReplyData,
    handleRenderLoading,
    loadReply,
    loading,
    replyNotViewed,
    translate,
  ])

  const handleRenderComment = useMemo(
    () => (
      <FototreeGlobalChatItemContent
        data={data}
        overlay={overlay}
        userType={userType}
        showReply
        stateRecipient={stateRecipient}
        stateDeleteModal={stateDeleteModal}
      />
    ),
    [data, overlay, stateDeleteModal, stateRecipient, userType],
  )

  const handleRenderReply = useMemo(
    () =>
      replyData &&
      replyData.map((item) => (
        <FototreeGlobalChatItemContent
          key={item.id}
          data={item}
          overlay={overlay}
          userType={userType}
          stateRecipient={stateRecipient}
          stateDeleteModal={stateDeleteModal}
          onReply={() => setCommentType('reply')}
        />
      )),
    [
      overlay,
      replyData,
      setCommentType,
      stateDeleteModal,
      stateRecipient,
      userType,
    ],
  )

  useDidUpdate(() => {
    if (loadReply) {
      handleLoadReplyData(currReplyPage.current, TREE_GLOBAL_CHAT_REPLY_LIMIT)
    }
  }, [handleLoadReplyData, loadReply])

  useDidUpdate(() => {
    if (loadReply && newReply) {
      setReplyData((prev) => [newReply, ...prev])
      setNewReply(undefined)
    }
  }, [loadReply, newReply])

  return (
    <StyledContainer isPreview={overlay !== 'full'}>
      {handleRenderDeleteConfirmation}
      {handleRenderComment}
      {overlay === 'full' && (
        <StyledReplyContainer>
          {handleRenderViewReply}
          {handleRenderReply}
          {handleRenderViewMoreReply}
        </StyledReplyContainer>
      )}
    </StyledContainer>
  )
}
