export function getAPIBaseUrl() {
  return process.env.API_BASE_URL
}

export function getCDNBaseUrl() {
  return process.env.CDN_BASE_URL
}

export function getOAuthClientId() {
  return process.env.OAUTH_CLIENT_ID!
}

export function getWebBaseUrl() {
  return process.env.WEB_BASE_URL
}

export function getAppleSSOClientId() {
  return process.env.OAUTH_APPLE_CLIENT_ID
}

export function getAppleSSORedirectUri() {
  return process.env.OAUTH_APPLE_REDIRECT_URI
}

export function getHowItWorksWebUrl() {
  return process.env.HOST_WEB_HOW_IT_WORKS
}

export function getSupergigaWebUrl() {
  return process.env.HOST_WEB_SUPERGIGA
}

export function getPlayStoreLink() {
  return process.env.HOST_PLAY_STORE
}

export function getAppStoreLink() {
  return process.env.HOST_APP_STORE
}

export function getWhatsAppLink() {
  return process.env.HOST_WHATSAPP
}

export function getInstagramLink() {
  return process.env.HOST_INSTAGRAM
}

export function getAuthToS() {
  return process.env.HOST_TERMS_OF_USE
}

export function getAuthPrivacy() {
  return process.env.HOST_PRIVACY_POLICY
}

export function getAuthCookie() {
  return process.env.HOST_COOKIE_POLICY
}

export function getCommunityGuideline() {
  return process.env.HOST_COMMUNITY_GUIDELINES
}

export function getCopyright() {
  return process.env.HOST_COPYRIGHT
}

export function getHostBiometric() {
  return process.env.HOST_BIOMETRIC
}

export function getCreatorHostPolicy() {
  return process.env.HOST_CREATOR_HOST
}

export function getUnderagePolicy() {
  return process.env.HOST_UNDERAGE_POLICY
}

export function getReasonOfUsage() {
  return process.env.HOST_REASON_OF_USAGE
}
