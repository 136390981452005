import { CSSProperties } from 'react'
import { FONT_FAMILY_MAP } from 'consts'
import { FontSizeType, FontWeightType } from 'types'
import convertUnit from 'lib/unit'

export function getFontSize(
  size: FontSizeType,
) {
  let unit: CSSProperties['fontSize'] = 12

  switch (size) {
    case 'xxl':
      unit = 30
      break
    case 'xl':
      unit = 24
      break
    case 'l':
      unit = 18
      break
    case 'm':
      unit = 15
      break
    case 'xs':
      unit = 10
      break
    case 'xxs':
      unit = 8
      break
  }

  return convertUnit(unit)
}

export function getFontFamily(type: FontWeightType) {
  return FONT_FAMILY_MAP[type]
}
