import React, {useEffect, useMemo} from 'react'
import {MapRef} from 'react-map-gl'
import Supercluster, {PointFeature} from 'supercluster'
import {TREE_MAP_MIN_ZOOM} from 'consts'
import {
  GiftshopLiveLocationData,
  TreeCreatorLiveFeatureData,
  TreeFotoTreeMapPointData,
  TreeFotoTreeResponse,
} from 'types'

type UseMapImageOptions = {
  mapRef: React.RefObject<MapRef>
  url: string[]
  name: string[]
  sdf?: boolean
}

type UseMapSourceOptions = {
  data: TreeFotoTreeResponse[]
  dataCreator: GiftshopLiveLocationData[]
  zoomLevel: number
}

export function useMapImage({
  mapRef,
  url,
  name,
  sdf = false,
}: UseMapImageOptions) {
  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap()

      url.forEach((urlElem, index) => {
        const nameElem = name[index]
        if (!map.hasImage(nameElem)) {
          map.loadImage(urlElem, (error, image) => {
            if (error) throw error
            map.addImage(nameElem, image, {sdf})
          })
        }
      })
    }
  }, [mapRef, name, sdf, url])
}

export function useMapSource({
  data,
  dataCreator,
  zoomLevel,
}: UseMapSourceOptions) {
  const featureData = useMemo<PointFeature<TreeFotoTreeMapPointData>[]>(
    () =>
      data.map((item) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [item.location.longitude, item.location.latitude],
        },
        properties: {
          alias: item.alias,
          name: item.name,
          id: item.id,
          category: item.category,
          leaf_count: item.leaf_count,
        },
      })),
    [data],
  )

  const featureDataCreator = useMemo<
    PointFeature<TreeCreatorLiveFeatureData>[]
  >(
    () =>
      dataCreator.map((item) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [item.location.long, item.location.lat],
        },
        properties: {
          distance: item.distance,
          created_at: new Date(item.created_at).getTime(),
          expired_at: item.expired_at,
          member_id: item.member.id,
          member_url: item.member.url,
          member_username: item.member.username,
        },
      })),
    [dataCreator],
  )

  const clusterSource = useMemo(() => {
    const index = new Supercluster({
      minZoom: TREE_MAP_MIN_ZOOM,
      maxZoom: 15,
      reduce: (acc, props) => {
        if (props.leaf_count > acc.leaf_count) {
          Object.assign(acc, props)
        }
      },
    })
    return index.load(featureData)
  }, [featureData])

  const creatorClusterSource = useMemo(() => {
    const index = new Supercluster({
      minZoom: TREE_MAP_MIN_ZOOM,
      maxZoom: 15,
      reduce: (acc, props) => {
        if (props.created_at > acc.created_at) {
          Object.assign(acc, props)
        }
      },
    })
    return index.load(featureDataCreator)
  }, [featureDataCreator])

  const cluster = useMemo(
    () =>
      clusterSource.getClusters(
        /**
         * @todo: find the actual bounding box (coordinates).
         */
        [180, -90, 180, 90],
        zoomLevel,
      ),
    [clusterSource, zoomLevel],
  )

  const creatorCluster = useMemo(
    () =>
      creatorClusterSource.getClusters(
        /**
         * @todo: find the actual bounding box (coordinates).
         */
        [180, -90, 180, 90],
        zoomLevel,
      ),
    [creatorClusterSource, zoomLevel],
  )

  return useMemo(
    () => ({
      cluster,
      creatorCluster,
    }),
    [cluster, creatorCluster],
  )
}
