import {
  AuthForgotPasswordEmailScreen,
  AuthForgotPasswordPhoneScreen,
  AuthForgotPasswordScreen,
  AuthLoginScreen,
  AuthLoginWefieScreen,
  AuthLoginUnderageScreen,
  AuthRegisterSSOScreen,
  AuthResetPasswordScreen,
  AuthVerificationEmailConfirmScreen,
  AuthVerificationEmailScreen,
  AuthVerificationPhoneConfirmScreen,
  AuthVerificationPhoneScreen,
  LegalPageScreen,
  GiftShopEditProfileChangePasswordStatusScreen,
  AuthEasySignupScreen,
  AuthEasySignupSelfieScreen,
  AuthEasySignupVerificationScreen,
  AuthEasySignupWelcomeScreen,
  AuthEasySignupSSOScreen,
} from 'pages'
import {RouteMap} from '../RouteMap'
import {RouteParamAuth} from './RouteParamAuth'

export const ROUTE_MAP_AUTH: RouteMap<RouteParamAuth> = {
  auth_easy_signup: {
    component: AuthEasySignupScreen,
    path: '/register',
    permission: 'guest',
  },
  auth_easy_signup_selfie: {
    component: AuthEasySignupSelfieScreen,
    path: '/register/selfie',
    permission: 'guest',
    defaultState: null,
  },
  auth_easy_signup_sso: {
    component: AuthEasySignupSSOScreen,
    path: '/register/sso',
    defaultState: null,
    permission: 'guest',
  },
  auth_easy_signup_verification: {
    component: AuthEasySignupVerificationScreen,
    path: '/register/verification',
    permission: 'guest',
    defaultState: null,
  },
  auth_easy_signup_welcome: {
    component: AuthEasySignupWelcomeScreen,
    path: '/welcome',
  },
  auth_forgot_password: {
    component: AuthForgotPasswordScreen,
    path: '/forgot',
    permission: 'guest',
  },
  auth_forgot_password_email: {
    component: AuthForgotPasswordEmailScreen,
    path: '/forgot-email',
    defaultState: {
      email: '',
    },
    permission: 'guest',
  },
  auth_forgot_password_phone: {
    component: AuthForgotPasswordPhoneScreen,
    path: '/forgot-phone',
    defaultState: null,
    permission: 'guest',
  },
  auth_login: {
    component: AuthLoginScreen,
    path: '/login',
    permission: 'guest',
  },
  auth_login_wefie: {
    component: AuthLoginWefieScreen,
    path: '/wefie',
    permission: 'guest',
  },
  auth_login_underage: {
    component: AuthLoginUnderageScreen,
    path: '/login-underage',
    permission: 'guest',
  },
  auth_register_sso: {
    component: AuthRegisterSSOScreen,
    path: '/register-sso',
    defaultState: null,
    permission: 'guest',
  },
  auth_reset_password: {
    component: AuthResetPasswordScreen,
    path: '/reset',
  },
  auth_verification_email: {
    component: AuthVerificationEmailScreen,
    path: '/verification-email',
    defaultState: null,
  },
  auth_verification_email_confirm: {
    component: AuthVerificationEmailConfirmScreen,
    path: '/verification-email-confirm',
  },
  auth_verification_phone: {
    component: AuthVerificationPhoneScreen,
    path: '/verification-phone',
    defaultState: null,
    permission: 'guest',
  },
  auth_verification_phone_confirm: {
    component: AuthVerificationPhoneConfirmScreen,
    path: '/verification-phone-confirm',
    permission: 'guest',
  },
  giftshop_change_password_status: {
    component: GiftShopEditProfileChangePasswordStatusScreen,
    path: '/reset/confirm',
  },
  giftshop_legal: {
    component: LegalPageScreen,
    path: '/how-it-works',
  },
}
