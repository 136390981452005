import React, {useMemo} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_MOBILE_WIDTH, WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {useWindowMode} from 'windows'
import {Icon, Image, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TreeHostProfileHeaderProps} from './TreeHostProfileHeaderProps'

const StyledCoverContainer = styled.div`
  height: ${convertUnit(200)};
  width: 100%;
  position: relative;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    height: ${convertUnit(120)};
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    height: ${convertUnit(100)};
  }
`

const StyledCover = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`

const StyledCoverPlaceholder = styled.div`
  background-color: ${({theme}) => theme.gray_1};
  height: inherit;
  width: 100%;
`

const StyledLogo = styled(Image)`
  position: absolute;
  bottom: ${convertUnit(20)};
  left: ${convertUnit(20)};
  width: ${convertUnit(80)};
  height: ${convertUnit(80)};
  object-fit: cover;
  object-position: center;
  border-radius: ${convertUnit(8)};
  box-sizing: border-box;
  border: ${convertUnit(2)} solid ${({theme}) => theme.white_3};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    bottom: -${convertUnit(40)};
  }
`

const StyledLogoPlaceholder = styled.div`
  background-color: ${({theme}) => theme.gray_1};
  position: absolute;
  bottom: ${convertUnit(20)};
  left: ${convertUnit(20)};
  width: ${convertUnit(80)};
  height: ${convertUnit(80)};
  border-radius: ${convertUnit(8)};
  box-sizing: border-box;
  border: ${convertUnit(2)} solid ${({theme}) => theme.white_3};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    bottom: -${convertUnit(40)};
  }
`

const StyledHostInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(8)};
  margin: ${convertUnit(12)} 0;
  padding: 0 ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-top: ${convertUnit(48)};
  }
`

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledHostCountContainer = styled(StyledRowContainer)`
  gap: ${convertUnit(20)};
`

const StyledHostCountItems = styled(StyledRowContainer)`
  gap: ${convertUnit(4)};
`

const StyledDesktopHostInfo = styled(StyledRowContainer)`
  margin: ${convertUnit(12)} 0;
  padding-left: ${convertUnit(20)};
  padding-right: ${convertUnit(40)};
  justify-content: space-between;
`

const StyledLockIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const StyledBio = styled(Paragraph)`
  margin-top: ${convertUnit(8)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-top: 0;
  }
`

export default function TreeHostProfileHeader({
  sellCount = 0,
}: TreeHostProfileHeaderProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const {
    coverPhoto,
    logoPhoto,
    tagName,
    biodata,
    adminCount,
    creatorCount,
  } = useSelector('hostState')

  const handleRenderCover = useMemo(
    () => (
      <StyledCoverContainer>
        {logoPhoto ? (
          <StyledLogo src={logoPhoto} alt={logoPhoto} />
        ) : (
          <StyledLogoPlaceholder />
        )}
        {coverPhoto ? (
          <StyledCover src={coverPhoto} alt={coverPhoto} />
        ) : (
          <StyledCoverPlaceholder />
        )}
      </StyledCoverContainer>
    ),
    [coverPhoto, logoPhoto],
  )

  const handleRenderHashtag = useMemo(
    () => (
      <Paragraph fontSize="l" fontWeight="bold">
        {tagName}
      </Paragraph>
    ),
    [tagName],
  )

  const handleRenderSellCount = useMemo(
    () => (
      <StyledHostCountItems>
        <Paragraph fontSize="s" fontWeight="bold">
          {sellCount}
        </Paragraph>
        <Paragraph fontSize="s" color="gray_5">
          {translate('tree:hostProfileSellCount')}
        </Paragraph>
        <StyledLockIconContainer>
          <Icon type="lock" size={convertUnit(12)} color="gray_5" />
        </StyledLockIconContainer>
      </StyledHostCountItems>
    ),
    [sellCount, translate],
  )

  const handleRenderCreatorCount = useMemo(
    () => (
      <StyledHostCountItems>
        <Paragraph fontSize="s" fontWeight="bold">
          {creatorCount}
        </Paragraph>
        <Paragraph fontSize="s" color="gray_5">
          {translate('tree:hostProfileCreatorCount')}
        </Paragraph>
      </StyledHostCountItems>
    ),
    [creatorCount, translate],
  )

  const handleRenderAdminCount = useMemo(
    () => (
      <StyledHostCountItems>
        <Paragraph fontSize="s" fontWeight="bold">
          {adminCount}
        </Paragraph>
        <Paragraph fontSize="s" color="gray_5">
          {translate('tree:hostProfileAdminCount')}
        </Paragraph>
      </StyledHostCountItems>
    ),
    [adminCount, translate],
  )

  const handleRenderCounts = useMemo(
    () => (
      <StyledHostCountContainer>
        {handleRenderSellCount}
        <Paragraph color="white_3">{'|'}</Paragraph>
        {handleRenderCreatorCount}
        <Paragraph color="white_3">{'|'}</Paragraph>
        {handleRenderAdminCount}
      </StyledHostCountContainer>
    ),
    [handleRenderAdminCount, handleRenderCreatorCount, handleRenderSellCount],
  )

  const handleRenderHostBio = useMemo(
    () => (
      <StyledBio fontSize="s" color={biodata ? 'black' : 'gray_5'}>
        {biodata ?? translate('tree:profileEmptyBio')}
      </StyledBio>
    ),
    [biodata, translate],
  )

  const handleRenderHostInfoMobile = useMemo(
    () => (
      <StyledHostInfoContainer>
        {handleRenderHashtag}
        {handleRenderCounts}
        {handleRenderHostBio}
      </StyledHostInfoContainer>
    ),
    [handleRenderCounts, handleRenderHashtag, handleRenderHostBio],
  )

  const handleRenderHostInfoDesktop = useMemo(
    () => (
      <StyledDesktopHostInfo>
        <div>
          {handleRenderHashtag}
          {handleRenderHostBio}
        </div>
        {handleRenderCounts}
      </StyledDesktopHostInfo>
    ),
    [handleRenderCounts, handleRenderHashtag, handleRenderHostBio],
  )

  return (
    <>
      {handleRenderCover}
      {mode === 'website'
        ? handleRenderHostInfoDesktop
        : handleRenderHostInfoMobile}
    </>
  )
}
