import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  REGEX_ALPHABET_AND_SPACE,
  REGEX_NUMBER,
  SERVICE_CANCELLATION_SEARCH_BANK,
  SERVICE_CANCELLED_RESPONSE,
} from 'consts'
import {useTranslation} from 'i18n'
import {AddBankAccountFormData, GiftShopBankResponseBody} from 'types'
import {useDebounce, useDidMount} from 'utils'
import {requestDataPayload} from 'services'
import convertUnit from 'lib/unit'
import {
  Button,
  Input,
  Paragraph,
  ListItemSelect,
  Menu,
  ListLazy,
  Icon,
} from 'common/components'
import {
  VALIDATION_GIFTSHOP_ADDBANKACCOUNT_ACCOUNTNUMBER,
  VALIDATION_GIFTSHOP_ADDBANKACCOUNT_FULLNAME,
} from 'common/validations'
import {GiftShopAddBankAccountFormProps} from './GiftShopAddBankAccountFormProps'

const StyledContentListContainer = styled.div`
  border-top: ${convertUnit(1)};
  border-style: solid;
  border-color: ${({theme}) => theme.gray_1};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
`

const StyledContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${convertUnit(500)};
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(8)};
  padding: ${convertUnit(50)};
  margin: ${convertUnit(25)};
`

const StyledHeading = styled(Paragraph)`
  margin-bottom: ${convertUnit(10)};
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(50)};
`

export default function GiftShopAddBankAccountForm({
  onSubmit,
}: GiftShopAddBankAccountFormProps) {
  const {translate} = useTranslation()
  const debounce = useDebounce()
  const stateToggle = useState(false)
  const setToggle = stateToggle[1]
  const stateBanks = useState<readonly GiftShopBankResponseBody[]>([])
  const banks = stateBanks[0]
  const [searchBank, setSearchBank] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [loading, setLoading] = useState(false)

  const defaultValues: AddBankAccountFormData = useMemo(
    () => ({
      id: '',
      account_name: '',
      account_number: '',
      bank_detail: {name: '', logo: '', id: '', code: ''},
      branch_name: '',
    }),
    [],
  )
  const form = useForm<AddBankAccountFormData>({defaultValues})
  const {handleSubmit, watch, reset, errors, register, setValue} = form
  const values = watch()
  const {account_name, account_number, bank_detail, branch_name} = values

  const handleAddBankAccount = useCallback(async () => {
    setLoading(true)
    onSubmit(values)
    reset(defaultValues)
    register('bank_detail')

    setSearchBank('')
    setLoading(false)
  }, [defaultValues, onSubmit, register, reset, values])

  const handleLoadData = useCallback(
    async (page: number, limit: number, q: string) => {
      const response = await requestDataPayload('giftshop_get_banks', {
        params: {page, limit, q},
        useDefaultMessage: true,
        actionType: 'fetch',
        cancelId: SERVICE_CANCELLATION_SEARCH_BANK,
      })

      if (
        typeof response === 'string' &&
        response === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof response !== 'string' && response?.status === 200
        ? response.data.result
        : null
    },
    [],
  )

  const handleDeleteBankDetailValue = useCallback(() => {
    setValue('bank_detail', {
      name: '',
      logo: '',
      id: '',
      code: '',
    })
  }, [setValue])

  const disabledButton = useMemo(
    () =>
      account_name === '' ||
      !REGEX_ALPHABET_AND_SPACE.test(account_name) ||
      account_number.length < 10 ||
      !REGEX_NUMBER.test(account_number) ||
      bank_detail.id === '' ||
      errors.account_name !== undefined ||
      errors.account_number !== undefined ||
      errors.bank_detail !== undefined ||
      errors.branch_name !== undefined,
    [
      account_name,
      account_number,
      bank_detail.id,
      errors.account_name,
      errors.account_number,
      errors.bank_detail,
      errors.branch_name,
    ],
  )

  const handleRenderButtonSubmit = useMemo(
    () => (
      <StyledButton
        disabled={disabledButton}
        isLoading={loading}
        label={translate('global:add')}
        onClick={handleSubmit(handleAddBankAccount)}
      />
    ),
    [disabledButton, handleAddBankAccount, handleSubmit, loading, translate],
  )

  const handleSelect = useCallback(
    (item: GiftShopBankResponseBody) => {
      setSearchBank(item.name)
      setValue('bank_detail', item)
      setToggle((previous) => !previous)
    },
    [setToggle, setValue],
  )

  const handleRenderDropdownItem = useCallback(
    (item: GiftShopBankResponseBody) => (
      <ListItemSelect title={item.name} onClick={() => handleSelect(item)} />
    ),
    [handleSelect],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledContentListContainer>
        <ListLazy
          stateData={stateBanks}
          loadData={handleLoadData}
          search={searchQuery}
          data={banks}
          keyExtractor={(bank) => bank.id}
          renderItem={handleRenderDropdownItem}
        />
      </StyledContentListContainer>
    ),
    [banks, handleLoadData, handleRenderDropdownItem, searchQuery, stateBanks],
  )

  useDidMount(() => {
    register('bank_detail')
  })

  return (
    <StyledContainer>
      <StyledHeading fontSize="xl" fontWeight="bold">
        {translate('giftShop:newBankAccount')}
      </StyledHeading>
      <Input
        name="account_name"
        form={form}
        formRules={VALIDATION_GIFTSHOP_ADDBANKACCOUNT_FULLNAME}
        autoComplete={false}
        label={translate('giftShop:addBankInputAccountNameLabel')}
        placeholder={translate('giftShop:addBankInputAccountNamePlaceholder')}
        containerStyle={{
          marginTop: convertUnit(30),
        }}
        allowedCharacters={REGEX_ALPHABET_AND_SPACE}
        rightIcon={
          account_name.length ? (
            <Icon
              size={16}
              type="close"
              onClick={() => {
                setValue('account_name', '')
              }}
            />
          ) : (
            <></>
          )
        }
      />
      <Input
        name="account_number"
        form={form}
        formRules={VALIDATION_GIFTSHOP_ADDBANKACCOUNT_ACCOUNTNUMBER}
        autoComplete={false}
        allowedCharacters={REGEX_NUMBER}
        label={translate('giftShop:addBankInputAccountNumberLabel')}
        placeholder={translate('giftShop:addBankInputAccountNumberPlaceholder')}
        containerStyle={{marginTop: convertUnit(25)}}
        rightIcon={
          account_number.length ? (
            <Icon
              size={16}
              type="close"
              onClick={() => {
                setValue('account_number', '')
              }}
            />
          ) : (
            <></>
          )
        }
      />
      <Menu
        stateToggle={stateToggle}
        contentContainerStyle={{
          height: convertUnit(250),
          borderRadius: convertUnit(8),
        }}
        renderContent={handleRenderContent}>
        <Input
          value={searchBank}
          name="bank_detail"
          label={translate('giftShop:addBankInputBankNameLabel')}
          autoComplete={false}
          placeholder={translate('giftShop:addBankInputBankNamePlaceholder')}
          containerStyle={{marginTop: convertUnit(25)}}
          onChangeText={(text) => {
            setSearchBank(text)
            debounce(() => setSearchQuery(text), 500)
            if (bank_detail.id && bank_detail.name !== text) {
              handleDeleteBankDetailValue()
            }
          }}
          onFocus={() => setToggle(true)}
          rightIcon={
            searchBank.length ? (
              <Icon
                size={16}
                type="close"
                onClick={() => {
                  setSearchBank('')
                  setSearchQuery('')
                  handleDeleteBankDetailValue()
                }}
              />
            ) : (
              <></>
            )
          }
        />
      </Menu>
      <Input
        name="branch_name"
        form={form}
        label={translate('giftShop:addBankInputBankBranchLabel')}
        placeholder={translate('giftShop:addBankInputBankBranchPlaceholder')}
        containerStyle={{marginTop: convertUnit(25)}}
        rightIcon={
          branch_name.length ? (
            <Icon
              size={16}
              type="close"
              onClick={() => {
                setValue('branch_name', '')
              }}
            />
          ) : (
            <></>
          )
        }
      />
      {handleRenderButtonSubmit}
    </StyledContainer>
  )
}
