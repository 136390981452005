import React from 'react'
import styled from 'styled-components'
import {ThemeColor} from 'themes'
import convertUnit from 'lib/unit'
import {Icon} from 'common/components'
import {GiftShopTemplateContentRightBadgeProps} from './GiftShopTemplateContentBadgeProps'

interface StyledBadgeContainerProps {
  backgroundColor: ThemeColor
}

const StyledBadgeContainer = styled.div<StyledBadgeContainerProps>`
  padding: ${convertUnit(2)} ${convertUnit(3)};
  position: absolute;
  top: 0;
  right: 0;
  width: fit-content;
  border-radius: 0 0 0 ${convertUnit(5)};
  background-color: ${({theme, backgroundColor}) => theme[backgroundColor]};
  justify-content: center;
  align-items: center;
`

export default function GiftShopTemplateContentRightBadge({
  icon,
  backgroundColor,
}: GiftShopTemplateContentRightBadgeProps) {
  return (
    <StyledBadgeContainer backgroundColor={backgroundColor}>
      <Icon type={icon} size={convertUnit(16)} color="white_1" />
    </StyledBadgeContainer>
  )
}
