import React, {useCallback, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {AuthFormSimpleRegisterData} from 'types'
import {parseDate, useHistory} from 'utils'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'
import {AuthRegisterUsernameProps} from './AuthRegisterUsernameProps'
import {TemplateRegisterForm} from '../../../../template'

const StyledContainer = styled.div`
  padding: 0 0 ${convertUnit(25)} 0;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: ${convertUnit(40)} 0 ${convertUnit(25)} 0;
  }
`

export default function AuthRegisterUsername({
  stateValues,
  stateType,
  stateCountry,
  stateSubmit,
}: AuthRegisterUsernameProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const {update} = useDispatch()
  const [savedValues, setSavedValues] = stateValues
  const form = useForm<AuthFormSimpleRegisterData>({
    defaultValues: savedValues,
  })
  const {watch} = form
  const values = watch()
  const type = stateType[0]
  const country = stateCountry[0]
  const setSubmit = stateSubmit[1]
  const {username, password, dateOfBirth} = values
  const stateError = useState<string>()
  const setErrorMessage = stateError[1]
  const stateLoading = useState(false)
  const setLoading = stateLoading[1]
  const {lang} = useSelector('languageState')

  const handleRegister = useCallback(async () => {
    const finalValues = {
      ...savedValues,
      ...values,
    }
    setSubmit(true)
    setSavedValues(finalValues)
    setLoading(true)

    const {email, phone} = finalValues
    const response = await requestData(
      type === 'email'
        ? 'auth_register_oauth_email'
        : 'auth_register_oauth_phone',
      {
        actionType: 'execute',
        data:
          type === 'email'
            ? {
                email,
                password,
                username,
                date_of_birth: parseDate(dateOfBirth.toString(), 'DD/MM/YYYY'),
                lang: lang || 'id',
              }
            : {
                date_of_birth: parseDate(dateOfBirth.toString(), 'DD/MM/YYYY'),
                password,
                username,
                phone_code: country.dial_code,
                phone_number: phone,
                lang: lang || 'id',
              },
        onRequestReceived: () => setLoading(false),
        onRequestFailed: (reason) =>
          setErrorMessage(translate('global:messageError', {context: reason})),
      },
    )

    if (typeof response !== 'string') {
      if (response.status === 201) {
        if (type === 'email') {
          history.replace('auth_verification_email', {email})
        } else if (type === 'phone') {
          update('lastUserState', {phoneOTPTime: new Date().getTime()})
          history.replace('auth_verification_phone', {
            phoneCode: country.dial_code,
            phoneNumber: phone,
            method: 'whatsapp',
          })
        }
      } else if (
        response.status === 409 &&
        response.data.message === 'user already exist'
      ) {
        setErrorMessage(translate('auth:validationUsernameHasBeenTaken'))
      }
    }
  }, [
    savedValues,
    values,
    setSubmit,
    setSavedValues,
    setLoading,
    type,
    password,
    username,
    dateOfBirth,
    lang,
    country.dial_code,
    setErrorMessage,
    translate,
    history,
    update,
  ])

  return (
    <StyledContainer>
      <TemplateRegisterForm
        form={form}
        stateError={stateError}
        stateValue={stateValues}
        stateLoading={stateLoading}
        onClick={handleRegister}
      />
    </StyledContainer>
  )
}
