import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {FirebaseMessageRoomItemPayload, GiftShopContentData} from 'types'
import {getContentURL, useDidMount, useHistory} from 'utils'
import {Button, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import GiftShopMessageRoomContentItemSingleContent from '../GiftShopMessageRoomContentItemImage'

const StyledButton = styled(Button)`
  width: 100%;
  padding-top: ${convertUnit(16)};
`

export interface GiftShopMessageRoomContentItemNotificationResendApprovalProps {
  payload: FirebaseMessageRoomItemPayload['notification_resend_approval']
}

export default function GiftShopMessageRoomContentItemNotificationResendApproval({
  payload,
}: GiftShopMessageRoomContentItemNotificationResendApprovalProps) {
  const {translate} = useTranslation()
  const {content_id, creator_nickname, invoice_id, username} = payload
  const history = useHistory()
  const [data, setData] = useState<GiftShopContentData>()
  const [loading, setLoading] = useState(true)
  const getUrl = getContentURL({url: data?.url || '', type: 'collection'})

  const handleLoadContent = useCallback(() => {
    requestData('giftshop_get_contents_detail', {
      actionType: 'fetch',
      useDefaultMessage: true,
      params: {content_ids: content_id},
      onRequestSuccess: ({status, data: {result}}) => {
        setLoading(false)
        status === 200 && setData(result[0])
      },
      onRequestFailed: () => {
        setLoading(false)
      },
    })
  }, [content_id])

  const handleRenderUpperMessage = useMemo(
    () => (
      <>
        <Paragraph fontSize="m">
          {translate('messages:hiUsername', {username})}
        </Paragraph>
        <br />
        <Paragraph fontSize="m">
          {translate('messages:notificationResendApprovalFirst', {
            creator_nickname,
          })}
        </Paragraph>
      </>
    ),
    [creator_nickname, translate, username],
  )

  const handleRenderContent = useMemo(
    () => (
      <GiftShopMessageRoomContentItemSingleContent
        data={data}
        onClick={() => {
          data &&
            history.push('giftshop_collection_detail', {
              data: [{...data, type: 'collection'}],
              selectedItemId: content_id,
              limitContent: true,
            })
        }}
        src={getUrl}
        loading={loading}
      />
    ),
    [content_id, data, getUrl, history, loading],
  )

  const handleRenderBottomMessage = useMemo(
    () => (
      <>
        <Paragraph fontSize="m" fontWeight="bold">
          {translate('messages:notificationResendApprovalInvoiceId', {
            invoice_id,
          })}
        </Paragraph>
        <br />
        <Paragraph fontSize="m">
          {translate('messages:notificationResendApprovalSecond')}
        </Paragraph>
        <br />
      </>
    ),
    [invoice_id, translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        label={translate('messages:notificationResendApprovalButton')}
        onClick={() => history.push('giftshop_collector_resend_approval', {})}
      />
    ),
    [history, translate],
  )

  useDidMount(handleLoadContent)

  return (
    <>
      {handleRenderUpperMessage}
      {handleRenderContent}
      {handleRenderBottomMessage}
      {handleRenderButton}
    </>
  )
}
