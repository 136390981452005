import React, {useMemo} from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_CONTENT_CONTAINER_MAX_WIDTH,
  GIFT_SHOP_EXPLORE_EMPTY_CONTAINER_PADDING,
  GIFT_SHOP_ROOT_CONTENT_EMPTY_IMAGE_HEIGHT,
  GIFT_SHOP_ROOT_CONTENT_EMPTY_PADDING,
  GIFT_SHOP_ROOT_CONTENT_EMPTY_SPACE,
} from 'consts'
import {Icon, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopRootEmptyProps} from './GiftShopRootEmptyProps'

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding-left: ${convertUnit(GIFT_SHOP_EXPLORE_EMPTY_CONTAINER_PADDING)};
  padding-right: ${convertUnit(GIFT_SHOP_EXPLORE_EMPTY_CONTAINER_PADDING)};
`

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-width: ${convertUnit(GIFT_SHOP_CONTENT_CONTAINER_MAX_WIDTH)};
  padding-bottom: ${convertUnit(GIFT_SHOP_ROOT_CONTENT_EMPTY_PADDING)};
  align-items: center;
  justify-content: center;
`

const StyledTitle = styled.div`
  margin-bottom: ${convertUnit(5)};
`

const StyledParagraph = styled(Paragraph)`
  white-space: pre-line;
  text-align: center;
`

const StyledImage = styled(Image)`
  object-fit: contain;
  height: ${convertUnit(GIFT_SHOP_ROOT_CONTENT_EMPTY_IMAGE_HEIGHT)};
  max-width: 100%;
  margin-bottom: ${convertUnit(GIFT_SHOP_ROOT_CONTENT_EMPTY_SPACE)};
`

export default function GiftShopRootEmpty({
  image,
  imageAlt,
  title,
  message,
  messageAlt,
  bottomElement,
  iconOnTitle = false,
}: GiftShopRootEmptyProps) {
  const handleRenderTitle = useMemo(
    () =>
      title ? (
        <StyledParagraph fontSize="l" fontWeight="bold">
          {title}
          {iconOnTitle ? <Icon type="robopet" /> : <></>}
        </StyledParagraph>
      ) : undefined,
    [title],
  )

  const handleRenderMessage = useMemo(
    () =>
      message ? (
        <StyledParagraph fontSize="s" color="gray_5">
          {message}
          {messageAlt ? <Icon type="robopet" color="gray_5" size={16} /> : null}
        </StyledParagraph>
      ) : undefined,
    [message, messageAlt],
  )

  const handleRenderAltMessage = useMemo(
    () =>
      messageAlt ? (
        <StyledParagraph fontSize="s" color="gray_5">
          {messageAlt}
        </StyledParagraph>
      ) : undefined,
    [messageAlt],
  )

  const handleRenderImage = useMemo(
    () => <StyledImage src={image} alt={imageAlt} />,
    [image, imageAlt],
  )

  return (
    <StyledContainer>
      <StyledContentContainer>
        {handleRenderImage}
        <StyledTitle>{handleRenderTitle}</StyledTitle>
        {handleRenderMessage}
        {handleRenderAltMessage}
        {bottomElement}
      </StyledContentContainer>
    </StyledContainer>
  )
}
