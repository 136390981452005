import {useMemo, useCallback} from 'react'
import {requestData} from 'services'
import {TreePlantTreeParams} from 'types'
import {handleUploadSingleFileToWasabi} from '../../files'

export function usePlantTree({
  mainForm,
  imageForm,
  videoForm,
  srcState,
  stateLoading,
}: TreePlantTreeParams) {
  const {watch} = mainForm
  const {watch: watchVid} = videoForm
  const {watch: watchImg} = imageForm

  const {
    fotoTreeCategory,
    fototreeBio,
    fototreeName,
    latitude,
    longitude,
    eventEndDate,
    eventStartDate,
    cityName,
  } = watch()

  const {
    extension: vidExt,
    height: vidHeight,
    size: vidSize,
    width: vidWidth,
    file: vidFile,
    mime: vidMime,
  } = watchVid()

  const {
    imgExtension,
    imgFile,
    imgHeight,
    imgMime,
    imgSize,
    imgWidth,
  } = watchImg()

  const formattedStartDate = useMemo(() => {
    const [year, month, day] = eventStartDate?.split('-') ?? []
    return `${day}/${month}/${year}`
  }, [eventStartDate])

  const formattedEndDate = useMemo(() => {
    const [year, month, day] = eventEndDate?.split('-') ?? []
    return `${day}/${month}/${year}`
  }, [eventEndDate])

  const getVideoLink = useCallback(async () => {
    const response = await requestData(
      'tree_post_plant_fototree_profile_link',
      {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {
          is_intro: true,
          video_intro: {
            height: vidHeight,
            extension: vidExt,
            size: vidSize,
            width: vidWidth,
          },
        },
      },
    )
    return typeof response !== 'string' && response.status === 200
      ? response.data.result.video_intro_url
      : null
  }, [vidExt, vidHeight, vidSize, vidWidth])

  const getProfileLink = useCallback(async () => {
    const response = await requestData(
      'tree_post_plant_fototree_profile_link',
      {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {
          profile: {
            extension: imgExtension,
            height: imgHeight,
            size: imgSize,
            width: imgWidth,
          },
        },
      },
    )
    return typeof response !== 'string' && response.status === 200
      ? response.data.result.profile_url
      : null
  }, [imgExtension, imgHeight, imgSize, imgWidth])

  const handlePublishToWasabi = useCallback(
    async (
      link: string,
      width: number,
      height: number,
      file?: File,
      mime?: string,
    ) => {
      if (file && mime) {
        try {
          return handleUploadSingleFileToWasabi(file, link, mime, {
            'Content-Type': mime,
            'x-amz-meta-imagewidth': width.toString(),
            'x-amz-meta-imageheight': height.toString(),
          })
        } catch (error) {
          return false
        }
      }
      return false
    },
    [],
  )

  const handlePublishVideo = useCallback(async () => {
    const videoLink = await getVideoLink()
    if (videoLink) {
      const videoWasabiStatus = await handlePublishToWasabi(
        videoLink,
        vidWidth,
        vidHeight,
        vidFile,
        vidMime,
      )
      if (videoWasabiStatus) {
        return videoLink
      }
    }
    stateLoading && stateLoading[1](false)
    return undefined
  }, [
    getVideoLink,
    handlePublishToWasabi,
    stateLoading,
    vidFile,
    vidHeight,
    vidMime,
    vidWidth,
  ])

  const handlePublishProfile = useCallback(async () => {
    const imgLink = await getProfileLink()
    if (imgLink) {
      const imgWasabiStatus = await handlePublishToWasabi(
        imgLink,
        imgWidth,
        imgHeight,
        imgFile,
        imgMime,
      )
      if (imgWasabiStatus) {
        return imgLink
      }
    }
    return undefined
  }, [
    getProfileLink,
    handlePublishToWasabi,
    imgFile,
    imgHeight,
    imgMime,
    imgWidth,
  ])

  const handleSubmitForm = useCallback(async () => {
    const imgLink = await handlePublishProfile()
    const vidLink = srcState[0] ? await handlePublishVideo() : undefined

    const eventDate = eventStartDate &&
      eventEndDate && {
        event_start_date: formattedStartDate,
        event_end_date: formattedEndDate,
      }

    if (srcState[0] && imgLink && vidLink) {
      const response = await requestData('tree_post_plant_fototree', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {
          category_id: fotoTreeCategory.id,
          location: JSON.stringify({longitude, latitude}),
          profile_size: imgSize,
          profile_url: imgLink,
          tree_bio: fototreeBio,
          tree_name: fototreeName,
          video_intro: {
            height: vidHeight,
            meta: undefined,
            size: vidSize,
            url: vidLink,
            width: vidWidth,
          },
          city_name: cityName || undefined,
          ...eventDate,
        },
      })

      stateLoading && stateLoading[1](false)

      return typeof response !== 'string'
        ? {status: response.status, data: response.data}
        : {status: undefined, data: undefined}
    }

    if (imgLink) {
      const response = await requestData('tree_post_plant_fototree', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {
          category_id: fotoTreeCategory.id,
          location: JSON.stringify({longitude, latitude}),
          profile_size: imgSize,
          profile_url: imgLink,
          tree_bio: fototreeBio,
          tree_name: fototreeName,
          city_name: cityName || undefined,
          ...eventDate,
        },
      })
      return typeof response !== 'string'
        ? {status: response.status, detail: response.data.detail}
        : {status: undefined, detail: undefined}
    }

    return {status: undefined, detail: undefined}
  }, [
    cityName,
    eventEndDate,
    eventStartDate,
    formattedEndDate,
    formattedStartDate,
    fotoTreeCategory,
    fototreeBio,
    fototreeName,
    handlePublishProfile,
    handlePublishVideo,
    imgSize,
    latitude,
    longitude,
    srcState,
    stateLoading,
    vidHeight,
    vidSize,
    vidWidth,
  ])

  return useMemo(
    () => ({
      handleSubmitForm,
    }),
    [handleSubmitForm],
  )
}
