import React, {useMemo} from 'react'
import styled, {css} from 'styled-components'
import {ThemeColor} from 'themes'
import {IconType} from 'types'
import {getBorder} from 'utils'
import {useWindowMode} from 'windows'
import convertUnit from 'lib/unit'
import {InputRangeProps} from './InputRangeProps'
import {Icon} from '../Icon'

interface StyledContainerProps {
  descPosition?: 'side' | 'bottom'
}
interface StyledThumbProps {
  thumbColor?: ThemeColor
}

const trackStyle = css`
  appearance: none;
  background: transparent;
  border: transparent;
`

const thumbStyle = css`
  pointer-events: all;
  height: ${convertUnit(24)};
  width: ${convertUnit(24)};
  border-radius: 0px;
  border: 0 none;
`

const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex-direction: ${({descPosition}) =>
    descPosition === 'bottom' ? 'column' : 'row'};
  gap: ${({descPosition}) => (descPosition === 'side' ? convertUnit(20) : 0)};
  justify-content: center;
  align-items: center;
`

const StyledElementContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: ${convertUnit(24)};
  width: 100%;
`

const StyledInputContainer = styled.div`
  width: 100%;
  position: absolute;
  height: ${convertUnit(24)};
`

const StyledInputRange = styled.input`
  position: relative;
  width: 100%;
  appearance: none;
  cursor: pointer;
  height: ${convertUnit(24)};
  opacity: 0;
  z-index: 4;
  padding: 0;

  //track
  &::-moz-range-track {
    ${trackStyle}
  }
  &::-ms-track {
    ${trackStyle}
  }

  &::-webkit-slider-runnable-track {
    ${trackStyle}
  }

  //thumb
  &::-webkit-slider-thumb {
    ${thumbStyle}
  }

  &::-moz-range-thumb {
    ${thumbStyle}
  }

  &::-ms-thumb {
    ${thumbStyle}
  }
`

const StyledThumb = styled.div<StyledThumbProps>`
  width: ${convertUnit(10)};
  height: ${convertUnit(10)};
  border-radius: 50%;
  position: absolute;
  background-color: ${({theme, thumbColor = 'white_1'}) => theme[thumbColor]};
  border: ${({theme}) => getBorder(2, 'solid', theme.primary_5)};
  box-sizing: border-box;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  z-index: 4;
  cursor: grab;
`

const StyledTickContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  justify-content: space-between;
  z-index: 2;
  width: inherit;
`

const StyledSliderItemContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledDescContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${convertUnit(12)};
`

export default function InputRange({
  min = 0,
  max = 10,
  step,
  showTick,
  thumbColor,
  leftDesc,
  rightDesc,
  stateValue,
  descPosition = 'bottom',
  darkMode = false,
  onSlide,
  ...props
}: InputRangeProps) {
  const [value, setValue] = stateValue
  const mode = useWindowMode()

  const handleSliderSize: number = useMemo(() => {
    switch (mode) {
      case 'mobile':
        return 20
      case 'tablet':
        return 30
      case 'website':
        return descPosition === 'bottom' ? 40 : 30
    }
  }, [descPosition, mode])

  return (
    <StyledContainer descPosition={descPosition}>
      {descPosition === 'side' ? leftDesc : <></>}
      <StyledElementContainer>
        <StyledInputContainer>
          <StyledInputRange
            {...props}
            type="range"
            value={value}
            min={min}
            max={max}
            step={step}
            onChange={(e) => {
              const val = parseInt(e.currentTarget.value, 10)
              setValue(val)
            }}
            onMouseUp={(e) => {
              const val = parseInt(e.currentTarget.value, 10)
              onSlide && onSlide(val)
            }}
          />
        </StyledInputContainer>

        {showTick ? (
          <StyledTickContainer>
            {Array.from({length: max}, (_, i) => (
              <StyledSliderItemContainer
                key={i}
                onClick={() => setValue(i + 1)}>
                {value === i + 1 ? (
                  <StyledThumb thumbColor={thumbColor} />
                ) : (
                  <></>
                )}
                <Icon
                  type={`sld-${i + 1}` as IconType}
                  color={
                    i + 1 >= value
                      ? 'primary_5'
                      : darkMode
                      ? 'gray_8'
                      : 'gray_1'
                  }
                  size={handleSliderSize}
                />
              </StyledSliderItemContainer>
            ))}
          </StyledTickContainer>
        ) : (
          <></>
        )}
      </StyledElementContainer>
      {descPosition === 'side' ? rightDesc : <></>}
      {descPosition === 'bottom' ? (
        <StyledDescContainer>
          {leftDesc}
          {rightDesc}
        </StyledDescContainer>
      ) : (
        <></>
      )}
    </StyledContainer>
  )
}
