import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {showModalLimitedAccess, showModalReprocessKyc, useHistory} from 'utils'
import {useWindowMode} from 'windows'
import {useTranslation} from 'i18n'
import {InputPicker} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {ValidateKYCModal} from '../../../../../gift-shop'
import {TemplateAuthAccessModal} from '../../../../../template'
import {ForkygramTemplatePostButtonsItem} from '../ButtonsItem'

const StyledTopRightButtonsContainer = styled.div`
  cursor: pointer;
  display: flex;
  margin-right: ${convertUnit(8)};
  gap: ${convertUnit(8)};
  flex-direction: column;
  justify-content: center;
  width: ${convertUnit(45)};
`
const StyledTopButtonsContainer = styled.div`
  position: absolute;
  top: ${convertUnit(8)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-self: center;
  width: fill-available;
  max-width: ${convertUnit(600)};
  z-index: 1;
`
const StyledLeftButtonsContainer = styled.div`
  left: ${convertUnit(7)};
  flex-direction: column;
  align-items: flex-start;
`

export default function ForkygramTemplatePostOverlayTopButtons() {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const history = useHistory()
  const {access_token} = useSelector('user') || {}
  const {kycStatus} = useSelector('yuserActivationState')
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const stateModalLogin = useState(false)
  const [loginModal, setLoginModal] = stateModalLogin
  const [modalKyc, setModalKyc] = useState(false)
  const ref = useRef<HTMLInputElement>(null)
  const {status: childStatus} = useSelector('parentalConsentState')
  const handleFeatureDisabled = useCallback(() => {
    if (childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [childStatus, history, kycStatus, similarStatus])

  const handleClickOverlay = useCallback(
    (onSuccess: () => void) => {
      if (access_token) {
        if (
          childStatus === 'UNDERAGE' ||
          !(kycStatus === 'verified' || kycStatus === 'verified_check')
        ) {
          handleFeatureDisabled()
          return
        }
        kycStatus !== 'verified' && kycStatus !== 'verified_check'
          ? setModalKyc(true)
          : onSuccess()
      } else setLoginModal(true)
    },
    [
      access_token,
      childStatus,
      handleFeatureDisabled,
      kycStatus,
      setLoginModal,
    ],
  )

  const handleRenderSendToFaceIcon = useMemo(
    () =>
      mode !== 'website' && (
        <StyledTopRightButtonsContainer>
          <ForkygramTemplatePostButtonsItem
            icon="send-to-face-ol-icon"
            label={translate('giftShop:sendToFace')}
            isOverlay
            isSendToFace
            onClickIcon={() => handleClickOverlay(() => ref.current?.click())}
            onClickLabel={() => handleClickOverlay(() => ref.current?.click())}
          />
          <ForkygramTemplatePostButtonsItem
            icon="search-friends"
            label={translate('forkygram:searchFriends')}
            isOverlay
            onClickIcon={() =>
              handleClickOverlay(() =>
                history.push('forkygram_search_friends', {}),
              )
            }
            onClickLabel={() =>
              handleClickOverlay(() =>
                history.push('forkygram_search_friends', {}),
              )
            }
          />
        </StyledTopRightButtonsContainer>
      ),
    [handleClickOverlay, history, mode, translate],
  )

  const handleNavigateFotoTree = useCallback(() => {
    history.push('tree_fototree', {})
  }, [history])

  const handleRenderLeftButtons = useMemo(
    () =>
      mode !== 'website' && (
        <StyledLeftButtonsContainer>
          <ForkygramTemplatePostButtonsItem
            icon="tree-feature"
            label={'FotoTree'}
            onClickIcon={handleNavigateFotoTree}
            onClickLabel={handleNavigateFotoTree}
            isOverlay
          />
        </StyledLeftButtonsContainer>
      ),
    [handleNavigateFotoTree, mode],
  )

  const handleRenderInput = useMemo(
    () => (
      <InputPicker
        multiple
        inputRef={ref}
        onLoadFiles={(files) =>
          files.length > 0 && history.push('giftshop_send_to_face', {files})
        }
      />
    ),
    [history],
  )

  const handleRenderValidateKYCModal = useMemo(
    () => (
      <ValidateKYCModal
        visible={modalKyc}
        toggleModal={() => setModalKyc(false)}
      />
    ),
    [modalKyc],
  )

  const handleRenderModalLogin = useMemo(
    () => (
      <TemplateAuthAccessModal
        toggleModal={() => setLoginModal((prev) => !prev)}
        visible={loginModal}
      />
    ),
    [loginModal, setLoginModal],
  )

  return (
    <>
      <StyledTopButtonsContainer>
        {handleRenderLeftButtons}
        {handleRenderSendToFaceIcon}
      </StyledTopButtonsContainer>
      {handleRenderModalLogin}
      {handleRenderValidateKYCModal}
      {handleRenderInput}
    </>
  )
}
