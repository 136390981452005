import React, {useCallback} from 'react'
import styled from 'styled-components'
import {ThemeColor} from 'themes'
import convertUnit from 'lib/unit'
import {Paragraph} from '../Paragraph'
import {Icon} from '../Icon'
import {ChipsProps} from './ChipsProps'

interface StyledTagChipsProps {
  backgroundColor?: ThemeColor
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const StyledTagChips = styled.div<StyledTagChipsProps>`
  ${({theme, backgroundColor}) => ({
    backgroundColor: backgroundColor ? theme[backgroundColor] : theme.white_3,
  })}
  height: ${convertUnit(25)};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 ${convertUnit(12)};
  margin-right: ${convertUnit(5)};
  margin-top: ${convertUnit(5)};
  border-radius: ${convertUnit(25)};
`

const StyledIconContainer = styled.div`
  margin-left: ${convertUnit(9.5)};
  justify-content: center;
  display: flex;
`

export default function Chips<ItemT>({
  data,
  fontSize = 's',
  showCloseButton = true,
  backgroundColor,
  keyExtractor,
  labelExtractor,
  typeExtractor,
  fontColor,
  showHost = false,
  onDelete,
}: ChipsProps<ItemT>) {
  const handleDelete = useCallback(
    (item: ItemT) => {
      onDelete && onDelete(item)
    },
    [onDelete],
  )

  const handleRenderChip = useCallback(
    (item: ItemT) => {
      const key = keyExtractor(item)
      const label = labelExtractor(item)
      const type = typeExtractor && typeExtractor(item)

      return (
        <>
          {(type !== 'host' || showHost) && (
            <StyledTagChips key={key} backgroundColor={backgroundColor}>
              <Paragraph
                color={fontColor || 'gray_6'}
                fontWeight="medium"
                fontSize={fontSize}>
                {label}
              </Paragraph>
              {showCloseButton && (
                <StyledIconContainer>
                  <Icon
                    data-testid="icon"
                    type="close"
                    color="gray_6"
                    size={9}
                    onClick={() => handleDelete(item)}
                  />
                </StyledIconContainer>
              )}
            </StyledTagChips>
          )}
        </>
      )
    },
    [
      keyExtractor,
      labelExtractor,
      typeExtractor,
      showHost,
      backgroundColor,
      fontColor,
      fontSize,
      showCloseButton,
      handleDelete,
    ],
  )

  return <StyledContainer>{data.map(handleRenderChip)}</StyledContainer>
}
