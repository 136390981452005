import {
  API_GIFTSHOP_ACCOUNT,
  API_GIFTSHOP_BALANCE,
  API_GIFTSHOP_CART,
  API_GIFTSHOP_COLLECTION,
  API_GIFTSHOP_COLLECTION_SHARE,
  API_GIFTSHOP_CREATION,
  API_GIFTSHOP_CREATION_LINK,
  API_GIFTSHOP_DOWNLOAD_COLLECTION,
  API_GIFTSHOP_EXPLORE,
  API_GIFTSHOP_REPORT,
  API_GIFTSHOP_ROBOPET,
  API_GIFTSHOP_TAGS,
  API_GIFTSHOP_TRANSACTION_HISTORY,
  API_GIFTSHOP_WISHLIST,
  API_LOCATION_SEARCH,
  API_MEMBER_BANK_ACCOUNT,
  API_MEMBER_BANK_RESEND_OTP,
  API_MEMBER_BANK_VERIFY_OTP,
  API_MEMBER_DELETE_ACCOUNT,
  API_MEMBER_EDIT_PASSWORD,
  API_MEMBER_EDIT_PROFILE,
  API_MEMBER_FOLLOWER,
  API_MEMBER_FOLLOWING,
  API_MEMBER_GSINFO,
  API_MEMBER_LANGUAGE,
  API_MEMBER_NICKNAMES,
  API_PAYMENT_BANK,
  API_TRANSACTION_CURRENCY,
  API_PAYMENT_TRANSACTION,
  API_PAYMENT_TRANSACTION_DETAIL,
  API_PAYMENT_TRANSACTION_FEE,
  API_PAYMENT_WITHDRAW_AVAILABILITY,
  API_TRANSACTION_WITHDRAWAL,
  API_TRANSACTION_WITHDRAW_FEE,
  API_GIFTSHOP_STORAGE,
  API_GIFTSHOP_STORAGE_HISTORY,
  API_MEMBER_CHECK_DOB,
  API_GIFTSHOP_EXPLORE_FILTER,
  API_GIFTSHOP_RESEND_LINK,
  API_GIFTSHOP_RESEND,
  API_GIFTSHOP_GOPAY_CANCEL,
  API_GIFTSHOP_MAKE_IT_FREE,
  API_GIFTSHOP_MESSAGE_MESSAGE,
  API_GIFTSHOP_CART_DETAIL,
  API_GIFTSHOP_CREATION_DETAIL,
  API_GIFTSHOP_WISHLIST_DETAIL,
  API_GIFTSHOP_MESSAGE_ROOM,
  API_PROFILE_ADD_EMAIL,
  API_PROFILE_VERIFY_ADD_PHONE,
  API_PROFILE_VERIFY_ADD_EMAIL,
  API_PROFILE_RESEND_ADD_EMAIL,
  API_PROFILE_RESEND_ADD_PHONE,
  API_PROFILE_ADD_PHONE,
  API_AUTH_CHECK_CHANGE_PASSWORD_TOKEN,
  API_GIFTSHOP_CREATION_GROUP,
  API_GIFTSHOP_ACCOUNT_STATUS,
  API_GIFTSHOP_CREATOR_VERIFICATION_STATUS,
  API_GIFTSHOP_DELETE_EXPLORE,
  API_REPORT_BLOCK_ACCOUNT,
  API_REPORT_BLOCKED_ACCOUNT_LIST,
  API_GIFTSHOP_IFM_TAGS,
  API_MEMBER_FOLLOW_COUNT,
  API_MEMBER_FOLLOW,
  API_GIFTSHOP_CREATION_LATEST,
  API_GIFTSHOP_CREATION_UPLOAD,
  API_GIFTSHOP_SEND_TO_FACE,
  API_GIFTSHOP_CREATOR_COMPRESS_RESEND,
  API_GIFTSHOP_CREATOR_WHOLESALE,
  API_GIFTSHOP_CART_WHOLESALES,
  API_GIFTSHOP_CREATOR_RATING,
  API_GIFTSHOP_CREATOR_STATUS,
  API_GIFTSHOP_CREATOR_RATING_CHECK,
  API_GIFTSHOP_EXPLORE_TRASH,
  API_GIFTSHOP_EXPLORE_RECOVER,
  API_GIFTSHOP_RESENDPOLOGY_CANCEL,
  API_GIFTSHOP_CREATION_EDIT_BULK,
  API_PAYMENT_BUYER,
  API_GIFTSHOP_CREATION_GROUP_FOLDER,
  API_GIFTSHOP_CART_COUNT,
  API_GIFTSHOP_ONBOARDING,
  API_GIFTSHOP_COLLECTION_RESEND_APPROVAL,
  API_GIFTSHOP_CONTENTS_DETAIL,
  API_GIFTSHOP_CREATION_RESEND_REVISION,
  API_GIFTSHOP_UPLOAD_RESEND_REVISION,
  API_GIFTSHOP_CREATION_DATE,
  API_GIFTSHOP_COLLECTION_RESEND_APPROVAL_COUNT,
  API_GIFTSHOP_COLLECTION_DELETE,
  API_GIFTSHOP_USER,
  API_GIFTSHOP_USER_UPLOAD_LINK,
  API_GIFTSHOP_CREATION_DETAIL_NEW,
  API_GIFTSHOP_CREATION_VIDEO,
  API_GIFTSHOP_CREATION_VIDEO_LINK,
  API_GIFTSHOP_CREATION_VIDEO_SEND_TO_FACE_LINK,
  API_GIFTSHOP_CREATION_VIDEO_SEND_TO_FACE,
  API_GIFTSHOP_CREATION_VIDEO_RESEND,
  API_GIFTSHOP_CREATION_VIDEO_RESEND_LINK,
  API_GIFTSHOP_CREATION_VIDEO_RESEND_REVISION_UPLOAD,
  API_GIFTSHOP_EXPLORE_BIB,
  API_GIFTSHOP_EXPLORE_FILTER_STATUS,
  API_GIFTSHOP_CREATION_COUNT,
  API_GIFTSHOP_TAGS_POST,
  API_GIFTSHOP_CREATOR_DATA,
  API_GIFTSHOP_FAVORITES,
  API_GIFTSHOP_ARCHIVE,
  API_GIFTSHOP_TRANSACTION_REPORT,
  API_GIFTSHOP_ACCOUNT_SENSITIVITY,
  API_MEMBER_PROFILE_SHARE,
  API_MEMBER_SIMILAR,
  API_MEMBER_SIMILAR_STATUS,
  API_GIFTSHOP_EXPLORE_CREATOR,
  API_GIFTSHOP_USER_RETAKE_LINK,
  API_GIFTSHOP_USER_RETAKE,
  API_PAYMENT_TRANSACTION_CANCEL,
  API_MEMBER_EDIT_PROFILE_USERNAME,
  API_MEMBER_SIMILAR_SKIP,
  API_GIFTSHOP_STORAGE_REQUEST,
  API_GIFTSHOP_CREATOR_SHARE,
  API_NOTIFICATION_PARTNERSHIP,
  API_GIFTSHOP_CREATION_LINK_OLD,
  API_GIFTSHOP_MESSAGE_ROOM_DELETE,
  API_MEMBER_EDIT_PROFILE_NICKNAME,
  API_PAYMENT_TRANSACTION_WAITING_DETAIL,
  API_PAYMENT_TRANSACTION_WAITING,
  API_PAYMENT_TRANSACTION_COUNT,
  API_GIFTSHOP_LIVE_LOCATION,
  API_GIFTSHOP_LIVE_LOCATION_STATUS,
  API_GIFTSHOP_MESSAGE_ENTER,
  API_MEMBER_DEVICE,
} from 'consts'
import {ServiceMap} from 'types'
import {getAPIBaseUrl} from 'utils'
import {ServiceParamGiftShop} from './ServiceParamGiftShop'

export const SERVICE_MAP_GIFT_SHOP: ServiceMap<ServiceParamGiftShop> = {
  giftshop_member_following_list: {
    method: 'GET',
    url: getAPIBaseUrl() + API_MEMBER_FOLLOWING,
  },
  giftshop_member_follower_list: {
    method: 'GET',
    url: getAPIBaseUrl() + API_MEMBER_FOLLOWER,
  },
  giftshop_member_unfollow: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_MEMBER_FOLLOWING,
  },
  giftshop_member_follow: {
    method: 'POST',
    url: getAPIBaseUrl() + API_MEMBER_FOLLOWER,
  },
  giftshop_delete_blocked_account: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_REPORT_BLOCK_ACCOUNT,
  },
  giftshop_get_blocked_account: {
    method: 'GET',
    url: getAPIBaseUrl() + API_REPORT_BLOCKED_ACCOUNT_LIST,
  },
  giftshop_cancel_transaction: {
    method: 'POST',
    url: getAPIBaseUrl() + API_PAYMENT_TRANSACTION_CANCEL,
  },
  giftshop_check_withdraw_availability: {
    method: 'POST',
    url: getAPIBaseUrl() + API_PAYMENT_WITHDRAW_AVAILABILITY,
  },
  giftshop_checkout: {
    method: 'POST',
    url: getAPIBaseUrl() + API_PAYMENT_TRANSACTION,
  },
  giftshop_create_archives: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_ARCHIVE,
  },
  giftshop_delete_account: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_MEMBER_DELETE_ACCOUNT,
  },
  giftshop_member_device: {
    method: 'POST',
    url: getAPIBaseUrl() + API_MEMBER_DEVICE,
  },
  giftshop_delete_archives: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_GIFTSHOP_ARCHIVE,
  },
  giftshop_delete_bank_account: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_MEMBER_BANK_ACCOUNT,
  },
  giftshop_delete_cart: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_GIFTSHOP_CART,
  },
  giftshop_delete_collection: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_GIFTSHOP_COLLECTION_DELETE,
  },
  giftshop_delete_creation: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION,
  },
  giftshop_delete_explore: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_GIFTSHOP_DELETE_EXPLORE,
  },
  giftshop_delete_favorite: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_GIFTSHOP_FAVORITES,
  },
  giftshop_delete_live_location: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_GIFTSHOP_LIVE_LOCATION,
  },
  giftshop_delete_wishlist: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_GIFTSHOP_WISHLIST,
  },
  giftshop_dm_post_message: {
    url: getAPIBaseUrl() + API_GIFTSHOP_MESSAGE_MESSAGE,
    method: 'POST',
  },
  giftshop_dm_post_room: {
    url: getAPIBaseUrl() + API_GIFTSHOP_MESSAGE_ROOM,
    method: 'POST',
  },
  giftshop_dm_delete_room: {
    url: getAPIBaseUrl() + API_GIFTSHOP_MESSAGE_ROOM_DELETE,
    method: 'DELETE',
  },
  giftshop_dm_room_enter: {
    url: getAPIBaseUrl() + API_GIFTSHOP_MESSAGE_ENTER,
    method: 'POST',
  },
  giftshop_edit_content: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION,
  },
  giftshop_get_account: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_ACCOUNT,
  },
  giftshop_get_account_nickname: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_ACCOUNT,
  },
  giftshop_get_account_status: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_ACCOUNT_STATUS,
  },
  giftshop_get_archives: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_ARCHIVE,
  },
  giftshop_get_balance: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_BALANCE,
  },
  giftshop_get_bank_account: {
    method: 'GET',
    url: getAPIBaseUrl() + API_MEMBER_BANK_ACCOUNT,
  },
  giftshop_get_banks: {
    method: 'GET',
    url: getAPIBaseUrl() + API_PAYMENT_BANK,
  },
  giftshop_get_buyers: {
    method: 'GET',
    url: getAPIBaseUrl() + API_PAYMENT_BUYER,
  },
  giftshop_get_currency: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TRANSACTION_CURRENCY,
  },
  giftshop_get_cart: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CART,
  },
  giftshop_get_cart_count: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CART_COUNT,
  },
  giftshop_get_cart_detail: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CART_DETAIL,
  },
  giftshop_get_cart_wholesale_discount: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CART_WHOLESALES,
  },
  giftshop_get_collection: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_COLLECTION,
  },
  gitfshop_get_collection_resend_approval: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_COLLECTION_RESEND_APPROVAL,
  },
  gitfshop_get_collection_resend_approval_count: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_COLLECTION_RESEND_APPROVAL_COUNT,
  },
  giftshop_get_collection_share: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_COLLECTION_SHARE,
  },
  giftshop_get_contents_detail: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CONTENTS_DETAIL,
  },
  giftshop_get_creation: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_DATE,
  },
  giftshop_get_creation_count: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_COUNT,
  },
  giftshop_get_latest_creation: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_LATEST,
  },
  giftshop_get_creation_detail: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_DETAIL,
  },
  giftshop_get_creation_detail_new: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_DETAIL_NEW,
  },
  giftshop_get_creation_group: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_GROUP,
  },
  giftshop_get_creation_group_folder: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_GROUP_FOLDER,
  },
  giftshop_get_creation_resend_revision: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_RESEND_REVISION,
  },
  giftshop_get_creator: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATOR_DATA,
  },
  giftshop_get_creator_status: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATOR_STATUS,
  },
  giftshop_get_creator_wholesale: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATOR_WHOLESALE,
  },
  giftshop_get_date_of_birth: {
    method: 'GET',
    url: getAPIBaseUrl() + API_MEMBER_CHECK_DOB,
  },
  giftshop_get_download_collection: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_DOWNLOAD_COLLECTION,
  },
  giftshop_get_explore: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_EXPLORE,
  },
  giftshop_get_explore_creator: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_EXPLORE_CREATOR,
  },
  giftshop_get_explore_filter_status: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_EXPLORE_FILTER_STATUS,
  },
  giftshop_post_explore_filter: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_EXPLORE_FILTER,
  },
  giftshop_get_explore_trash: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_EXPLORE_TRASH,
  },
  giftshop_get_favorite: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_FAVORITES,
  },
  giftshop_get_follow_count: {
    method: 'GET',
    url: getAPIBaseUrl() + API_MEMBER_FOLLOW_COUNT,
  },
  giftshop_post_follow: {
    method: 'POST',
    url: getAPIBaseUrl() + API_MEMBER_FOLLOW,
  },
  giftshop_delete_follow: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_MEMBER_FOLLOW,
  },
  giftshop_get_gs_self_info: {
    method: 'GET',
    url: getAPIBaseUrl() + API_MEMBER_GSINFO,
  },
  giftshop_get_creator_verification_status: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATOR_VERIFICATION_STATUS,
  },
  giftshop_get_ifm_tags: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_IFM_TAGS,
  },
  giftshop_get_live_location: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_LIVE_LOCATION,
  },
  giftshop_get_live_location_status: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_LIVE_LOCATION_STATUS,
  },
  giftshop_get_location: {
    method: 'GET',
    url: getAPIBaseUrl() + API_LOCATION_SEARCH,
  },
  giftshop_get_nickname: {
    method: 'GET',
    url: getAPIBaseUrl() + API_MEMBER_NICKNAMES,
  },
  giftshop_get_report: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_REPORT,
  },
  giftshop_get_robopet: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_ROBOPET,
  },
  giftshop_get_share_creator_link: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATOR_SHARE,
  },
  giftshop_get_storage: {
    url: getAPIBaseUrl() + API_GIFTSHOP_STORAGE,
    method: 'GET',
  },
  giftshop_get_storage_history: {
    url: getAPIBaseUrl() + API_GIFTSHOP_STORAGE_HISTORY,
    method: 'GET',
  },
  giftshop_get_tags: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_TAGS,
  },
  giftshop_get_tags_post: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_TAGS_POST,
  },
  giftshop_get_transaction_detail: {
    method: 'GET',
    url: getAPIBaseUrl() + API_PAYMENT_TRANSACTION_DETAIL,
  },
  giftshop_get_waiting_transaction_detail: {
    method: 'GET',
    url: getAPIBaseUrl() + API_PAYMENT_TRANSACTION_WAITING_DETAIL,
  },
  giftshop_get_waiting_transaction: {
    method: 'GET',
    url: getAPIBaseUrl() + API_PAYMENT_TRANSACTION_WAITING,
  },
  giftshop_get_transaction_count: {
    method: 'GET',
    url: getAPIBaseUrl() + API_PAYMENT_TRANSACTION_COUNT,
  },
  giftshop_get_transaction_fee: {
    method: 'GET',
    url: getAPIBaseUrl() + API_PAYMENT_TRANSACTION_FEE,
  },
  giftshop_get_transaction_history: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_TRANSACTION_HISTORY,
  },
  giftshop_get_video_sendtoface_content: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_VIDEO_SEND_TO_FACE,
  },
  giftshop_get_wishlist: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_WISHLIST,
  },
  giftshop_get_wishlist_detail: {
    url: getAPIBaseUrl() + API_GIFTSHOP_WISHLIST_DETAIL,
    method: 'GET',
  },
  giftshop_get_withdraw_fee: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TRANSACTION_WITHDRAW_FEE,
  },
  giftshop_patch_bank_account: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_MEMBER_BANK_ACCOUNT,
  },
  giftshop_patch_bank_resend_otp: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_MEMBER_BANK_RESEND_OTP,
  },
  gitfshop_patch_collection_resend_approval: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_GIFTSHOP_COLLECTION_RESEND_APPROVAL,
  },
  giftshop_patch_creations_bulk: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_EDIT_BULK,
  },
  giftshop_patch_creator: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATOR_COMPRESS_RESEND,
  },
  giftshop_patch_creator_wholesale: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATOR_WHOLESALE,
  },
  giftshop_patch_nickname: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_MEMBER_EDIT_PROFILE_NICKNAME,
  },
  giftshop_patch_onboarding: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_GIFTSHOP_ONBOARDING,
  },
  giftshop_patch_password_otp: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_AUTH_CHECK_CHANGE_PASSWORD_TOKEN,
  },
  giftshop_patch_profile: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_MEMBER_EDIT_PROFILE,
  },
  giftshop_patch_resend_email: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_PROFILE_RESEND_ADD_EMAIL,
  },
  giftshop_patch_resend_phone: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_PROFILE_RESEND_ADD_PHONE,
  },
  giftshop_patch_sensitivity_level: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_GIFTSHOP_ACCOUNT_SENSITIVITY,
  },
  giftshop_patch_username: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_MEMBER_EDIT_PROFILE_USERNAME,
  },
  giftshop_patch_userPassword: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_MEMBER_EDIT_PASSWORD,
  },
  giftshop_patch_verify_email: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_PROFILE_VERIFY_ADD_EMAIL,
  },
  giftshop_patch_verify_phone: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_PROFILE_VERIFY_ADD_PHONE,
  },
  giftshop_post_add_email: {
    method: 'POST',
    url: getAPIBaseUrl() + API_PROFILE_ADD_EMAIL,
  },
  giftshop_post_bank_account: {
    method: 'POST',
    url: getAPIBaseUrl() + API_MEMBER_BANK_ACCOUNT,
  },
  giftshop_post_bank_verify_otp: {
    method: 'POST',
    url: getAPIBaseUrl() + API_MEMBER_BANK_VERIFY_OTP,
  },
  giftshop_post_bib_contents: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_EXPLORE_BIB,
  },
  giftshop_post_cart: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_CART,
  },
  giftshop_post_creator_rating: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATOR_RATING,
  },
  giftshop_post_creator_rating_check: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATOR_RATING_CHECK,
  },
  giftshop_post_explore: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_EXPLORE,
  },
  giftshop_post_live_location: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_LIVE_LOCATION,
  },
  giftshop_post_add_favorite: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_FAVORITES,
  },
  giftshop_register_user_link: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_USER_UPLOAD_LINK,
  },
  giftshop_register_user: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_USER,
  },
  giftshop_user_retake_link: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_USER_RETAKE_LINK,
  },
  giftshop_user_retake: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_USER_RETAKE,
  },
  giftshop_post_nickname: {
    method: 'POST',
    url: getAPIBaseUrl() + API_MEMBER_NICKNAMES,
  },
  giftshop_post_phone: {
    method: 'POST',
    url: getAPIBaseUrl() + API_PROFILE_ADD_PHONE,
  },
  giftshop_post_profile_share: {
    method: 'POST',
    url: getAPIBaseUrl() + API_MEMBER_PROFILE_SHARE,
  },
  giftshop_post_recover_content: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_EXPLORE_RECOVER,
  },
  giftshop_post_report: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_REPORT,
  },
  giftshop_post_resend_revision: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_UPLOAD_RESEND_REVISION,
  },
  gistshop_post_send_to_face: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_SEND_TO_FACE,
  },
  giftshop_post_storage_request: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_STORAGE_REQUEST,
  },
  giftshop_post_upload_content_link: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_LINK,
  },
  giftshop_post_upload_content_link_old: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_LINK_OLD,
  },
  giftshop_upload_resend_content_link: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_RESEND_LINK,
  },
  giftshop_post_upload_presigned_url: {
    method: 'PUT',
    url: '',
  },
  giftshop_post_wishlist: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_WISHLIST,
  },
  giftshop_profile_edit_language: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_MEMBER_LANGUAGE,
  },
  giftshop_upload_content: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_UPLOAD,
  },
  giftshop_upload_resend_content: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_RESEND,
  },
  giftshop_withdraw: {
    method: 'POST',
    url: getAPIBaseUrl() + API_TRANSACTION_WITHDRAWAL,
  },
  giftshop_gopay_cancel: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_GOPAY_CANCEL,
  },
  giftshop_make_it_free: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_MAKE_IT_FREE,
  },
  giftshop_post_resend_video: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_VIDEO_RESEND,
  },
  giftshop_post_resend_video_link: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_VIDEO_RESEND_LINK,
  },
  gitfshop_post_resend_revision_video: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_VIDEO_RESEND_REVISION_UPLOAD,
  },
  giftshop_post_resendpology_cancel: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_RESENDPOLOGY_CANCEL,
  },
  giftshop_post_upload_video: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_VIDEO,
  },
  giftshop_post_upload_video_link: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_VIDEO_LINK,
  },
  giftshop_post_upload_video_sendtoface_link: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_CREATION_VIDEO_SEND_TO_FACE_LINK,
  },
  giftshop_get_transaction_report: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_TRANSACTION_REPORT,
  },
  giftshop_post_transaction_report: {
    method: 'POST',
    url: getAPIBaseUrl() + API_GIFTSHOP_TRANSACTION_REPORT,
  },
  giftshop_similar_get_account: {
    method: 'GET',
    url: getAPIBaseUrl() + API_MEMBER_SIMILAR,
  },
  giftshop_similar_post_account: {
    method: 'POST',
    url: getAPIBaseUrl() + API_MEMBER_SIMILAR,
  },
  giftshop_similar_get_status: {
    method: 'GET',
    url: getAPIBaseUrl() + API_MEMBER_SIMILAR_STATUS,
  },
  giftshop_similar_delete_other: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_MEMBER_SIMILAR,
  },
  giftshop_similar_patch_account: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_MEMBER_SIMILAR,
  },
  giftshop_similar_skip_account: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_MEMBER_SIMILAR_SKIP,
  },
  giftshop_post_partnership: {
    method: 'POST',
    url: getAPIBaseUrl() + API_NOTIFICATION_PARTNERSHIP,
  },
}
