import React, {useCallback, useEffect, useState} from 'react'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {ImageProps} from './ImageProps'

const StyledLazyLoad = styled(LazyLoadImage)`
  overflow: hidden;
  font-size: 0;
  object-fit: cover;
`

const StyledImg = styled.img`
  overflow: hidden;
  font-size: 0;
  object-fit: cover;
`

export default function Image({
  useLazyLoad = false,
  src: srcBase,
  fallbackSrc,
  placeholder,
  onError,
  ...props
}: ImageProps) {
  const placeholderImage = IMAGE_ASSET('giftshop', 'placeholder.png')
  const [src, setSrc] = useState<string | undefined>(srcBase)

  const handleError = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      onError && onError(e)
      if (fallbackSrc) {
        setSrc(fallbackSrc)
      }
    },
    [onError, fallbackSrc],
  )

  useEffect(() => {
    setSrc(srcBase)
  }, [srcBase])

  return useLazyLoad ? (
    <StyledLazyLoad
      {...props}
      src={src}
      placeholderSrc={placeholder || placeholderImage}
      onError={handleError}
    />
  ) : (
    <StyledImg
      {...props}
      src={src}
      placeholder={placeholder}
      onError={handleError}
    />
  )
}
