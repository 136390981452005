import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {AuthFormSimpleRegisterData} from 'types'
import {parseDate, useHistory} from 'utils'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {TemplateRegisterForm} from '../../../../template'
import {AuthRegisterSSOUsernameProps} from './AuthRegisterSSOUsernameProps'

const StyledContainer = styled.div`
  padding: 0 0 ${convertUnit(25)} 0;
  flex: 1;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: ${convertUnit(40)} 0 ${convertUnit(25)} 0;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`

export default function AuthRegisterSSOUsername({
  stateValue,
  suggestions,
  method,
}: AuthRegisterSSOUsernameProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const [savedValues, setSavedValues] = stateValue
  const form = useForm<AuthFormSimpleRegisterData>({
    defaultValues: {...savedValues, username: suggestions},
  })
  const {watch} = form
  const values = watch()
  const {username, password, dateOfBirth} = values
  const stateError = useState<string>()
  const setErrorMessage = stateError[1]
  const stateLoading = useState(false)
  const setLoading = stateLoading[1]
  const {lang} = useSelector('languageState')

  const handleRegister = useCallback(() => {
    const finalValues = {
      ...savedValues,
      ...values,
    }

    const {token} = finalValues

    setSavedValues(finalValues)

    setLoading(true)
    requestData(
      method === 'google'
        ? 'auth_register_sso_google'
        : 'auth_register_sso_apple',
      {
        actionType: 'execute',
        data:
          method === 'apple'
            ? {
                token,
                username,
                date_of_birth: parseDate(dateOfBirth.toString(), 'DD/MM/YYYY'),
                lang: lang || 'id',
              }
            : {
                token,
                username,
                date_of_birth: parseDate(dateOfBirth.toString(), 'DD/MM/YYYY'),
                password,
                lang: lang || 'id',
              },
        onRequestReceived: () => setLoading(false),
        onRequestFailed: (reason) =>
          setErrorMessage(translate('global:messageError', {context: reason})),
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            history.push('giftshop_robopet', {})
          } else if (status === 409) {
            setErrorMessage(translate('auth:validationUsernameHasBeenTaken'))
          } else if (status >= 400 && status !== 409) {
            setErrorMessage(translate('global:messageError'))
          }
        },
      },
    )
  }, [
    dateOfBirth,
    history,
    lang,
    method,
    password,
    savedValues,
    setErrorMessage,
    setLoading,
    setSavedValues,
    translate,
    username,
    values,
  ])

  return (
    <StyledContainer>
      <TemplateRegisterForm
        form={form}
        stateError={stateError}
        stateLoading={stateLoading}
        stateValue={stateValue}
        hidePasswordField={method === 'apple'}
        onClick={handleRegister}
      />
    </StyledContainer>
  )
}
