import React, {useMemo} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {useHistory} from 'utils'
import {Button, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {AuthTemplateScreen} from '../../template'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
`

const StyledContentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`

const StyledHeader = styled(Paragraph)`
  text-align: center;
`

const StyledImageContainer = styled.div`
  justify-content: center;
  display: flex;
  margin-top: ${convertUnit(30)};
`

const StyledRobopet = styled(Image)`
  object-fit: contain;
  width: ${convertUnit(226)};
  height: ${convertUnit(140)};
  flex-grow: 0;
`

const StyledParagraph = styled(Paragraph)`
  width: ${convertUnit(280)};
  text-align: center;
  display: inline;
`

const StyledParagraphContainer = styled.div`
  justify-content: center;
  margin-top: ${convertUnit(20)};
  text-align: center;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
`

export default function AuthLoginUnderageScreen() {
  const {translate} = useTranslation()
  const img = IMAGE_ASSET('giftshop', 'robopet-offer.png')
  const history = useHistory()

  const handleRenderContent = useMemo(
    () => (
      <>
        <StyledParagraphContainer>
          <StyledParagraph fontSize="m" color="black">
            {translate('auth:loginUnderageHeader')}
          </StyledParagraph>
        </StyledParagraphContainer>
        <StyledImageContainer>
          <StyledRobopet src={img} alt="img" />
        </StyledImageContainer>
        <StyledParagraphContainer>
          <StyledParagraph fontSize="m" color="black">
            {translate('auth:loginUnderageDescription')}
          </StyledParagraph>
        </StyledParagraphContainer>
        <StyledButton
          label={translate('global:ok')}
          onClick={() => history.push('auth_login', {})}
        />
      </>
    ),
    [history, img, translate],
  )

  return (
    <AuthTemplateScreen>
      <StyledContainer>
        <StyledContentContainer>
          <StyledHeader fontSize="xl" fontWeight="bold">
            {translate('auth:loginUnderage')}
          </StyledHeader>
          {handleRenderContent}
        </StyledContentContainer>
      </StyledContainer>
    </AuthTemplateScreen>
  )
}
