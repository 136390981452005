import React from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {useHistory} from 'utils'
import {Button, ButtonOutline, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledOuterContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: ${convertUnit(325)};
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: 100%;
    padding: ${convertUnit(25)};
  }
`

const StyledDescription = styled.div`
  margin-top: ${convertUnit(10)};
  margin-bottom: ${convertUnit(15)};
`

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${convertUnit(170)};
  gap: ${convertUnit(8)};
`

const StyledButtonOutline = styled(ButtonOutline)`
  height: ${convertUnit(32)};
`
const StyledButton = styled(Button)`
  height: ${convertUnit(32)};
`

export default function TemplateAuthAccessFullPage() {
  const {translate} = useTranslation()
  const history = useHistory()

  return (
    <StyledOuterContainer>
      <StyledContainer>
        <Paragraph fontSize="l" fontWeight="bold">
          {translate('forkygram:authAccessHeader')}
        </Paragraph>

        <StyledDescription>
          <Paragraph fontSize="s" color="gray_5">
            {translate('forkygram:authAccessDescription')}
          </Paragraph>
        </StyledDescription>

        <StyledButtonsContainer>
          <StyledButtonOutline
            borderColor="gray_5"
            label={translate('global:signUp')}
            color="gray_5"
            onClick={() => history.push('auth_easy_signup', {})}
          />
          <StyledButton
            label={translate('global:login')}
            onClick={() => history.push('auth_login', {})}
            data-cy="TemplateAuthAccessLoginButton"
          />
        </StyledButtonsContainer>
      </StyledContainer>
    </StyledOuterContainer>
  )
}
