import React, {CSSProperties, useCallback, useMemo} from 'react'
import {translate} from 'i18n'
import convertUnit from 'lib/unit'
import styled from 'styled-components'
import {useTimer} from 'utils'
import {Paragraph} from '../Paragraph'
import {TextTimerProps} from './TextTimerProps'

interface StyledResendProps {
  cursor: CSSProperties['cursor']
}

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`
const StyledResend = styled(Paragraph)<StyledResendProps>`
  margin-left: ${convertUnit(5)};
  cursor: ${({cursor}) => cursor};
  text-align: center;
`

export default function TextTimer({
  textCooldown,
  textAvailable,
  cooldown = 60,
  resetWhenPress = true,
  onPress,
  leadingText,
  hasLeadingText = false,
  lastSaved,
  countOnStart,
  ...props
}: TextTimerProps) {
  const today = useMemo(() => new Date().getTime(), [])
  const timeDiff = Math.floor(today - lastSaved) / 1000
  const continueCount: boolean = timeDiff < cooldown
  const {countdown, reset} = useTimer({
    countOnStart: countOnStart ?? true,
    duration: lastSaved && continueCount ? cooldown - timeDiff : 0,
  })
  const isAvailable = useMemo(() => countdown > 0, [countdown])

  const countDownMinute = Math.floor(countdown / 60)
  const countDownSecond = countdown - 60 * countDownMinute

  const text = useMemo(
    () =>
      isAvailable && resetWhenPress
      ? textCooldown === 'Email'
        ? translate('auth:verificationEmailResendCountdown', {
            countdown:
              countdown > 60
                ? `${countDownMinute}m${countDownSecond}`
                : `${countDownSecond}`,
          })
        : textCooldown === 'whatsapp'
        ? translate('auth:verificationWhatsappResendCountdown', {
            countdown:
              countdown > 60
                ? `${countDownMinute}m${countDownSecond}`
                : `${countDownSecond}`,
          })
        : translate('auth:forgotPasswordPhoneResendCountdown', {
            countdown:
              countdown > 60
                ? `${countDownMinute}m${countDownSecond}`
                : `${countDownSecond}`,
          })
      : textAvailable,
    [
      isAvailable,
      resetWhenPress,
      textCooldown,
      countdown,
      countDownMinute,
      countDownSecond,
      textAvailable,
    ],
  )

  const handlePressResend = useCallback(() => {
    if (!isAvailable && resetWhenPress) {
      reset(cooldown)
      onPress()
    }
  }, [isAvailable, resetWhenPress, onPress, reset, cooldown])

  const handleLeadingText = useMemo(
    () => (
      <>
        {isAvailable ? (
          <Paragraph fontSize="s" color="gray_5">
            {leadingText}
          </Paragraph>
        ) : (
          <Paragraph fontSize="s" color="gray_5">{`${translate(
            'auth:verificationPhoneOTP',
          )} `}</Paragraph>
        )}
      </>
    ),
    [isAvailable, leadingText],
  )

  return (
    <StyledContainer>
      {hasLeadingText ? handleLeadingText : <></>}
      <StyledResend
        {...props}
        cursor={countdown === 0 && resetWhenPress ? 'pointer' : 'default'}
        color={isAvailable || !resetWhenPress ? 'gray_3' : 'primary_5'}
        fontWeight="medium"
        onClick={handlePressResend}>
        {text}
      </StyledResend>
    </StyledContainer>
  )
}
