import { QueryParam, RoutePath, Routes } from 'types'
import { ROUTE_MAP } from '../../routes/RouteMap'

export function getRoute<K extends Routes>(key: K) {
  return ROUTE_MAP[key]
}

export function getQueryParam(queryParam: QueryParam) {
  const res: string[] = []

  Object.keys(queryParam).forEach((key) =>
    res.push(`?${key}=${queryParam[key]}`),
  )

  return res.toString().replaceAll(',', '')
}

export function getRoutePath(path: RoutePath) {
  return typeof path === 'string'
    ? getRoute(path).path
    : getRoute(path.key).path + getQueryParam(path.param)
}
