import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {
  GiftShopTemplateHostFeeModal,
  GiftShopTemplateZeroPriceModal,
} from 'pages'
import {GiftShopExploreDetailTagResponse, WindowModeType} from 'types'
import {showSnackbar, useGiftshopUploadPublish} from 'utils'
import {useWindowMode} from 'windows'
import {Button} from 'common/components'
import convertUnit from 'lib/unit'
import GiftShopUploadPublishModal from './GiftShopUploadPublishModal'
import {GiftShopUploadPublishProps} from './GiftShopUploadPublishProps'

interface StyledContainerProps {
  mode: WindowModeType
}

interface StyledButtonProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, mode}) => ({
    padding: mode === 'mobile' ? 0 : `0 ${convertUnit(20)}`,
    backgroundColor: theme.white_1,
    justifyContent: 'flex-end',
  })}
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
`

const StyledButton = styled(Button)<StyledButtonProps>`
  flex: 1;
  ${({mode}) => ({
    width: mode === 'website' ? convertUnit(200) : '100%',
  })}
`

export default function GiftShopUploadPublishVideo({
  data,
  formRef,
  mapRef,
  uploadType,
  videoFaceData,
}: GiftShopUploadPublishProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const {publish, retry} = useGiftshopUploadPublish({
    data,
    mapRef,
    formRef,
    videoFaceData,
    uploadType,
  })
  const {active, mapForm} = mapRef.map.current
  const [modal, setModal] = useState<'zeroprice' | 'hostfee' | undefined>(
    undefined,
  )
  const disableButton = useMemo(() => {
    const sendToFaceSrc = videoFaceData?.src
    return !videoFaceData || !sendToFaceSrc || sendToFaceSrc === ''
  }, [videoFaceData])
  const [loading, setLoading] = useState(false)

  const handleToggleModal = useCallback(() => {
    setModal(undefined)
  }, [])

  const handleCheckZeroPriceAndHostFee = useCallback(
    async (price: string, tag?: GiftShopExploreDetailTagResponse) => {
      if (price === '') {
        showSnackbar(translate('giftShop:uploadPriceEmpty'))
        return false
      }
      if (parseInt(price, 10) === 0) {
        setModal('zeroprice')
        return false
      }
      if (!!tag && tag.host_fee) {
        setModal('hostfee')
        return false
      }
      return true
    },
    [],
  )

  const handleCheckLocation = useCallback(
    async (location: string, tags: GiftShopExploreDetailTagResponse[]) => {
      if (location === '' && tags.length === 0) {
        showSnackbar(
          translate('giftShop:uploadNeedLocationOrFototreeErrorMessage'),
        )
        return false
      }
      return true
    },
    [translate],
  )

  const handleCheckUploadRequirement = useCallback(async () => {
    const formData = Object.values(formRef.current)
    setLoading(true)

    for (const dt of formData) {
      // eslint-disable-next-line no-await-in-loop
      const checkLocation = await handleCheckLocation(dt.location, dt.tags)

      if (!checkLocation) {
        setLoading(false)
        return
      }

      // eslint-disable-next-line no-await-in-loop
      const checkPriceAndHostFee = await handleCheckZeroPriceAndHostFee(
        dt.price,
        dt.tags[0],
      )
      if (!checkLocation || !checkPriceAndHostFee) {
        setLoading(false)
        return
      }
    }
    publish()
  }, [formRef, handleCheckLocation, handleCheckZeroPriceAndHostFee, publish])

  const handleRenderZeroPriceConfirmationModal = useMemo(
    () => (
      <GiftShopTemplateZeroPriceModal
        visible
        onConfirm={() => {
          setLoading(true)
          publish()
        }}
        toggleModal={handleToggleModal}
        onCancel={() => setLoading(false)}
      />
    ),
    [handleToggleModal, publish],
  )

  const handleRenderHostFeeConfirmationModal = useMemo(
    () => (
      <GiftShopTemplateHostFeeModal
        visible
        onConfirm={() => {
          setLoading(true)
          publish()
        }}
        onCancel={() => setLoading(false)}
        toggleModal={handleToggleModal}
      />
    ),
    [handleToggleModal, publish],
  )

  const handleRenderModal = useMemo(() => {
    switch (modal) {
      case 'hostfee':
        return handleRenderHostFeeConfirmationModal
      case 'zeroprice':
        return handleRenderZeroPriceConfirmationModal
      default:
        return <></>
    }
  }, [
    handleRenderHostFeeConfirmationModal,
    handleRenderZeroPriceConfirmationModal,
    modal,
  ])

  return (
    <StyledContainer mode={mode}>
      {handleRenderModal}
      <GiftShopUploadPublishModal
        mapForm={mapForm}
        onRetry={retry}
        toggle={active}
      />
      <StyledButton
        mode={mode}
        label={translate('global:upload')}
        onClick={() => {
          handleCheckUploadRequirement()
        }}
        backgroundColor="primary_5"
        isLoading={loading}
        disabled={disableButton || loading || active}
      />
    </StyledContainer>
  )
}
