import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {ThemeColor} from 'themes'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateProfileCreationChipProps} from './TemplateProfileCreationChipProps'

interface StyledChipItemProps {
  active: boolean
  disabled?: boolean
}

interface StyledCountProps {
  color: ThemeColor
}

const StyledChipItem = styled.div<StyledChipItemProps>`
  ${({theme, active}) => ({
    backgroundColor: active ? theme.primary_6 : theme.gray_1,
    color: active ? theme.white_1 : theme.gray_9,
  })};
  text-align: center;
  padding: ${convertUnit(5)} ${convertUnit(16)};
  border-radius: ${convertUnit(50)};
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  margin-right: ${convertUnit(8)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledCount = styled.div<StyledCountProps>`
  width: ${convertUnit(18)};
  height: ${convertUnit(18)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: ${convertUnit(4)};
  background-color: ${({color, theme}) => theme[color]};
`

export default function TemplateProfileCreationChip({
  type,
  resendCount,
  resendpologyCount,
  disabled,
  stateCreationType,
}: TemplateProfileCreationChipProps) {
  const {translate} = useTranslation()
  const [creationType, setCreationType] = stateCreationType

  return (
    <StyledChipItem
      active={type === creationType}
      disabled={disabled}
      onClick={() => (disabled ? undefined : setCreationType(type))}>
      <Paragraph
        fontSize="s"
        fontWeight="medium"
        color={type !== creationType && disabled ? 'gray_3' : 'inherit'}>
        {translate('giftShop:creationChip', {context: type})}
      </Paragraph>
      {(resendCount && resendCount > 0) ||
      (resendpologyCount && resendpologyCount > 0) ? (
        <StyledCount
          color={resendCount && resendCount > 0 ? 'danger_5' : 'warning_5'}>
          <Paragraph fontSize="s" fontWeight="medium" color="white_1">
            {resendCount && resendCount > 0 ? resendCount : resendpologyCount}
          </Paragraph>
        </StyledCount>
      ) : null}
    </StyledChipItem>
  )
}
