import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {parseShape} from 'types'
import {formatRelativeDateDiff, showModalReprocessKyc, useHistory} from 'utils'
import {TemplateAuthAccessModal} from 'pages'
import {
  IconVerifiedBadge,
  Image,
  Paragraph,
  ParsedText,
} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {ForkygramCommentReplyProps} from './ForkygramCommentItemProps'

const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  margin-right: ${convertUnit(4)};
  line-height: ${convertUnit(16)};
  margin-left: ${convertUnit(4)};
`

const StyledAvatar = styled(Image)`
  border-radius: 50%;
  object-fit: cover;
  margin-right: ${convertUnit(12)};
  cursor: pointer;
  width: ${convertUnit(40)};
  height: ${convertUnit(40)};
  border: solid ${convertUnit(1)} ${({theme}) => theme.white_1};
`

const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledContentContainer = styled(StyledColumnContainer)`
  display: flex;
  width: fill-available;
  width: -webkit-fill-available;
  width: -moz-fill-available;
`

const StyledHeaderContainer = styled(StyledRowContainer)`
  align-items: center;
  gap: ${convertUnit(4)};
  width: 100%;
`

const StyledDescriptionContainer = styled(StyledRowContainer)`
  margin-top: ${convertUnit(2)};
  gap: ${convertUnit(16)};
  display: flex;
  flex: 1;
  justify-content: space-between;
`

const StyledCommentContainer = styled(StyledColumnContainer)`
  display: flex;
  flex-direction: column;
  flex: 1;
  word-break: break-word;
  word-wrap: break-word;
  gap: ${convertUnit(4)};
`

const StyledContainer = styled(StyledRowContainer)`
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${convertUnit(12)} ${convertUnit(20)};
  padding-left: ${convertUnit(0)};
  box-sizing: border-box;
`

export default function ForkygramCommentReply({
  data,
  commentId,
  stateRecipient,
}: ForkygramCommentReplyProps) {
  const history = useHistory()
  const {access_token, id: userId} = useSelector('user') || {}
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const {kycStatus} = useSelector('yuserActivationState')
  const {translate} = useTranslation()

  const {id, username, photo} = data.member
  const {comment, is_creator, mentions} = data

  const [loginModal, setLoginModal] = useState(false)
  const setRecipient = stateRecipient[1]

  const handleFeatureDisabled = useCallback(() => {
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [history, kycStatus, similarStatus])

  const handleReplyComment = useCallback(() => {
    if (access_token) {
      if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
        handleFeatureDisabled()
        return
      }
      setRecipient({
        comment_id: commentId,
        username,
      })
    } else setLoginModal(true)
  }, [access_token, commentId, handleFeatureDisabled, kycStatus, setRecipient, username])

  const handleNavigateProfile = useCallback(() => {
    history.push(
      'giftshop_profile',
      {
        self: id === userId,
        postData: {member_id: id, username, photo},
      },
      username,
    )
  }, [history, id, photo, userId, username])

  const handleRenderBadge = useMemo(
    () => is_creator === 'verified' && <StyledIconVerifiedBadge />,
    [is_creator],
  )

  const patterns: parseShape[] = useMemo(
    () =>
      mentions &&
      mentions.map((mention) => {
        const regex = new RegExp(`{{${mention.id}}}`)
        return {
          pattern: regex,
          style: {fontFamily: 'roboto-bold', cursor: 'pointer'},
          renderText: (text) => text.replace(regex, `@${mention.username}`),
          onClick: () => {
            history.push(
              'giftshop_profile',
              {
                self: mention.id === userId,
                postData: {
                  member_id: mention.id,
                  username: mention.username,
                  photo: mention.photo,
                },
              },
              mention.username,
            )
          },
        }
      }),
    [history, mentions, userId],
  )

  const handleRenderAvatar = useMemo(
    () => (
      <StyledAvatar src={photo} alt="avatar" onClick={handleNavigateProfile} />
    ),
    [handleNavigateProfile, photo],
  )

  const handleRenderUsername = useMemo(
    () => (
      <Paragraph
        className="pressable"
        fontSize="s"
        fontWeight="bold"
        onClick={handleNavigateProfile}>
        {username}
      </Paragraph>
    ),
    [handleNavigateProfile, username],
  )

  const handleRenderDate = useMemo(
    () => formatRelativeDateDiff(data.created_at),
    [data.created_at],
  )

  const handleRenderLoginModal = useMemo(
    () => (
      <TemplateAuthAccessModal
        toggleModal={() => setLoginModal((prev) => !prev)}
        visible={loginModal}
      />
    ),
    [loginModal],
  )

  const handleRenderReply = useMemo(
    () => (
      <>
        <StyledContentContainer>
          <StyledHeaderContainer>
            {handleRenderUsername}
            {handleRenderBadge}
          </StyledHeaderContainer>
          <Paragraph fontSize="s">
            <ParsedText parse={patterns}>{comment}</ParsedText>
          </Paragraph>
        </StyledContentContainer>
        <StyledDescriptionContainer>
          <Paragraph fontSize="s" color="gray_5">
            {handleRenderDate}
          </Paragraph>
          <Paragraph
            fontSize="s"
            fontWeight="bold"
            color="gray_5"
            style={{cursor: 'pointer'}}
            onClick={handleReplyComment}>
            {translate('forkygram:reply')}
          </Paragraph>
        </StyledDescriptionContainer>
      </>
    ),
    [
      comment,
      handleRenderBadge,
      handleRenderDate,
      handleRenderUsername,
      handleReplyComment,
      patterns,
      translate,
    ],
  )

  return (
    <StyledContainer>
      {handleRenderLoginModal}
      {handleRenderAvatar}
      <StyledCommentContainer>{handleRenderReply}</StyledCommentContainer>
    </StyledContainer>
  )
}
