import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {parseShape} from 'types'
import {formatRelativeDateDiff, useDebounce, useHistory} from 'utils'
import {requestData} from 'services'
import {
  Icon,
  IconVerifiedBadge,
  Image,
  ListItemSelect,
  Menu,
  Paragraph,
  ParsedText,
} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TemplateAuthAccessModal} from 'pages'
import {ForkygramCommentCommentProps} from './ForkygramCommentItemProps'
import {ForkygramReplyContent} from '../../Content'

const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  margin-right: ${convertUnit(4)};
  line-height: ${convertUnit(16)};
  margin-left: ${convertUnit(4)};
`

const StyledAvatar = styled(Image)`
  border-radius: 50%;
  object-fit: cover;
  margin-right: ${convertUnit(12)};
  cursor: pointer;
  width: ${convertUnit(40)};
  height: ${convertUnit(40)};
  border: solid ${convertUnit(1)} ${({theme}) => theme.white_1};
`

const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledContentContainer = styled(StyledColumnContainer)`
  display: flex;
  width: fill-available;
  width: -webkit-fill-available;
  width: -moz-fill-available;
`

const StyledHeaderContainer = styled(StyledRowContainer)`
  align-items: center;
  gap: ${convertUnit(4)};
  width: 100%;
  justify-content: space-between;
`

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: ${convertUnit(2)};
  gap: ${convertUnit(16)};
`

const StyledLoadMoreContainer = styled(StyledRowContainer)`
  gap: ${convertUnit(12)};
  cursor: pointer;
`

const StyledCommentContainer = styled(StyledColumnContainer)`
  display: flex;
  flex: 1;
  word-break: break-word;
  word-wrap: break-word;
  gap: ${convertUnit(4)};
`

const StyledContainer = styled(StyledRowContainer)`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${convertUnit(12)} ${convertUnit(20)};
  box-sizing: border-box;
`

const StyledMenu = styled(Menu)`
  display: flex;
  height: 100%;
  align-items: center;
`

const StyledLikeCommentWrapper = styled(StyledRowContainer)`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export default function ForkygramCommentComment({
  data,
  ownerId,
  stateSelectedComment,
  stateOpenModalDelete,
  stateRecipient,
  stateOpenModalReport,
}: ForkygramCommentCommentProps) {
  const history = useHistory()
  const debounce = useDebounce()
  const {access_token, id: userId} = useSelector('user') || {}
  const {translate} = useTranslation()

  const [loadReply, setLoadReply] = useState(false)
  const [isLikeComment, setIsLikeComment] = useState(data.is_liked)
  const [likeCommentCount, setLikeCommentCount] = useState(data.like_count)
  const stateToggle = useState(false)
  const isCommentAuthor = userId === data.member.id
  const isPicOwner = userId === ownerId
  const canDelete = isCommentAuthor || isPicOwner
  const setToggle = stateToggle[1]
  const {id, username, photo, creator_status} = data.member
  const {comment, comment_id, mentions, reply_count} = data
  const [loginModal, setLoginModal] = useState(false)

  const setSelectedComment = stateSelectedComment[1]
  const setRecipient = stateRecipient[1]
  const setOpenModalDelete = stateOpenModalDelete[1]
  const setOpenModalReport = stateOpenModalReport[1]

  const handleLikeComment = useCallback(() => {
    debounce(() => {
      setIsLikeComment((prev) => !prev)
      if (isLikeComment) {
        setLikeCommentCount((prev) => prev - 1)
        requestData('forkygram_delete_like_comment', {
          data: {comment_id},
          onRequestFailed: () => {
            setIsLikeComment((prev) => !prev)
            setLikeCommentCount((prev) => prev + 1)
          },
        })
      } else {
        setLikeCommentCount((prev) => prev + 1)
        requestData('forkygram_post_like_comment', {
          data: {comment_id},
          onRequestFailed: () => {
            setIsLikeComment((prev) => !prev)
            setLikeCommentCount((prev) => prev - 1)
          },
        })
      }
    })
  }, [comment_id, debounce, isLikeComment])

  const handlePressLikeComment = useCallback(() => {
    if (access_token) handleLikeComment()
    else setLoginModal(true)
  }, [access_token, handleLikeComment])

  const handleRenderBadge = useMemo(
    () => creator_status === 'verified' && <StyledIconVerifiedBadge />,
    [creator_status],
  )

  const handleNavigateProfile = useCallback(() => {
    history.push(
      'giftshop_profile',
      {
        self: id === userId,
        postData: {member_id: id, username, photo},
      },
      username,
    )
  }, [history, id, photo, userId, username])

  const patterns: parseShape[] = useMemo(
    () =>
      mentions &&
      mentions.map((mention) => {
        const regex = new RegExp(`{{${mention.id}}}`)
        return {
          pattern: regex,
          style: {fontFamily: 'roboto-bold', cursor: 'pointer'},
          renderText: (text) => text.replace(regex, `@${mention.username}`),
          onClick: () => {
            history.push(
              'giftshop_profile',
              {
                self: mention.id === userId,
                postData: {
                  member_id: mention.id,
                  username: mention.username,
                  photo: mention.photo,
                },
              },
              mention.username,
            )
          },
        }
      }),
    [history, mentions, userId],
  )

  const handleRenderLoginModal = useMemo(
    () => (
      <TemplateAuthAccessModal
        toggleModal={() => setLoginModal((prev) => !prev)}
        visible={loginModal}
      />
    ),
    [loginModal],
  )

  const handleRenderAvatar = useMemo(
    () => (
      <StyledAvatar src={photo} alt="avatar" onClick={handleNavigateProfile} />
    ),
    [handleNavigateProfile, photo],
  )

  const handleRenderUsername = useMemo(
    () => (
      <Paragraph
        className="pressable"
        fontWeight="bold"
        onClick={handleNavigateProfile}>
        {username}
      </Paragraph>
    ),
    [handleNavigateProfile, username],
  )

  const handleRenderDate = useMemo(
    () => formatRelativeDateDiff(data.created_at),
    [data.created_at],
  )

  const handleRenderOptions = useMemo(
    () => (
      <StyledColumnContainer>
        {!isCommentAuthor && (
          <ListItemSelect
            title={translate('forkygram:reportComment')}
            onClick={() => {
              setToggle((prev) => !prev)
              setOpenModalReport(true)
              setSelectedComment({
                comment_id: data.comment_id,
                member_id: data.member.id,
              })
            }}
          />
        )}
        {canDelete && (
          <ListItemSelect
            title={translate('forkygram:deletePostComment')}
            onClick={() => {
              setToggle((prev) => !prev)
              setOpenModalDelete(true)
              setSelectedComment({
                comment_id: data.comment_id,
                member_id: data.member.id,
              })
            }}
          />
        )}
      </StyledColumnContainer>
    ),
    [
      isCommentAuthor,
      translate,
      canDelete,
      setToggle,
      setOpenModalReport,
      setSelectedComment,
      data.comment_id,
      data.member.id,
      setOpenModalDelete,
    ],
  )

  const handleRenderActions = useMemo(
    () => (
      <StyledMenu
        stateToggle={stateToggle}
        renderContent={handleRenderOptions}
        contentContainerStyle={{
          width: '100%',
          minWidth: convertUnit(185),
          right: 0,
          top: convertUnit(-60),
          borderRadius: convertUnit(8),
        }}>
        <Icon
          size={16}
          color="gray_5"
          type="more-filled"
          style={{cursor: 'pointer'}}
          onClick={() =>
            access_token ? setToggle((prev) => !prev) : setLoginModal(true)
          }
        />
      </StyledMenu>
    ),
    [stateToggle, handleRenderOptions, access_token, setToggle],
  )
  const handleRenderLikeComment = useMemo(
    () => (
      <StyledLikeCommentWrapper>
        <Icon
          size={16}
          type={isLikeComment ? 'like-filled' : 'like-outlined'}
          color={isLikeComment ? 'primary_5' : 'gray_5'}
          onClick={handlePressLikeComment}
        />
        <Paragraph
          color="primary_5"
          fontWeight={isLikeComment ? 'medium' : 'regular'}>
          {likeCommentCount || ''}
        </Paragraph>
      </StyledLikeCommentWrapper>
    ),
    [handlePressLikeComment, isLikeComment, likeCommentCount],
  )

  const handleReply = useCallback(() => {
    if (access_token) {
      setRecipient({
        comment_id,
        username,
      })
    } else setLoginModal(true)
  }, [access_token, comment_id, setRecipient, username])

  const handleRenderComment = useMemo(
    () => (
      <>
        <StyledContentContainer>
          <StyledHeaderContainer>
            <StyledRowContainer>
              {handleRenderUsername}
              {handleRenderBadge}
            </StyledRowContainer>
            <Paragraph color="gray_5">{handleRenderDate}</Paragraph>
          </StyledHeaderContainer>
          <div>
            <Paragraph id="comment">
              <ParsedText parse={patterns}>{comment}</ParsedText>
            </Paragraph>
          </div>
        </StyledContentContainer>
        <StyledDescriptionContainer>
          {handleRenderLikeComment}
          <Paragraph
            fontWeight="bold"
            color="gray_5"
            style={{cursor: 'pointer'}}
            onClick={handleReply}>
            {translate('forkygram:reply')}
          </Paragraph>
          {handleRenderActions}
        </StyledDescriptionContainer>
      </>
    ),
    [
      comment,
      handleRenderActions,
      handleRenderBadge,
      handleRenderDate,
      handleRenderLikeComment,
      handleRenderUsername,
      handleReply,
      patterns,
      translate,
    ],
  )

  const handleRenderLoadMore = useMemo(
    () => (
      <StyledLoadMoreContainer
        onClick={() => {
          setLoadReply((prev) => !prev)
        }}>
        <Icon
          type={loadReply ? 'drop-up' : 'drop-down'}
          size={16}
          color="primary_5"
        />
        <Paragraph fontWeight="bold" color="primary_5">
          {loadReply
            ? translate('forkygram:hideReply')
            : translate('forkygram:viewReply', {count: data.reply_count})}
        </Paragraph>
      </StyledLoadMoreContainer>
    ),
    [data.reply_count, loadReply, translate],
  )

  const handleRenderReply = useMemo(
    () => (
      <ForkygramReplyContent
        commentId={data.comment_id}
        stateRecipient={stateRecipient}
        replyCount={reply_count}
      />
    ),
    [data.comment_id, reply_count, stateRecipient],
  )

  return (
    <StyledContainer>
      {handleRenderLoginModal}
      {handleRenderAvatar}
      <StyledCommentContainer>
        {handleRenderComment}
        {data.reply_count > 0 && handleRenderLoadMore}
        {loadReply && handleRenderReply}
      </StyledCommentContainer>
    </StyledContainer>
  )
}
