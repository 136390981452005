import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import {Button, Icon, Image, Paragraph, ParsedText} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {useTheme} from 'themes'
import {parseShape} from 'types'
import {
  getBoxShadow,
  getFontFamily,
  parseDate,
  showModalLimitedAccess,
  showModalReprocessKyc,
  useHistory,
} from 'utils'
import {FototreeMapCreatorPopupContentProps} from './FototreeMapCreatorProps'

const StyledImage = styled(Image)`
  width: ${convertUnit(68)};
  aspect-ratio: 0.75;
  border-radius: ${convertUnit(8)};
  object-fit: contain;
`

const StyledContainer = styled.div`
  display: flex;
  gap: ${convertUnit(20)};
  padding: ${convertUnit(20)};
  box-shadow: ${({theme}) =>
    getBoxShadow(theme, {verticalOffset: 4, opacity: 0.16})};
  border-radius: ${convertUnit(16)};
  background-color: ${({theme}) => theme.white_1};
`

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledScheduleContainer = styled.div`
  display: inline-flex;
`

const StyledButtonWrapper = styled.div`
  display: flex;
  margin-top: ${convertUnit(8)};
  gap: ${convertUnit(12)};
`

const StyledLeftIcon = styled(Icon)`
  margin-right: ${convertUnit(12)};
`

export default function FototreeMapCreatorPopupContent({
  data,
}: FototreeMapCreatorPopupContentProps) {
  const history = useHistory()
  const theme = useTheme()
  const {translate} = useTranslation()
  const {id} = useSelector('user') || {}
  const {kycStatus} = useSelector('yuserActivationState')
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const {status: childStatus} = useSelector('parentalConsentState')

  const handleFeatureDisabled = useCallback(() => {
    if (childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [childStatus, history, kycStatus, similarStatus])

  const handleClickProfile = useCallback(() => {
    if (childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    history.push(
      'giftshop_profile',
      {
        self: data.member_id === id,
        postData: {
          member_id: data.member_id,
          username: data.member_username,
          photo: data.member_url,
        },
      },
      data.member_username,
    )
  }, [childStatus, data, history, id])

  const handleClickChat = useCallback(() => {
    if (
      childStatus === 'UNDERAGE' ||
      !(
        kycStatus === 'verified' ||
        kycStatus === 'verified_check' ||
        kycStatus === 'onhold'
      )
    ) {
      handleFeatureDisabled()
      return
    }
    history.push('giftshop_message_room', {
      participantId: data.member_id,
      roomType: 'chat',
      roomName: data.member_username,
      roomPhoto: data.member_url,
    })
  }, [childStatus, data, handleFeatureDisabled, history, kycStatus])

  const patterns: parseShape[] = useMemo(
    () => [
      {
        pattern: /<date>.*?<\/date>/,
        style: {
          color: theme.black_1,
          fontFamily: getFontFamily('bold'),
        },
        renderText: (text) => text.replace(/<date>|<\/date>/g, ''),
      },
    ],
    [theme.black_1],
  )

  const relativeDate = useMemo(() => {
    const date = new Date(data.expired_at)
    if (dayjs().isSame(date, 'day')) {
      return `${translate('global:today')}, ${parseDate(
        data.expired_at,
        'HH:mm',
      )}`
    }
    if (dayjs().add(1, 'day').isSame(date, 'day')) {
      return `${translate('global:tomorrow')}, ${parseDate(
        data.expired_at,
        'HH:mm',
      )}`
    }
    return parseDate(data.expired_at, 'D MMMM YYYY, HH:mm')
  }, [data.expired_at, translate])

  return (
    <StyledContainer>
      <StyledImage src={data.member_url} alt={data.member_username} />
      <StyledContentContainer>
        <Paragraph fontWeight="bold" fontSize="l" lineHeight={31}>
          {data.member_username}
        </Paragraph>
        <StyledScheduleContainer>
          <Icon type="calendar-event" size={convertUnit(16)} color="gray_5" />
          <Paragraph>
            <ParsedText parse={patterns}>
              {translate('tree:creatorViewCameraLiveAction', {
                date: relativeDate,
              })}
            </ParsedText>
          </Paragraph>
        </StyledScheduleContainer>
        <StyledButtonWrapper>
          <Button
            type="small"
            fontSize="s"
            label={translate('global:profile')}
            leftIcon={
              <StyledLeftIcon
                type="account"
                size={convertUnit(16)}
                color="white_1"
              />
            }
            onClick={handleClickProfile}
          />
          <Button
            type="small"
            fontSize="s"
            label={translate('global:chat')}
            leftIcon={
              <StyledLeftIcon
                type="message"
                size={convertUnit(16)}
                color="white_1"
              />
            }
            onClick={handleClickChat}
          />
        </StyledButtonWrapper>
      </StyledContentContainer>
    </StyledContainer>
  )
}
