import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import process from 'process'
import base64 from 'base-64'
import {
  getAPIBaseUrl,
  logout,
  showBanModal,
  showLogoutModal,
  showModalOnHold,
  showDownTimeModal,
  getLocationPermission,
} from 'utils'
import {ServiceResponses, UserData} from 'types'
import {API_OAUTH_REFRESH_TOKEN} from 'consts'
import {reduxUpdateDispatcher} from 'lib/redux'
import {REDUX_STORE} from '../lib/redux/ReduxStore'

function handleBanModal(status: number, message: string) {
  showBanModal(status, message)
}

function handleLogoutModal() {
  logout()
  showLogoutModal()
}

function handleDownTimeModal() {
  showDownTimeModal(true)
}

export function handleRequestOnFulfilled(request: AxiosRequestConfig) {
  const token = REDUX_STORE.getState().user?.access_token
  let latitude = REDUX_STORE.getState().user?.latitude
  let longitude = REDUX_STORE.getState().user?.longitude

  /** @todo uncomment after update on infra */
  // const timezoneOffset = -new Date().getTimezoneOffset()

  if (!(latitude && longitude))
    getLocationPermission(
      () => {},
      () =>
        navigator.geolocation.getCurrentPosition(
          (position) => {
            longitude = position.coords.longitude
            latitude = position.coords.latitude
          },
          () => {},
          {enableHighAccuracy: true},
        ),
    )

  // const location = [longitude, latitude]

  if (token && request.headers) {
    request.headers.Authorization = `Bearer ${token}`
  }

  // if (location) {
  //   request.headers['X-Location'] = location
  // }

  // request.headers['X-Timezone-Offset'] = timezoneOffset

  return request
}

export function handleServiceResponseInterceptor(response: AxiosResponse) {
  const accToken = REDUX_STORE.getState().user?.access_token
  const {data, status, config} = response

  if (
    status === 403 &&
    (data.message === 'Banned' || data.message === 'Underage')
  ) {
    handleBanModal(status, data.message)
  } else if (status === 403 && data.message === 'Duplicate Account') {
    accToken
      ? window.location.replace('/similar-identity')
      : showModalOnHold(true)
  } else if (status === 403 && data.message === 'Feature Is Disabled') {
    handleDownTimeModal()
  } else if (
    status === 423 &&
    (data.message === 'Suspended' ||
      data.message === 'Attempt Login Suspended' ||
      data.message === 'Locked')
  ) {
    handleBanModal(status, data.message)
  } else if (status === 401 && data.message !== 'unverified') {
    const token = REDUX_STORE.getState().user?.refresh_token

    /** @todo uncomment after update on infra */
    // const timezoneOffset = -new Date().getTimezoneOffset()

    if (data.detail.access_token === 'invalid access token') {
      handleLogoutModal()
      return
    }

    if (token) {
      const user = 'web'
      const password = process.env.AUTH_CLIENT_PASSWORD

      return axios
        .post(
          getAPIBaseUrl() + API_OAUTH_REFRESH_TOKEN,
          {
            code_verifier: process.env.AUTH_CODE_VERIFIER,
            grant_type: 'refresh_token',
            refresh_token: token,
          },
          {
            headers: {
              Authorization: `Basic ${base64.encode(`${user}:${password}`)}`,
            },
          },
        )
        .then((value) => {
          if (value.status === 200 && config.headers) {
            const {result} = value.data as ServiceResponses<UserData>
            config.headers.Authorization = `Bearer ${result.access_token}`

            /** @todo uncomment after update on infra */
            // config.headers['X-Timezone-Offset'] = timezoneOffset
            // config.headers['X-Location'] = location

            reduxUpdateDispatcher('user', result)
            return axios(config)
          }
          return value
        })
        .catch((value) => {
          if (
            typeof value === 'object' &&
            value.response.status === 401 &&
            (value.response.data.detail.refresh_token ===
              'expired refresh token' ||
              value.response.data.detail.refresh_token ===
                'invalid refresh token')
          ) {
            handleLogoutModal()
          }
        })
    }
  }

  return response
}
