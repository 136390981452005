import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {requestData} from 'services'
import {GiftShopMyCreationStatusStates} from 'types'
import {showModalLimitedAccess, useHistory} from 'utils'
import {ButtonOutline, Icon} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {useSelector} from 'lib/redux'

const StyledActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${convertUnit(20)};
`

const StyledButton = styled(ButtonOutline)`
  width: 100%;
  border-width: ${convertUnit(2)};
`

export default function GiftShopRoboPetButton() {
  const history = useHistory()
  const {username} = useSelector('user') || {}
  const {status: childStatus} = useSelector('parentalConsentState')
  const {translate} = useTranslation()
  const [creator, setCreator] = useState<GiftShopMyCreationStatusStates>(
    'unverified',
  )

  const handleLoadCreatorStatus = useCallback(() => {
    requestData('giftshop_get_creator_verification_status', {
      useDefaultMessage: true,
      actionType: 'fetch',
      onRequestSuccess: ({data: dataResponse}) => {
        setCreator(dataResponse.result.status)
      },
    })
  }, [])

  const handleUpload = useCallback(() => {
    if (childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    if (creator === 'verified') {
      history.push('giftshop_upload', {})
    } else {
      history.push('giftshop_profile', {self: true}, username)
    }
  }, [childStatus, creator, history, username])

  const handleRenderContent = useMemo(
    () => (
      <>
        <StyledActionContainer>
          <StyledButton
            borderColor="primary_5"
            color="primary_5"
            label={translate('giftShop:retakeKYC')}
            fontSize="m"
            fontWeight="bold"
            leftIcon={
              <Icon type="face-scan" color="primary_5" size={convertUnit(16)} />
            }
            onClick={() => {
              history.push('giftshop_robopet_retake', {})
            }}
          />
          <StyledButton
            borderColor="gray_5"
            color="gray_5"
            label={translate('global:settings')}
            fontSize="m"
            fontWeight="bold"
            leftIcon={<Icon type="cog" color="gray_5" size={convertUnit(16)} />}
            onClick={() => {
              history.push('giftshop_setting', {})
            }}
          />
          <StyledButton
            borderColor="gray_5"
            color="gray_5"
            label={translate('global:upload')}
            fontSize="m"
            fontWeight="bold"
            leftIcon={
              <Icon type="upload" color="gray_5" size={convertUnit(16)} />
            }
            onClick={handleUpload}
          />
        </StyledActionContainer>
      </>
    ),
    [handleUpload, history, translate],
  )

  useEffect(() => {
    handleLoadCreatorStatus()
  }, [handleLoadCreatorStatus])

  return handleRenderContent
}
