import React, {useCallback, useMemo, useState} from 'react'
import {translate} from 'i18n'
import {useSelector} from 'lib/redux'
import {requestData} from 'services'
import {TreeFotoTreeLeafFootprintTree} from 'types'
import {useHistory, useLocation} from 'utils'
import {useWindowMode} from 'windows'
import {ListLazy} from 'common/components'
import {GiftShopRootScreen} from 'pages/gift-shop'
import {
  ForkygramListGridItem,
  ForkygramTemplateListGridContent,
} from '../containers'

export default function ForkygramFootprintDetailScreen() {
  const mode = useWindowMode()
  const {memberId} = useLocation('forkygram_footprint_all').state
  const {id: userId} = useSelector('user') || {}
  const [count, setCount] = useState(0)
  const column = mode === 'website' ? 5 : 4
  const history = useHistory()

  const handleClickItem = useCallback(
    (item: TreeFotoTreeLeafFootprintTree) => {
      history.push('forkygram_footprint_detail', {
        memberId,
        treeId: item.tree_id,
        treeName: item.tree_name,
        treeAlias: item.alias,
      })
    },
    [history, memberId],
  )

  const handleRenderItem = useCallback(
    (item: TreeFotoTreeLeafFootprintTree) => (
      <ForkygramListGridItem
        borderColor="black"
        column={column}
        data={{
          contentType: item.post?.content_type || 'photo',
          url: item.url,
          thumbnail: item.url,
          caption: item.tree_name,
          isHidden: item.is_hidden,
        }}
        onClickItem={
          item.is_hidden && userId !== memberId
            ? undefined
            : () => handleClickItem(item)
        }
      />
    ),
    [column, handleClickItem, memberId, userId],
  )

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('tree_get_leaf_footprints', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {page, limit, member_id: memberId},
        onRequestSuccess: ({status, data: {result}}) => {
          if (status === 200) setCount(result.count)
        },
      })
      return typeof response !== 'string' && response.status === 200
        ? response.data.result.footprints
        : []
    },
    [memberId],
  )

  const handleRenderContent = useMemo(
    () => (
      <ForkygramTemplateListGridContent
        innerMargin={0}
        header={false}
        backgroundColor="black"
        containerHeight="100%">
        <ListLazy
          renderItem={handleRenderItem}
          loadData={handleLoadData}
          keyExtractor={(item: TreeFotoTreeLeafFootprintTree) => item.url}
          numColumns={column}
          scrollbar={false}
        />
      </ForkygramTemplateListGridContent>
    ),
    [column, handleLoadData, handleRenderItem],
  )
  return (
    <GiftShopRootScreen
      showBackIcon
      showBadgeIcon={false}
      darkBar
      darkHeader
      headerTitle={translate('tree:fototreeFootprints')}
      headerSubtitle={translate('tree:fototreeFootprintsTreeCount', {count})}>
      {handleRenderContent}
    </GiftShopRootScreen>
  )
}
