import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {WebMessageReceiver} from 'consts'
import {translate} from 'i18n'
import {requestData} from 'services'
import {GiftShopCartDiscountDetail, WindowModeType} from 'types'
import {
  getCurrencyValue,
  getThemeColor,
  parseDate,
  useDebounce,
  useDidMount,
  useHistory,
  useLocation,
  useToggle,
} from 'utils'
import {useWindowMode} from 'windows'
import {Button, CheckboxItem, Icon, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TransactionInfoItem} from 'pages/transaction'
import PaymentCheckoutContentItem from './PaymentCheckoutContentItem'
import PaymentCheckoutErrorModal from './PaymentCheckoutErrorModal'
import {PaymentDoubleBookedModal} from '../DoubleBookedModal'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, mode}) => ({
    backgroundColor: theme.white_1,
    flexDirection: mode === 'website' ? 'row' : 'column',
    margin: mode === 'mobile' ? 0 : convertUnit(20),
    borderRadius: mode === 'mobile' ? 0 : convertUnit(16),
    width: mode === 'mobile' ? '100%' : convertUnit(884),
  })}
  display: flex;
  padding: ${convertUnit(20)};
  padding-bottom: 0;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
  }
`

const StyledContainerSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: ${convertUnit(10)};
`

const StyledContainerSectionData = styled.div`
  margin: ${convertUnit(10)};
`

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledCheckboxContainer = styled.div`
  padding-top: ${convertUnit(20)};
  display: flex;
  flex-direction: row;
`

const StyledInfoTitle = styled(Paragraph)`
  margin-bottom: ${convertUnit(15)};
  user-select: none;
`

const StyledInfoItem = styled(TransactionInfoItem)`
  margin-top: ${convertUnit(10)};
`

const StyledButtonContinue = styled(Button)`
  margin-top: ${convertUnit(25)};
`

const StyledContinueContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_1,
  })}
  position: sticky;
  width: 100%;
  bottom: 0;
`

const StyledPreviewInfoContainer = styled.div`
  background-color: ${({theme}) => getThemeColor(theme, 'white_3', 0.5)};
  border-radius: ${convertUnit(8)};
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin-top: ${convertUnit(20.5)};
  padding: ${convertUnit(8)} ${convertUnit(16)};
  justify-content: left;
  text-align: left;
`

const StyledIconInfo = styled(Icon)`
  align-self: 'left';
`

const StyledPreviewInfoParagraph = styled(Paragraph)`
  text-align: left;
  align-self: center;
  margin-left: ${convertUnit(16)};
  line-height: ${convertUnit(21)};
`

export default function PaymentCheckout() {
  const mode = useWindowMode()
  const debounce = useDebounce()
  const history = useHistory()
  const {data, firstPurchase, totalPrice} = useLocation(
    'payment_checkout',
  ).state
  const {access_token} = useSelector('user')!
  const {value: repostDisabled, toggle: toggleRepostDisabled} = useToggle(false)
  const {value: error, toggle: toggleError} = useToggle(false)
  const token = useRef('')
  const [isLoading, setIsLoading] = useState(false)
  const [discount, setDiscount] = useState(0)
  const paymentPopup = useRef<Window | null>(null)
  const paymentHost = useMemo(() => process.env.HOST_WEB_PAYMENT || '', [])
  const [discDetail, setDiscDetail] = useState<GiftShopCartDiscountDetail[]>([])
  const {value: doubleBooked, toggle: toggleDoubleBooked} = useToggle(false)
  const contentIds = data.map((val) => val.content_id)

  const validateOrigin = useCallback(
    (origin: string) => origin.startsWith(paymentHost),
    [paymentHost],
  )

  const content_ids = useMemo(() => data.map((content) => content.content_id), [
    data,
  ])

  const wholesale_ids = useMemo(
    () =>
      data
        .filter(
          (content) =>
            !firstPurchase.some((v) => v.content_id === content.content_id),
        )
        .map((content) => content.content_id),
    [data, firstPurchase],
  )

  const firstBuySelection = useMemo(
    () =>
      firstPurchase.reduce((prev, curr) => {
        const hostId = curr.tags.find((v) => v.type === 'host')?.id
        if (hostId) {
          prev[hostId] = curr.content_id
        }
        return prev
      }, {} as Record<string, string>),
    [firstPurchase],
  )

  const handleGetDiscount = useCallback(() => {
    wholesale_ids.length > 0 &&
      requestData('giftshop_get_cart_wholesale_discount', {
        actionType: 'fetch',
        params: {content_ids: wholesale_ids.join(',')},
        onRequestSuccess: ({status, data: {result}}) => {
          if (status === 200) {
            setDiscount(result.discount)
            setDiscDetail(result.discount_detail)
          }
        },
      })
  }, [wholesale_ids])

  const handleOpenPaymentWindow = useCallback(() => {
    paymentPopup.current?.close()
    paymentPopup.current = window.open(
      `${process.env.HOST_WEB_PAYMENT}/checkout?token=${token.current}&access_token=${access_token}&version=1.1`,
      '_blank',
      'toolbar=yes,scrollbars=no,top=500,left=500,width=400, height=600',
    )
  }, [access_token])

  const handleCheckout = useCallback(() => {
    debounce(() => {
      setIsLoading(true)
      requestData('checkout_token', {
        data: {
          content_ids,
          autopost_disabled: repostDisabled,
          first_buy_selection: firstBuySelection,
        },
        onRequestSuccess: ({status, data: response}) => {
          if (status === 200) {
            token.current = response.result
            setIsLoading(false)
            handleOpenPaymentWindow()
          } else {
            setIsLoading(false)
            toggleError()
          }
        },
        onRequestFailed: () => {
          setIsLoading(false)
          toggleError()
        },
      })
    })
  }, [
    content_ids,
    debounce,
    firstBuySelection,
    handleOpenPaymentWindow,
    repostDisabled,
    toggleError,
  ])

  const handleCheckDoubleBooked = useCallback(() => {
    setIsLoading(true)
    requestData('checkout_check_double', {
      actionType: 'execute',
      useDefaultMessage: true,
      data: {content_ids: contentIds},
      onRequestSuccess: ({status, data: {result}}) => {
        if (status === 200) {
          const isDoubleBooked = !!result.contents.find(
            (res) => res.status === 'PROCESSED',
          )
          if (isDoubleBooked) {
            setIsLoading(false)
            toggleDoubleBooked()
          } else {
            handleCheckout()
          }
        } else setIsLoading(false)
      },
    })
  }, [contentIds, handleCheckout, toggleDoubleBooked])

  const timestamp = useMemo(() => new Date(), [])

  const finalPrice = useMemo(() => totalPrice - discount, [
    discount,
    totalPrice,
  ])

  const handleRenderDiscount = useMemo(
    () =>
      discount > 0 && (
        <StyledInfoItem
          title={translate('giftShop:discount')}
          description={getCurrencyValue(discount)}
        />
      ),
    [discount],
  )

  const handleRenderFinalPrice = useMemo(
    () => (
      <StyledInfoItem
        title={translate('giftShop:totalPrice')}
        description={getCurrencyValue(finalPrice)}
        totalPrice
        boldDescription
      />
    ),
    [finalPrice],
  )

  const handleRenderInfo = useMemo(
    () => (
      <StyledSection>
        <StyledInfoTitle fontSize="xl" fontWeight="bold">
          {translate('transaction:checkoutTransactionTitle')}
        </StyledInfoTitle>
        <StyledInfoItem
          title={translate('transaction:checkoutTransactionType')}
          description="Purchase"
        />
        <StyledInfoItem
          title={translate('transaction:checkoutTransactionDate')}
          description={parseDate(
            timestamp.toISOString(),
            'MMMM D, YYYY, HH:mm',
          )}
        />
        {handleRenderDiscount}
        {handleRenderFinalPrice}
      </StyledSection>
    ),
    [handleRenderDiscount, handleRenderFinalPrice, timestamp],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledContainerSectionData>
        {data.map((item) => (
          <PaymentCheckoutContentItem
            key={item.content_id}
            data={item}
            discount={discDetail.filter((v) => v.id === item.content_id)[0]}
            isFree={firstPurchase.some((v) => v.content_id === item.content_id)}
          />
        ))}
      </StyledContainerSectionData>
    ),
    [data, discDetail, firstPurchase],
  )

  const handleRenderDoNotAutopost = useMemo(
    () => (
      <StyledCheckboxContainer>
        <CheckboxItem
          isSelected={repostDisabled}
          size={16}
          onClick={toggleRepostDisabled}
          color="gray_5"
          fontSize="xs"
          fontWeight="medium"
          description={translate('transaction:checkoutContentDoNotAutopost')}
        />
      </StyledCheckboxContainer>
    ),
    [repostDisabled, toggleRepostDisabled],
  )

  const handleErrorModal = useMemo(
    () => (
      <PaymentCheckoutErrorModal visible={error} toggleModal={toggleError} />
    ),
    [error, toggleError],
  )

  const responseHandler = useCallback(
    ({origin, data: messageData}: MessageEvent) => {
      const valid = validateOrigin(origin)
      const {type} = JSON.parse(messageData) as WebMessageReceiver

      if (!valid) {
        return
      }

      switch (type) {
        case 'close':
          paymentPopup.current?.close()
          break
        case 'explore':
          paymentPopup.current?.close()
          history.push('giftshop_explore', {})
          break
        case 'history':
          paymentPopup.current?.close()
          history.replace('giftshop_balance', {})
          break
        case 'collection':
          paymentPopup.current?.close()
          history.replace('giftshop_collection', {})
          break
        default:
      }
    },
    [history, validateOrigin],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButtonContinue
        label={translate('global:continue')}
        onClick={handleCheckDoubleBooked}
        isLoading={isLoading}
        disabled={isLoading}
      />
    ),
    [handleCheckDoubleBooked, isLoading],
  )

  const handleRenderButtonBottom = useMemo(
    () => (
      <StyledContinueContainer>{handleRenderButton}</StyledContinueContainer>
    ),
    [handleRenderButton],
  )

  const handleRenderRefundMessage = useMemo(
    () => (
      <StyledPreviewInfoContainer>
        <StyledIconInfo
          type="info"
          size={mode === 'mobile' ? 's' : 'm'}
          color="black"
        />
        <StyledPreviewInfoParagraph
          fontSize="s"
          fontWeight="medium"
          color="black">
          {translate('giftShop:checkoutRefundMessage')}
        </StyledPreviewInfoParagraph>
      </StyledPreviewInfoContainer>
    ),
    [mode],
  )

  const handleRenderDoubleBookedModal = useMemo(
    () => (
      <PaymentDoubleBookedModal
        visible={doubleBooked}
        onContinue={() => {
          toggleDoubleBooked()
          handleCheckout()
        }}
        toggleModal={() => {
          toggleDoubleBooked()
          history.goBack()
        }}
      />
    ),
    [doubleBooked, handleCheckout, history, toggleDoubleBooked],
  )

  const handleRenderWeb = useMemo(() => {
    if (mode === 'website') {
      return (
        <StyledContainer mode={mode}>
          <StyledContainerSection>
            {handleRenderInfo}
            {handleRenderButton}
          </StyledContainerSection>
          <StyledContainerSection>
            {handleRenderContent}
            {handleRenderDoNotAutopost}
            {handleRenderRefundMessage}
          </StyledContainerSection>
        </StyledContainer>
      )
    }
    return (
      <StyledContainer mode={mode}>
        {handleRenderInfo}
        {handleRenderContent}
        {handleRenderDoNotAutopost}
        {handleRenderRefundMessage}
        {handleRenderButtonBottom}
      </StyledContainer>
    )
  }, [
    handleRenderButton,
    handleRenderButtonBottom,
    handleRenderContent,
    handleRenderDoNotAutopost,
    handleRenderInfo,
    handleRenderRefundMessage,
    mode,
  ])

  useDidMount(handleGetDiscount)

  useEffect(() => {
    window.addEventListener('message', responseHandler)
    return () => window.removeEventListener('message', responseHandler)
  }, [responseHandler])

  return (
    <>
      {handleRenderWeb}
      {handleErrorModal}
      {handleRenderDoubleBookedModal}
    </>
  )
}
