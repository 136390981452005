import React, {useMemo} from 'react'
import styled from 'styled-components'
import {isIOS, isSafari} from 'react-device-detect'
import {useTranslation} from 'i18n'
import {Button, Icon, Image, Paragraph} from 'common/components'
import {IMAGE_ASSET} from 'consts'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {useHistory} from 'utils'

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: ${convertUnit(560)};
  padding: 0 ${convertUnit(20)};
`
const StyledKycWarning = styled(Paragraph)`
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: ${convertUnit(8)};
`
const StyledTopContainer = styled.div`
  display: flex;
  margin: ${convertUnit(20)} 0;
  flex-direction: column;
`
const StyledRoboyuMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledRoboyuContainer = styled(Image)`
  aspect-ratio: 1;
  max-width: ${convertUnit(60)};
`
const StyledRoboyuMessage = styled(Paragraph)`
  padding: ${convertUnit(20)};
  flex: 1;
  background-color: ${({theme}) => theme.white_4};
  border-radius: ${convertUnit(8)};
`
const StyledBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  align-self: center;
`
const StyledSelfieContainer = styled.div`
  width: 100%;
  max-width: ${convertUnit(560 / 2)};
  background-color: ${({theme}) => theme.white_4};
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: ${convertUnit(8)};
`
const StyledSelfieImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`
const StyledSelfie = styled(Image)`
  object-fit: contain;
  width: 100%;
  max-width: ${convertUnit(560 / 2)};
  margin-bottom: ${convertUnit(8)};
  border-radius: ${convertUnit(8)} ${convertUnit(8)} 0 0;
`
const StyledParagraph = styled(Paragraph)`
  text-align: center;
  padding: ${convertUnit(8)};
`
const StyledDescriptionContainer = styled.div`
  justify-content: center;
  align-items: center;
  padding: ${convertUnit(8)};
  padding-bottom: ${convertUnit(16)};
`
const StyledButton = styled(Button)`
  width: 100%;
`
const StyledPlaceholderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: ${convertUnit(8)};
  border-radius: ${convertUnit(8)};
  border-color: ${({theme}) => theme.gray_5};
  border-style: dashed;
  flex-direction: column;
`
const StyledButtonText = styled(Button)`
  width: 100%;
  :hover {
    cursor: default;
  }
`

export default function GiftShopRobopetRetake() {
  const {translate} = useTranslation()
  const history = useHistory()
  const {originalKycPhoto, latestKycPhoto, retakeStatus} =
    useSelector('yuserActivationState') || {}
  const handleRenderKycUnsupportedWarning = useMemo(
    () =>
      isIOS &&
      !isSafari && (
        <StyledKycWarning color="danger_5" fontWeight="medium">
          {translate('giftShop:kycWarningUnsupported')}
        </StyledKycWarning>
      ),
    [translate],
  )
  const handleRenderSelfiePlaceholder = useMemo(() => (
    <StyledPlaceholderContainer>
      <Icon type={
        retakeStatus === 'processing'
          ? 'cloud-ol'
          : retakeStatus === 'rejected'
          ? 'robopet'
          : 'face-scan'}
          color="gray_5" size={32} />
      <StyledParagraph color="gray_5" fontWeight="medium">
        {translate('giftShop:retakeKYCPlaceholder', {context: retakeStatus})}
      </StyledParagraph>
    </StyledPlaceholderContainer>
  ), [retakeStatus, translate])
  const handleRenderRoboyuMessage = useMemo(() => (
    <StyledRoboyuMessageContainer>
      <StyledRoboyuContainer src={IMAGE_ASSET('giftshop', 'robopet-happy.png')} alt="" />
      <StyledRoboyuMessage fontWeight="medium">{translate('giftShop:retakeKYCRoboyuMessage')}</StyledRoboyuMessage>
    </StyledRoboyuMessageContainer>
  ), [translate])
  const handleRenderSelfieContainer = useMemo(() => (
    <StyledBottomContainer>
      <StyledSelfieContainer>
        <StyledSelfieImageContainer>
          <StyledSelfie src={originalKycPhoto} alt="" />
        </StyledSelfieImageContainer>
        <StyledDescriptionContainer>
          <StyledButtonText
            backgroundColor="white_4" 
            backgroundHoverColor="white_4"
            label="Original"
            color="black"
          />
        </StyledDescriptionContainer>
      </StyledSelfieContainer>
      <StyledSelfieContainer>
        {latestKycPhoto ? (
          retakeStatus === 'rejected' ? (
            handleRenderSelfiePlaceholder
          ) : (
            <StyledSelfieImageContainer>
              <StyledSelfie src={latestKycPhoto} alt="" />
            </StyledSelfieImageContainer>
          )
        ) : (
          handleRenderSelfiePlaceholder
        )}
        <StyledDescriptionContainer>
          <StyledButton
            label={translate('giftShop:retakeKYC')}
            disabled={retakeStatus === 'processing'}
            onClick={() => history.push('giftshop_robopet_retake_selfie', {})}
          />
        </StyledDescriptionContainer>
      </StyledSelfieContainer>
    </StyledBottomContainer>
  ), [handleRenderSelfiePlaceholder, history, latestKycPhoto, originalKycPhoto, retakeStatus, translate])

  return (
    <StyledContainer>
      <StyledTopContainer>
        {handleRenderKycUnsupportedWarning}
        {handleRenderRoboyuMessage}
      </StyledTopContainer>
      {handleRenderSelfieContainer}
    </StyledContainer>
  )
}
