import {useSelector} from 'lib/redux'
import React, {useMemo} from 'react'
import {FirebaseMessageListItem} from 'types'
import {useFirebaseMessageRoom, useFirebaseMessageRoomMember} from 'utils'
import GiftShopMessageListItemForkygram from './GiftShopMessageListItemForkygram'
import GiftShopMessageListItemGiftshop from './GiftShopMessageListItemGiftshop'
import GiftShopMessageListItemSystem from './GiftShopMessageListItemSystem'

export interface GiftShopMessageListItemProps {
  data: FirebaseMessageListItem
  search: string
}

export default function GiftShopMessageListItem({
  data,
  search,
}: GiftShopMessageListItemProps) {
  const {last_update, last_enter} = data
  const messageRoomState = Object.values(useSelector('messageRoomState'))
  const last_leave =
    messageRoomState.find((elem) => elem.room_id === data.room_id)
      ?.last_leave || undefined
  const time = Math.max(
    last_enter ? new Date(last_enter).getTime() : 0,
    last_leave ? new Date(last_leave).getTime() : 0,
  )
  const hasNewMessage = time < new Date(last_update).getTime()

  const {members} = useFirebaseMessageRoomMember({
    roomId: data.room_id,
    roomType: data.room_type,
  })
  const {messages} = useFirebaseMessageRoom({
    roomId: data.room_id,
    roomType: data.room_type,
    limit: 1,
    sort: 'desc',
  })

  const message = useMemo(() => (messages.length ? messages[0] : undefined), [
    messages,
  ])

  if (!message) {
    return null
  }

  switch (data.room_type) {
    case 'chat':
      return (
        <GiftShopMessageListItemForkygram
          roomId={data.room_id}
          roomType={data.room_type}
          members={members}
          messages={message}
          hasNewMessage={hasNewMessage}
          search={search}
        />
      )
    case 'giftshop':
      return (
        <GiftShopMessageListItemGiftshop
          roomId={data.room_id}
          roomType={data.room_type}
          members={members}
          messages={message}
          hasNewMessage={hasNewMessage}
          search={search}
        />
      )
    case 'system':
      return (
        <GiftShopMessageListItemSystem
          roomId={data.room_id}
          roomType={data.room_type}
          members={members}
          messages={message}
          hasNewMessage={hasNewMessage}
          search={search}
        />
      )
    default:
      return null
  }
}
