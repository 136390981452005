import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_SENSITIVITY_LEVEL_MAX,
  GIFT_SHOP_SENSITIVITY_LEVEL_MIN,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {TranslationKey, useTranslation} from 'i18n'
import {requestData} from 'services'
import {parseShape} from 'types'
import {
  showSnackbar,
  useDidMount,
  useFirestoreRoboyuStatus,
  useHistory,
  useLocation,
} from 'utils'
import {InputRange, Paragraph, ParsedText} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {GiftShopRetakeKYCModal} from '../../../explore'
import {GiftShopRoboPetButton} from '../Button'
import {
  GiftShopRobopetActivationIncomplete,
  GiftShopRobopetActivationProcessing,
  GiftShopRobopetActivationRejected,
  GiftShopRobopetActivationUnregistered,
  GiftShopRobopetActivationVerified,
} from '../ActivationItem'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(20)};
  overflow-y: scroll;
`

const StyledUpperSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    flex-direction: column;
    gap: ${convertUnit(20)};
  }
`

const StyledSearchAccuracySectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  gap: ${convertUnit(20)};
  padding: ${convertUnit(40)} ${convertUnit(40)} ${convertUnit(20)}
    ${convertUnit(40)};

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: inherit;
    padding: ${convertUnit(20)};
  }
`

const StyledAccuracyTitleContainer = styled.div`
  padding-bottom: ${convertUnit(8)};
  text-align: center;
`

const StyledAccuracyDescContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledRoboyuFindingsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(8)};
  text-align: left;
  padding: ${convertUnit(20)} ${convertUnit(40)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    padding: ${convertUnit(20)};
  }
`

export default function GiftShopRoboPetContent() {
  const {kycStatus} = useSelector('yuserActivationState')
  const {update} = useDispatch()
  const history = useHistory()
  const {afterKyc} = useLocation('giftshop_robopet').state || {}
  const {translate} = useTranslation()
  const {sensitivityLevel} = useSelector('roboyuSensitivityLevel')
  const stateRangeValue = useState(sensitivityLevel)
  const [modalRetake, setModalRetake] = useState(false)

  const handleGetStatus = useCallback(() => {
    requestData('giftshop_get_account', {
      actionType: 'fetch',
      onRequestSuccess: ({status, data: {result}}) => {
        if (status === 200) {
          update('yuserActivationState', {
            kycStatus: result.status,
            originalKycPhoto: result.original_kyc_photo,
            latestKycPhoto: result.latest_kyc_photo,
            retakeStatus: result.retake_status,
          })
          update('similarAccountStatus', {status: result.similar_status})
          if (result.retake_status === 'required') {
            setModalRetake(true)
            return
          }
          if (result.status === 'onhold') {
            history.replace('giftshop_similar_identity', {
              afterKyc,
              fromRoboyu: true,
            })
          }
        }
      },
    })
  }, [afterKyc, history, update])

  const handlePatchSensitivityLevel = useCallback(
    (accuracy: number) => {
      requestData('giftshop_patch_sensitivity_level', {
        data: {sensitivity_level: accuracy},
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            update('roboyuSensitivityLevel', {sensitivityLevel: accuracy})
            showSnackbar(translate('giftShop:roboyuAccuracySuccessSnackbar'))
          }
        },
      })
    },
    [translate, update],
  )

  const handleRenderRetakeKYCModal = useMemo(
    () => (
      <GiftShopRetakeKYCModal
        visible={modalRetake}
        toggleModal={() => setModalRetake((prev) => !prev)}
      />
    ),
    [modalRetake],
  )

  const handleRenderContent = useMemo(() => {
    switch (kycStatus) {
      case 'incomplete':
        return <GiftShopRobopetActivationIncomplete />
      case 'processing':
        return <GiftShopRobopetActivationProcessing />
      case 'rejected':
        return <GiftShopRobopetActivationRejected />
      case 'unregistered':
        return <GiftShopRobopetActivationUnregistered />
      case 'verified':
        return <GiftShopRobopetActivationVerified />
      case 'verified_check':
        return <GiftShopRobopetActivationVerified />
    }
    return null
  }, [kycStatus])

  const handleRenderInputRangeLeftDesc = useMemo(
    () => (
      <StyledAccuracyDescContainer style={{textAlign: 'left'}}>
        <Paragraph fontSize="m" fontWeight="bold">
          {translate('giftShop:roboyuAccuracyMediumDesc')}
        </Paragraph>
        <Paragraph fontWeight="medium">{translate('global:medium')}</Paragraph>
      </StyledAccuracyDescContainer>
    ),
    [translate],
  )

  const handleRenderInputRangeRightDesc = useMemo(
    () => (
      <StyledAccuracyDescContainer style={{textAlign: 'right'}}>
        <Paragraph fontSize="m" fontWeight="bold">
          {translate('giftShop:roboyuAccuracyHighDesc')}
        </Paragraph>
        <Paragraph fontWeight="medium">{translate('global:high')}</Paragraph>
      </StyledAccuracyDescContainer>
    ),
    [translate],
  )

  const handleRenderInputRange = useMemo(
    () => (
      <InputRange
        showTick
        stateValue={stateRangeValue}
        min={GIFT_SHOP_SENSITIVITY_LEVEL_MIN}
        max={GIFT_SHOP_SENSITIVITY_LEVEL_MAX}
        leftDesc={handleRenderInputRangeLeftDesc}
        rightDesc={handleRenderInputRangeRightDesc}
        onSlide={(val) => handlePatchSensitivityLevel(val)}
      />
    ),
    [
      handlePatchSensitivityLevel,
      handleRenderInputRangeLeftDesc,
      handleRenderInputRangeRightDesc,
      stateRangeValue,
    ],
  )

  const handleRenderSearchAccuracy = useMemo(
    () => (
      <StyledSearchAccuracySectionContainer>
        <StyledAccuracyTitleContainer>
          <Paragraph fontSize="l" fontWeight="bold">
            {translate('giftShop:roboyuAccuracyTitle')}
          </Paragraph>
        </StyledAccuracyTitleContainer>
        {handleRenderInputRange}
        <GiftShopRoboPetButton />
        <Paragraph fontSize="s" color="gray_5">
          {translate('giftShop:roboyuUploadInvitation')}
        </Paragraph>
      </StyledSearchAccuracySectionContainer>
    ),
    [handleRenderInputRange, translate],
  )

  const roboyuFindingTranslationKeys: TranslationKey[] = useMemo(
    () => [
      'giftShop:searchResultDisclaimer1',
      'giftShop:searchResultDisclaimer2',
      'giftShop:searchResultDisclaimer3',
      'giftShop:searchResultDisclaimer4',
      'giftShop:searchResultDisclaimer5',
      'giftShop:searchResultDisclaimer6',
      'giftShop:searchResultDisclaimer7',
      'giftShop:searchResultDisclaimer8',
    ],
    [],
  )

  const patterns: parseShape[] = useMemo(() => {
    const regex = /\*\*(.+?)\*\*/
    return [
      {
        pattern: regex,
        style: {fontFamily: 'roboto-bold'},
        renderText: (text) => text.replace(regex, `$1`),
      },
    ]
  }, [])

  const handleRenderRoboyuFindings = useMemo(
    () => (
      <StyledRoboyuFindingsSection>
        <Paragraph fontSize="xl" fontWeight="bold">
          {translate('giftShop:searchResultDisclaimerTitle')}
        </Paragraph>
        {roboyuFindingTranslationKeys.map((key) => (
          <>
            <Paragraph fontSize="m" fontWeight="regular" lineHeight={26}>
              <ParsedText parse={patterns}>{translate(key)}</ParsedText>
            </Paragraph>
            <br />
          </>
        ))}
      </StyledRoboyuFindingsSection>
    ),
    [patterns, roboyuFindingTranslationKeys, translate],
  )

  useDidMount(() => {
    handleGetStatus()
  })

  useFirestoreRoboyuStatus()

  return (
    <StyledContainer>
      {handleRenderRetakeKYCModal}
      <StyledUpperSectionContainer>
        {kycStatus === 'verified' || kycStatus === 'verified_check' ? (
          handleRenderSearchAccuracy
        ) : (
          <></>
        )}
        {handleRenderContent}
      </StyledUpperSectionContainer>
      {handleRenderRoboyuFindings}
    </StyledContainer>
  )
}
