import React, {useMemo, useState} from 'react'
import {InteractiveMapProps, Marker} from 'react-map-gl'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useDidUpdate} from 'utils'
import {useWindowMode} from 'windows'
import convertUnit from 'lib/unit'
import {
  ButtonIcon,
  Pin,
  Paragraph,
  Map,
  ModalFullscreen,
} from 'common/components'
import {GiftShopTemplateDetailLocationProps} from './GiftShopTemplateDetailLocationProps'

const StyledCloseIcon = styled(ButtonIcon)`
  z-index: 1;
  position: absolute;
  top: ${convertUnit(25)};
  left: ${convertUnit(25)};
  margin-top: ${convertUnit(18)};
  margin-right: ${convertUnit(25)};
`

const StyledMarkIcon = styled(ButtonIcon)`
  margin-right: ${convertUnit(15)};
`

const StyledInfoLocation = styled.div`
  z-index: 1;
  position: absolute;
  top: ${convertUnit(25)};
  left: ${convertUnit(100)};
  padding: ${convertUnit(15)};
  height: auto;
  background-color: ${({theme}) => theme.white_1};
  display: flex;
  box-sizing: border-box;
  border-radius: ${convertUnit(16)};

  @media (min-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-right: ${convertUnit(25)};
  }

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    top: auto;
    bottom: ${convertUnit(25)};
    left: ${convertUnit(25)};
    right: ${convertUnit(25)};
  }
`

const StyledParagraphContainer = styled.div`
  padding: ${convertUnit(5)};
  margin-right: ${convertUnit(15)};
`

const StyledMap = styled(Map)`
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`

const StyledParagraph = styled(Paragraph)`
  overflow-wrap: anywhere;
`

export default function GiftShopTemplateDetailLocation({
  visible,
  location,
  handleClick,
}: GiftShopTemplateDetailLocationProps) {
  const mode = useWindowMode()

  const name = useMemo(() => location && location?.name, [location])
  const longitude = useMemo(() => location && location?.longitude, [location])
  const latitude = useMemo(() => location && location?.latitude, [location])

  const mapAttributeState = useState<InteractiveMapProps>({
    latitude: location?.latitude,
    longitude: location?.longitude,
    zoom: 15,
    maxZoom: 16,
    minZoom: 8,
  })
  const [mapAttr, setMapAttr] = mapAttributeState

  const renderMarker = useMemo(() => {
    if (latitude && longitude) {
      return (
        <Marker longitude={longitude} latitude={latitude}>
          <Pin />
        </Marker>
      )
    }
    return null
  }, [latitude, longitude])

  useDidUpdate(() => {
    if (mapAttr.latitude !== location?.latitude) {
      setMapAttr((prev) => ({
        ...prev,
        latitude: location?.latitude,
        longitude: location?.longitude,
      }))
    }
  }, [location])

  return (
    <ModalFullscreen visible={visible}>
      <StyledCloseIcon
        type="large"
        rounded
        iconType="close"
        iconColor="primary_5"
        backgroundColor="white_2"
        backgroundHoverColor="white_2"
        onClick={handleClick}
      />
      <StyledInfoLocation>
        <StyledMarkIcon
          type="large"
          rounded
          iconType="mark"
          iconColor="danger_5"
          backgroundColor="white_3"
          disabled
        />
        <StyledParagraphContainer>
          <StyledParagraph
            fontSize={mode === 'website' ? 'l' : 'm'}
            color="black"
            fontWeight="medium">
            {name === `lat: ${latitude} lng: ${longitude}`
              ? `${latitude}, ${longitude}`
              : name}
          </StyledParagraph>
          <StyledParagraph
            fontSize={mode === 'website' ? 'm' : 's'}
            color="gray_3"
            fontWeight="regular">
            {name === `lat: ${latitude} lng: ${longitude}`
              ? 'Latitude & Longitude'
              : `Lat: ${latitude}, Long: ${longitude}`}
          </StyledParagraph>
        </StyledParagraphContainer>
      </StyledInfoLocation>
      <StyledMap viewportState={mapAttributeState}>{renderMarker}</StyledMap>
    </ModalFullscreen>
  )
}
