import React from 'react'
import styled from 'styled-components'
import {Image, Paragraph} from 'common/components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {useWindowMode} from 'windows'

interface GiftShopRobopetActivationSelfieCaptureProps {
  onClick(): void
  isRetakeSelfie?: boolean
}

const StyledContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`
const StyledOutlineOverlay = styled(Image)`
  height: 100%;
  width: 100%;
`
const StyledTitleContainer = styled.div`
  max-width: ${convertUnit(325)};
  position: absolute;
  flex-direction: column;
  margin-top: ${convertUnit(20)};
  margin-left: ${convertUnit(20)};
  margin-right: ${convertUnit(20)};
  z-index: 1;
`
const StyledParagraph = styled(Paragraph)`
  text-align: center;
`
const StyledBottomContainer = styled.div`
  max-width: ${convertUnit(325)};
  position: absolute;
  flex-direction: column;
  margin-left: ${convertUnit(20)};
  margin-right: ${convertUnit(20)};
  bottom: ${convertUnit(20)};
`
const StyledButton = styled.div`
  width: ${convertUnit(50)};
  height: ${convertUnit(50)};
  border-radius: ${convertUnit(50)};
  border: ${convertUnit(8)} solid ${({theme}) => theme.white_1};
  cursor: pointer;
  background-color: ${({theme}) => theme.white_1};
`
const StyledButtonContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: ${convertUnit(8)};
`

export default function GiftShopRobopetActivationSelfieCapture({
  onClick,
  isRetakeSelfie,
}: GiftShopRobopetActivationSelfieCaptureProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const faceOverlay = IMAGE_ASSET('giftshop', 'overlay-face.png')
  const faceOverlayLandscape = IMAGE_ASSET ('giftshop', 'overlay-face-web.png')
  
  return (
    <>
      <StyledTitleContainer>
        <StyledParagraph color="white_1" fontWeight="medium" fontSize="l">
          {translate(isRetakeSelfie ? 'giftShop:retakeKYC' : 'giftShop:kycSelfie')}
        </StyledParagraph>
        <StyledParagraph color="white_1">
          {translate(isRetakeSelfie ? 'giftShop:retakeKYCWarning' : 'giftShop:kycSelfieWarning')}
        </StyledParagraph>
      </StyledTitleContainer>
      <StyledContainer>
        <StyledOutlineOverlay src={mode !== 'mobile' ? faceOverlayLandscape : faceOverlay} alt='' />
      </StyledContainer>
      <StyledBottomContainer>
        <StyledButtonContainer>
          <StyledButton onClick={onClick} />
        </StyledButtonContainer>
      </StyledBottomContainer>
    </>
  )
}
