import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {AUTH_COUNTRY_DEFAULT} from 'consts'
import {useTranslation} from 'i18n'
import {AuthCredentialType, AuthFormRegisterData, WindowModeType} from 'types'
import {useHistory, showPrompt} from 'utils'
import {useWindowMode} from 'windows'
import {Icon, ConfirmationModal, LanguageToggle} from 'common/components'
import convertUnit from 'lib/unit'
import {AuthTemplateScreen} from '../../template'
import {AuthRegisterIdentifier, AuthRegisterUsername} from '../containers'

interface StyledIconProps {
  mode: WindowModeType
}

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
  }
`

const StyledContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`

const StyledIconContainer = styled.div<StyledIconProps>`
  padding: ${convertUnit(20)} 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledBackIconCursor = styled.div`
  cursor: pointer;
  width: fit-content;
`

export default function AuthRegisterScreen() {
  const {translate} = useTranslation()
  const stateIndex = useState(0)
  const stateAgreement = useState(false)
  const stateType = useState<AuthCredentialType | undefined>('email')
  const stateCountry = useState(AUTH_COUNTRY_DEFAULT)
  const stateSubmit = useState(false)
  const stateValues = useState<AuthFormRegisterData>({
    token: '',
    email: '',
    password: '',
    phone: '',
    username: '',
    dateOfBirth: undefined,
  })
  const [index, setIndex] = stateIndex
  const history = useHistory()
  const values = stateValues[0]
  const submit = stateSubmit[0]
  const emailLen = values.email.length
  const phoneLen = values.phone.length
  const [confirmation, setConfirmation] = useState(false)
  const mode = useWindowMode()

  const handleClickBack = useCallback(() => {
    if (index === 3) {
      return setConfirmation(true)
    }
    if (index > 0) return setIndex(index - 1)
    return history.goBack()
  }, [history, index, setIndex])

  const handleRenderContent = useMemo(() => {
    switch (index) {
      case 1:
        return (
          <AuthRegisterUsername
            stateValues={stateValues}
            stateType={stateType}
            stateCountry={stateCountry}
            stateSubmit={stateSubmit}
          />
        )
      default:
        return (
          <AuthRegisterIdentifier
            stateAgreement={stateAgreement}
            stateIndex={stateIndex}
            stateType={stateType}
            stateValues={stateValues}
            stateCountry={stateCountry}
          />
        )
    }
  }, [
    index,
    stateAgreement,
    stateCountry,
    stateIndex,
    stateSubmit,
    stateType,
    stateValues,
  ])

  useEffect(() => {
    const prompt = (emailLen > 0 || phoneLen > 0) && !submit
    showPrompt(prompt, translate('giftShop:uploadModalMessage'))
  }, [emailLen, phoneLen, submit, translate])

  return (
    <>
      <ConfirmationModal
        visible={confirmation}
        toggleModal={() => setConfirmation((prev) => !prev)}
        title={translate('global:cancel')}
        text={translate('giftShop:uploadModalMessage')}
        onConfirm={() => setIndex(index - 1)}
      />
      <AuthTemplateScreen>
        <StyledContainer>
          <StyledIconContainer mode={mode}>
            <StyledBackIconCursor onClick={handleClickBack}>
              <Icon type="back" size={convertUnit(20)} color="primary_5" />
            </StyledBackIconCursor>
            <LanguageToggle />
          </StyledIconContainer>
          <StyledContentContainer>{handleRenderContent}</StyledContentContainer>
        </StyledContainer>
      </AuthTemplateScreen>
    </>
  )
}
