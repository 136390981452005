import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {IMAGE_ASSET, OTP_COOLDOWN} from 'consts'
import {requestData} from 'services'
import {formatCooldownTime, maskEmail, showSnackbar, useLocation} from 'utils'
import {Image, Paragraph, TextTimer} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'

const StyledContentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`

const StyledHeaderDescription = styled(Paragraph)`
  margin-top: ${convertUnit(10)};
  text-align: center;
`

const StyledImgContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${convertUnit(20)} 0;
`

const StyledIllustration = styled(Image)`
  max-width: ${convertUnit(325)};
  width: 100%;
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledResendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

interface AuthEasySignupVerificationEmailProps {
  shouldCensor?: boolean
  showResend?: boolean
}
export default function AuthEasySignupVerificationEmail({
  shouldCensor = true,
  showResend = true,
}: AuthEasySignupVerificationEmailProps) {
  const {translate} = useTranslation()
  const {email, identifier} = useLocation('auth_easy_signup_verification').state
  const emailConfirmImage = IMAGE_ASSET('giftshop', 'email-confirm.png')
  const {update} = useDispatch()
  const {emailTime} = useSelector('lastUserState')
  const emailMask = email ? maskEmail(email) : ''
  const [resetOnPress, setResetOnPress] = useState(true)

  const handleResend = useCallback(() => {
    requestData('auth_easy_signup_resend', {
      data: {
        identifier: identifier || email,
        method: 'email',
      },
      onRequestSuccess: ({status, data}) => {
        if (status === 200) {
          update('lastUserState', {
            emailTime: new Date().getTime(),
          })
        } else if (status === 429) {
          const errorDetail = data.result
          if (errorDetail.is_limit) {
            setResetOnPress(false)
          }
          const {hour, minute, second} = formatCooldownTime(
            errorDetail.resend_in,
          )
          showSnackbar(
            translate(
              errorDetail.is_limit
                ? 'auth:registerEasySignupResendLinkLimitSnackbar'
                : 'auth:registerEasySignupResendLinkCooldownSnackbar',
              {
                hour,
                minute,
                second,
              },
            )
              .replace(
                hour === ''
                  ? /<hourStamp>.*?<\/hourStamp>/
                  : /<hourStamp>|<\/hourStamp>/g,
                '',
              )
              .replace(
                minute === ''
                  ? /<minuteStamp>.*?<\/minuteStamp>/
                  : /<minuteStamp>|<\/minuteStamp>/g,
                '',
              )
              .replace(/<secondStamp>|<\/secondStamp>/g, ''),
            [
              {
                pattern: /<timestamp>.*?<\/timestamp>/,
                style: {
                  fontFamily: 'Roboto-Bold',
                },
                renderText: (text) =>
                  text.replace(/<timestamp>|<\/timestamp>/g, ''),
              },
            ],
          )
        } else {
          showSnackbar(translate('global:messageError', {context: `code-${status}`}))
        }
      },
    })
  }, [email, identifier, translate, update])

  const handleRenderResend = useMemo(() => (
    <StyledResendContainer>
      <Paragraph color="gray_5">{translate('auth:verificationEmailDidNotGet')}</Paragraph>
      <TextTimer
        textAvailable={translate('auth:verificationEmailResend')}
        textCooldown="Email"
        lastSaved={emailTime}
        cooldown={OTP_COOLDOWN}
        onPress={handleResend}
        resetWhenPress={resetOnPress}
        countOnStart={false}
      />
    </StyledResendContainer>
  ), [emailTime, handleResend, resetOnPress, translate])

  const handleRenderTitle = useMemo(
    () => (
      <>
        <StyledParagraph fontSize="xl" fontWeight="bold">
          {shouldCensor ? emailMask : email}
        </StyledParagraph>
        <StyledHeaderDescription fontSize="m" color="gray_5">
          {translate('auth:verificationEmailHeaderDescription')}
        </StyledHeaderDescription>
      </>
    ),
    [email, emailMask, shouldCensor, translate],
  )

  const handleRenderMidSection = useMemo(
    () => (
      <>
        <StyledImgContainer>
          <StyledIllustration src={emailConfirmImage} alt="Illustration" />
        </StyledImgContainer>
        {showResend ? handleRenderResend : <></>}
      </>
    ),
    [emailConfirmImage, handleRenderResend, showResend],
  )

  return (
    <StyledContentContainer>
      {handleRenderTitle}
      {handleRenderMidSection}
    </StyledContentContainer>
  )
}
