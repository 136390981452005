import {IMAGE_SIGNATURE_SIZE} from 'consts'

export function formatColorToHex(value: number) {
  return value.toString(16).padStart(2, '0')
}

export async function createImageSignature(
  image: HTMLImageElement,
  size: number = IMAGE_SIGNATURE_SIZE,
) {
  const canvas = document.createElement('canvas')
  canvas.width = size
  canvas.height = size

  const context = canvas.getContext('2d')

  if (!context) return null
  context.drawImage(image, 0, 0, size, size)

  const {data} = context.getImageData(0, 0, size, size)
  let signature = ''

  for (let i = 0; i < data.length; i += 4) {
    const r = data[i]
    const g = data[i + 1]
    const b = data[i + 2]

    signature += formatColorToHex(r)
    signature += formatColorToHex(g)
    signature += formatColorToHex(b)
  }

  return signature
}

export function createImageSignatureFromFile(
  file: File,
  size: number = IMAGE_SIGNATURE_SIZE,
) {
  return new Promise<string | null>((resolve) => {
    const blob = URL.createObjectURL(file)
    const image = new Image()

    const handleError = () => {
      URL.revokeObjectURL(blob)
      resolve(null)
    }

    const handleLoadImage = () => {
      URL.revokeObjectURL(blob)
      resolve(createImageSignature(image, size))
    }

    image.src = blob
    image.onabort = handleError
    image.onerror = handleError
    image.onload = handleLoadImage
  })
}
