import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useWindowMode} from 'windows'
import {IMAGE_ASSET, WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {getHowItWorksWebUrl, getSupergigaWebUrl} from 'utils'
import {Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {AuthTemplateScreenProps} from './AuthTemplateScreenProps'

const StyledContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_2,
  })}
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`

const StyledMobileImage = styled.div`
  flex: 0;
`
const StyledWebContainer = styled.div`
  flex: 1.3;
`

const StyledWebImage = styled(Image)`
  width: 100%;
  height: 100%;
  position: relative;
  object-position: 5%;
`

const StyledSectionForm = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
`

const StyledFormBack = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_1,
  })}
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const StyledFormContainer = styled.form`
  ${({theme}) => ({backgroundColor: theme.white_1})}
  width: 100%;
  display: flex;
  overflow-y: scroll;
  padding: ${convertUnit(40)} ${convertUnit(60)};
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: 0 ${convertUnit(20)};
  }
`

const StyledFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-self: flex-end;
  width: 100%;
  padding: ${convertUnit(12)} 0;
  background-color: ${({theme}) => theme.white_3};
`

const StyledLink = styled.a`
  text-decoration: none;
`

export default function AuthTemplateScreen({
  ...props
}: AuthTemplateScreenProps) {
  const mode = useWindowMode()
  const background = IMAGE_ASSET('giftshop', 'bg-new.png')
  const {translate} = useTranslation()

  const handleRenderSectionImage = useMemo(
    () =>
      mode === 'website' ? (
        <StyledWebContainer>
          <StyledWebImage src={background} alt={background} />
        </StyledWebContainer>
      ) : (
        <StyledMobileImage />
      ),
    [background, mode],
  )

  return (
    <StyledContainer>
      {handleRenderSectionImage}
      <StyledSectionForm>
        <StyledFormBack>
          <StyledFormContainer {...props} />
        </StyledFormBack>
        <StyledFooterContainer>
          <StyledLink href={getHowItWorksWebUrl()} target="_blank">
            <Paragraph fontSize="s" fontWeight="medium" color="gray_5">
              {translate('global:howItWorks')}
            </Paragraph>
          </StyledLink>
          <StyledLink href={getSupergigaWebUrl()} target="_blank">
            <Paragraph fontSize="s" fontWeight="medium" color="gray_5">
              Supergiga
            </Paragraph>
          </StyledLink>
          <Paragraph fontSize="s" fontWeight="medium" color="gray_3">
            &copy;{new Date().getFullYear()} {translate('global:fotoyu')}
          </Paragraph>
        </StyledFooterContainer>
      </StyledSectionForm>
    </StyledContainer>
  )
}
