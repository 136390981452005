import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {requestData} from 'services'
import {ForkygramGetReplyResponse} from 'types'
import {Paragraph} from 'common/components'
import {useTranslation} from 'i18n'
import {ForkygramReplyContentProps} from './ForkygramCommentModalContentProps'
import {ForkygramCommentReply} from '../ForkygramComment'

const StyledLoadMore = styled(Paragraph)`
  cursor: pointer;
`

export default function ForkygramReplyContent({
  stateRecipient,
  commentId,
  replyCount,
}: ForkygramReplyContentProps) {
  const {translate} = useTranslation()
  const [data, setData] = useState<ForkygramGetReplyResponse[]>([])
  const currPage = useRef(1)
  const count = useMemo(() => Math.max(0, replyCount - data.length), [data.length, replyCount])

  const handleLoadData = useCallback(async (page: number, limit: number) => {
    const response = await requestData('forkygram_get_reply', {
      useDefaultMessage: true,
      actionType: 'fetch',
      params: {id: commentId, page, limit},
    })
    if (typeof response !== 'string' && response.status === 200) {
      setData((prev) => [...prev, ...response.data.result])
    }
  }, [commentId])

  const handleRenderItem = useMemo(
    () =>
      data &&
      data.map((item) => (
        <ForkygramCommentReply
          data={item}
          stateRecipient={stateRecipient}
          key={item.reply_id}
          commentId={commentId}
        />
      )),
    [commentId, data, stateRecipient],
  )

  const renderButtonLoadMore = useMemo(
    () => (
      <StyledLoadMore
        color="primary_5"
        fontWeight="medium"
        onClick={() => {
          handleLoadData(currPage.current + 1, 10)
          currPage.current += 1
        }}>
          {translate('forkygram:viewReply', {count})}
      </StyledLoadMore>
    ), [count, handleLoadData, translate],
  )

  useEffect(() => {
    handleLoadData(currPage.current, 10)
  }, [handleLoadData, currPage])

  return (
    <>
      {handleRenderItem}
      {count > 0 ? renderButtonLoadMore : <></>}
    </>
  )
}
