import {
  API_GIFTSHOP_TRANSACTION_HISTORY,
  API_PAYMENT_TRANSACTION_DETAIL,
  API_PAYMENT_WITHDRAW_AVAILABILITY,
  API_TRANSACTION_WITHDRAWAL,
  API_TRANSACTION_WITHDRAW_FEE,
  API_TREE_HOSTS,
  API_TREE_HOST_ADMINS,
  API_TREE_HOSTS_INFO,
  API_TREE_HOST_BANK,
  API_TREE_HOST_CREATION,
  API_TREE_HOST_CREATORS,
  API_TREE_HOST_SEARCH_ADMINS,
  API_TREE_HOST_SEARCH_CREATORS,
  API_TREE_POST_COVER_LINK,
  API_TREE_POST_DOCUMENT_LINK,
  API_TREE_POST_LOGO_LINK,
  API_TREE_REGISTER_HOST,
  API_TREE_PLANT_FOTOTREE_PROFILE_LINK,
  API_TREE_PLANT_FOTOTREE_CATEGORY,
  API_TREE_POST_PLANT_FOTOTREE,
  API_TREE_GET_FOTOTREE,
  API_TREE_GET_FOTOTREE_DETAIL,
  API_TREE_LEAF,
  API_TREE_FARMER,
  API_TREE_SEARCH_FARMER,
  API_TREE_SEARCH_FOTOTREE,
  API_TREE_HOST_CREATION_GROUP,
  API_TREE_HOST_INVITATION,
  API_TREE_HOST_CREATORS_INVITE,
  API_TREE_LEAF_FOOTPRINTS,
  API_TREE_EVENTS,
  API_TREE_GENERATE_QR,
  API_TREE_SHARE_FOTOTREE,
  API_TREE_LEAF_SHARE,
  API_TREE_CHECK,
  API_TREE_LEAF_SHARE_GENEREATE,
  API_TREE_COMMENT,
  API_TREE_FOOTPRINT,
  API_TREE_LEADERBOARD,
  API_TREE_LOCATION,
  API_TREE_COMMENT_REPLY,
} from 'consts'
import {ServiceMap} from 'types'
import {getAPIBaseUrl} from 'utils'
import {ServiceParamTree} from './ServiceParamTree'

export const SERVICE_MAP_TREE: ServiceMap<ServiceParamTree> = {
  tree_check_withdraw_availability: {
    method: 'POST',
    url: getAPIBaseUrl() + API_PAYMENT_WITHDRAW_AVAILABILITY,
  },
  tree_delete_farmer: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_TREE_FARMER,
  },
  tree_delete_leaf: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_TREE_LEAF,
  },
  tree_fetch_hosts: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_HOSTS_INFO,
  },
  tree_fetch_creation: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_HOST_CREATION,
  },
  tree_get_creation_group: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_HOST_CREATION_GROUP,
  },
  tree_get_farmers: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_FARMER,
  },
  tree_get_leaf_footprints: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_LEAF_FOOTPRINTS,
  },
  tree_get_leaf_share: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_LEAF_SHARE,
  },
  tree_get_leaves: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_LEAF,
  },
  tree_get_location: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_LOCATION,
  },

  tree_get_plant_fototree_category: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_PLANT_FOTOTREE_CATEGORY,
  },
  tree_get_host_bank: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_HOST_BANK,
  },
  tree_get_host_invitation: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_HOST_INVITATION,
  },
  tree_get_transaction_history: {
    method: 'GET',
    url: getAPIBaseUrl() + API_GIFTSHOP_TRANSACTION_HISTORY,
  },
  tree_get_transaction_detail: {
    method: 'GET',
    url: getAPIBaseUrl() + API_PAYMENT_TRANSACTION_DETAIL,
  },
  tree_get_withdraw_fee: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TRANSACTION_WITHDRAW_FEE,
  },
  tree_get_fototree: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_GET_FOTOTREE,
  },
  tree_get_fototree_check: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_CHECK,
  },
  tree_get_fototree_detail: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_GET_FOTOTREE_DETAIL,
  },
  tree_get_fototree_footprint: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_FOOTPRINT,
  },
  tree_get_fototree_leaderboard: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_LEADERBOARD,
  },
  tree_patch_hosts: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_TREE_HOSTS,
  },
  tree_patch_host_admin: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_TREE_HOST_ADMINS,
  },
  tree_patch_host_creator: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_TREE_HOST_CREATORS,
  },
  tree_post_cover_link: {
    method: 'POST',
    url: getAPIBaseUrl() + API_TREE_POST_COVER_LINK,
  },
  tree_patch_fototree: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_TREE_GET_FOTOTREE,
  },
  tree_post_plant_fototree_profile_link: {
    method: 'POST',
    url: getAPIBaseUrl() + API_TREE_PLANT_FOTOTREE_PROFILE_LINK,
  },
  tree_post_document_link: {
    method: 'POST',
    url: getAPIBaseUrl() + API_TREE_POST_DOCUMENT_LINK,
  },
  tree_post_logo_link: {
    method: 'POST',
    url: getAPIBaseUrl() + API_TREE_POST_LOGO_LINK,
  },
  tree_put_farmers: {
    method: 'PUT',
    url: getAPIBaseUrl() + API_TREE_FARMER,
  },
  tree_register_host: {
    method: 'POST',
    url: getAPIBaseUrl() + API_TREE_REGISTER_HOST,
  },
  tree_post_plant_fototree: {
    method: 'POST',
    url: getAPIBaseUrl() + API_TREE_POST_PLANT_FOTOTREE,
  },
  tree_search_fototree: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_SEARCH_FOTOTREE,
  },
  tree_host_get_host_admin: {
    url: getAPIBaseUrl() + API_TREE_HOST_ADMINS,
    method: 'GET',
  },
  tree_host_demote_host_admin: {
    url: getAPIBaseUrl() + API_TREE_HOST_ADMINS,
    method: 'DELETE',
  },
  tree_host_get_host_creator: {
    url: getAPIBaseUrl() + API_TREE_HOST_CREATORS,
    method: 'GET',
  },
  tree_host_demote_creator: {
    url: getAPIBaseUrl() + API_TREE_HOST_CREATORS,
    method: 'DELETE',
  },
  tree_host_search_admin: {
    url: getAPIBaseUrl() + API_TREE_HOST_SEARCH_ADMINS,
    method: 'GET',
  },
  tree_host_promote_admin: {
    url: getAPIBaseUrl() + API_TREE_HOST_ADMINS,
    method: 'POST',
  },
  tree_host_promote_creator: {
    url: getAPIBaseUrl() + API_TREE_HOST_CREATORS_INVITE,
    method: 'POST',
  },
  tree_host_search_creator: {
    url: getAPIBaseUrl() + API_TREE_HOST_SEARCH_CREATORS,
    method: 'GET',
  },
  tree_search_events: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_EVENTS,
  },
  tree_search_farmers: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_SEARCH_FARMER,
  },
  tree_withdraw: {
    method: 'POST',
    url: getAPIBaseUrl() + API_TRANSACTION_WITHDRAWAL,
  },
  tree_post_generate_qr: {
    method: 'POST',
    url: getAPIBaseUrl() + API_TREE_GENERATE_QR,
  },
  tree_post_share_fototree: {
    method: 'POST',
    url: getAPIBaseUrl() + API_TREE_SHARE_FOTOTREE,
  },
  tree_post_share_leaf: {
    method: 'POST',
    url: getAPIBaseUrl() + API_TREE_LEAF_SHARE_GENEREATE,
  },
  tree_get_fototree_comment: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_COMMENT,
  },
  tree_get_fototree_comment_reply: {
    method: 'GET',
    url: getAPIBaseUrl() + API_TREE_COMMENT_REPLY,
  },
  tree_post_fototree_comment: {
    method: 'POST',
    url: getAPIBaseUrl() + API_TREE_COMMENT,
  },
  tree_post_fototree_comment_reply: {
    method: 'POST',
    url: getAPIBaseUrl() + API_TREE_COMMENT_REPLY,
  },
  tree_delete_fototree_comment: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_TREE_COMMENT,
  },
  tree_delete_fototree_comment_reply: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_TREE_COMMENT_REPLY,
  },
}
