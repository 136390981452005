import React, {useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {IMAGE_ASSET} from 'consts'
import {Button, CheckboxItem, Image, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {AuthEasySignupDuplicateModalProps} from './TemplateAuthEasySignupDuplicateModalProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StyledImageContainer = styled(Image)`
  width: 100%;
  max-width: ${convertUnit(200)};
  aspect-ratio: 1;
  object-fit: contain;
  margin: ${convertUnit(20)} auto;
`
const StyledButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: ${convertUnit(20)};
  gap: ${convertUnit(12)};
`
const StyledContinueContainer = styled.div`
  display: flex;
  gap: ${convertUnit(8)};
  flex-direction: column;
`
const StyledButton = styled(Button)`
  width: 100%;
`
const StyledCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledCheckbox = styled(CheckboxItem)`
  margin-right: ${convertUnit(10)};
`

export default function AuthEasySignupDuplicateModal({
  stateVisible,
  onContinue,
}: AuthEasySignupDuplicateModalProps) {
  const {translate} = useTranslation()
  const [visible, setVisible] = stateVisible
  const [isSelected, setIsSelected] = useState(false)
  return (
    <StyledModal visible={visible}>
      <Paragraph fontSize="l" fontWeight="bold">
        {translate('auth:registerEasySignupDuplicateTitle')}
      </Paragraph>
      <StyledImageContainer
        src={IMAGE_ASSET('giftshop', 'robopet-offer.png')}
        alt=""
      />
      <Paragraph fontSize="m" color="danger_5">
        {translate('auth:registerEasySignupDuplicateDesc')}
      </Paragraph>
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:cancel')}
          backgroundColor="white_2"
          color="primary_5"
          onClick={() => {
            setIsSelected(false)
            setVisible(false)
          }}
        />
        <StyledContinueContainer>
          <StyledCheckboxContainer>
            <StyledCheckbox
              isSelected={isSelected}
              onClick={() => setIsSelected((prev) => !prev)}></StyledCheckbox>
            <Paragraph fontSize="m" color="gray_5">
              {translate('auth:registerEasySignupDuplicateConfirmation')}
            </Paragraph>
          </StyledCheckboxContainer>
          {isSelected ? (
            <StyledButton
              label={translate('global:continue')}
              onClick={() => {
                setIsSelected(false)
                setVisible(false)
                onContinue()
              }}
            />
          ) : (
            <></>
          )}
        </StyledContinueContainer>
      </StyledButtonContainer>
    </StyledModal>
  )
}
