import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {isIOS, isSafari} from 'react-device-detect'
import styled from 'styled-components'
import {
  AuthCountryResponse,
  AuthCredentialType,
  AuthFormRegisterIdentifierData,
  parseShape,
} from 'types'
import {useTranslation} from 'i18n'
import {
  AUTH_COUNTRY_DEFAULT,
  REGEX_PHONE_NUMBER,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {requestData} from 'services'
import {useTheme} from 'themes'
import {
  getAuthCookie,
  getAuthPrivacy,
  getAuthToS,
  showPrompt,
  useDidUpdate,
} from 'utils'
import {
  Button,
  Icon,
  Input,
  InputPhone,
  Link,
  Modal,
  Paragraph,
  ParsedText,
  TabView,
} from 'common/components'
import convertUnit from 'lib/unit'
import {VALIDATION_AUTH_EMAIL, VALIDATION_AUTH_PHONE} from 'common/validations'
import {useSelector} from 'lib/redux'
import {AuthLoginApple, AuthLoginGoogle} from '../../../login'
import {AuthRegisterIdentifierProps} from './AuthRegisterIdentifierProps'
import {AuthRegisterTermOfCondition} from '../TermOfCondition'
import {AuthRegisterWaitingModal} from '../WaitingModal'

const StyledContainer = styled.div`
  padding: 0 0 ${convertUnit(25)} 0;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: ${convertUnit(40)} 0 ${convertUnit(25)} 0;
  }
`

const StyledTabContainer = styled.div`
  height: fit-content;
`

const StyledButtonLogin = styled(Button)`
  margin-top: ${convertUnit(25)};
`

const StyledError = styled(Paragraph)`
  margin-top: ${convertUnit(25)};
`

const StyledSSOButtonContainer = styled.div`
  display: flex;
  height: fit-content;
  gap: ${convertUnit(20)};
  margin-top: ${convertUnit(25)};
  flex-direction: row;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    flex-direction: column;
  }
`

const StyledSectionLogin = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${convertUnit(25)};
`

const StyledLogin = styled(Paragraph)`
  margin-left: ${convertUnit(9)};
`

const StyledAgreement = styled(Paragraph)`
  margin-top: ${convertUnit(25)};
`

const StyledKycWarning = styled(Paragraph)`
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: ${convertUnit(10)};
`

const StyledModal = styled(Modal)`
  width: ${convertUnit(420)};
  padding: 0 ${convertUnit(25)} ${convertUnit(25)};
  display: flex;
  flex-direction: column;
  height: ${convertUnit(470)};
  overflow-y: scroll;
  scrollbar-width: none;
  position: relative;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledIcon = styled.div`
  margin: ${convertUnit(10)} 0;
  width: ${convertUnit(20)};
  height: ${convertUnit(20)};
  background-color: ${({theme}) => theme.gray_5};
  border-radius: ${convertUnit(6)};
  padding: ${convertUnit(3)} ${convertUnit(2)};
  align-self: flex-end;
`

const StyledIframe = styled.iframe`
  flex: 1;
  border: 0;
  width: 100%;
  height: ${convertUnit(470)};
`
const StyledHeader = styled(Paragraph)`
  text-align: center;
`

export default function AuthRegisterIdentifier({
  stateValues,
  stateType,
  stateIndex,
  stateCountry,
}: AuthRegisterIdentifierProps) {
  const {translate} = useTranslation()
  const theme = useTheme()
  const [savedValues, setSavedValues] = stateValues
  const form = useForm<AuthFormRegisterIdentifierData>({
    defaultValues: savedValues,
  })
  const {watch, errors} = form
  const values = watch()
  const {email, phone} = values
  const type = stateType[0]
  const id = type === 'email' ? email : phone
  const country = stateCountry[0]
  type LegalModalType = 'tos' | 'privacy' | 'cookies'
  const [legalModal, setLegalModal] = useState<LegalModalType>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [loading, setLoading] = useState(false)
  const setIndex = stateIndex[1]
  const [submit, setSubmit] = useState(false)
  const [modal, setModal] = useState(false)
  const showTermsState = useState(false)
  const setShowTerms = showTermsState[1]
  const {lang} = useSelector('languageState')

  const handleLoadDataPhoneDummy = useCallback(
    (search: string): AuthCountryResponse[] =>
      !search || 'indonesia'.includes(search.toLowerCase())
        ? [AUTH_COUNTRY_DEFAULT]
        : [],
    [],
  )

  const handleRenderWaitingModal = useMemo(
    () => (
      <AuthRegisterWaitingModal
        visible={modal}
        toggleModal={() => setModal(false)}
      />
    ),
    [modal],
  )

  const handleContinue = useCallback(() => {
    setSubmit(true)
    setLoading(true)
    requestData(type === 'email' ? 'auth_check_email' : 'auth_check_phone', {
      actionType: 'execute',
      data: {
        email,
        phone_number: phone,
        phone_code: country.dial_code,
      },
      onRequestReceived: () => setLoading(false),
      onRequestFailed: (reason) =>
        setErrorMessage(translate('global:messageError', {context: reason})),
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          setSavedValues({...savedValues, ...values})
          setIndex((previous) => previous + 1)
          return
        }
        if (status === 409) {
          setErrorMessage(
            translate(
              type === 'email'
                ? 'auth:validationEmailHasBeenTaken'
                : 'auth:validationPhoneHasBeenTaken',
            ),
          )
          return
        }
        if (status === 503) {
          setModal(true)
        } else {
          setErrorMessage(translate('global:messageError'))
        }
      },
    })
  }, [
    type,
    email,
    phone,
    country.dial_code,
    translate,
    setSavedValues,
    savedValues,
    values,
    setIndex,
  ])

  const handleRenderTabTitle = useCallback(
    (key: AuthCredentialType) =>
      translate(
        key === 'phone'
          ? 'auth:registerIdentifierTabPhone'
          : 'auth:registerIdentifierTabEmail',
      ),
    [translate],
  )

  const handleRenderTabItem = useCallback(
    (key: AuthCredentialType) =>
      key === 'phone' ? (
        <InputPhone
          name="phone"
          loadData={handleLoadDataPhoneDummy}
          stateSelect={stateCountry}
          form={form}
          formRules={VALIDATION_AUTH_PHONE}
          allowedCharacters={REGEX_PHONE_NUMBER}
          emptyMessage={(search) =>
            translate('auth:registerIdentifierSearchCountryEmpty', {
              search,
            })
          }
          label={translate('auth:registerIdentifierInputPhoneLabel')}
          placeholder={translate(
            'auth:registerIdentifierInputPhonePlaceholder',
          )}
          maxLength={13}
          searchPlaceholder={translate('global:search')}
          autoComplete={false}
          containerStyle={{marginTop: convertUnit(25)}}
          rightIcon="delete"
        />
      ) : (
        <Input
          name="email"
          form={form}
          formRules={VALIDATION_AUTH_EMAIL}
          label={translate('auth:registerIdentifierInputEmailLabel')}
          placeholder={translate(
            'auth:registerIdentifierInputEmailPlaceholder',
          )}
          type="email"
          containerStyle={{marginTop: convertUnit(25)}}
          rightIcon="delete"
        />
      ),
    [form, stateCountry, handleLoadDataPhoneDummy, translate],
  )

  const handleRenderTabView = useMemo(
    () => (
      <StyledTabContainer>
        <TabView
          containerStyle={{marginTop: convertUnit(16)}}
          routes={['email', 'phone']}
          stateSelect={stateType}
          renderTitle={handleRenderTabTitle}
          renderItem={handleRenderTabItem}
        />
      </StyledTabContainer>
    ),
    [stateType, handleRenderTabTitle, handleRenderTabItem],
  )

  const handleContinueLogin = useCallback(() => {
    setShowTerms(false)
    handleContinue()
  }, [handleContinue, setShowTerms])

  const handleOpenTermsModal = useCallback(() => {
    setShowTerms(true)
  }, [setShowTerms])

  const handleRenderError = useMemo(
    () =>
      errorMessage && (
        <StyledError color="danger_5" fontWeight="medium">
          {errorMessage}
        </StyledError>
      ),
    [errorMessage],
  )

  const handleRenderKycUnsupportedWarning = useMemo(
    () =>
      isIOS &&
      !isSafari && (
        <StyledKycWarning color="danger_5" fontWeight="medium">
          {translate('giftShop:kycWarningUnsupported')}
        </StyledKycWarning>
      ),
    [translate],
  )

  const handleClose = useCallback(() => {
    setLegalModal(undefined)
  }, [])

  const handleRenderLegalModal = useMemo(() => {
    let src: string | undefined = ''
    switch (legalModal) {
      case 'cookies':
        src = getAuthCookie()
        break
      case 'privacy':
        src = getAuthPrivacy()
        break
      case 'tos':
        src = getAuthToS()
        break
      default:
        return ''
    }
    return `${src}&lang=${lang}`
  }, [lang, legalModal])

  const handleAgreementText: parseShape[] = useMemo(
    () => [
      {
        pattern: /<tos>.*?<\/tos>/,
        style: {color: theme.primary_3, cursor: 'pointer'},
        renderText: (text) => text.replace(/<tos>|<\/tos>/g, ''),
        onClick: () => setLegalModal('tos'),
      },
      {
        pattern: /<privacy>.*?<\/privacy>/,
        style: {color: theme.primary_3, cursor: 'pointer'},
        renderText: (text) => text.replace(/<privacy>|<\/privacy>/g, ''),
        onClick: () => setLegalModal('privacy'),
      },
      {
        pattern: /<cookie>.*?<\/cookie>/,
        style: {color: theme.primary_3, cursor: 'pointer'},
        renderText: (text) => text.replace(/<cookie>|<\/cookie>/g, ''),
        onClick: () => setLegalModal('cookies'),
      },
    ],
    [theme.primary_3],
  )

  useDidUpdate(() => setErrorMessage(undefined), [type])

  useEffect(() => {
    const prompt =
      (email !== '' || phone !== '') &&
      (email !== '' || phone !== undefined) &&
      (email !== undefined || phone !== '') &&
      !submit
    showPrompt(prompt, translate('auth:registerIdentifierCancelModalMessage'))
  }, [email, phone, submit, translate])

  return (
    <StyledContainer>
      <StyledHeader fontSize="xl" fontWeight="bold">
        {translate('auth:registerHeader')}
      </StyledHeader>
      {handleRenderKycUnsupportedWarning}
      {handleRenderWaitingModal}
      <StyledModal visible={legalModal !== undefined}>
        <StyledIcon onClick={handleClose}>
          <Icon type="close" size={14} color={'white_1'} />
        </StyledIcon>
        <StyledIframe src={handleRenderLegalModal} title="Legal" />
      </StyledModal>
      {handleRenderTabView}
      <StyledAgreement color="gray_5">
        <ParsedText parse={handleAgreementText}>
          {translate('auth:registerTermsOfService', {context: stateType[0]})}
        </ParsedText>
      </StyledAgreement>
      {handleRenderError}
      <StyledButtonLogin
        isLoading={loading}
        disabled={Object.keys(errors).length > 0 || id === ''}
        label={translate('global:continue')}
        onClick={handleOpenTermsModal}
      />
      <StyledSSOButtonContainer>
        <AuthLoginGoogle label={translate('auth:registerGoogleButtonLabel')} />
        <AuthLoginApple label={translate('auth:registerAppleButtonLabel')} />
      </StyledSSOButtonContainer>
      <StyledSectionLogin>
        <Paragraph fontSize="m" color="gray_3">
          {translate('auth:registerIdentifierAlreadyHaveAnAccount')}
        </Paragraph>
        <Link to="auth_login">
          <StyledLogin
            fontSize="m"
            fontWeight="medium"
            className="pressable"
            color="primary_5">
            {translate('global:login')}
          </StyledLogin>
        </Link>
      </StyledSectionLogin>
      <AuthRegisterTermOfCondition
        showModalState={showTermsState}
        handleAgree={handleContinueLogin}
      />
    </StyledContainer>
  )
}
