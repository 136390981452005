import React, {useCallback, useState} from 'react'
import {REGEX_LEADING_ZERO} from 'consts'
import {requestData} from 'services'
import {maskPhone} from 'utils'
import {useSelector} from 'lib/redux'
import {GiftShopEditProfileOTPModalProps} from './GiftShopEditProfileModalProps'
import {GiftShopTemplateOTPModal} from '../../../template'

export default function GiftShopEditProfileVerifyOTPModal({
  verificationMethod,
  onCancel,
  onResend,
  onSuccess,
  onFailed,
}: GiftShopEditProfileOTPModalProps) {
  const {phoneCode, phoneNumber} = useSelector('userNewPhone') || {}
  const {phoneOTPTime} = useSelector('lastUserState')

  const stateIncorrect = useState(false)
  const setIncorrect = stateIncorrect[1]

  const handleVerify = useCallback(
    (otp: string) => {
      requestData('giftshop_patch_verify_phone', {
        actionType: 'execute',
        data: {code: otp},
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setIncorrect(false)
            onSuccess()
          } else if (status === 404) {
            setIncorrect(true)
          } else {
            onFailed()
          }
        },
      })
    },
    [onFailed, onSuccess, setIncorrect],
  )

  return (
    <GiftShopTemplateOTPModal
      stateError={stateIncorrect}
      dataToVerify={
        phoneCode &&
        phoneNumber &&
        maskPhone(phoneCode, phoneNumber.replace(REGEX_LEADING_ZERO, ''))
      }
      verificationMethod={verificationMethod === 'sms' ? 'phone' : 'whatsapp'}
      timeRemaining={phoneOTPTime}
      onCancel={onCancel}
      onVerify={handleVerify}
      onResend={onResend}
    />
  )
}
