import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useForm} from 'react-hook-form'
import AvatarEditor from 'react-avatar-editor'
import styled from 'styled-components'
import {
  HOST_IMAGE_TYPE,
  HOST_LOGO_MAX_SIZE,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {TreeHostPatchPictureForm, TreeUploadImageExtensionType} from 'types'
import {
  cropImage,
  handleUploadSingleFileToWasabi,
  showSnackbar,
  useDidMount,
} from 'utils'
import {Icon, Image, ImageCropper, ModalLoading} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeEditProfileLogoProps} from './TreeEditProfileLogoProps'
import {TreeEditProfileModalImageOptions} from '../Modal'

const StyledLogoContainer = styled.div`
  position: absolute;
  bottom: ${convertUnit(20)};
  left: ${convertUnit(20)};
  width: ${convertUnit(80)};
  height: ${convertUnit(80)};

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    bottom: -${convertUnit(40)};
  }
`

const StyledLogo = styled(Image)`
  position: absolute;
  width: ${convertUnit(80)};
  height: ${convertUnit(80)};
  object-fit: cover;
  object-position: center;
  border-radius: ${convertUnit(8)};
  box-sizing: border-box;
  border: ${convertUnit(2)} solid ${({theme}) => theme.white_3};
`

const StyledLogoPlaceholder = styled.div`
  background-color: ${({theme}) => theme.gray_1};
  position: absolute;
  width: ${convertUnit(80)};
  height: ${convertUnit(80)};
  border-radius: ${convertUnit(8)};
  box-sizing: border-box;
  border: ${convertUnit(2)} solid ${({theme}) => theme.white_3};
`

const StyledEditContainer = styled.div`
  position: absolute;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({theme}) => theme.white_1};
  width: ${convertUnit(32)};
  height: ${convertUnit(32)};
  border: ${convertUnit(1.89)} solid ${({theme}) => theme.white_3};
  left: ${convertUnit(50)};
  top: ${convertUnit(50)};
`

const StyledInput = styled.input`
  display: none;
`

export default function TreeEditProfileLogo({
  logoPhoto,
}: TreeEditProfileLogoProps) {
  const {translate} = useTranslation()
  const [modal, setModal] = useState(false)
  const [crop, setCrop] = useState(false)
  const [loading, setLoading] = useState(false)
  const canvas = useRef<AvatarEditor>(null)
  const logoRef = useRef<HTMLInputElement>(null)

  const form = useForm<TreeHostPatchPictureForm>()
  const {register, setValue, watch} = form
  const {imgExtension, imgFile, imgHeight, imgMime, imgSize, imgWidth} = watch()

  const handleCheckLogo = useCallback(() => {
    setModal(false)
    if (logoRef.current?.files?.length) {
      const file = logoRef.current.files[0]
      const filename = file.name
      const extension = filename.substring(
        filename.lastIndexOf('.') + 1,
      ) as TreeUploadImageExtensionType
      const {type, size} = file
      if (!HOST_IMAGE_TYPE.includes(type)) {
        showSnackbar(translate('tree:addHostInfoErrorInvalidFileFormat'))
        return false
      }
      if (size > HOST_LOGO_MAX_SIZE) {
        showSnackbar(translate('tree:addHostInfoErrorMaxFileSize'))
        return false
      }

      setValue('imgMime', type)
      setValue('imgExtension', extension)
      return true
    }
    return false
  }, [setValue, translate])

  const handleGenerateLogoLink = useCallback(async () => {
    setLoading(true)
    const response = await requestData('tree_post_logo_link', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {
        logo: {
          extension: imgExtension,
          height: imgHeight,
          size: imgSize,
          width: imgWidth,
        },
      },
      onRequestSuccess: ({status}) => status !== 200 && setLoading(false),
      onRequestFailed: () => setLoading(false),
    })
    return typeof response !== 'string' && response.status === 200
      ? response.data.result.logo
      : null
  }, [imgExtension, imgHeight, imgSize, imgWidth])

  const handlePublishToWasabi = useCallback(
    async (link: string) => {
      try {
        return handleUploadSingleFileToWasabi(imgFile, link, imgMime, {
          'Content-Type': imgMime,
          'x-amz-meta-imagewidth': imgWidth.toString(),
          'x-amz-meta-imageheight': imgHeight.toString(),
        })
      } catch (error) {
        return false
      }
    },
    [imgFile, imgHeight, imgMime, imgWidth],
  )

  const handleChangeLogo = useCallback(
    async (link: string) => {
      const response = await requestData('tree_patch_hosts', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {
          logo_link: link,
          logo_size: imgSize,
        },
      })
      return typeof response !== 'string' && response.status === 200
    },
    [imgSize],
  )

  const handlePublishLogo = useCallback(async () => {
    const link = await handleGenerateLogoLink()
    if (link) {
      const wasabiStatus = await handlePublishToWasabi(link)
      if (wasabiStatus) {
        const uploadStatus = await handleChangeLogo(link)
        if (uploadStatus) {
          setLoading(false)
          setCrop(false)
          location.reload()
        }
      }
    }
  }, [handleChangeLogo, handleGenerateLogoLink, handlePublishToWasabi])

  const handleCrop = useCallback(
    (img?: File) => {
      if (img) {
        const reader = new FileReader()
        reader.readAsDataURL(img)
        reader.onload = () => {
          const url = reader.result?.toString()
          const image = document.createElement('img')
          if (url) {
            image.src = url
            image.onload = () => {
              const {width, height} = image
              setValue('imgFile', img)
              setValue('imgHeight', height)
              setValue('imgWidth', width)
              setValue('imgSize', img.size)
            }
          }
        }
      }
    },
    [setValue],
  )

  const handleRenderLogo = useMemo(
    () => (
      <StyledLogoContainer>
        {logoPhoto ? (
          <StyledLogo src={logoPhoto} alt={logoPhoto} />
        ) : (
          <StyledLogoPlaceholder />
        )}
        <StyledEditContainer onClick={() => setModal(true)}>
          <Icon type="pencil" size={convertUnit(12)} />
        </StyledEditContainer>
      </StyledLogoContainer>
    ),
    [logoPhoto],
  )

  const handleRenderChangePicOPtions = useMemo(
    () => (
      <TreeEditProfileModalImageOptions
        visible={modal}
        onClickOption={() => {
          logoRef.current && logoRef.current.click()
        }}
        toggleModal={() => setModal((prev) => !prev)}
      />
    ),
    [modal],
  )

  const handleRenderLogoCropper = useMemo(
    () =>
      logoRef.current?.files?.length ? (
        <ImageCropper
          visible={crop}
          borderRadius={0}
          border={50}
          componentRef={canvas}
          file={logoRef.current.files[0]}
          onBackdropPress={() => setCrop(false)}
          onClickSave={() => cropImage(canvas, 'logo', handleCrop)}
        />
      ) : (
        <></>
      ),
    [crop, handleCrop],
  )

  useDidMount(() => {
    register('imgFile')
    register('imgExtension')
    register('imgHeight')
    register('imgSize')
    register('imgWidth')
    register('imgMime')
  })

  useEffect(() => {
    if (imgHeight && imgWidth && imgSize) {
      handlePublishLogo()
    }
  }, [handlePublishLogo, imgHeight, imgSize, imgWidth])

  return (
    <>
      {handleRenderChangePicOPtions}
      {handleRenderLogo}
      {handleRenderLogoCropper}
      <ModalLoading visible={loading} />
      <StyledInput
        ref={logoRef}
        type="file"
        onChange={() => {
          const check = handleCheckLogo()
          return check ? setCrop(true) : undefined
        }}
        accept={HOST_IMAGE_TYPE.join(',')}
      />
    </>
  )
}
