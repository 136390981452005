import {TranslationKeyTransaction} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_TRANSACTION_ID: TranslationResource<TranslationKeyTransaction> = {
  translation: {
    'transaction:cancelTransactionModalMessage':
      'Kamu yakin ingin membatalkan transaksi ini?',

    'transaction:cartDoubleBookedDescription':
      'Apakah kamu mau memesan konten ini lagi dan membatalkan faktur sebelumnya?',
    'transaction:cartDoubleBookedTitle': 'Pemesanan Ganda',
    'transaction:checkoutContentDoNotAutopost': 'Tolong tidak repost',
    'transaction:checkoutContentExpand': 'Tampilkan Semua',
    'transaction:checkoutContentPrice': 'Harga',
    'transaction:creditCardLastFour': '(******{{number}})',
    'transaction:checkoutTransactionDate': 'Tanggal Transaksi',
    'transaction:checkoutTransactionTitle': 'Detail Transaksi',
    'transaction:checkoutTransactionType': 'Jenis Transaksi',
    'transaction:checkoutWaitForHiresInfoDesc1':
      'Hi-Res akan muncul di “Koleksi” di tab “FotoYu”.',
    'transaction:checkoutWaitForHiresInfoDesc2':
      'Kamu juga dapat mengobrol dengan kreator penjual, dengan mengklik ikon pesan di bawah setiap konten yang kamu beli.',
    'transaction:checkoutWaitForHiresInfoDesc3':
      'Kreator harus mengirimkan Hi-Res dalam 48 jam, atau kamu akan menerima pengembalian dana.',
    'transaction:checkoutWaitForHiresInfoTitle': 'Mohon tunggu versi Hi-Res.',
    'transaction:paymentFailedModalMessage':
      'Pembayaran tidak dapat diproses saat ini, silakan coba lagi nanti',
  },
}
