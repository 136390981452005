import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {useWindowMode} from 'windows'
import {getCurrencyValue, parseDate, showSnackbar, useHistory} from 'utils'
import {requestData} from 'services'
import {GiftShopContentData} from 'types'
import {Button, ConfirmationModal, Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplatePaymentProofModal} from 'pages'
import {TransactionWaitingDetailContentProps} from './TransactionDetailContentProps'
import {TransactionDetailContentItem} from '../ContentItem'

const StyledContainer = styled.div<CSSProperties>`
  display: flex;
  max-width: ${({maxWidth}) => maxWidth};
  margin: ${convertUnit(25)};
  padding: ${convertUnit(25)} 0;
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_1};
  align-self: center;
  overflow: auto;

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    flex-direction: column;
    height: 100%;
  }
`

const StyledContentSection = styled.div<CSSProperties>`
  display: flex;
  width: ${({width}) => width};
  flex-direction: column;
  margin: 0 ${convertUnit(25)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
    margin: 0;
  }
`

const StyledHeaderContainer = styled.div<CSSProperties>`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(8)};
  margin-bottom: ${convertUnit(20)};
  justify-content: space-between;
  align-items: center;
`

const StyledTitle = styled(Paragraph)`
  margin: ${convertUnit(10)} 0;
`

const StyledInfoContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: ${convertUnit(25)};
`

const StyledInfoItem = styled.div`
  width: 50%;
  margin-bottom: ${convertUnit(16)};
`

const StyledDetailText = styled(Paragraph)`
  text-align: right;
`

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(8)};
  align-items: center;
`

const StyledMessageContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_4,
  })}
  flex-direction: 'column';
  border-radius: ${convertUnit(8)};
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: ${convertUnit(8)} ${convertUnit(16)};
  margin-top: ${convertUnit(4)};
  margin-bottom: ${convertUnit(20)};
`

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  display: flex;
  flex: 1;
  width: 100%;
`

export default function TransactionWaitingDetailContent({
  stateData,
  dataInvoice,
}: TransactionWaitingDetailContentProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const history = useHistory()
  const statusState = status
  const [sendProofModal, setSendProofModal] = useState(false)
  const [sendProofLoading, setSendProofLoading] = useState(false)
  const [sendProofSent, setSendProofSent] = useState<boolean>()

  const {
    data,
    invoice_id,
    payment_invoice,
    payment_type,
    transaction_method,
    transaction_total,
    transaction_date,
  } = stateData
  const [cancelModal, setCancelModal] = useState(false)

  const enableHowToPayButton = useMemo(
    () =>
      payment_type === 'Virtual Account' ||
      payment_type === 'Retail' ||
      (payment_type === 'Ewallet' &&
        (transaction_method === 'GoPay' || transaction_method === 'ShopeePay')),
    [payment_type, transaction_method],
  )

  const handleClickPendingItem = useCallback(
    (item: GiftShopContentData) => {
      history.push('giftshop_transaction_pending_content_detail', {
        data: [item],
        transaction_date,
        invoiceId: dataInvoice,
      })
    },
    [history, transaction_date, dataInvoice],
  )

  const handleClickCollectionItem = useCallback(
    (item: GiftShopContentData) => {
      history.push('giftshop_collection_detail', {
        data,
        selectedItemId: item.content_id,
        limitContent: true,
      })
    },
    [history, data],
  )

  const handleHowToPay = useCallback(() => {
    transaction_method === 'GoPay' || transaction_method === 'ShopeePay'
      ? history.push('giftshop_ewallet_payment_history', {
          invoiceId: payment_invoice,
          paymentMethod: transaction_method,
          transactionDate: new Date(transaction_date),
        })
      : history.push('giftshop_payment_history', {
          invoiceId: payment_invoice,
          paymentMethod: transaction_method,
          paymentType: payment_type,
          transactionDate: new Date(transaction_date),
        })
  }, [
    history,
    payment_invoice,
    payment_type,
    transaction_date,
    transaction_method,
  ])

  const handleCheckPaymentProofIsSent = useCallback(() => {
    if (invoice_id === '') return

    requestData('giftshop_get_transaction_report', {
      params: {
        invoice_id,
      },
      onRequestSuccess: ({status: stat}) => {
        setSendProofSent(stat === 200)
      },
    })
  }, [invoice_id])

  const handleCancelTransaction = useCallback(() => {
    requestData('giftshop_cancel_transaction', {
      actionType: 'execute',
      useDefaultMessage: true,
      data: {payment_invoice},
      onRequestSuccess: ({status: reqStatus}) => {
        reqStatus === 200 && history.replace('giftshop_balance', {})
      },
    })
  }, [history, payment_invoice])

  const renderItemPurchased = useMemo(
    () =>
      data && (
        <StyledContentSection
          marginTop={mode !== 'website' ? convertUnit(50) : 0}
          width="50%">
          {data.map((item: GiftShopContentData) => (
            <TransactionDetailContentItem
              item={item}
              onClickItem={
                statusState === 'COMPLETED'
                  ? handleClickCollectionItem
                  : handleClickPendingItem
              }
            />
          ))}
          {enableHowToPayButton && mode !== 'website' && (
            <StyledButton
              label={translate('giftShop:howToPay')}
              onClick={handleHowToPay}
            />
          )}
        </StyledContentSection>
      ),
    [data, mode, enableHowToPayButton, translate, handleHowToPay, statusState, handleClickCollectionItem, handleClickPendingItem],
  )

  const handleRenderCancelTransactionModal = useMemo(
    () => (
      <ConfirmationModal
        visible={cancelModal}
        title={translate('global:cancel')}
        text={translate('transaction:cancelTransactionModalMessage')}
        cancelText={translate('global:stay')}
        confirmText={translate('global:yesImSure')}
        onConfirm={handleCancelTransaction}
        toggleModal={() => setCancelModal(false)}
      />
    ),
    [cancelModal, handleCancelTransaction, translate],
  )

  const handleSubmitPaymentProof = useCallback(
    (file: File, notes?: string) => {
      setSendProofLoading(true)
      requestData('giftshop_post_transaction_report', {
        useDefaultMessage: true,
        actionType: 'execute',
        headerContentType: 'multipart/form-data',
        dataType: 'form-data',
        data: {
          payment_invoice,
          notes,
          receipt_photo: file,
        },
        onRequestSuccess: ({status: paymentProofStatus}) => {
          setSendProofLoading(false)
          if (paymentProofStatus === 200) {
            setSendProofModal(false)
            setSendProofLoading(false)
            setSendProofSent(true)
            showSnackbar(translate('giftShop:paymentSendProofSuccess'))
          }
        },
        onRequestFailed: () => {
          setSendProofLoading(false)
          showSnackbar(translate('giftShop:paymentSendProofFailed'))
        },
      })
    },
    [payment_invoice, translate],
  )

  useEffect(() => {
    handleCheckPaymentProofIsSent()
  }, [handleCheckPaymentProofIsSent])

  const handleRenderPaymentProofMessage = useMemo(
    () =>
      sendProofSent ? (
        <StyledMessageContainer>
          <Icon
            type="circular-check"
            size={16}
            style={{
              alignSelf: 'center',
              marginRight: convertUnit(16),
            }}
            color="success_5"
          />
          <Paragraph
            fontSize="s"
            fontWeight="medium"
            style={{alignSelf: 'center'}}>
            <Paragraph fontSize="s" fontWeight="bold" color="success_5">
              {translate('giftShop:paymentSendProofSuccess')}
              <br />
            </Paragraph>
            <Paragraph fontSize="s" fontWeight="medium" color="gray_5">
              {translate('giftShop:paymentProofAlreadySent')}
            </Paragraph>
          </Paragraph>
        </StyledMessageContainer>
      ) : (
        <StyledMessageContainer>
          <Icon
            type="info"
            size={16}
            style={{
              alignSelf: 'center',
              marginRight: convertUnit(16),
            }}
          />
          <Paragraph
            fontSize="s"
            fontWeight="medium"
            style={{alignSelf: 'center', lineHeight: convertUnit(21)}}>
            {translate('giftShop:paymentWaitOrSendProofFirst')}
            <Paragraph
              fontSize="s"
              fontWeight="medium"
              color="primary_5"
              style={{
                display: 'inline',
                cursor: 'pointer',
              }}
              onClick={() => setSendProofModal((prev) => !prev)}>
              {translate('giftShop:paymentWaitOrSendProofSecond')}
            </Paragraph>
            .
          </Paragraph>
        </StyledMessageContainer>
      ),
    [sendProofSent, translate],
  )

  const handleRenderPaymentProof = useMemo(
    () => (
      <GiftShopTemplatePaymentProofModal
        toggleModal={() => {
          setSendProofModal((prev) => !prev)
        }}
        visible={sendProofModal}
        loading={sendProofLoading}
        onSubmit={handleSubmitPaymentProof}
      />
    ),
    [handleSubmitPaymentProof, sendProofLoading, sendProofModal],
  )

  const renderContent = useMemo(
    () =>
      invoice_id && (
        <>
          <StyledContentSection width="50%">
            <StyledHeaderContainer>
              <StyledTitleContainer>
                <StyledTitle fontSize="xl" fontWeight="bold">
                  {translate('giftShop:transactionDetail')}
                </StyledTitle>
              </StyledTitleContainer>
            </StyledHeaderContainer>
            <StyledInfoContainer>
              <StyledInfoItem>
                <Paragraph fontSize="m" fontWeight="medium">
                  {translate('giftShop:transactionType')}
                </Paragraph>
              </StyledInfoItem>
              <StyledInfoItem>
                <StyledDetailText fontSize="m">
                  {translate('giftShop:transactionType_PURCHASE')}
                </StyledDetailText>
              </StyledInfoItem>

              <StyledInfoItem>
                <Paragraph fontSize="m" fontWeight="medium">
                  {translate('giftShop:transactionTime')}
                </Paragraph>
              </StyledInfoItem>
              <StyledInfoItem>
                <StyledDetailText fontSize="m">
                  {(transaction_date &&
                    parseDate(transaction_date, 'MMMM D, YYYY, HH:mm')) ||
                    ''}
                </StyledDetailText>
              </StyledInfoItem>

              <StyledInfoItem>
                <Paragraph fontWeight="medium" fontSize="m">
                  {translate('giftShop:totalPayment')}
                </Paragraph>
              </StyledInfoItem>
              <StyledInfoItem>
                <StyledDetailText
                  fontSize="m"
                  fontWeight="bold"
                  color="primary_5">
                  {transaction_total && getCurrencyValue(transaction_total)}
                </StyledDetailText>
              </StyledInfoItem>
            </StyledInfoContainer>
            {enableHowToPayButton &&
              sendProofSent !== undefined &&
              handleRenderPaymentProofMessage}
            <StyledButtonsContainer>
              {enableHowToPayButton && mode === 'website' && (
                <StyledButton
                  label={translate('giftShop:howToPay')}
                  onClick={handleHowToPay}
                />
              )}
            </StyledButtonsContainer>
          </StyledContentSection>
          {renderItemPurchased}
        </>
      ),
    [
      invoice_id,
      translate,
      transaction_date,
      transaction_total,
      enableHowToPayButton,
      sendProofSent,
      handleRenderPaymentProofMessage,
      mode,
      handleHowToPay,
      renderItemPurchased,
    ],
  )

  return (
    <StyledContainer maxWidth={convertUnit(2000)}>
      {handleRenderPaymentProof}
      {renderContent}
      {handleRenderCancelTransactionModal}
    </StyledContainer>
  )
}
