import {useCallback, useMemo, useState} from 'react'
import {
  GIFT_SHOP_MAX_FILE_NAME,
  GIFT_SHOP_UPLOAD_DEFAULT_LOCATION,
} from 'consts'
import {requestData} from 'services'
import {
  GiftShopUploadSeriesData,
  GiftshopUploadPublishFormData,
  GiftshopUploadPublishFormMap,
  GiftshopUploadPublishSeriesSendToFaceParam,
  GiftshopUploadPublishSeriesValues,
  ImageExtensionType,
} from 'types'
import {useSelector} from 'lib/redux'
import {useDidMount} from 'utils/objects'
import {getGiftshopLocationName} from '../GiftshopHelper'
import {getFileName, handleUploadSingleFileToWasabi} from '../../../files'
import {useHistory} from '../../../routes'

export function useGiftshopUploadPublishSeriesSendToFace({
  mapRef,
  sendToFaceData = {description: '', tags: []},
}: GiftshopUploadPublishSeriesSendToFaceParam) {
  const {map, setMapValue, updateMap} = mapRef
  const {username} = useSelector('user') || {}
  const history = useHistory()
  const {
    latitude: cacheLat,
    longitude: cacheLong,
    name: cacheName,
  } = useSelector('lastUploadStatePhoto')
  const [gpsLoc, setGpsLoc] = useState('')

  const handleGetLocationGPS = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const {coords} = position
        setGpsLoc(
          getGiftshopLocationName({
            latitude: coords.latitude.toString(),
            longitude: coords.longitude.toString(),
          }),
        )
      },
      undefined,
      {enableHighAccuracy: false, maximumAge: 0},
    )
  }, [])

  const handleGetLocation = useCallback(
    (item: GiftshopUploadPublishFormData) => {
      const {location} = item
      const {tags} = sendToFaceData

      // priority 1: metadata
      if (location) {
        return location
      }

      // priority 2: fototree
      if (
        tags &&
        tags[0] &&
        tags[0].location &&
        Object.keys(tags[0].location).length > 1
      ) {
        const {name: tagName, location: tagLocation} = tags[0]
        return getGiftshopLocationName({
          latitude: tagLocation.latitude.toString(),
          longitude: tagLocation.longitude.toString(),
          name: tagName,
        })
      }

      // priority 3: cache
      if (cacheLat && cacheLong) {
        return getGiftshopLocationName({
          latitude: cacheLat,
          longitude: cacheLong,
          name: cacheName,
        })
      }

      // priority 4: current location
      if (gpsLoc) {
        return gpsLoc
      }

      // priority 5: default location
      return GIFT_SHOP_UPLOAD_DEFAULT_LOCATION
    },
    [cacheLat, cacheLong, cacheName, gpsLoc, sendToFaceData],
  )

  const handleFinished = useCallback(() => {
    const currentEntries = Object.entries(map.current.mapForm)
    const currentTotal = currentEntries.length
    const currentSuccess = currentEntries.filter(
      ([, {status}]) => status === 'success',
    ).length

    if (currentTotal === currentSuccess) {
      setTimeout(
        () => history.push('giftshop_profile', {self: true}, username),
        1000,
      )
    }
  }, [history, map, username])

  const handlePublishGetLink = useCallback(
    async (item: GiftshopUploadPublishFormData, totalSize: number) => {
      const {height, width, size, extension} = item.image
      const imgExtension = extension as ImageExtensionType

      const response = await requestData(
        'giftshop_post_upload_content_link_old',
        {
          data: {
            content: {
              size,
              height,
              width,
              extension: imgExtension,
            },
            series: {
              total_size: totalSize,
            },
          },
        },
      )

      return typeof response !== 'string' && response.status === 200
        ? response.data.result.original_link
        : null
    },
    [],
  )

  const handlePublishToWasabi = useCallback(
    async (item: GiftshopUploadPublishFormData, link: string) => {
      const {file, width, height, extension} = item.image
      const mime = `image/${extension}`

      try {
        return handleUploadSingleFileToWasabi(file, link, mime, {
          'Content-Type': mime,
          'x-amz-meta-imagewidth': width.toString(),
          'x-amz-meta-imageheight': height.toString(),
        })
      } catch (error) {
        return false
      }
    },
    [],
  )

  const handlePublishProperties = useCallback(
    async (
      item: GiftshopUploadPublishFormData,
      link: string,
      isParent: boolean,
      seriesId: string,
    ) => {
      const {image, currencyId, originalAt} = item
      const location = handleGetLocation(item)

      const response = await requestData('gistshop_post_send_to_face', {
        data: {
          resend: false,
          title: getFileName(image.file.name, GIFT_SHOP_MAX_FILE_NAME),
          description: sendToFaceData.description,
          location,
          content: link,
          height: image.height,
          width: image.width,
          size: image.size,
          exif: JSON.stringify(image.exif),
          currency_id: currencyId,
          price: 0,
          original_at: originalAt,
          unix_original_at: Math.floor(new Date(originalAt).getTime() / 1000),
          tag_ids: sendToFaceData.tags?.map((tag) => tag.id),
          is_parent: isParent,
          series_id: seriesId,
          nickname: '',
        },
      })

      return typeof response !== 'string' && response.status === 200
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sendToFaceData],
  )

  const handlePublishSingle = useCallback(
    async (
      item: GiftshopUploadPublishFormData,
      isParent: boolean,
      seriesId: string,
      totalSize: number,
    ) => {
      const link = await handlePublishGetLink(item, totalSize)
      const isSuccess =
        !!link &&
        (await handlePublishToWasabi(item, link)) &&
        (await handlePublishProperties(item, link, isParent, seriesId))

      setMapValue({
        mapForm: {
          ...map.current.mapForm,
          [item.image.id]: {
            item,
            id: item.image.id,
            status: isSuccess ? 'success' : 'failed',
          },
        },
      })
      updateMap()
      handleFinished()

      return isSuccess
    },
    [
      handlePublishGetLink,
      handlePublishToWasabi,
      handlePublishProperties,
      setMapValue,
      map,
      updateMap,
      handleFinished,
    ],
  )

  const handlePublishSendToFace = useCallback(
    async ({id, parent: parentId}: GiftShopUploadSeriesData) => {
      const variants = Object.values(map.current.mapForm).filter(
        (variant) => variant.item.series === id,
      )
      const parent = variants.find((variant) => variant.id === parentId)
      const totalSize = variants.reduce(
        (prev, curr) => prev + curr.item.image.size,
        0,
      )

      if (
        parent &&
        parent.status === 'not-started' &&
        !(await handlePublishSingle(parent.item, true, id, totalSize))
      ) {
        setMapValue({
          mapForm: variants.reduce<GiftshopUploadPublishFormMap>(
            (previous, variant): GiftshopUploadPublishFormMap => ({
              ...previous,
              [variant.id]: {...variant, status: 'failed'},
            }),
            map.current.mapForm,
          ),
        })
        updateMap()
        handleFinished()
        return
      }

      const children = variants.filter(
        (child) => child.status === 'not-started' && child.id !== parentId,
      )

      for (const child of children) {
        // eslint-disable-next-line no-await-in-loop
        await handlePublishSingle(child.item, false, id, 0)
      }
    },
    [map, setMapValue, updateMap, handleFinished, handlePublishSingle],
  )

  useDidMount(handleGetLocationGPS)

  return useMemo<GiftshopUploadPublishSeriesValues>(
    () => ({publishSeries: handlePublishSendToFace}),
    [handlePublishSendToFace],
  )
}
