import React, {useMemo} from 'react'
import styled from 'styled-components'
import {
  CREATOR_PRICE_EXAMPLE,
  CREATOR_PRICE_PERCENTAGE,
  HOST_FEE_EXAMPLE,
  HOST_FEE_PERCENTAGE,
  FOTOYU_FEE_EXAMPLE,
  FOTOYU_FEE_PERCENTAGE,
  IMAGE_ASSET,
  NET_HOST_EXAMPLE,
  NET_HOST_PERCENTAGE,
} from 'consts'
import {getBorder, getFontFamily, getFontSize} from 'utils'
import {Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {howItWorkLang, useTranslation} from 'i18n'

const StyledList = styled.ol`
  padding-left: ${convertUnit(25)};
`
const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`
const StyledTextContainer = styled.div`
  margin-top: ${convertUnit(63)};
  padding-right: ${convertUnit(5)};
  padding-left: ${convertUnit(38)};
  text-align: justify;
`
const StyledTitle = styled(Paragraph)`
  ${({theme}) => ({
    color: theme.primary_5,
  })}
  text-align: justify;
  padding-top: ${convertUnit(35)};
`
const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`
const StyledTitleContainer = styled.div`
  margin-top: ${convertUnit(42)};
  padding-right: ${convertUnit(5)};
  padding-left: ${convertUnit(38)};
  text-align: justify;
`
const StyledTable = styled.table`
  margin-top: ${convertUnit(18)};
  width: ${convertUnit(400)};
  border-collapse: collapse;
`
const StyledTableHeader = styled.tr`
  border: ${({theme}) => getBorder(2, 'solid', theme.black)};
  border-collapse: collapse;
`
const StyledTableContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${convertUnit(18)};
`
const StyledPlusMinusIcon = styled.div`
  padding-top: ${convertUnit(65)};
  padding-left: ${convertUnit(10)};
`
const StyledImage = styled(Image)`
  margin-left: ${convertUnit(5)};
  margin-right: ${convertUnit(5)};
  margin-top: ${convertUnit(20)};
  object-fit: contain;
`

export default function LegalHostContent() {
  const hostImageEn = IMAGE_ASSET('howitworks', 'host-en.png')
  const hostImageId = IMAGE_ASSET('howitworks', 'host-id.png')
  const {translate} = useTranslation()

  const handleTitle = useMemo(
    () => (
      <StyledTitleContainer>
        <StyledTitle fontWeight="bold" fontSize="xxl">
          {translate('policy:host')}
        </StyledTitle>
        <StyledImage
          src={howItWorkLang() === 'id' ? hostImageId : hostImageEn}
          alt="hostImage"
          width="100%"
        />
        <StyledDescription>
          {translate('policy:hostDescription')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:hostDescription', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTitleContainer>
    ),
    [hostImageEn, hostImageId, translate],
  )

  const handleHowHostWorks = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:hostHowWorks')}</StyledSubTitle>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:hostHowWorks', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleProcedure = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:hostProcedures')}</StyledSubTitle>
        <StyledSubTitle fontSize="l">
          {translate('policy:hostProceduresRegistration')}
        </StyledSubTitle>
        <StyledList>
          {Array.from({length: 4}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:hostProceduresRegistration', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledSubTitle fontSize="l">
          {translate('policy:hostProceduresManual')}
        </StyledSubTitle>
        <StyledList>
          {Array.from({length: 8}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:hostProceduresManual', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleOfficialHost = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:hostOfficialCreator')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:hostOfficialCreatorDesc1')}
          <br />
          <br />
          {translate('policy:hostOfficialCreatorDesc2')}
          <br />
          <br />
          {translate('policy:hostOfficialCreatorDesc3')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleOfficialHostProcedure = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:hostOfficialCreatorProcedures')}
        </StyledSubTitle>
        <StyledSubTitle fontSize="l">
          {translate('policy:hostOfficialCreatorProceduresRegistration')}
        </StyledSubTitle>
        <StyledList>
          {Array.from({length: 6}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:hostOfficialCreatorProceduresRegistration', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledSubTitle fontSize="l">
          {translate('policy:hostOfficialCreatorProceduresManual')}
        </StyledSubTitle>
        <StyledList>
          {Array.from({length: 6}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:hostOfficialCreatorProceduresManual', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleServiceFee = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:hostPayment')}</StyledSubTitle>
        <StyledSubTitle fontSize="l">
          {translate('policy:hostCreatorServicesFee')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:hostCreatorServicesFeeDesc1')}
          <br />
          {translate('policy:hostCreatorServicesFeeDesc2')}
          <br />
          {translate('policy:hostCreatorServicesFeeDesc3')}
        </StyledDescription>
        <StyledTableContainer>
          <StyledTable>
            <tr>
              <td>
                <Paragraph fontSize="l">
                  {translate('policy:hostBasicPrice')}
                </Paragraph>
              </td>
              <td>
                <Paragraph fontSize="l">{CREATOR_PRICE_PERCENTAGE}</Paragraph>
              </td>
              <td>
                <Paragraph fontSize="l">{CREATOR_PRICE_EXAMPLE}</Paragraph>
              </td>
            </tr>
            <tr>
              <td>
                <Paragraph fontSize="l">
                  {translate('policy:hostFotoyuFee')}
                </Paragraph>
              </td>
              <td>
                <Paragraph fontSize="l">{FOTOYU_FEE_PERCENTAGE}</Paragraph>
              </td>
              <td>
                <Paragraph fontSize="l">{FOTOYU_FEE_EXAMPLE}</Paragraph>
              </td>
            </tr>
            <tr>
              <td>
                <Paragraph fontSize="l">
                  {translate('policy:hostHostFee')}
                </Paragraph>
              </td>
              <td>
                <Paragraph fontSize="l">{HOST_FEE_PERCENTAGE}</Paragraph>
              </td>
              <td>
                <Paragraph fontSize="l">{HOST_FEE_EXAMPLE}</Paragraph>
              </td>
            </tr>
            <StyledTableHeader>
              <th>
                <Paragraph fontSize="l" fontWeight="bold">
                  {translate('policy:hostNetCreator')}
                </Paragraph>
              </th>
              <th>
                <Paragraph fontSize="l" fontWeight="bold">
                  {NET_HOST_PERCENTAGE}
                </Paragraph>
              </th>
              <th>
                <Paragraph fontSize="l" fontWeight="bold">
                  {NET_HOST_EXAMPLE}
                </Paragraph>
              </th>
            </StyledTableHeader>
          </StyledTable>
          <StyledPlusMinusIcon>
            <Paragraph fontSize="l" fontWeight="bold">
              {'-'}
            </Paragraph>
          </StyledPlusMinusIcon>
        </StyledTableContainer>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleWithdraw = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:hostBalanceWithdrawal')}
        </StyledSubTitle>
        <StyledList>
          {Array.from({length: 6}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:hostBalanceWithdrawal', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRounding = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:hostRounding')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:hostRoundingDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:hostMinimumPrice')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:hostMinimumPriceDesc1')}
          <br />
          {translate('policy:hostMinimumPriceDesc2')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:hostRefund')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:hostRefundDesc')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  return (
    <>
      {handleTitle}
      {handleHowHostWorks}
      {handleProcedure}
      {handleOfficialHost}
      {handleOfficialHostProcedure}
      {handleServiceFee}
      {handleWithdraw}
      {handleRounding}
    </>
  )
}
