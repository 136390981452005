import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useForm} from 'react-hook-form'
import {WINDOW_MODE_MOBILE_WIDTH, WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {AuthLoginWefieWaitingModal, AuthLoginWefieReapplyModal} from 'pages'
import {
  AuthFormLoginData,
  AuthLoginUnverifiedResponse,
  FirestoreExploreRefreshPayload,
} from 'types'
import {requestData} from 'services'
import {
  useHistory,
  showSnackbar,
  getPhoneData,
  formatCooldownTime,
  useLocation,
  getWhatsAppLink,
  getInstagramLink,
} from 'utils'
import firebase from 'lib/firebase'
import convertUnit from 'lib/unit'
import {AUTH_LOGIN_MAX_ATTEMPT} from 'consts/auth'
import {
  Button,
  Input,
  Paragraph,
  Link,
  Icon,
  LanguageToggle,
} from 'common/components'
import {
  VALIDATION_AUTH_LOGIN_ACCOUNT,
  VALIDATION_AUTH_LOGIN_PASSWORD,
} from 'common/validations'
import {useDispatch} from 'lib/redux'
import {AuthLoginGoogle} from '../Google'
import {AuthLoginApple} from '../Apple'

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    justify-content: flex-start;
  }
`

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    height: inherit;
    justify-content: center;
  }
`

const StyledSectionRegister = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const StyledForgotPassword = styled(Paragraph)`
  margin-top: ${convertUnit(5)};
`

const StyledButtonLogin = styled(Button)`
  margin-top: ${convertUnit(25)};
`

const StyledError = styled(Paragraph)`
  margin-top: ${convertUnit(25)};
  text-align: center;
`

const StyledRegister = styled(Paragraph)`
  margin-left: ${convertUnit(9)};
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledIconContainer = styled.div`
  padding: ${convertUnit(20)} 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${convertUnit(12)};
`

const StyledSSOButtonContainer = styled.div`
  display: flex;
  height: fit-content;
  gap: ${convertUnit(20)};
  margin-top: ${convertUnit(25)};
  flex-direction: row;
  flex: 1;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    flex-direction: column;
    height: auto;
  }
`

const StyledSupportContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-top: ${convertUnit(20)};
  margin-right: ${convertUnit(20)};
  gap: ${convertUnit(4)};
`

const StyledSupportIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${convertUnit(6)};
`

const StyledLink = styled.a`
  text-decoration: none;
`

const StyledBackIconCursor = styled.div`
  cursor: pointer;
  width: fit-content;
`

export default function AuthLoginForm() {
  const {translate} = useTranslation()
  const location = useLocation('auth_login')
  const {next} = location.query
  const [index, setIndex] = useState<'form' | 'password'>('form')
  const hidePasswordField = useMemo(() => index === 'form', [index])
  const form = useForm<AuthFormLoginData>({
    defaultValues: {account: '', password: ''},
  })
  const {handleSubmit, reset, clearErrors, watch} = form
  const values = watch()
  const history = useHistory('auth_login')
  const {update} = useDispatch()
  const {account, password} = values
  const [errorMessage, setErrorMessage] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [waitingModal, setWaitingModal] = useState(false)
  const [reapplyModal, setReapplyModal] = useState(false)

  const handleRenderWefieWaitingModal = useMemo(
    () => (
      <AuthLoginWefieWaitingModal
        visible={waitingModal}
        toggleModal={() => setWaitingModal(false)}
      />
    ),
    [waitingModal],
  )

  const handleRenderWefieReapplyModal = useMemo(
    () => (
      <AuthLoginWefieReapplyModal
        visible={reapplyModal}
        toggleModal={() => setReapplyModal(false)}
      />
    ),
    [reapplyModal],
  )

  const handleCheckLastUpdate = useCallback(
    (id: string) => {
      const getLastExplore = localStorage.getItem('last_explore_time')
      firebase
        .firestore()
        .collection(process.env.FIRESTORE_COLLECTION_CONTENT!)
        .doc(id)
        .get({
          source: 'server',
        })
        .then((response) => {
          const responseData = response.data() as FirestoreExploreRefreshPayload
          if (responseData) {
            if (Number(getLastExplore) < responseData.explore_last_update) {
              localStorage.setItem(
                'last_explore_time',
                String(responseData.explore_last_update),
              )
              history.push('giftshop_explore', {})
            }
          }
        })
    },
    [history],
  )

  const handleResendVerifLink = useCallback(
    (id: string) => {
      const phoneData = getPhoneData(id)
      requestData('auth_easy_signup_resend', {
        data: {
          identifier: account,
        },
        onRequestSuccess: ({status, data}) => {
          if (status === 200) {
            update(
              'lastUserState',
              phoneData
                ? {
                    phoneOTPTime: new Date().getTime(),
                  }
                : {
                    emailTime: new Date().getTime(),
                  },
            )
            history.push('auth_easy_signup_verification', {
              method: phoneData ? 'whatsapp' : 'email',
              email: phoneData ? '' : id,
              phone_code: '62',
              phone_number: phoneData ? id.substring(2) : '',
              identifier: account,
            })
          } else if (status === 429) {
            const errorDetail = data.result
            const {hour, minute, second} = formatCooldownTime(
              errorDetail.resend_in,
            )
            showSnackbar(
              translate(
                errorDetail.is_limit
                  ? 'auth:registerEasySignupResendLinkLimitSnackbar'
                  : 'auth:registerEasySignupResendLinkCooldownSnackbar',
                {
                  hour,
                  minute,
                  second,
                },
              )
                .replace(
                  hour === ''
                    ? /<hourStamp>.*?<\/hourStamp>/
                    : /<hourStamp>|<\/hourStamp>/g,
                  '',
                )
                .replace(
                  minute === ''
                    ? /<minuteStamp>.*?<\/minuteStamp>/
                    : /<minuteStamp>|<\/minuteStamp>/g,
                  '',
                )
                .replace(/<secondStamp>|<\/secondStamp>/g, ''),
              [
                {
                  pattern: /<timestamp>.*?<\/timestamp>/,
                  style: {
                    fontFamily: 'Roboto-Bold',
                  },
                  renderText: (text) =>
                    text.replace(/<timestamp>|<\/timestamp>/g, ''),
                },
              ],
            )
          } else {
            showSnackbar(translate('global:messageError', {context: status}))
          }
        },
      })
    },
    [account, history, translate, update],
  )

  const handleShowPasswordField = useCallback(() => {
    setIndex('password')
  }, [setIndex])

  const handleLogin = useCallback(() => {
    setLoading(true)
    if (!hidePasswordField) {
      requestData('auth_login', {
        actionType: 'execute',
        data: {identifier: account, password},
        onRequestReceived: () => {
          clearErrors()
          setLoading(false)
        },
        onRequestSuccess: ({status, data: {result, detail, message}}) => {
          if (status === 200) {
            if (next) {
              if (history.length > 1) {
                history.goBack()
              } else {
                window.location.replace(decodeURIComponent(next))
              }
              return
            }
            handleCheckLastUpdate(result.id)
            return
          }
          if (status === 403 && message === 'Underage') {
            return history.push('auth_login_underage', {})
          }
          if (status === 202) {
            const response = result as AuthLoginUnverifiedResponse
            if (response.child_account_status === 'unregistered') {
              history.push('auth_login_wefie', {})
            } else if (response.child_account_status === 'processing') {
              setWaitingModal(true)
            } else if (response.child_account_status === 'rejected') {
              setReapplyModal(true)
            }
            return
          }
          reset({account, password})
          if (
            status === 400 &&
            typeof detail !== 'undefined' &&
            detail.error === 'password does not exist'
          ) {
            setErrorMessage(
              translate('auth:validationLoginNoPasswordMissmatch'),
            )
            return
          }
          status === 400 && typeof detail !== 'undefined'
            ? setErrorMessage(
                translate('auth:validationLoginAttempt', {
                  attempt: AUTH_LOGIN_MAX_ATTEMPT - Number(detail.attempt),
                }),
              )
            : setErrorMessage(
                translate(
                  status === 400 || status === 401 || status === 404
                    ? 'auth:validationLoginMissMatch'
                    : 'global:messageError',
                ),
              )
        },
      })
      return
    }
    requestData('auth_login_check_account_status', {
      data: {
        identifier: account,
      },
      onRequestReceived: () => {
        clearErrors()
        setLoading(false)
      },
      onRequestSuccess: ({status, data}) => {
        if (status === 200) {
          const {has_password, is_verified, identifer} = data.result
          if (is_verified && has_password) {
            handleShowPasswordField()
          } else if (identifer) {
            handleResendVerifLink(identifer)
          }
          return
        }
        if (status === 202) {
          const response = data.result as AuthLoginUnverifiedResponse
          if (response.signup_method === 'email' && response.email) {
            if (response.is_expired) {
              response.email &&
                history.push('auth_verification_email', {
                  email: response.email,
                  isExpired: response.is_expired,
                })
            } else {
              showSnackbar(translate('auth:unverifiedEmailSnackbar'))
            }
          } else if (
            (response.signup_method === 'phone' ||
              response.signup_method === 'whatsapp') &&
            response.phone_code &&
            response.phone_number
          ) {
            history.push('auth_verification_phone', {
              phoneCode: response.phone_code,
              phoneNumber: response.phone_number,
              isExpired: response.is_expired,
              method: response.signup_method,
            })
          } else if (response.child_account_status === 'unregistered') {
            history.push('auth_login_wefie', {})
          } else if (response.child_account_status === 'processing') {
            setWaitingModal(true)
          } else if (response.child_account_status === 'rejected') {
            setReapplyModal(true)
          }
          return
        }
        if (!(status === 403 || status === 423)) {
          setErrorMessage(
            translate(
              status === 400 || status === 401 || status === 404
                ? 'auth:validationLoginMissMatch'
                : 'global:messageError',
            ),
          )
        }
      },
    })
  }, [
    hidePasswordField,
    account,
    password,
    clearErrors,
    reset,
    translate,
    next,
    handleCheckLastUpdate,
    history,
    handleShowPasswordField,
    handleResendVerifLink,
  ])

  const handleClickEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && !(account === '' || password === '')) {
        handleLogin()
      }
    },
    [account, handleLogin, password],
  )

  const handleRenderError = useMemo(
    () =>
      errorMessage && (
        <StyledError
          color="danger_5"
          fontWeight="medium"
          data-cy="AuthLoginFormErrorMessage">
          {errorMessage}
        </StyledError>
      ),
    [errorMessage],
  )

  const handleRenderButtonLogin = useMemo(
    () => (
      <StyledButtonLogin
        disabled={account === '' || password === ''}
        isLoading={loading}
        label={translate(hidePasswordField ? 'global:next' : 'global:login')}
        onClick={handleSubmit(handleLogin)}
        data-cy="AuthLoginFormLoginButton"
      />
    ),
    [
      account,
      password,
      loading,
      translate,
      hidePasswordField,
      handleSubmit,
      handleLogin,
    ],
  )

  return (
    <>
      {handleRenderWefieWaitingModal}
      {handleRenderWefieReapplyModal}
      <StyledContainer>
        <StyledIconContainer>
          <StyledBackIconCursor
            onClick={() =>
              index === 'password' ? setIndex('form') : history.goBack()
            }>
            <Icon type="back" size={convertUnit(20)} color="primary_5" />
          </StyledBackIconCursor>
          <LanguageToggle />
        </StyledIconContainer>
        <StyledContentContainer>
          <StyledParagraph fontSize="xl" fontWeight="bold">
            {translate('global:login')}
          </StyledParagraph>
          <StyledSectionRegister>
            <Paragraph fontSize="m" color="gray_3">
              {translate('auth:loginNotHaveAccount')}
            </Paragraph>
            <Link to="auth_easy_signup">
              <StyledRegister
                fontSize="m"
                fontWeight="medium"
                className="pressable"
                color="primary_5">
                {translate('global:register')}
              </StyledRegister>
            </Link>
          </StyledSectionRegister>
          <Input
            name="account"
            form={form}
            disabled={!hidePasswordField}
            formRules={VALIDATION_AUTH_LOGIN_ACCOUNT}
            onChangeText={() => setErrorMessage('')}
            label={translate('auth:loginInputAccountLabel')}
            placeholder={translate('auth:loginInputAccountPlaceholder')}
            containerStyle={{marginTop: convertUnit(25)}}
            rightIcon={hidePasswordField ? 'delete' : undefined}
            onKeyPress={handleClickEnter}
            data-cy="AuthLoginFormAccountInput"
          />
          {hidePasswordField ? (
            <></>
          ) : (
            <>
              <Input
                name="password"
                type="password"
                form={form}
                formRules={VALIDATION_AUTH_LOGIN_PASSWORD}
                label={translate('auth:loginInputPasswordLabel')}
                placeholder={translate('auth:loginInputPasswordPlaceholder')}
                containerStyle={{marginTop: convertUnit(25)}}
                rightIcon="visibility"
                onKeyPress={handleClickEnter}
                data-cy="AuthLoginFormPasswordInput"
              />
              <StyledForgotPassword
                className="pressable"
                fontWeight="medium"
                color="primary_5"
                onClick={() => history.push('auth_forgot_password', {})}
                data-cy="AuthLoginFormForgotPasswordLink">
                {translate('auth:loginForgotPassword')}
              </StyledForgotPassword>
            </>
          )}
          {handleRenderError}
          {handleRenderButtonLogin}
          <StyledSSOButtonContainer>
            <AuthLoginGoogle label={translate('auth:loginButtonGoogleTitle')} />
            <AuthLoginApple label={translate('auth:loginButtonAppleTitle')} />
          </StyledSSOButtonContainer>
          <StyledSupportContainer>
            <Paragraph fontWeight="medium" color="gray_5">
              {translate('global:needHelp')}
            </Paragraph>
            <StyledSupportIconsContainer>
              <StyledLink href={getWhatsAppLink()} target="_blank">
                <Icon type="whatsapp-ol" color="primary_5" size={20} />
              </StyledLink>
              <StyledLink href={getInstagramLink()} target="_blank">
                <Icon type="instagram-ol" color="primary_5" size={18} />
              </StyledLink>
            </StyledSupportIconsContainer>
          </StyledSupportContainer>
        </StyledContentContainer>
      </StyledContainer>
    </>
  )
}
