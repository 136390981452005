import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  SERVICE_CANCELLATION_SEARCH_FOTOTREE,
  SERVICE_CANCELLED_RESPONSE,
  TREE_MAX_BIO_CHARACTER,
  TREE_MAX_NAME_CHARACTER,
} from 'consts'
import {useTranslation} from 'i18n'
import {fototreeMaxEventDate, getBorder, showSnackbar, useDebounce} from 'utils'
import {
  Input,
  InputArea,
  Label,
  ListLazy,
  Menu,
  Paragraph,
} from 'common/components'
import {
  VALIDATION_TREE_PLANT_FOTOTREE_BIO,
  VALIDATION_TREE_PLANT_FOTOTREE_EVENT_DATE,
  VALIDATION_TREE_PLANT_FOTOTREE_NAME,
} from 'common/validations'
import convertUnit from 'lib/unit'
import {requestData} from 'services'
import {TreeSearchFototreeResponse} from 'types'
import {TreeFototreeTemplateListTree} from '../../../template'
import {TreePlantTreeUppperFormProps} from './TreePlantTreeFormProps'

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledContainer = styled(StyledColumn)`
  align-self: center;
  box-sizing: border-box;
`

const StyledContainerItem = styled.div`
  margin-top: ${convertUnit(20)};
`

const StyledEventDateContainer = styled.div`
  display: flex;
  gap: ${convertUnit(16)};
`

const StyledSubtitle = styled(Paragraph)`
  padding: ${convertUnit(4)} 0;
`

const StyledListHeader = styled(Paragraph)`
  padding: ${convertUnit(8)} ${convertUnit(16)};
  border-bottom: ${({theme}) => getBorder(1, 'solid', theme.white_4)};
`

export default function TreePlantTreeUpperForm({
  form,
}: TreePlantTreeUppperFormProps) {
  const debounce = useDebounce()
  const {translate} = useTranslation()
  const {watch, setValue} = form
  const {fototreeBio, fotoTreeCategory, eventStartDate} = watch()
  const [search, setSearch] = useState('')
  const stateToggle = useState(false)
  const setToggle = stateToggle[1]

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('tree_search_fototree', {
        useDefaultMessage: true,
        cancelId: SERVICE_CANCELLATION_SEARCH_FOTOTREE,
        params: {name: search, page, limit},
        actionType: 'fetch',
      })

      if (
        typeof response === 'string' &&
        response === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof response !== 'string' && response.status === 200
        ? response.data.result
        : []
    },
    [search],
  )

  const handleInputBio = useCallback(
    (text: string) => {
      if (fototreeBio.length >= TREE_MAX_BIO_CHARACTER) {
        showSnackbar(
          translate('giftShop:bioMaxMessage', {count: TREE_MAX_BIO_CHARACTER}),
        )
      } else setValue('fototreeBio', text)
    },
    [fototreeBio.length, setValue, translate],
  )

  const handleRenderItem = useCallback(
    (item: TreeSearchFototreeResponse) => (
      <TreeFototreeTemplateListTree data={item} isFindSimilar />
    ),
    [],
  )

  const handleRenderSimilarTree = useMemo(
    () => (
      <ListLazy
        keyExtractor={(item) => item.id}
        loadData={handleLoadData}
        search={search}
        listHeaderElement={
          <StyledListHeader fontWeight="bold">
            {translate('tree:similarFototreeName')}
          </StyledListHeader>
        }
        renderItem={handleRenderItem}
      />
    ),
    [handleLoadData, handleRenderItem, search, translate],
  )

  const handleRenderEventDateStart = useMemo(
    () => (
      <StyledColumn>
        <StyledSubtitle fontWeight="medium" color="gray_6">
          {translate('tree:eventDateStart')}
        </StyledSubtitle>
        <Input
          name="eventStartDate"
          form={form}
          type="date"
          onChangeText={(text) => {
            setValue('eventStartDate', text)
            setValue('eventEndDate', text)
          }}
        />
      </StyledColumn>
    ),
    [form, setValue, translate],
  )

  const handleRenderEventDateEnd = useMemo(
    () => (
      <StyledColumn>
        <StyledSubtitle fontWeight="medium" color="gray_6">
          {translate('tree:eventDateEnd')}
        </StyledSubtitle>
        <Input
          name="eventEndDate"
          form={form}
          type="date"
          min={eventStartDate}
          max={
            eventStartDate ? fototreeMaxEventDate(eventStartDate) : undefined
          }
          disabled={!eventStartDate}
          formRules={
            eventStartDate
              ? VALIDATION_TREE_PLANT_FOTOTREE_EVENT_DATE(eventStartDate)
              : undefined
          }
          onChangeText={(text) => setValue('eventEndDate', text)}
        />
      </StyledColumn>
    ),
    [eventStartDate, form, setValue, translate],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledContainer>
        <StyledContainerItem>
          <Menu
            stateToggle={stateToggle}
            contentContainerStyle={{
              height: convertUnit(155),
              borderRadius: convertUnit(8),
            }}
            renderContent={handleRenderSimilarTree}>
            <Input
              name="fototreeName"
              form={form}
              formRules={VALIDATION_TREE_PLANT_FOTOTREE_NAME}
              maxLength={TREE_MAX_NAME_CHARACTER}
              label={translate('tree:name')}
              placeholder={translate('tree:namePlaceholder')}
              onChangeText={(text) => {
                setValue('fototreeName', text)
                setToggle(true)
                debounce(() => setSearch(text), 500)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') setToggle(false)
              }}
            />
          </Menu>
        </StyledContainerItem>
        <StyledContainerItem>
          <InputArea
            name="fototreeBio"
            form={form}
            formRules={VALIDATION_TREE_PLANT_FOTOTREE_BIO}
            placeholder={translate('tree:bioPlaceholder')}
            label={translate('tree:bio')}
            labelDescription={translate('global:charCount', {
              count: fototreeBio.length,
              max: TREE_MAX_BIO_CHARACTER,
            })}
            labelDescriptionColor={
              fototreeBio.length === TREE_MAX_BIO_CHARACTER
                ? 'danger_5'
                : undefined
            }
            inputStyle={{
              height: convertUnit(89),
              width: '100%',
            }}
            onChangeText={handleInputBio}
            maxLength={TREE_MAX_BIO_CHARACTER}
          />
        </StyledContainerItem>
        {fotoTreeCategory && fotoTreeCategory.name === 'event' && (
          <StyledContainerItem>
            <Label label={translate('tree:eventDate')} />
            <StyledEventDateContainer>
              {handleRenderEventDateStart}
              {handleRenderEventDateEnd}
            </StyledEventDateContainer>
          </StyledContainerItem>
        )}
      </StyledContainer>
    ),
    [
      debounce,
      form,
      fotoTreeCategory,
      fototreeBio.length,
      handleInputBio,
      handleRenderEventDateEnd,
      handleRenderEventDateStart,
      handleRenderSimilarTree,
      setToggle,
      setValue,
      stateToggle,
      translate,
    ],
  )

  return <>{handleRenderContent}</>
}
