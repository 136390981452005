import React, {useMemo, useRef, useState} from 'react'
import AvatarEditor from 'react-avatar-editor'
import styled from 'styled-components'
import {HOST_IMAGE_TYPE, IMAGE_ASSET, WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {cropImage, useDidMount, useTreeImageFile} from 'utils'
import {Button, Icon, Image, ImageCropper, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateProfilePhotoModal} from 'pages/template'
import {TreeEditProfileImageProps} from './TreeEditFormProps'

interface StyledProfilePictureProps {
  isPressable: boolean
}

const StyledProfileContainer = styled.div`
  display: flex;
`

const StyledButtonContainer = styled.div`
  margin-top: ${convertUnit(20)};
`

const StyledButtonUpload = styled(Button)`
  height: ${convertUnit(32)};
  max-width: ${convertUnit(200)};
  margin-bottom: ${convertUnit(8)};
`

const StyledProfilePicture = styled(Image)<StyledProfilePictureProps>`
  aspect-ratio: 0.75;
  width: ${convertUnit(108)};
  border-radius: ${convertUnit(8)};
  object-fit: contain;
  cursor: ${({isPressable}) => (isPressable ? 'pointer' : 'default')};
`

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-left: ${convertUnit(40)};

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    margin-left: ${convertUnit(20)};
  }
`

const StyledImageUpload = styled.input`
  display: none;
`

export default function TreeEditProfileImage({
  stateLoading,
  stateImageBroken,
  treeInfo,
  imageForm,
  onSaveChanges,
}: TreeEditProfileImageProps) {
  const defaultImage = IMAGE_ASSET('tree', 'default.png')

  const {translate} = useTranslation()
  const inputImageRef = useRef<HTMLInputElement>(null)
  const canvas = useRef<AvatarEditor>(null)
  const stateCrop = useState(false)
  const stateTempImage = useState<File>()
  const [crop, setCrop] = stateCrop
  const [tempImage, setTempImage] = stateTempImage
  const [isImageBroken, setImageBroken] = stateImageBroken
  const loading = stateLoading[0]
  const [previewModal, setPreviewModal] = useState(false)

  const {register: registerImg, watch: watchImg} = imageForm

  const imgUrl = watchImg('imgUrl', treeInfo.url)

  const {handleCheckImage, handleCrop} = useTreeImageFile({
    ref: inputImageRef,
    form: imageForm,
    stateCrop,
    stateTempImage,
    stateLoading,
    onSaveChanges,
  })

  const handleRenderCropperModal = useMemo(
    () =>
      tempImage ? (
        <ImageCropper
          shape="portrait"
          borderRadius={0}
          visible={crop}
          componentRef={canvas}
          file={tempImage}
          onBackdropPress={() => {
            setTempImage(undefined)
            setCrop(false)
          }}
          onClickSave={() =>
            !loading && cropImage(canvas, 'fototree-profile', handleCrop)
          }
        />
      ) : null,
    [crop, handleCrop, loading, setCrop, setTempImage, tempImage],
  )

  const cameraIcon = useMemo(
    () => (
      <Icon
        type="camera"
        color="white_1"
        size={16}
        marginRight={convertUnit(12)}
      />
    ),
    [],
  )

  const handleRenderImageModal = useMemo(
    () =>
      previewModal && (
        <TemplateProfilePhotoModal
          src={imgUrl}
          title={translate('tree:profilePicture')}
          toggleModal={() => setPreviewModal((prev) => !prev)}
        />
      ),
    [imgUrl, previewModal, translate],
  )

  const handleRenderImage = useMemo(
    () => (
      <StyledProfileContainer>
        <StyledProfilePicture
          useLazyLoad
          src={imgUrl}
          fallbackSrc={defaultImage}
          alt="tree-profile-picture"
          isPressable={!isImageBroken}
          onClick={() => !isImageBroken && setPreviewModal(true)}
          onError={() => setImageBroken(true)}
        />
        <StyledDescriptionContainer>
          <div>
            <Paragraph color="gray_5" fontSize="m">
              {translate('tree:name')}
            </Paragraph>
            <Paragraph fontWeight="medium" fontSize="l" lineHeight={22}>
              {treeInfo.name}
            </Paragraph>
          </div>
          <StyledButtonContainer>
            <StyledButtonUpload
              fontSize="s"
              label={translate('tree:changePhotoButton')}
              leftIcon={cameraIcon}
              onClick={() => {
                inputImageRef.current && inputImageRef.current.click()
              }}
            />
            <Paragraph color="gray_3">
              {translate('tree:uploadRequirement')}
            </Paragraph>
          </StyledButtonContainer>
        </StyledDescriptionContainer>
      </StyledProfileContainer>
    ),
    [
      cameraIcon,
      defaultImage,
      imgUrl,
      isImageBroken,
      setImageBroken,
      translate,
      treeInfo.name,
    ],
  )

  useDidMount(() => {
    registerImg('imgFile')
    registerImg('imgExtension')
    registerImg('imgHeight')
    registerImg('imgSize')
    registerImg('imgWidth')
    registerImg('imgMime')
  })

  return (
    <>
      {handleRenderCropperModal}
      {handleRenderImageModal}
      {handleRenderImage}
      <StyledImageUpload
        type="file"
        ref={inputImageRef}
        accept={HOST_IMAGE_TYPE.join(',')}
        onChange={handleCheckImage}
        onClick={() => {
          inputImageRef.current && (inputImageRef.current.value = '')
        }}
      />
    </>
  )
}
