import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {FirebaseMessageRoomItemPayload, GiftShopContentData} from 'types'
import {showSnackbar, useDidMount, useHistory} from 'utils'
import {Button, Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import GiftShopMessageRoomContentList from './GiftShopMessageRoomContentItemList'
import {GiftShopMessageCreatorRatingModal} from '../CreatorRatingModal'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(16)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledFilledRatingContainer = styled(StyledRowContainer)`
  gap: ${convertUnit(4)};
`

export interface GiftShopMessageRoomContentItemNotificationPurchaseCompletedReviewProps {
  payload: FirebaseMessageRoomItemPayload['notification_purchase_completed_review']
  isSelf?: boolean
}

export default function GiftShopMessageRoomContentItemNotificationPurchaseCompletedReview({
  payload,
  isSelf,
}: GiftShopMessageRoomContentItemNotificationPurchaseCompletedReviewProps) {
  const {translate} = useTranslation()
  const [data, setData] = useState<GiftShopContentData[]>([])
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const history = useHistory()
  const {
    creator_id,
    contents,
    creator_profile_url,
    creator_username,
    invoice_id,
    username,
    creator_verified_status,
  } = payload

  const [rating, setRating] = useState<number>()
  const arr = useMemo(() => [1, 2, 3, 4, 5], [])

  const handleCheckReviewed = useCallback(() => {
    requestData('giftshop_post_creator_rating_check', {
      actionType: 'execute',
      data: {creator_id, invoice_id},
      onRequestSuccess: ({status, data: {result}}) => {
        if (status === 200 && result.rating) setRating(result.rating)
      },
    })
  }, [creator_id, invoice_id])

  const handleLoadData = useCallback(
    () =>
      contents &&
      requestData('giftshop_get_contents_detail', {
        params: {content_ids: contents.toString()},
        onRequestSuccess: ({status, data: {result}}) => {
          status === 200 && setData(result)
          setLoading(false)
        },
      }),
    [contents],
  )

  const handlePostRating = useCallback(
    (ratingInput: number) => {
      requestData('giftshop_post_creator_rating', {
        actionType: 'execute',
        data: {creator_id, invoice_id, rating: ratingInput},
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            showSnackbar(translate('messages:creatorRatingSubmitSuccess'))
            setRating(ratingInput)
            setVisible((prev) => !prev)
          } else {
            showSnackbar(translate('messages:creatorRatingSubmitFailed'))
          }
        },
      })
    },
    [creator_id, invoice_id, translate],
  )

  const handleRenderReviewModal = useMemo(
    () => (
      <GiftShopMessageCreatorRatingModal
        isVerified={creator_verified_status === 'verified'}
        photo={creator_profile_url}
        username={creator_username}
        visible={visible}
        onSubmit={handlePostRating}
        toggleModal={() => setVisible((prev) => !prev)}
      />
    ),
    [
      creator_profile_url,
      creator_username,
      creator_verified_status,
      handlePostRating,
      visible,
    ],
  )

  const handleRenderUpper = useMemo(
    () => (
      <>
        <Paragraph fontSize="m">
          {translate('messages:notificationPurchaseReviewOpening', {
            username,
          })}
        </Paragraph>
        <br />
        <Paragraph fontSize="m">
          {translate('messages:notificationPurchaseReviewInfo')}
        </Paragraph>
      </>
    ),
    [translate, username],
  )

  const handleRenderContent = useMemo(
    () => (
      <GiftShopMessageRoomContentList
        data={data}
        onClick={(item) =>
          history.push('giftshop_collection_detail', {
            data,
            selectedItemId: item.content_id,
            limitContent: true,
          })
        }
        dataCount={contents.length}
        loading={loading}
      />
    ),
    [contents.length, data, history, loading],
  )

  const handleRenderRatingQuestion = useMemo(
    () => (
      <Paragraph>
        {translate('messages:notificationPurchaseReviewRatingQuestion', {
          creator_username,
        })}
      </Paragraph>
    ),
    [creator_username, translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        label={translate('messages:notificationPurchaseReviewButton')}
        onClick={() => setVisible((prev) => !prev)}
      />
    ),
    [translate],
  )

  const handleRenderRating = useMemo(
    () =>
      rating && (
        <StyledFilledRatingContainer>
          <Paragraph>
            {translate('messages:notificationPurchaseReviewRatingFilled')}
          </Paragraph>
          <StyledRowContainer>
            {arr.map((item, idx) => (
              <Icon
                key={`star-${item}`}
                type={idx < rating ? 'star-solid' : 'star-ol'}
                size={convertUnit(16)}
                color={idx < rating ? 'success_5' : 'gray_3'}
              />
            ))}
          </StyledRowContainer>
        </StyledFilledRatingContainer>
      ),
    [arr, rating, translate],
  )

  const handleRenderRatingSection = useMemo(
    () =>
      rating ? (
        handleRenderRating
      ) : (
        <>
          {handleRenderRatingQuestion}
          {handleRenderButton}
        </>
      ),

    [
      handleRenderButton,
      handleRenderRating,
      handleRenderRatingQuestion,
      rating,
    ],
  )

  useDidMount(() => {
    handleLoadData()
    handleCheckReviewed()
  })

  return (
    <StyledContainer>
      {handleRenderReviewModal}
      {handleRenderUpper}
      {handleRenderContent}
      {!isSelf && handleRenderRatingSection}
    </StyledContainer>
  )
}
