import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {requestData} from 'services'
import {formatExploreFilterDate, showSnackbar, useHistory} from 'utils'
import {GiftShopContentData} from 'types'
import {useTranslation} from 'i18n'
import {IMAGE_ASSET, WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {Button, ButtonOutline, Icon, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {GiftShopTemplateMasonry} from '../../../template'
import {GiftShopRootEmpty} from '../../../root'
import {GiftShopExploreTabView} from '../ExploreTabView'
import {GiftShopExploreContentProps} from './GiftShopExploreContentProps'

interface StyledButtonsProps {
  loading: boolean
}

const StyledMasonryContainer = styled.div`
  position: relative;
  height: 100%;
  flex: '1 1 auto';
`

const StyledButtonContainer = styled.div`
  margin-top: ${convertUnit(20)};
`

const StyledSelectBulkOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${convertUnit(12)} ${convertUnit(20)};
  gap: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    justify-content: space-between;
  }
`

const StyledSelectBulkOptionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(12)};
`

const StyledButtonOutline = styled(ButtonOutline)<StyledButtonsProps>`
  height: ${convertUnit(30)};
  cursor: ${({loading}) => (loading ? 'progress' : 'pointer')};
`

const StyledYesButton = styled(Button)<StyledButtonsProps>`
  height: ${convertUnit(30)};
  cursor: ${({loading}) => (loading ? 'progress' : 'pointer')};
`

export default function GiftShopExploreContent({
  isFilter,
  stateSelect,
  stateSelectMode,
  stateLoading,
  stateData,
  disableBulkAction,
  onClearFilter,
  hideSelfieButton,
}: GiftShopExploreContentProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const [data, setData] = stateData
  const emptyImage = IMAGE_ASSET(
    'giftshop',
    isFilter
      ? 'roboyu-filter.png'
      : hideSelfieButton
      ? 'robopet-confused.png'
      : 'robopet-offer.png',
  )
  const filter = useSelector('exploreFilter')
  const [select, setSelect] = stateSelect
  const selectIds = useMemo(() => select.map((item) => item.content_id), [
    select,
  ])
  const [selectMode, setSelectMode] = stateSelectMode
  const [loading, setLoading] = stateLoading
  const {sensitivityLevel} = useSelector('roboyuSensitivityLevel')
  const handleTabViewVisible = useMemo(() => data.length !== 0 && !isFilter, [
    data.length,
    isFilter,
  ])

  const handleNavigateToItem = useCallback(
    (item: GiftShopContentData) => {
      history.push('giftshop_explore_detail', {
        data: [...data],
        selectedItemId: item.content_id,
        isFilter,
      })
    },
    [data, history, isFilter],
  )

  const handleClickItem = useCallback(
    (item: GiftShopContentData) =>
      loading
        ? undefined
        : selectMode
        ? setSelect((prev) =>
            prev.includes(item)
              ? prev.filter((val) => val.content_id !== item.content_id)
              : [...prev, item],
          )
        : handleNavigateToItem(item),
    [handleNavigateToItem, loading, selectMode, setSelect],
  )

  const handleCancelSelect = useCallback(() => {
    setSelect([])
    setSelectMode(false)
  }, [setSelect, setSelectMode])

  const handleBulkConfirm = useCallback(() => {
    setLoading(true)
    const isAllConfirmed = !select.filter((item) => item.type === 'is_this_you')
      .length
    if (isAllConfirmed) {
      setLoading(false)
      showSnackbar(translate('giftShop:isThisYouBulkConfirmSuccess'))
      handleCancelSelect()
    } else {
      requestData('giftshop_post_wishlist', {
        actionType: 'execute',
        useDefaultMessage: true,
        data: {content_ids: selectIds},
        onRequestReceived: () => setLoading(false),
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setData((prev) =>
              prev.map((item) => {
                if (selectIds.includes(item.content_id))
                  return {...item, is_wishlist: true, type: 'fotoyu'}
                return item
              }),
            )
            showSnackbar(translate('giftShop:isThisYouBulkConfirmSuccess'))
            handleCancelSelect()
          }
        },
      })
    }
  }, [handleCancelSelect, select, selectIds, setData, setLoading, translate])

  const handleBulkNope = useCallback(() => {
    setLoading(true)
    requestData('giftshop_delete_explore', {
      actionType: 'execute',
      useDefaultMessage: true,
      data: {content_ids: selectIds},
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          setData((prev) =>
            prev.filter((item) => !selectIds.includes(item.content_id)),
          )
          showSnackbar(translate('giftShop:isThisYouBulkConfirmSuccess'))
          handleCancelSelect()
        }
      },
    })
  }, [handleCancelSelect, selectIds, setData, setLoading, translate])

  const handleRenderSelectBulkOption = useMemo(
    () => (
      <StyledSelectBulkOptionContainer>
        <Paragraph fontSize="m" fontWeight="bold">
          {translate('giftShop:isThisYou')}
        </Paragraph>
        <StyledSelectBulkOptionButtonContainer>
          <StyledButtonOutline
            label={translate('giftShop:fotoyuIsNotLabel')}
            color="gray_5"
            borderColor="gray_5"
            borderWidth={2}
            disabled={disableBulkAction}
            disabledBorderColor="gray_2"
            loading={loading}
            leftIcon={
              <Icon
                type="not-interested"
                size={16}
                color={disableBulkAction ? 'gray_2' : 'gray_5'}
                style={{marginRight: convertUnit(9)}}
              />
            }
            onClick={handleBulkNope}
          />
          <StyledYesButton
            label={translate('giftShop:fotoyuIsYesLabel')}
            disabled={disableBulkAction}
            loading={loading}
            leftIcon={
              <Icon
                type="check-custom"
                size={16}
                color={disableBulkAction ? 'gray_3' : 'white_1'}
                style={{marginRight: convertUnit(9)}}
              />
            }
            onClick={handleBulkConfirm}
          />
        </StyledSelectBulkOptionButtonContainer>
      </StyledSelectBulkOptionContainer>
    ),
    [disableBulkAction, handleBulkConfirm, handleBulkNope, loading, translate],
  )

  const handleTabView = useMemo(
    () =>
      handleTabViewVisible ? (
        selectMode ? (
          handleRenderSelectBulkOption
        ) : (
          <GiftShopExploreTabView selectedTab="all" />
        )
      ) : (
        <></>
      ),
    [handleRenderSelectBulkOption, handleTabViewVisible, selectMode],
  )

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const {
        startDate,
        endDate,
        latitude,
        longitude,
        tags,
        creators,
        filterSensitivity,
      } = filter
      const {startTimestamp, endTimestamp} = formatExploreFilterDate(
        startDate,
        endDate,
      )
      const tagIds = tags.map((item) => item.id)
      const lat = parseFloat(latitude)
      const long = parseFloat(longitude)
      const creatorIds = creators
        ? creators.map((creator) => creator.creator_id)
        : []
      const response = await requestData('giftshop_post_explore', {
        actionType: 'execute',
        data: {
          tags: tagIds,
          page,
          limit,
          is_filter: isFilter,
          latitude: lat,
          longitude: long,
          type: 'all',
          from: startTimestamp,
          to: endTimestamp,
          creator_ids: creatorIds,
          sensitivity_level: isFilter ? filterSensitivity : sensitivityLevel,
        },
      })

      if (typeof response !== 'string' && response.status === 200) {
        const {result} = response.data
        localStorage.setItem(
          'last_explore_time',
          String(new Date().getTime() / 1000),
        )
        if (result.length < limit && (page !== 1 || result.length !== 0))
          showSnackbar(translate('giftShop:fotoyuFinishedLoadingSnackbar'))
        return result.filter((item) => item.is_reported !== true)
      }

      return []
    },
    [filter, isFilter, sensitivityLevel, translate],
  )

  const handleClearFilterButton = useMemo(
    () =>
      isFilter && (
        <StyledButtonContainer>
          <Button
            onClick={onClearFilter}
            label={translate('giftShop:isThisYouFilterEmptyButton')}
          />
        </StyledButtonContainer>
      ),
    [isFilter, onClearFilter, translate],
  )

  const handleRenderAddSelfie = useMemo(
    () =>
      !isFilter &&
      !hideSelfieButton && (
        <StyledButtonContainer>
          <Button
            onClick={() => history.push('giftshop_robopet_retake', {})}
            label={translate('giftShop:retakeKYC')}
            leftIcon={<Icon type="face-scan" color="white_1" />}
          />
        </StyledButtonContainer>
      ),
    [hideSelfieButton, history, isFilter, translate],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <GiftShopRootEmpty
        image={emptyImage}
        imageAlt={emptyImage}
        title={
          isFilter
            ? translate('giftShop:isThisYouFilterEmptyTitle')
            : hideSelfieButton
            ? translate('giftShop:similarWaitingApprovalSubtitle')
            : translate('giftShop:exploreEmptyTitle')
        }
        iconOnTitle={!isFilter && !hideSelfieButton}
        message={
          isFilter
            ? translate('giftShop:isThisYouFilterEmptyMessage')
            : hideSelfieButton
            ? translate('giftShop:similarWaitingApprovalDesc')
            : translate('giftShop:exploreEmptySubtitle1')
                .concat('\n')
                .concat(translate('giftShop:exploreEmptySubtitle2'))
        }
        bottomElement={
          isFilter ? handleClearFilterButton : handleRenderAddSelfie
        }
      />
    ),
    [
      emptyImage,
      handleClearFilterButton,
      handleRenderAddSelfie,
      hideSelfieButton,
      isFilter,
      translate,
    ],
  )

  return (
    <>
      {handleTabView}
      <StyledMasonryContainer>
        <GiftShopTemplateMasonry
          loadData={handleLoadData}
          stateData={stateData}
          showResBadge
          search={filter.lastUpdated}
          listEmptyElement={handleRenderEmpty}
          selected={select}
          toggleSelect={selectMode}
          onClickItem={handleClickItem}
          onSelectRule={(item) => item.type !== 'collection'}
        />
      </StyledMasonryContainer>
    </>
  )
}
