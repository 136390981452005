import React, {useState, useMemo, useEffect} from 'react'
import styled from 'styled-components'
import {Modal, Paragraph, InputOTP, Button, TextTimer} from 'common/components'
import {OTP_COOLDOWN} from 'consts'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {GiftShopTemplateOTPModalProps} from './GiftShopTemplateOTPModalProps'

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledHeaderContainer = styled(StyledColumn)`
  gap: ${convertUnit(8)};
`

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: ${convertUnit(295)};
  padding: ${convertUnit(20)};
  gap: ${convertUnit(20)};
`

const StyledButtonContainer = styled.div`
  display: flex;
  gap: ${convertUnit(20)};
`

const StyledContentContainer = styled(StyledColumn)`
  align-items: center;
  gap: ${convertUnit(4)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopTemplateOTPModal({
  stateError,
  dataToVerify,
  timeRemaining,
  verificationMethod,
  onVerify,
  onResend,
  onCancel,
}: GiftShopTemplateOTPModalProps) {
  const {translate} = useTranslation()
  const [error, setError] = stateError
  const [codes, setCodes] = useState('')

  const handleRenderHeader = useMemo(
    () => (
      <StyledHeaderContainer>
        <Paragraph fontWeight="bold" fontSize="l">
          {translate('giftShop:OTPVerification')}
        </Paragraph>
        <Paragraph fontSize="m" lineHeight={28}>
          {translate('giftShop:OTPVerificationDescription', {
            number: dataToVerify,
            context: verificationMethod,
          })}
        </Paragraph>
      </StyledHeaderContainer>
    ),
    [dataToVerify, translate, verificationMethod],
  )

  const handleRenderErrorMessages = useMemo(
    () =>
      error ? (
        <>
          <Paragraph fontSize="m" fontWeight="medium" color="danger_5">
            {translate('giftShop:incorrectOTP')}
          </Paragraph>
          <Paragraph fontSize="m">
            {translate('giftShop:incorrectOTPPleaseEnterCorrect')}
          </Paragraph>
        </>
      ) : (
        <Paragraph fontSize="m">
          {translate('giftShop:didNotReceiveTheCode')}
        </Paragraph>
      ),
    [error, translate],
  )

  const handleRenderContent = useMemo(
    () => (
      <>
        <InputOTP onSubmit={(value) => setCodes(value)} />
        <StyledContentContainer>
          {handleRenderErrorMessages}
          <TextTimer
            textAvailable={translate('auth:verificationPhoneResend')}
            textCooldown="otp"
            onPress={onResend}
            lastSaved={timeRemaining}
            cooldown={OTP_COOLDOWN}
          />
        </StyledContentContainer>
      </>
    ),
    [handleRenderErrorMessages, onResend, timeRemaining, translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:cancel')}
          backgroundColor="white_2"
          backgroundHoverColor="white_3"
          color="primary_5"
          onClick={onCancel}
        />
        <StyledButton
          label={translate('global:confirm')}
          disabled={codes.length < 4}
          onClick={() => onVerify(codes)}
        />
      </StyledButtonContainer>
    ),
    [codes, onCancel, onVerify, translate],
  )

  useEffect(() => {
    if (codes === '') setError(false)
  }, [codes, setError])

  return (
    <StyledModal visible>
      {handleRenderHeader}
      {handleRenderContent}
      {handleRenderButton}
    </StyledModal>
  )
}
