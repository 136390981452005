import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Modal, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {maskPhoneZero} from 'utils'
import {GiftShopTemplateVerificationMethodItem} from '../../../template'
import {GiftShopEditProfilePhoneResendModalProps} from './GiftShopEditProfileModalProps'

const StyledModal = styled(Modal)`
  padding: ${convertUnit(20)};
  width: 100%;
  max-width: ${convertUnit(295)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${convertUnit(20)};
  text-align: center;
`

const StyledSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(8)};
`

const StyledButtonContainer = styled.div`
  display: flex;
  gap: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopEditProfilePhoneResendModal({
  stateVerificationMethod,
  onCancel,
  onSelect,
}: GiftShopEditProfilePhoneResendModalProps) {
  const {translate} = useTranslation()
  const {phoneCode, phoneNumber} = useSelector('userNewPhone') || {}
  const [verificationMethod, setVerificationMethod] = stateVerificationMethod

  const phoneMask = useMemo(
    () =>
      (phoneCode &&
        phoneNumber &&
        `0${maskPhoneZero(phoneNumber).trimLeft()}`) ||
      '',
    [phoneCode, phoneNumber],
  )

  const handleRenderWhatsappSelection = useMemo(
    () => (
      <GiftShopTemplateVerificationMethodItem
        icon="whatsapp-ol"
        text={translate('giftShop:whatsappTo')}
        info={phoneMask}
        selected={verificationMethod === 'whatsapp'}
        onClick={() => setVerificationMethod('whatsapp')}
      />
    ),
    [phoneMask, setVerificationMethod, translate, verificationMethod],
  )

  const handleRenderTextSelection = useMemo(
    () => (
      <GiftShopTemplateVerificationMethodItem
        icon="message-ol"
        text={translate('giftShop:textTo')}
        info={phoneMask}
        selected={verificationMethod === 'sms'}
        onClick={() => setVerificationMethod('sms')}
      />
    ),
    [phoneMask, setVerificationMethod, translate, verificationMethod],
  )

  const handleRenderCancelBtn = useMemo(
    () => (
      <StyledButton
        label={translate('global:cancel')}
        color="primary_5"
        backgroundColor="white_2"
        backgroundHoverColor="white_3"
        onClick={onCancel}
      />
    ),
    [onCancel, translate],
  )

  const handleRenderSelectBtn = useMemo(
    () => (
      <StyledButton
        label={translate('global:select')}
        onClick={() => onSelect(verificationMethod)}
      />
    ),
    [onSelect, translate, verificationMethod],
  )

  return (
    <StyledModal visible>
      <Paragraph fontSize="l" fontWeight="bold">
        {translate('giftShop:selectVerificationMethod')}
      </Paragraph>
      <StyledSelectionContainer>
        {handleRenderWhatsappSelection}
        {handleRenderTextSelection}
      </StyledSelectionContainer>
      <StyledButtonContainer>
        {handleRenderCancelBtn}
        {handleRenderSelectBtn}
      </StyledButtonContainer>
    </StyledModal>
  )
}
