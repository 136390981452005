import {useCallback, useMemo} from 'react'
import {requestData} from 'services'
import {REDUX_STORE} from 'lib/redux'
import {showModalMaintenance} from 'utils/components'
import {parseDate} from 'utils/dates'

export function useCheckMaintenance() {
  const handleCheckMaintenance = useCallback(async () => {
    const response = await requestData('notification_maintenance')
    const {showMaintenanceModal} = REDUX_STORE.getState().maintenanceModalStatus
    if (typeof response !== 'string' && response.status === 200) {
      const {end_date, start_date, status} = response.data.result
      if (status && showMaintenanceModal) {
        showModalMaintenance(
          status,
          parseDate(start_date, 'DD MMMM YYYY HH:mm'),
          parseDate(end_date, 'DD MMMM YYYY HH:mm'),
        )
      }
    }
  }, [])
  return useMemo(() => ({handleCheckMaintenance}), [handleCheckMaintenance])
}
