import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_SENSITIVITY_LEVEL_DEFAULT,
  GIFT_SHOP_SENSITIVITY_LEVEL_MAX,
  GIFT_SHOP_SENSITIVITY_LEVEL_MIN,
  IMAGE_ASSET,
  REGEX_MULTIPLE_NEWLINES,
  REGEX_NUMBER,
  SERVICE_CANCELLATION_SEARCH_BIB,
  SERVICE_CANCELLED_RESPONSE,
  WINDOW_MODE_MOBILE_WIDTH,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {
  GiftShopIncompleteKYCModal,
  GiftShopRetakeKYCModal,
  GiftShopTemplateMasonry,
  GiftShopTemplateModalPassword,
} from 'pages'
import {requestData} from 'services'
import {GiftShopContentData, TreeFotoTreeDetailResponse} from 'types'
import {
  getBorder,
  showModalReprocessKyc,
  showSnackbar,
  useDidMount,
  useDidUpdate,
  useHistory,
  useLocation,
  useQueryParamValue,
} from 'utils'
import {useWindowMode} from 'windows'
import {
  Button,
  Icon,
  Image,
  Input,
  InputRange,
  Paragraph,
} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TemplateProfilePhotoModal} from 'pages/template'

interface StyledSearchButtonProps {
  disabled: boolean
}

interface StyledImageProps {
  isPressable: boolean
}

interface StyledHeaderContainerProps {
  isSearch: boolean
}

const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledUpperContainer = styled(StyledColumnContainer)`
  justify-content: flex-start;
`

const StyledContainer = styled(StyledColumnContainer)`
  gap: ${convertUnit(20)};
  height: 100%;
  justify-content: flex-start;
`

const StyledHeaderContainer = styled.div<StyledHeaderContainerProps>`
  ${({isSearch, theme}) => ({
    justifyContent: isSearch ? 'flex-end' : 'space-between',
    borderBottom: getBorder(isSearch ? 0 : 1, 'solid', theme.gray_8),
  })};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: ${convertUnit(12)} ${convertUnit(20)};
  gap: ${convertUnit(40)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    flex-direction: column-reverse;
    gap: ${convertUnit(25)};
  }
`

const StyledSliderContainer = styled.div`
  width: 100%;
  max-width: ${convertUnit(600)};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    max-width: 100%;
  }
`

const StyledInputRangeParagraph = styled(Paragraph)`
  white-space: nowrap;
`

const StyledInputRangeContainer = styled.div`
  width: 100%;
`

const StyledSearchContainer = styled.div`
  width: 40%;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 60%;
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: 85%;
  }
`

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
`

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledPhotoContainer = styled(StyledRowContainer)`
  gap: ${convertUnit(20)};
`

const StyledLeafHeightContainer = styled(StyledRowContainer)`
  gap: ${convertUnit(4)};
`

const StyledSeparator = styled.div`
  margin: 0 ${convertUnit(8)};
  width: ${convertUnit(1)};
  background-color: ${({theme}) => theme.white_1};
  height: ${convertUnit(12)};
`

const StyledTitleInfoContainer = styled(StyledColumnContainer)`
  gap: ${convertUnit(4)};
`

const StyledInfoContainer = styled(StyledColumnContainer)`
  gap: ${convertUnit(10)};
  margin-top: ${convertUnit(25)};
  margin-bottom: ${convertUnit(20)};
  padding: 0 ${convertUnit(20)};
`

const StyledImage = styled(Image)<StyledImageProps>`
  width: 100%;
  max-width: ${convertUnit(80)};
  aspect-ratio: 0.75;
  border-radius: ${convertUnit(8)};
  object-fit: contain;
  cursor: ${({isPressable}) => (isPressable ? 'pointer' : 'default')};
`

const StyledLocationButton = styled(Button)`
  width: ${convertUnit(195)};
  margin-top: ${convertUnit(4)};
  height: ${convertUnit(28)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
  }
`

const StyledEmptyContainer = styled(StyledColumnContainer)`
  padding: ${convertUnit(20)};
  text-align: center;
  justify-content: center;
  align-items: center;
`

const StyledError = styled(Paragraph)`
  margin-top: ${convertUnit(4)};
  margin-bottom: ${convertUnit(12)};
  margin-left: ${convertUnit(20)};
  margin-right: ${convertUnit(20)};
`

const StyledSearchButton = styled.div<StyledSearchButtonProps>`
  ${({theme, disabled}) => ({
    backgroundColor: disabled ? theme.gray_8 : theme.primary_5,
    border: getBorder(1, 'solid', theme.gray_6),
    cursor: disabled ? 'default' : 'pointer',
  })}
  margin-left: ${convertUnit(20)};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  aspect-ratio: 1;
  border-radius: 50%;
  height: 100%;
`

const StyledParagraph = styled(Paragraph)`
  word-break: break-word;
  white-space: pre-wrap;
  line-height: ${convertUnit(18)};
`

const StyledAccuracyDescContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export default function TreeEventsBIBMasonry() {
  const {translate} = useTranslation()
  const emptyImage = IMAGE_ASSET('giftshop', 'robopet-confused.png')
  const defaultImage = IMAGE_ASSET('tree', 'default.png')
  const location = useLocation('tree_events_bib_masonry')
  const {tree_name, tree_url} = location.state
  const {search: searchParam, tree_id, tag_id} = location.query
  const tempSearch = useRef(REGEX_NUMBER.test(searchParam) ? searchParam : '')
  const [search, setSearch] = useState(tempSearch.current)
  const [error, setError] = useState(false)
  const stateData = useState<ReadonlyArray<GiftShopContentData>>([])
  const data = stateData[0]
  const [info, setInfo] = useState<TreeFotoTreeDetailResponse>()
  const history = useHistory()
  const user = useSelector('user')
  const {update} = useDispatch()
  const [disabledSearch, setDisabledSearch] = useState(true)
  const {shouldShowIncompleteKycModal} = useSelector('incompleteKycState')
  const {accuracy} = useQueryParamValue(['accuracy'])
  const stateRangeValue = useState(
    accuracy ? Number(accuracy) : GIFT_SHOP_SENSITIVITY_LEVEL_DEFAULT,
  )
  const sensitivity = stateRangeValue[0]
  const stateModalPassword = useState(
    !!user?.access_token &&
      !user?.has_password &&
      user?.signup_method !== 'apple',
  )
  const [modal, setModal] = useState<
    'kyc' | 'incomplete' | 'profile' | undefined
  >(undefined)
  const mode = useWindowMode()
  const [isImageBroken, setImageBroken] = useState(false)

  const handleLoadInfo = useCallback(() => {
    requestData('tree_get_fototree_detail', {
      params: {
        tree_id,
      },
      onRequestSuccess: ({status, data: {result}}) => {
        status === 200 && setInfo(result)
      },
    })
  }, [tree_id])

  const handleLoadData = useCallback(
    async (page: number, limit: number, searchList: string) => {
      const response = !search
        ? await requestData('giftshop_post_explore', {
            data: {
              page,
              limit,
              is_filter: true,
              type: 'all',
              tags: [tag_id],
              sensitivity_level: sensitivity,
            },
          })
        : await requestData('giftshop_post_bib_contents', {
            cancelId: SERVICE_CANCELLATION_SEARCH_BIB,
            data: {
              bib: searchList,
              tag_id,
              page,
              limit,
            },
          })

      if (
        typeof response === 'string' &&
        response === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof response !== 'string' && response.status === 200
        ? response.data.result
        : []
    },
    [search, sensitivity, tag_id],
  )

  const handleToggleModal = useCallback(() => {
    setModal(undefined)
  }, [])

  const handleLoadAccountInfo = useCallback(() => {
    if (user?.access_token) {
      requestData('giftshop_get_account', {
        useDefaultMessage: true,
        actionType: 'fetch',
        onRequestSuccess: ({status, data: {result}}) => {
          if (status === 200) {
            const {retake_status, sensitivity_level, similar_status} = result
            const kycStatus = result.status
            update('roboyuSensitivityLevel', {
              sensitivityLevel: sensitivity_level,
            })
            update('similarAccountStatus', {status: similar_status})
            update('yuserActivationState', {
              kycStatus,
              retakeStatus: retake_status,
            })
            retake_status === 'required' && setModal('kyc')
            kycStatus === 'incomplete' &&
              shouldShowIncompleteKycModal &&
              setModal('incomplete')
            if (kycStatus === 'onhold') {
              if (similar_status === 'CHOOSE_SELF') {
                showModalReprocessKyc()
                return
              }
              history.push('giftshop_similar_identity', {fromIfm: true})
            }
          }
        },
      })
    }
  }, [history, shouldShowIncompleteKycModal, update, user?.access_token])

  const handleRenderInputRangeLeftDesc = useMemo(
    () => (
      <StyledAccuracyDescContainer style={{textAlign: 'left'}}>
        <Paragraph
          fontSize={mode === 'mobile' ? 'xxs' : 'm'}
          fontWeight="bold"
          color={mode === 'mobile' ? 'gray_5' : 'white_1'}>
          {translate('giftShop:roboyuAccuracyMediumDesc')}
        </Paragraph>
        {mode !== 'mobile' && (
          <StyledInputRangeParagraph fontWeight="medium" color="gray_5">
            {translate('global:medium')}
          </StyledInputRangeParagraph>
        )}
      </StyledAccuracyDescContainer>
    ),
    [mode, translate],
  )

  const handleRenderInputRangeRightDesc = useMemo(
    () => (
      <StyledAccuracyDescContainer style={{textAlign: 'right'}}>
        <Paragraph
          fontSize={mode === 'mobile' ? 'xxs' : 'm'}
          fontWeight="bold"
          color={mode === 'mobile' ? 'gray_5' : 'white_1'}>
          {translate('giftShop:roboyuAccuracyHighDesc')}
        </Paragraph>
        {mode !== 'mobile' && (
          <StyledInputRangeParagraph fontWeight="medium" color="gray_5">
            {translate('global:high')}
          </StyledInputRangeParagraph>
        )}
      </StyledAccuracyDescContainer>
    ),
    [mode, translate],
  )

  const handleRenderInputRange = useMemo(
    () => (
      <StyledSliderContainer>
        <StyledInputRangeContainer>
          <InputRange
            showTick
            darkMode
            stateValue={stateRangeValue}
            min={GIFT_SHOP_SENSITIVITY_LEVEL_MIN}
            max={GIFT_SHOP_SENSITIVITY_LEVEL_MAX}
            leftDesc={handleRenderInputRangeLeftDesc}
            rightDesc={handleRenderInputRangeRightDesc}
            onSlide={() =>
              showSnackbar(
                translate('giftShop:roboyuAccuracySuccessSnackbar_content'),
              )
            }
            descPosition="side"
          />
        </StyledInputRangeContainer>
      </StyledSliderContainer>
    ),
    [
      handleRenderInputRangeLeftDesc,
      handleRenderInputRangeRightDesc,
      stateRangeValue,
      translate,
    ],
  )

  const handleRenderSearch = useMemo(
    () => (
      <StyledHeaderContainer isSearch={!!search}>
        {!search && handleRenderInputRange}
        {info?.is_bib ? (
          <StyledSearchContainer>
            <StyledInputContainer>
              <Input
                defaultValue={search}
                placeholder={translate('tree:eventBIBSearchPlaceholder')}
                placeholderColor="gray_3"
                containerStyle={{
                  width: '100%',
                }}
                backgroundColor="gray_8"
                borderColor={error ? 'danger_5' : 'gray_6'}
                focusBorderColor={error ? 'danger_5' : 'white_1'}
                focusBackgroundColor="gray_8"
                color="white_1"
                rightIcon="delete"
                onChangeText={(text) => {
                  tempSearch.current = text
                  setDisabledSearch(!text || !REGEX_NUMBER.test(text))
                  setError(text.length > 0 && !REGEX_NUMBER.test(text))
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setSearch(e.currentTarget.value)
                  }
                }}
                onRightIconClick={() => {
                  tempSearch.current = ''
                  setSearch('')
                  setDisabledSearch(true)
                }}
                allowedCharacters={REGEX_NUMBER}
                iconColor="white_1"
                rightElement={
                  <StyledSearchButton
                    disabled={disabledSearch}
                    onClick={() =>
                      disabledSearch ? undefined : setSearch(tempSearch.current)
                    }>
                    <Icon type="search" color="white_1" />
                  </StyledSearchButton>
                }
              />
            </StyledInputContainer>
            {error ? (
              <StyledError fontWeight="medium" color="danger_5">
                {translate('tree:eventBIBSearchErrorNumeric')}
              </StyledError>
            ) : (
              <></>
            )}
          </StyledSearchContainer>
        ) : (
          <></>
        )}
      </StyledHeaderContainer>
    ),
    [
      disabledSearch,
      error,
      handleRenderInputRange,
      info?.is_bib,
      search,
      translate,
    ],
  )

  const handleRenderImage = useMemo(
    () => (
      <StyledImage
        src={info?.url || tree_url}
        alt={info?.name || tree_name}
        fallbackSrc={defaultImage}
        isPressable={!isImageBroken}
        onClick={() => !isImageBroken && setModal('profile')}
        onError={() => setImageBroken(true)}
      />
    ),
    [defaultImage, info?.name, info?.url, isImageBroken, tree_name, tree_url],
  )

  const handleRenderLeafHeight = useMemo(
    () =>
      info?.count_memory && info.height ? (
        <StyledLeafHeightContainer>
          <Paragraph fontWeight="bold" color="white_1">
            {info?.count_memory}
          </Paragraph>
          <Paragraph color="white_4">{translate('forkygram:leafs')}</Paragraph>
          <StyledSeparator />
          <Paragraph fontWeight="bold" color="white_1">
            {info?.height}
          </Paragraph>
          <Paragraph color="white_4">
            {translate('forkygram:treeHeight')}
          </Paragraph>
        </StyledLeafHeightContainer>
      ) : (
        <></>
      ),
    [info?.count_memory, info?.height, translate],
  )

  const handleRenderLocationButton = useMemo(
    () =>
      info ? (
        <StyledLocationButton
          label={translate('tree:viewLocation')}
          backgroundColor="white_1"
          color="primary_5"
          leftIcon={
            <Icon type="location" color="primary_5" size={convertUnit(12)} />
          }
          onClick={() =>
            history.push('tree_fototree', {
              initialData: {
                id: info.id,
                location: info.location,
                name: info.name,
                category_name: info.category_name,
              },
            })
          }
        />
      ) : (
        <></>
      ),
    [history, info, translate],
  )

  const handleRenderTitleInfo = useMemo(
    () => (
      <StyledTitleInfoContainer>
        <Paragraph
          fontSize="l"
          fontWeight="bold"
          color="white_1"
          lineHeight={22}>
          {info?.name || tree_name}
        </Paragraph>
        {handleRenderLeafHeight}
        {handleRenderLocationButton}
      </StyledTitleInfoContainer>
    ),
    [handleRenderLocationButton, handleRenderLeafHeight, info?.name, tree_name],
  )

  const handleRenderImageInfo = useMemo(
    () => (
      <StyledPhotoContainer>
        {handleRenderImage}
        {handleRenderTitleInfo}
      </StyledPhotoContainer>
    ),
    [handleRenderImage, handleRenderTitleInfo],
  )

  const handleRenderWholeInfo = useMemo(
    () => (
      <StyledInfoContainer>
        {handleRenderImageInfo}
        <StyledParagraph color="white_1">
          {info?.bio.replace(REGEX_MULTIPLE_NEWLINES, '\n')}
        </StyledParagraph>
      </StyledInfoContainer>
    ),
    [handleRenderImageInfo, info?.bio],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <StyledEmptyContainer>
        {search.length === 0 ? (
          <Image
            src={emptyImage}
            alt={translate('global:contentNotFound')}
            width={129}
            height={129}
          />
        ) : (
          <></>
        )}
        <Paragraph fontSize="l" fontWeight="bold" color="gray_5">
          {translate('tree:eventBIBEmptyMessageFirstHalf')}
        </Paragraph>
        <Paragraph fontSize="l" fontWeight="bold" color="gray_5">
          {translate('tree:eventBIBEmptyMessageSecondHalf')}
        </Paragraph>
      </StyledEmptyContainer>
    ),
    [emptyImage, search.length, translate],
  )

  const handleRenderMasonryHeader = useMemo(
    () => (
      <StyledUpperContainer>
        {handleRenderSearch}
        {handleRenderWholeInfo}
      </StyledUpperContainer>
    ),
    [handleRenderSearch, handleRenderWholeInfo],
  )

  const handleRenderMasonry = useMemo(
    () => (
      <GiftShopTemplateMasonry
        showResBadge
        stateData={stateData}
        loadData={handleLoadData}
        search={!search ? sensitivity.toString() : search}
        listEmptyElement={handleRenderEmpty}
        listHeaderElement={handleRenderMasonryHeader}
        onClickItem={(item) => {
          history.push('tree_events_bib_content_detail', {
            data: [...data],
            selectedItemId: item.content_id,
            tag_id,
            bib: search,
          })
        }}
      />
    ),
    [
      data,
      handleLoadData,
      handleRenderEmpty,
      handleRenderMasonryHeader,
      history,
      search,
      sensitivity,
      stateData,
      tag_id,
    ],
  )

  const handleRenderRetakeKYCModal = useMemo(
    () => <GiftShopRetakeKYCModal visible toggleModal={handleToggleModal} />,
    [handleToggleModal],
  )

  const handleRenderIncompleteKYCModal = useMemo(
    () => (
      <GiftShopIncompleteKYCModal
        visible
        toggleModal={handleToggleModal}
        onConfirm={() => {
          update('incompleteKycState', {shouldShowIncompleteKycModal: false})
          history.push('giftshop_robopet', {})
        }}
      />
    ),
    [handleToggleModal, history, update],
  )

  const handleRenderImageModal = useMemo(
    () => (
      <TemplateProfilePhotoModal
        src={info?.url || tree_url}
        title={translate('tree:profilePicture')}
        toggleModal={() => setModal(undefined)}
      />
    ),
    [info?.url, translate, tree_url],
  )

  const handleRenderModal = useMemo(() => {
    switch (modal) {
      case 'kyc':
        return handleRenderRetakeKYCModal
      case 'incomplete':
        return handleRenderIncompleteKYCModal
      case 'profile':
        return handleRenderImageModal
      default:
        return <></>
    }
  }, [
    handleRenderImageModal,
    handleRenderIncompleteKYCModal,
    handleRenderRetakeKYCModal,
    modal,
  ])

  useDidUpdate(() => {
    user?.has_password && handleLoadAccountInfo()
  }, [user?.has_password, handleLoadAccountInfo])

  useDidUpdate(() => {
    const {pathname} = window.location
    window.history.replaceState(
      null,
      '',
      `${pathname}?tree_id=${tree_id}&tag_id=${tag_id}&search=${searchParam}&accuracy=${sensitivity}`,
    )
  }, [sensitivity])

  useDidMount(() => {
    if (!user?.access_token) {
      update('treeQrState', {tag_id, tree_id})
      history.replace('auth_easy_signup', {})
      return
    }
    ;(user.has_password || user.signup_method === 'apple') &&
      handleLoadAccountInfo()
    handleLoadInfo()
  })

  return (
    <StyledContainer>
      <GiftShopTemplateModalPassword
        stateModal={stateModalPassword}
        onCancel={handleLoadAccountInfo}
      />
      {handleRenderModal}
      {handleRenderMasonry}
    </StyledContainer>
  )
}
