import React from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {Button, Modal, Paragraph, Image} from 'common/components'
import {TreeLocPermissionModalProps} from './TreeFototreeModalProps'

const StyledModal = styled(Modal)`
  position: relative;
  width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  text-align: center;
`
const StyledButton = styled(Button)`
  margin-top: ${convertUnit(8)};
`
const StyledTitle = styled(Paragraph)`
  margin: ${convertUnit(8)};
`
const StyledContent = styled(Paragraph)`
  margin: ${convertUnit(12)};
`

export default function TreeLocPermissionModal({
  toggleModal,
  visible,
}: TreeLocPermissionModalProps) {
  const {translate} = useTranslation()
  const roboImg = IMAGE_ASSET('giftshop', 'robopet-offer.png')

  return (
    <StyledModal visible={visible}>
      <StyledTitle fontSize="l" fontWeight="bold">
        {translate('giftShop:accessPermissionHeader')}
      </StyledTitle>
      <Image width={136} src={roboImg} alt={roboImg} />
      <StyledContent fontSize="m" fontWeight="regular">
        {translate('tree:locPermissionModalContent')}
      </StyledContent>
      <Button
        label={translate('tree:enableGPS')}
        onClick={() =>
          window.open('https://support.google.com/maps/answer/2839911')
        }
      />
      <StyledButton
        label={translate('global:cancel')}
        onClick={toggleModal}
        color="primary_5"
        backgroundColor="white_2"
      />
    </StyledModal>
  )
}
