import React, {
  CSSProperties,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactHlsPlayer from '@gumlet/react-hls-player'
import styled from 'styled-components'
import {
  BUTTON_PADDING_VERTICAL,
  GIFT_SHOP_CREATION_BADGE_BACKGROUND_COLORS,
  GIFT_SHOP_CREATION_BADGE_ICONS,
  IMAGE_ASSET,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {
  getThemeColor,
  parseDate,
  showSnackbar,
  useDebounce,
  getContentURL,
  clearUserCache,
  getPadding,
  getCurrencyValue,
  useDidUpdate,
  getVideoDuration,
  useHistory,
  getIFMTextShadowStyle,
  isDiscountPrice,
  getDefaultAvatar,
} from 'utils'
import {requestData} from 'services'
import {IconType, LayoutView} from 'types'
import {ThemeColor, useTheme} from 'themes'
import {useTranslation} from 'i18n'
import {useWindowMode} from 'windows'
import {Avatar, Button, Icon, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'
import {GiftShopTemplateDetailItemImageProps} from './GiftShopTemplateDetailContentItemImageProps'
import {GiftShopTemplateFilterOverlay} from '../FilterOverlay'
import {GiftShopTemplateContentDetailBadge} from '../Badge'
import {GiftShopTemplateDetailContentItemImageIcon} from '../DetailContentItemImageIcon'
import {GiftShopTemplateUnwishlistModal} from '../UnwishlistModal'
import {GiftShopTemplateResendpologyConfirmationModal} from '../ResendpologyConfirmationModal'

interface StyledImageProps extends CSSProperties {
  layout: LayoutView
  downscaled?: boolean
  broken?: boolean
}

interface StyledImageContainerProps {
  loaded?: boolean
  downscaled?: boolean
  layout: LayoutView
  darkBackground?: boolean
}

interface StyledTimeProps {
  showMarginLeft?: boolean
}

interface StyledVideoProps {
  layout: LayoutView
}

interface StyledTextShadowProps {
  showTextShadow?: boolean
}

interface StyledSectionUserProps {
  clickable?: boolean
}

const StyledImageContainer = styled.div<StyledImageContainerProps>`
  background-color: ${({theme}) => theme.black_2};
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${({theme, darkBackground}) =>
    darkBackground ? theme.black : theme.white_3};
  height: ${({layout}) => convertUnit(layout.clientHeight - 32)};
`

const StyledItemInfoContainer = styled.div`
  ${({theme}) => ({
    color: theme.white_1,
  })}
  display: flex;
  flex-direction: column;
  position: absolute;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: ${convertUnit(20)};
  justify-content: space-between;
`

const StyledSectionHeader = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledSectionUserAndPrice = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const StyledSectionUser = styled.div<StyledSectionUserProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${({clickable}) => (clickable ? 'pointer' : 'default')};
`

const StyledSectionPrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledSectionFooterCenter = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  align-self: center;
  text-align: center;
  bottom: ${convertUnit(8)};
  gap: ${convertUnit(4)};
`

const StyledSectionFooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(12)};
`

const StyledSectionFooterRight = styled.div`
  position: absolute;
  bottom: ${convertUnit(8)};
  right: ${convertUnit(20)};
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(12)};
`

const StyledSectionFooterLeft = styled.div`
  position: absolute;
  bottom: ${convertUnit(8)};
  left: ${convertUnit(20)};
`

const StyledBuyNowBtn = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: ${convertUnit(34)};
  radius: ${convertUnit(28)};
  background-color: ${({theme}) => getThemeColor(theme, 'black', 0.4)};
`

const StyledImage = styled(Image)<StyledImageProps>`
  ${({layout, broken, downscaled}) => {
    const standardHeight = layout.clientHeight - 32
    return {
      height: '100%',
      width: '100%',
      maxHeight: downscaled
        ? convertUnit(standardHeight * (7 / 10))
        : convertUnit(standardHeight),
      objectFit: downscaled ? undefined : broken ? 'scale-down' : 'contain',
      maxWidth: downscaled ? '66.67%' : undefined,
    }
  }}

  margin: auto;

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    max-height: ${({layout}) => convertUnit(layout.clientHeight - 32)};
    max-width: ${({downscaled}) => (downscaled ? '91%' : undefined)};
  }
`

const StyledVideo = styled(ReactHlsPlayer)<StyledVideoProps>`
  max-height: ${({layout}) => convertUnit(layout.clientHeight - 32)};
  object-fit: contain;
  width: 100%;
`

const StyledInfo = styled(Paragraph)`
  margin-top: ${convertUnit(4)};
`

const StyledPriceContainer = styled.div`
  flex-direction: column;
`

const StyledPrice = styled(Paragraph)<StyledTextShadowProps>`
  margin-left: ${convertUnit(8)};
  text-shadow: ${({theme, showTextShadow}) =>
    showTextShadow ? getIFMTextShadowStyle(theme) : 'none'};
`

const StyledDiscountPrice = styled(Paragraph)`
  text-align: right;
  text-decoration: line-through;
`

const StyledName = styled(Paragraph)<StyledTextShadowProps>`
  margin-left: ${convertUnit(8)};
  text-shadow: ${({theme, showTextShadow}) =>
    showTextShadow ? getIFMTextShadowStyle(theme) : 'none'};
`

const StyledIcon = styled(Icon)`
  margin-left: ${convertUnit(0)};
`

const StyledTime = styled(StyledInfo)<StyledTimeProps>`
  margin-left: ${({showMarginLeft}) => (showMarginLeft ? convertUnit(6) : 0)};
`

const StyledHiResBadgeContainer = styled.div`
  padding: ${convertUnit(2)} ${convertUnit(8)};
  border-radius: 0 0 ${convertUnit(5)} 0;
  background-color: ${({theme}) => getThemeColor(theme, 'gray_7', 0.6)};
  width: fit-content;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

const StyledResBadgeOverlayContainer = styled.div`
  padding: ${convertUnit(4)} ${convertUnit(8)};
  border-radius: 0 0 ${convertUnit(5)} 0;
  background-color: ${({theme}) => getThemeColor(theme, 'black', 0.4)};
  width: fit-content;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

const StyledPreviewBadgeContainer = styled.div<StyledTimeProps>`
  padding: ${convertUnit(2)} ${convertUnit(8)};
  border-radius: ${convertUnit(5)};
  background-color: ${({theme}) => getThemeColor(theme, 'black', 0.5)};
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${convertUnit(6)};
  margin-left: ${({showMarginLeft}) => (showMarginLeft ? convertUnit(6) : 0)};
`

const StyledTitle = styled(Paragraph)<StyledTextShadowProps>`
  color: inherit;
  margin-top: ${convertUnit(4)};
  text-shadow: ${({theme, showTextShadow}) =>
    showTextShadow ? getIFMTextShadowStyle(theme) : 'none'};
`

const StyledResendButton = styled(Button)`
  padding: ${getPadding(BUTTON_PADDING_VERTICAL, 48)};
`

const StyledResendButtonIcon = styled.div`
  margin-right: ${convertUnit(6)};
`

const StyledPlayButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-radius: ${convertUnit(28)};
  padding-right: ${convertUnit(12)};
  max-width: fit-content;
  z-index: 1;
  background-color: ${({theme}) => getThemeColor(theme, 'black_1', 0.4)};
`

const StyledVideoDurationContainer = styled.div`
  margin: ${convertUnit(6)} ${convertUnit(8)};
`

const StyledVideoBadgeContainer = styled.div`
  position: absolute;
  bottom: ${convertUnit(8)};
  z-index: 3;
`

const StyledPlayIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.primary_5};
  height: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
`

const StyledTextShadow = styled(Paragraph)<StyledTextShadowProps>`
  text-shadow: ${({theme, showTextShadow}) =>
    showTextShadow ? getIFMTextShadowStyle(theme) : 'none'};
`

export default function GiftShopTemplateDetailContentItemImage({
  showActionButton,
  data,
  layout,
  hideOverlayWhenExpand = false,
  hideOverlay,
  isHost = false,
  isLoading,
  toggle,
  showDate = false,
  showResBadge = false,
  showPreviewBadge = false,
  showSkipButton = false,
  stateData,
  stateBroken,
  showWishlistButton,
  showPurchaseStatusBadge = false,
  showUnwishlistBadge = false,
  showDownloadButton = false,
  showResBadgeOverlay = false,
  showBuyNowButton = false,
  showSaveButton,
  isRemoved = false,
  showFullOverlay = true,
  showEdit = true,
  showOverlayPrice = true,
  showDownscaledImage,
  handleResend,
  isEditable = false,
  darkBackground,
  stateTypeContent,
  removeAfterUnfavorite = false,
  showFavorite = false,
  isArchived = false,
  showTextShadow = false,
  redirectCreator,
  fromCreation = false,
  onRedirectCreator,
  onToggle,
  onDelete,
  onEdit,
  onDownload,
  onBuyNow,
  onResendpologyDelete,
}: GiftShopTemplateDetailItemImageProps) {
  const {translate} = useTranslation()
  const {
    location,
    member_id,
    is_wishlist,
    is_valid = true,
    is_cart,
    is_reported,
    content_id,
    original_at,
    profile_url,
    price,
    initial_price,
    currency_code,
    title,
    url,
    creator_name,
    purchased_at,
    purchase_status,
    is_deletable,
    quality,
    type,
    content_type,
    video_url,
    duration,
    is_favorite,
  } = data
  const [toggleWishlist, setToggleWishlist] = useState(
    is_wishlist || type === 'fotoyu',
  )

  const debounce = useDebounce()

  const [cart, setCart] = useState(!is_cart)
  const [toggleCart, setToggleCart] = useState(is_cart)
  const [toggleFavorite, setToggleFavorite] = useState(is_favorite)
  const [favorite, setFavorite] = useState<boolean | undefined>(!is_favorite)
  const history = useHistory()
  const {update} = useDispatch()
  const {id, photo: profilePhoto} = useSelector('user') || {}
  const {cart_count} = useSelector('cart')
  const {kycStatus} = useSelector('yuserActivationState')
  const [broken, setBroken] = stateBroken
  const brokenImage = IMAGE_ASSET('giftshop', 'broken-image-content-detail.png')
  const nowLoading = isLoading
  const theme = useTheme()
  const isSelf = id === member_id
  const cartIcon: IconType = toggleCart ? 'cart' : 'cart-plus-outlined'
  const cartBackgroundColor: ThemeColor | undefined = toggleCart
    ? 'primary_5'
    : undefined
  const cartBackgroundOpacity = toggleCart ? 1 : undefined
  const favoriteIcon: IconType = toggleFavorite ? 'heart' : 'heart-ol'
  const favoriteBackgroundColor: ThemeColor | undefined = toggleFavorite
    ? 'primary_5'
    : undefined
  const favoriteBackgroundOpacity = toggleFavorite ? 1 : undefined

  const isPriceDiscount = useMemo(() => isDiscountPrice(price, initial_price), [
    initial_price,
    price,
  ])

  const date = useMemo(
    () =>
      typeof original_at === 'string'
        ? original_at
        : new Date(original_at * 1000).toISOString(),
    [original_at],
  )
  const time = useMemo(
    () =>
      parseDate(
        date,
        showSaveButton ? 'D MMMM YYYY' : 'dddd, D MMMM YYYY, HH:mm:ss',
      ),
    [date, showSaveButton],
  )
  const [loaded, setLoaded] = useState(false)
  const [unwishlist, setUnwishlist] = useState(false)
  const mode = useWindowMode()
  const setData = stateData[1]
  const [cancelModal, setCancelModal] = useState(false)
  const hostState = useSelector('hostState')
  const [paused, setPaused] = useState(true)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [typeContent, setTypeContent] = stateTypeContent

  const handlePreventBubblingUp = useCallback((e: Event) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleRemoveWatermark = useCallback(() => {
    if (!toggleWishlist || typeContent === 'is_this_you') {
      setTypeContent('fotoyu')
    }
  }, [toggleWishlist, typeContent, setTypeContent])

  const handleCancelResendpology = useCallback(() => {
    requestData('giftshop_post_resendpology_cancel', {
      data: {
        content_id,
      },
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          setData((prev) =>
            prev.filter((content) => content.content_id !== content_id),
          )
          onResendpologyDelete && onResendpologyDelete()
          showSnackbar(translate('giftShop:deleteResendpologyNotification'))
        } else {
          showSnackbar(translate('giftShop:snackbarFailedExecute'))
        }
      },
    })
  }, [content_id, onResendpologyDelete, setData, translate])

  const handlePressWishlist = useCallback(() => {
    handleRemoveWatermark()
    !toggleWishlist && setToggleWishlist((previous) => !previous)
    debounce(() => {
      requestData(
        toggleWishlist ? 'giftshop_delete_wishlist' : 'giftshop_post_wishlist',
        {
          actionType: 'execute',
          useDefaultMessage: true,
          data: {content_ids: [content_id]},
          onRequestReceived: () => setUnwishlist(false),
          onRequestSuccess: ({status}) => {
            if (status === 200) {
              showSaveButton &&
                showSnackbar(
                  translate('tree:eventBIBContentSaveSuccessMessage'),
                )
              if (toggleWishlist) {
                if (toggleCart) {
                  clearUserCache('cart')
                  update('cart', {cart_count: cart_count - 1})
                }
                setData((prev) =>
                  prev.filter((content) => content.content_id !== content_id),
                )
              }
            }
          },
          onRequestFailed: () => {
            setToggleWishlist((previous) => !previous)
          },
        },
      )
    })
  }, [
    cart_count,
    content_id,
    debounce,
    handleRemoveWatermark,
    setData,
    showSaveButton,
    toggleCart,
    toggleWishlist,
    translate,
    update,
  ])

  const handleCartCount = useCallback(() => {
    update('cart', {
      cart_count: toggleCart ? cart_count - 1 : cart_count + 1,
    })
  }, [cart_count, toggleCart, update])

  const handleFeatureDisabled = useCallback(() => {
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [history, kycStatus])

  const handlePressCart = useCallback(() => {
    if (
      !(
        kycStatus === 'verified' ||
        kycStatus === 'verified_check' ||
        kycStatus === 'onhold'
      )
    ) {
      handleFeatureDisabled()
      return
    }
    handleRemoveWatermark()
    debounce(() => {
      setCart(toggleCart)
      if (cart !== toggleCart) {
        requestData(
          toggleCart ? 'giftshop_delete_cart' : 'giftshop_post_cart',
          {
            actionType: 'execute',
            useDefaultMessage: true,
            data: {content_ids: [content_id]},
            onRequestSuccess: ({status}) => {
              if (status === 422) {
                showSnackbar(translate('giftShop:cartMaximumErrorMessage'))
                return
              }
              if (status === 200) {
                setToggleCart((previous) => !previous)
                !toggleCart &&
                  !toggleWishlist &&
                  setToggleWishlist((prev) => !prev)
                if (!toggleCart && !toggleFavorite) {
                  setToggleFavorite(true)
                  update('giftshopState', {showFavoriteMark: true})
                }
                handleCartCount()
                showSnackbar(
                  toggleCart
                    ? translate('giftShop:removeCartSuccessMessage')
                    : translate('giftShop:addCartSuccessMessage'),
                )
              }
            },
            onRequestFailed: () => {
              setToggleCart((previous) => !previous)
              setCart(!toggleCart)
            },
          },
        )
      }
    })
  }, [
    kycStatus,
    handleRemoveWatermark,
    debounce,
    handleFeatureDisabled,
    toggleCart,
    cart,
    content_id,
    translate,
    toggleWishlist,
    toggleFavorite,
    handleCartCount,
    update,
  ])

  const handleDeleteExplore = useCallback(() => {
    requestData('giftshop_delete_explore', {
      data: {content_ids: [content_id]},
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          showSnackbar(translate('giftShop:contentDeleted'))
          setData((prev) =>
            prev.filter((content) => content.content_id !== content_id),
          )
        }
      },
    })
  }, [content_id, setData, translate])

  const handlePressFavorite = useCallback(() => {
    debounce(() => {
      setFavorite(toggleFavorite)
      if (favorite !== toggleFavorite) {
        requestData(
          toggleFavorite
            ? 'giftshop_delete_favorite'
            : 'giftshop_post_add_favorite',
          {
            data: {content_ids: [content_id]},
            onRequestSuccess: ({status}) => {
              if (status === 200) {
                setToggleFavorite((prev) => !prev)

                if (toggleFavorite && removeAfterUnfavorite) {
                  setData((prev) =>
                    prev.filter((item) => item.content_id !== content_id),
                  )
                }
                if (!toggleFavorite) {
                  update('giftshopState', {
                    showFavoriteMark: true,
                  })
                }
                showSnackbar(
                  translate(
                    toggleFavorite
                      ? 'giftShop:favoriteSuccessRemove'
                      : 'giftShop:favoriteSuccessAdd',
                  ),
                )
              } else {
                setToggleFavorite((prev) => !prev)
                if (!toggleFavorite && status === 400) {
                  showSnackbar(
                    translate('giftShop:favoriteErrorAddFavoriteAlreadyExist'),
                  )
                }
                showSnackbar(
                  translate(
                    toggleFavorite
                      ? 'giftShop:favoriteErrorRemoveFavorite'
                      : 'giftShop:favoriteErrorAddFavorite',
                  ),
                )
              }
            },
          },
        )
      }
    })
  }, [
    content_id,
    debounce,
    favorite,
    removeAfterUnfavorite,
    setData,
    toggleFavorite,
    translate,
    update,
  ])

  const handleUnarchiveContent = useCallback(() => {
    requestData('giftshop_delete_archives', {
      actionType: 'execute',
      useDefaultMessage: true,
      data: {content_ids: [content_id]},
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          showSnackbar(translate('giftShop:archiveUnarchiveSuccessMessage'))
          setData((prev) =>
            prev.filter((content) => content.content_id !== content_id),
          )
        }
      },
    })
  }, [content_id, setData, translate])

  const handleRecoverContent = useCallback(() => {
    requestData('giftshop_post_recover_content', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {content_ids: [content_id]},
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          showSnackbar(translate('giftShop:recoverContentSuccessMessage'))
          setData((prev) =>
            prev.filter((item) => item.content_id !== content_id),
          )
        }
      },
    })
  }, [content_id, setData, translate])

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, fn?: Function) => {
      fn && fn()
      event.stopPropagation()
    },
    [],
  )

  const handleOnBuyNow = useCallback(() => {
    if (onBuyNow) {
      onBuyNow()
      if (!toggleFavorite) {
        requestData('giftshop_post_add_favorite', {
          data: {
            content_ids: [content_id],
          },
          onRequestSuccess: ({status}) => {
            if (status === 200) {
              update('giftshopState', {showFavoriteMark: true})
              setToggleFavorite(true)
            }
          },
        })
      }
    }
  }, [content_id, onBuyNow, toggleFavorite, update])

  const handleRenderPurchaseStatusBadge = useMemo(
    () =>
      purchase_status === 'sold' &&
      showPurchaseStatusBadge && (
        <GiftShopTemplateContentDetailBadge type={purchase_status} />
      ),
    [purchase_status, showPurchaseStatusBadge],
  )

  const handleRenderResendStatusBadge = useMemo(
    () =>
      ((hostState &&
        (hostState.role === 'admin' || hostState.role === 'leader')) ||
        (hostState && isSelf)) &&
      (purchase_status === 'resend' || purchase_status === 'resendpology') &&
      showPurchaseStatusBadge && (
        <StyledSectionFooterCenter>
          <StyledResendButton
            backgroundColor={
              GIFT_SHOP_CREATION_BADGE_BACKGROUND_COLORS[purchase_status]
            }
            backgroundHoverColor={
              purchase_status === 'resend' ? 'danger_4' : 'warning_4'
            }
            onClick={(e: unknown) => {
              handlePreventBubblingUp(e as Event)
              if (handleResend) handleResend()
            }}>
            <StyledResendButtonIcon>
              <Icon
                type={GIFT_SHOP_CREATION_BADGE_ICONS[purchase_status]}
                size={convertUnit(16)}
                color="white_1"
              />
            </StyledResendButtonIcon>
            <Paragraph
              fontSize={mode === 'website' ? 'm' : 's'}
              fontWeight="medium"
              color="white_1">
              {translate('giftShop:creationBadge', {
                context: purchase_status,
              })}
            </Paragraph>
          </StyledResendButton>
        </StyledSectionFooterCenter>
      ),
    [
      hostState,
      isSelf,
      purchase_status,
      showPurchaseStatusBadge,
      mode,
      translate,
      handlePreventBubblingUp,
      handleResend,
    ],
  )

  const handleRenderUnwishlistBadge = useMemo(
    () =>
      showUnwishlistBadge &&
      is_deletable && (
        <GiftShopTemplateContentDetailBadge
          isDeletable={is_deletable}
          type={undefined}
          badgeStyle={{
            backgroundColor: `${getThemeColor(theme, 'black', 0.5)}`,
          }}
        />
      ),
    [showUnwishlistBadge, is_deletable, theme],
  )

  const handleRenderHiResBadge = useMemo(
    () =>
      showResBadge &&
      quality === 'hi-res' && (
        <StyledHiResBadgeContainer>
          <Paragraph
            fontSize={mode === 'website' ? 'm' : 's'}
            fontWeight="medium"
            color="white_1">
            {translate('giftShop:collectionHiResBadge')}
          </Paragraph>
        </StyledHiResBadgeContainer>
      ),
    [showResBadge, quality, mode, translate],
  )

  const handleRenderPreviewBadge = useMemo(
    () =>
      showPreviewBadge &&
      quality === 'not-hi-res' && (
        <StyledPreviewBadgeContainer showMarginLeft={!showSaveButton}>
          <Paragraph
            fontSize={mode === 'website' ? 's' : 'xs'}
            fontWeight="medium"
            color="white_1">
            {translate('giftShop:collectionPreviewBadge')}
          </Paragraph>
        </StyledPreviewBadgeContainer>
      ),
    [mode, quality, showPreviewBadge, showSaveButton, translate],
  )

  const handleRenderVideoBadge = useMemo(
    () =>
      content_type === 'video' &&
      !is_wishlist &&
      typeContent === 'is_this_you' ? (
        <StyledSectionFooterLeft>
          <StyledVideoBadgeContainer>
            <Icon type="video-view" size={convertUnit(24)} color="white_1" />
          </StyledVideoBadgeContainer>
        </StyledSectionFooterLeft>
      ) : (
        <></>
      ),
    [typeContent, content_type, is_wishlist],
  )

  const handleRenderPlayVideoButton = useMemo(
    () =>
      content_type === 'video' &&
      (is_wishlist || typeContent !== 'is_this_you') ? (
        <StyledPlayButtonContainer
          onClick={() => {
            setPaused((prev) => !prev)
            paused
              ? videoRef.current && videoRef.current.play()
              : videoRef.current && videoRef.current.pause()
          }}>
          <StyledPlayIconContainer>
            <Icon
              type={paused ? 'play' : 'pause'}
              size={convertUnit(16)}
              color="white_1"
            />
          </StyledPlayIconContainer>
          <StyledVideoDurationContainer>
            <Paragraph fontWeight="bold" color="white_1">
              {getVideoDuration(duration || 0, true)}
            </Paragraph>
          </StyledVideoDurationContainer>
        </StyledPlayButtonContainer>
      ) : (
        <></>
      ),
    [typeContent, content_type, duration, is_wishlist, paused, setPaused],
  )

  const handleRenderRecoverFooter = useMemo(
    () => (
      <>
        <StyledSectionFooterCenter>
          <Paragraph fontSize="s" fontWeight="bold" color="white_1">
            {translate('giftShop:recoverContentButtonLabel')}
          </Paragraph>
          <GiftShopTemplateDetailContentItemImageIcon
            hasIconShadow
            hasShadow={false}
            iconType="refresh"
            label={translate('giftShop:recoverContentButton')}
            onClick={handleRecoverContent}
          />
        </StyledSectionFooterCenter>
        <StyledSectionFooterRight>
          {handleRenderPlayVideoButton}
        </StyledSectionFooterRight>
      </>
    ),
    [handleRecoverContent, handleRenderPlayVideoButton, translate],
  )

  const handleRenderArchiveFooter = useMemo(
    () => (
      <>
        <StyledSectionFooterCenter>
          <GiftShopTemplateDetailContentItemImageIcon
            hasIconShadow
            hasShadow={false}
            iconType="archive-out"
            label={translate('giftShop:archiveUnarchive')}
            onClick={handleUnarchiveContent}
          />
        </StyledSectionFooterCenter>
        <StyledSectionFooterRight>
          {handleRenderPlayVideoButton}
        </StyledSectionFooterRight>
      </>
    ),
    [handleRenderPlayVideoButton, handleUnarchiveContent, translate],
  )

  const handleRenderUser = useMemo(
    () => (
      <StyledSectionUser
        clickable={redirectCreator}
        onClick={redirectCreator ? onRedirectCreator : undefined}>
        <Avatar
          src={
            fromCreation
              ? profilePhoto || getDefaultAvatar()
              : profile_url || getDefaultAvatar()
          }
          size={24}
          alt={creator_name}
        />
        <StyledName
          color="inherit"
          fontSize="m"
          fontWeight="bold"
          showTextShadow={showTextShadow}>
          {creator_name}
        </StyledName>
      </StyledSectionUser>
    ),
    [
      creator_name,
      fromCreation,
      onRedirectCreator,
      profilePhoto,
      profile_url,
      redirectCreator,
      showTextShadow,
    ],
  )

  const handleRenderPrice = useMemo(
    () =>
      showOverlayPrice && (
        <StyledSectionPrice>
          <Icon
            type="price-tags"
            size={16}
            hasShadow={showTextShadow}
            color="inherit"
            textShadow={getIFMTextShadowStyle(theme)}
          />
          <StyledPriceContainer>
            <StyledPrice
              color="inherit"
              fontSize="m"
              fontWeight="bold"
              showTextShadow={showTextShadow}>
              {getCurrencyValue(price, currency_code || undefined)}
            </StyledPrice>
            {isPriceDiscount && initial_price && (
              <StyledDiscountPrice color="gray_1" fontSize="s">
                {getCurrencyValue(initial_price, currency_code || undefined)}
              </StyledDiscountPrice>
            )}
          </StyledPriceContainer>
        </StyledSectionPrice>
      ),
    [
      currency_code,
      initial_price,
      isPriceDiscount,
      price,
      showOverlayPrice,
      showTextShadow,
      theme,
    ],
  )

  const handleRenderTitle = useMemo(
    () =>
      !showSaveButton && (
        <StyledTitle
          fontSize="xl"
          fontWeight="medium"
          showTextShadow={showTextShadow}>
          {title}
        </StyledTitle>
      ),
    [showSaveButton, showTextShadow, title],
  )

  const handleRenderInfo = useMemo(
    () => (
      <>
        {handleRenderTitle}
        {location?.name && (
          <StyledInfo color="inherit" fontSize="m" fontWeight="medium">
            <StyledIcon type="mark" size={15} color="white_1" />
            {location.name ===
            `lat: ${location.latitude} lng: ${location.longitude}`
              ? `${location.latitude}, ${location.longitude}`
              : location.name}
          </StyledInfo>
        )}
        {showDate && (
          <StyledTime
            color="inherit"
            fontSize="m"
            fontWeight="regular"
            showMarginLeft={!showSaveButton}>
            {time}
          </StyledTime>
        )}
        {handleRenderPurchaseStatusBadge}
        {handleRenderUnwishlistBadge}
        {handleRenderResendStatusBadge}
      </>
    ),
    [
      handleRenderTitle,
      location,
      showDate,
      showSaveButton,
      time,
      handleRenderPurchaseStatusBadge,
      handleRenderUnwishlistBadge,
      handleRenderResendStatusBadge,
    ],
  )

  const handleRenderFooterSelf = useMemo(
    () => (
      <StyledSectionFooterRight>
        {handleRenderPlayVideoButton}
        {!purchased_at && !is_deletable && isEditable && showEdit && (
          <GiftShopTemplateDetailContentItemImageIcon
            hasIconShadow
            hasShadow={false}
            iconType="pencil"
            onClick={(e) => handleClick(e, onEdit)}
          />
        )}
      </StyledSectionFooterRight>
    ),
    [
      handleRenderPlayVideoButton,
      purchased_at,
      is_deletable,
      isEditable,
      showEdit,
      handleClick,
      onEdit,
    ],
  )

  const handleRenderDownloadButton = useMemo(
    () =>
      (showDownloadButton || type === 'collection') && (
        <StyledSectionFooterRight>
          {handleRenderPlayVideoButton}
          <GiftShopTemplateDetailContentItemImageIcon
            hasIconShadow
            hasShadow={false}
            iconType="download"
            onClick={(e) => handleClick(e, onDownload)}
          />
        </StyledSectionFooterRight>
      ),
    [
      handleClick,
      handleRenderPlayVideoButton,
      onDownload,
      showDownloadButton,
      type,
    ],
  )

  const handleRenderFooterITY = useMemo(
    () => (
      <>
        {showSkipButton && (
          <GiftShopTemplateDetailContentItemImageIcon
            hasIconShadow
            hasShadow={false}
            iconType="not-interested"
            label={translate('giftShop:fotoyuIsNotLabel')}
            onClick={(e) => {
              handleClick(e, handleDeleteExplore)
            }}
          />
        )}
        {showWishlistButton && (
          <GiftShopTemplateDetailContentItemImageIcon
            hasIconShadow
            hasShadow={false}
            iconType="check-custom"
            label={translate('giftShop:fotoyuIsYesLabel')}
            onClick={(e) => handleClick(e, handlePressWishlist)}
          />
        )}
      </>
    ),
    [
      handleClick,
      handleDeleteExplore,
      handlePressWishlist,
      showSkipButton,
      showWishlistButton,
      translate,
    ],
  )

  const handleRenderFooterBIB = useMemo(
    () => (
      <StyledSectionFooterCenter>
        <GiftShopTemplateDetailContentItemImageIcon
          hasIconShadow
          hasShadow={false}
          iconType="check-custom"
          label={translate('global:save')}
          onClick={(e) => handleClick(e, handlePressWishlist)}
        />
      </StyledSectionFooterCenter>
    ),
    [handleClick, handlePressWishlist, translate],
  )

  const handleRenderFooterOther = useMemo(() => {
    if (isRemoved) return handleRenderRecoverFooter
    if (isArchived) return handleRenderArchiveFooter
    return (
      <>
        {handleRenderVideoBadge}
        {toggleWishlist && !showSaveButton && (
          <StyledSectionFooterLeft>
            <GiftShopTemplateDetailContentItemImageIcon
              hasIconShadow
              hasShadow={false}
              iconType="not-interested"
              onClick={() => setUnwishlist(true)}
            />
          </StyledSectionFooterLeft>
        )}
        {!toggleWishlist && showSaveButton && handleRenderFooterBIB}
        {!toggleWishlist && showWishlistButton && (
          <StyledSectionFooterCenter>
            <StyledTextShadow
              fontSize="s"
              fontWeight="bold"
              color="white_1"
              showTextShadow={showTextShadow}>
              {translate('giftShop:exploreHeader')}
            </StyledTextShadow>
            <StyledSectionFooterButtons>
              {handleRenderFooterITY}
            </StyledSectionFooterButtons>
          </StyledSectionFooterCenter>
        )}
        <StyledSectionFooterRight>
          {handleRenderPlayVideoButton}
          {toggleWishlist && showFavorite && (
            <GiftShopTemplateDetailContentItemImageIcon
              hasIconShadow
              hasShadow={false}
              iconType={favoriteIcon}
              backgroundColor={favoriteBackgroundColor}
              backgroundColorOpacity={favoriteBackgroundOpacity}
              onClick={(e) => {
                handleClick(e, handlePressFavorite)
              }}
            />
          )}
          {toggleWishlist && showBuyNowButton && (
            <StyledBuyNowBtn
              label={translate('giftShop:buyNow')}
              onClick={handleOnBuyNow}
            />
          )}
          <GiftShopTemplateDetailContentItemImageIcon
            hasIconShadow
            hasShadow={false}
            iconType={cartIcon}
            backgroundColor={cartBackgroundColor}
            backgroundColorOpacity={cartBackgroundOpacity}
            onClick={(e) => {
              handleClick(e, handlePressCart)
            }}
          />
        </StyledSectionFooterRight>
      </>
    )
  }, [
    cartBackgroundColor,
    cartBackgroundOpacity,
    cartIcon,
    favoriteBackgroundColor,
    favoriteBackgroundOpacity,
    favoriteIcon,
    handleClick,
    handleOnBuyNow,
    handlePressCart,
    handlePressFavorite,
    handleRenderArchiveFooter,
    handleRenderFooterBIB,
    handleRenderFooterITY,
    handleRenderPlayVideoButton,
    handleRenderRecoverFooter,
    handleRenderVideoBadge,
    isArchived,
    isRemoved,
    showBuyNowButton,
    showFavorite,
    showSaveButton,
    showTextShadow,
    showWishlistButton,
    toggleWishlist,
    translate,
  ])

  const handleRenderFooterHost = useMemo(
    () => (
      <StyledSectionFooterRight>
        {handleRenderPlayVideoButton}
        {!purchased_at && !is_deletable && isEditable && isSelf && (
          <GiftShopTemplateDetailContentItemImageIcon
            hasIconShadow
            hasShadow={false}
            iconType="pencil"
            onClick={(e) => handleClick(e, onEdit)}
          />
        )}
      </StyledSectionFooterRight>
    ),
    [
      handleClick,
      handleRenderPlayVideoButton,
      isEditable,
      isSelf,
      is_deletable,
      onEdit,
      purchased_at,
    ],
  )

  const handleRenderFooter = useMemo(
    () =>
      is_valid &&
      !hideOverlay &&
      (!hideOverlayWhenExpand || !toggle) &&
      showActionButton &&
      type !== 'collection' &&
      (broken
        ? null
        : isSelf && isEditable && !isHost
        ? handleRenderFooterSelf
        : isHost
        ? handleRenderFooterHost
        : handleRenderFooterOther),
    [
      is_valid,
      hideOverlay,
      hideOverlayWhenExpand,
      toggle,
      showActionButton,
      type,
      broken,
      isSelf,
      isEditable,
      isHost,
      handleRenderFooterSelf,
      handleRenderFooterHost,
      handleRenderFooterOther,
    ],
  )

  const handleRenderFilter = useMemo(
    () =>
      !is_valid && (
        <GiftShopTemplateFilterOverlay showMessage onDelete={onDelete} />
      ),
    [is_valid, onDelete],
  )

  const handleRenderOverlay = useMemo(
    () =>
      is_valid &&
      !hideOverlay &&
      (!hideOverlayWhenExpand || !toggle) &&
      (showDownloadButton || type !== 'collection') && (
        <StyledItemInfoContainer onClick={onToggle}>
          <StyledSectionHeader>
            {showFullOverlay ||
            purchase_status === 'resend' ||
            purchase_status === 'resendpology' ? (
              <>
                <StyledSectionUserAndPrice>
                  {handleRenderUser}
                  {handleRenderPrice}
                </StyledSectionUserAndPrice>
                {handleRenderInfo}
                {handleRenderPreviewBadge}
              </>
            ) : (
              <StyledSectionUserAndPrice>
                {handleRenderTitle}
                {handleRenderPrice}
              </StyledSectionUserAndPrice>
            )}
          </StyledSectionHeader>
        </StyledItemInfoContainer>
      ),
    [
      is_valid,
      hideOverlay,
      hideOverlayWhenExpand,
      toggle,
      showDownloadButton,
      type,
      onToggle,
      showFullOverlay,
      purchase_status,
      handleRenderUser,
      handleRenderPrice,
      handleRenderInfo,
      handleRenderPreviewBadge,
      handleRenderTitle,
    ],
  )

  const handleRenderResBadgeOverlay = useMemo(
    () =>
      quality !== 'low-res' &&
      (showResBadgeOverlay || type === 'collection') && (
        <StyledResBadgeOverlayContainer>
          <Paragraph
            fontSize={mode === 'website' ? 'm' : 's'}
            fontWeight="medium"
            color="white_1">
            {quality === 'hi-res'
              ? translate('giftShop:collectionHiResBadge')
              : translate('giftShop:collectionPreviewBadge')}
          </Paragraph>
        </StyledResBadgeOverlayContainer>
      ),
    [mode, quality, showResBadgeOverlay, translate, type],
  )

  const handleRenderImage = useMemo(
    () =>
      nowLoading ||
      (!is_reported && (
        <StyledImage
          src={getContentURL({url, type: typeContent})}
          alt={title}
          broken={broken}
          layout={layout}
          downscaled={showDownscaledImage}
          onError={(e) => {
            e.currentTarget.src = brokenImage
            setBroken(true)
          }}
          onLoad={() => setLoaded(true)}
          onClick={onToggle}
        />
      )),
    [
      broken,
      brokenImage,
      typeContent,
      is_reported,
      layout,
      nowLoading,
      onToggle,
      setBroken,
      showDownscaledImage,
      title,
      url,
    ],
  )

  const handleRenderVideo = useMemo(
    () =>
      !is_reported &&
      video_url && (
        <StyledVideo
          layout={layout}
          playerRef={videoRef}
          src={getContentURL({url: video_url, type: typeContent})}
          onEnded={(e) => {
            setPaused(true)
            e.currentTarget.currentTime = 0
          }}
          onLoadedData={() => setLoaded(true)}
          onClick={onToggle}
        />
      ),
    [is_reported, layout, onToggle, setPaused, typeContent, video_url],
  )

  const handleRenderUnwishlistConfirmation = useMemo(
    () => (
      <GiftShopTemplateUnwishlistModal
        visible={unwishlist}
        onConfirm={handlePressWishlist}
        toggleModal={() => setUnwishlist((prev) => !prev)}
      />
    ),
    [handlePressWishlist, unwishlist],
  )

  const handleModalConfirmDeleteResendpology = useMemo(
    () => (
      <GiftShopTemplateResendpologyConfirmationModal
        visible={cancelModal}
        onConfirm={handleCancelResendpology}
        toggleModal={() => setCancelModal((prev) => !prev)}
      />
    ),
    [cancelModal, handleCancelResendpology],
  )

  const handleRenderResendpologyDeleteButton = useMemo(
    () => (
      <StyledSectionFooterLeft>
        <GiftShopTemplateDetailContentItemImageIcon
          hasIconShadow
          hasShadow={false}
          iconType="trash"
          onClick={(e: unknown) => {
            handlePreventBubblingUp(e as Event)
            setCancelModal(true)
          }}
        />
      </StyledSectionFooterLeft>
    ),
    [handlePreventBubblingUp],
  )

  const handleRenderFooterResendpology = useMemo(
    () =>
      purchase_status === 'resendpology' &&
      showPurchaseStatusBadge && <>{handleRenderResendpologyDeleteButton}</>,
    [
      handleRenderResendpologyDeleteButton,
      purchase_status,
      showPurchaseStatusBadge,
    ],
  )

  useDidUpdate(() => {
    paused ? videoRef.current?.pause() : videoRef.current?.play()
  }, [paused])

  return (
    <>
      {handleRenderUnwishlistConfirmation}
      {handleModalConfirmDeleteResendpology}
      <StyledImageContainer
        layout={layout}
        darkBackground={darkBackground}
        loaded={loaded || is_reported}>
        {handleRenderFilter}
        {handleRenderOverlay}
        {handleRenderResBadgeOverlay}
        {handleRenderHiResBadge}
        {content_type === 'video' &&
        (is_wishlist || isEditable || typeContent !== 'is_this_you')
          ? handleRenderVideo
          : handleRenderImage}
        {handleRenderDownloadButton}
        {handleRenderFooterResendpology}
        {handleRenderFooter}
      </StyledImageContainer>
    </>
  )
}
