import React, {CSSProperties, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH, WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopPricingForm} from 'pages'
import {GiftShopUploadFormData, GiftShopUploadType, WindowModeType} from 'types'
import {parseDate, useDidMount, useDidUpdate} from 'utils'
import {useWindowMode} from 'windows'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateUploadFormProps} from './GiftShopTemplateUploadFormProps'
import GiftShopTemplateUploadFormPrice from './GiftShopTemplateUploadFormPrice'
import GiftShopTemplateUploadFormTags from './GiftShopTemplateUploadFormTags'
import GiftShopTemplateUploadFormDateTime from './GiftShopTemplateUploadFormDateTime'
import GiftShopTemplateUploadFormDescription from './GiftShopTemplateUploadFormDescription'
import GiftShopTemplateUploadFormLocation from './GiftShopTemplateUploadFormLocation'
import GiftShopTemplateUploadFormPeopleTag from './GiftShopTemplateUploadFormPeopleTag'

interface StyledFormProps {
  mode: WindowModeType
  uploadType?: GiftShopUploadType
}

interface StyledTagsSectionProps {
  flexDir: CSSProperties['flexDirection']
}

interface StyledContainerProps {
  uploadType?: GiftShopUploadType
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, uploadType}) => ({
    backgroundColor: theme.white_1,
    paddingTop: uploadType === 'bulk' ? 0 : convertUnit(25),
    paddingLeft: uploadType === 'bulk' ? 0 : convertUnit(25),
  })}
  box-sizing: border-box;
  border-radius: ${convertUnit(8)};
  padding-right: ${convertUnit(25)};
  padding-bottom: ${convertUnit(25)};
  width: 100%;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: 0;
    padding-bottom: ${({uploadType}) =>
      uploadType === 'video' ? convertUnit(20) : 0};
  }
`

const StyledSectionMain = styled.div<StyledFormProps>`
  display: flex;
  ${({mode}) =>
    mode === 'website' ? {flexDirection: 'row'} : {flexDirection: 'column'}}
`

const StyledFormContainer = styled.div<StyledFormProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StyledFormPrimary = styled(StyledFormContainer)`
  ${({mode, uploadType}) =>
    mode === 'website' &&
    uploadType === 'single' && {marginLeft: convertUnit(25)}}
  display: flex;
  justify-content: space-between;
`

const StyledFormSecondary = styled(StyledFormContainer)`
  ${({mode}) => ({
    marginLeft: mode === 'website' ? convertUnit(25) : undefined,
  })}
`

const StyledRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StyledDeleteContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-top: ${convertUnit(25)};
  }
`

const StyledDeleteContent = styled(Paragraph)`
  align-self: left;
  cursor: pointer;
`

const StyledTagsSection = styled.div<StyledTagsSectionProps>`
  flex-direction: ${({flexDir}) => flexDir};
  display: flex;
  width: 100%;
`

export default function GiftShopTemplateUploadForm({
  showDeleteButton = true,
  currencyData,
  renderImage,
  priceState,
  defaultValues,
  onFormUpdate,
  onDelete,
  onAddLocation,
  toggleModal,
  uploadType,
  hasLocationMetadata,
  latestData,
  quickFill,
  isEdit = false,
  ...props
}: GiftShopTemplateUploadFormProps) {
  const originalAt = useMemo(
    () => defaultValues.originalAt || new Date().toISOString(),
    [defaultValues],
  )
  const stateFileDate = useState(parseDate(originalAt, 'YYYY-MM-DD'))
  const stateFileTime = useState(parseDate(originalAt, 'HH:mm'))
  const defValDesc = useMemo(() => {
    if (uploadType === 'single' && !isEdit) {
      if (quickFill?.description && latestData?.description)
        return latestData.description
      return ''
    }
    return defaultValues.description
  }, [
    defaultValues.description,
    isEdit,
    latestData?.description,
    quickFill?.description,
    uploadType,
  ])
  const defValLocation = useMemo(() => {
    if (uploadType === 'single' && !isEdit) {
      if (quickFill?.location && latestData?.location)
        return JSON.stringify(latestData?.location)
      return ''
    }
    return defaultValues.location
  }, [
    defaultValues.location,
    isEdit,
    latestData?.location,
    quickFill?.location,
    uploadType,
  ])
  const defValPrice = useMemo(() => {
    if (uploadType === 'single' && !isEdit) {
      if (quickFill?.price && latestData?.price)
        return latestData.price.toString()
      return ''
    }
    return defaultValues.price
  }, [
    defaultValues.price,
    isEdit,
    latestData?.price,
    quickFill?.price,
    uploadType,
  ])
  const stateInputPrice = useState(defValPrice || '0')
  const form = useForm<GiftShopUploadFormData>({
    defaultValues: {
      ...defaultValues,
      description: defValDesc,
      location: defValLocation,
      price: defValPrice,
    },
  })
  const {watch, register, trigger, setValue} = form
  const {translate} = useTranslation()
  const values = watch()
  const mode = useWindowMode()
  const {currencyName, location, tags} = values
  const [inputPrice, setInputPrice] = stateInputPrice

  const handleRenderPriceForm = useMemo(
    () => (
      <GiftShopTemplateUploadFormPrice
        stateInputPrice={stateInputPrice}
        currencyName={currencyName}
        form={form}
        currencyData={currencyData}
      />
    ),
    [currencyData, currencyName, form, stateInputPrice],
  )
  const handleRenderTagsForm = useMemo(
    () => (
      <GiftShopTemplateUploadFormTags
        form={form}
        tags={tags}
        uploadType={uploadType}
        hasLocationMetadata={hasLocationMetadata}
      />
    ),
    [form, hasLocationMetadata, tags, uploadType],
  )

  const handleRenderPeopleTag = useMemo(
    () => (
      <GiftShopTemplateUploadFormPeopleTag
        form={form}
        uploadType={uploadType}
      />
    ),
    [form, uploadType],
  )

  const handleRenderDateTimeForm = useMemo(
    () => (
      <GiftShopTemplateUploadFormDateTime
        form={form}
        stateFileDate={stateFileDate}
        stateFileTime={stateFileTime}
      />
    ),
    [form, stateFileDate, stateFileTime],
  )

  const handleRenderDescriptionForm = useMemo(
    () => <GiftShopTemplateUploadFormDescription form={form} />,
    [form],
  )

  const handleRenderLocationForm = useMemo(
    () => (
      <GiftShopTemplateUploadFormLocation
        form={form}
        location={location}
        onAddLocation={onAddLocation}
        toggleModal={toggleModal}
      />
    ),
    [form, location, onAddLocation, toggleModal],
  )

  const handlePricingForm = useMemo(
    () => (
      <GiftShopPricingForm
        price={parseInt(inputPrice, 10)}
        priceState={priceState}
        tagId={tags && tags[0] ? tags[0].id : undefined}
      />
    ),
    [inputPrice, priceState, tags],
  )

  const handleRenderFormPrimary = useMemo(
    () => (
      <StyledFormPrimary mode={mode} uploadType={uploadType}>
        {handleRenderPriceForm}
        {handlePricingForm}
      </StyledFormPrimary>
    ),
    [handlePricingForm, handleRenderPriceForm, mode, uploadType],
  )

  const handleRenderTagsSection = useMemo(
    () => (
      <StyledTagsSection
        flexDir={
          uploadType === 'bulk' && mode === 'website' ? 'row' : 'column'
        }>
        {handleRenderTagsForm}
        {handleRenderPeopleTag}
      </StyledTagsSection>
    ),
    [handleRenderPeopleTag, handleRenderTagsForm, mode, uploadType],
  )

  const handleRenderFormSecondary = useMemo(
    () => (
      <StyledFormSecondary mode={mode}>
        {handleRenderLocationForm}
        {handleRenderDateTimeForm}
        {handleRenderDescriptionForm}
      </StyledFormSecondary>
    ),
    [
      mode,
      handleRenderLocationForm,
      handleRenderDateTimeForm,
      handleRenderDescriptionForm,
    ],
  )

  const handleRenderDeleteButton = useMemo(
    () =>
      showDeleteButton && (
        <StyledDeleteContainer>
          <StyledDeleteContent
            color="gray_3"
            fontSize="s"
            fontWeight="medium"
            onClick={onDelete}>
            {translate('giftShop:uploadDeleteContent')}
          </StyledDeleteContent>
        </StyledDeleteContainer>
      ),
    [showDeleteButton, onDelete, translate],
  )

  useDidMount(() => {
    register('content')
    register('currencyId')
    register('currencyName')
    register('extension')
    register('file')
    register('height')
    register('id')
    register('location')
    register('originalAt')
    register('peopleTag')
    register('size')
    register('tags')
    register('width')
  })

  useDidUpdate(() => {
    if (!latestData) return
    if (uploadType === 'single' && !isEdit) {
      if (quickFill?.price) {
        setValue('price', latestData.price.toString())
        setInputPrice(latestData.price.toString())
      } else setValue('price', '')
      quickFill?.location
        ? setValue('location', JSON.stringify(latestData.location))
        : setValue('location', '')
      quickFill?.description
        ? setValue('description', latestData.description)
        : setValue('description', '')
    }
  }, [quickFill, latestData, setValue])

  useEffect(() => {
    onFormUpdate && onFormUpdate(values)
  }, [values, onFormUpdate])

  useEffect(() => {
    defaultValues.location === '' && trigger('location')
  }, [values.location, defaultValues.location, trigger])

  return (
    <StyledContainer {...props} uploadType={uploadType}>
      <StyledSectionMain mode={mode}>
        {renderImage}
        <StyledRightContainer>
          <StyledSectionMain mode={mode}>
            {handleRenderFormPrimary}
            {handleRenderFormSecondary}
          </StyledSectionMain>
          {handleRenderTagsSection}
        </StyledRightContainer>
      </StyledSectionMain>
      {mode !== 'website' && handleRenderDeleteButton}
    </StyledContainer>
  )
}
