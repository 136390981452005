export const SERVICE_CANCELED_MESSAGE = 'canceled'
export const SERVICE_CANCELLED_RESPONSE = 'cancelled'

export const SERVICE_CANCELLATION_SEARCH_MEMBER = 'SearchMember'
export const SERVICE_CANCELLATION_SEARCH_CREATION_CONTENT = 'SearchCreations'
export const SERVICE_CANCELLATION_GET_CREATOR_LIVE = 'GetCreatorLive'
export const SERVICE_CANCELLATION_GET_GLOBAL_CHAT_REPLY = 'GetGlobalChatReply'
export const SERVICE_CANCELLATION_GET_PRICE = 'GetPrice'
export const SERVICE_CANCELLATION_GET_TRANSACTION_HISTORY =
  'GetTransactionHistory'
export const SERVICE_CANCELLATION_SEARCH_ADMIN = 'SearchAdmin'
export const SERVICE_CANCELLATION_SEARCH_BANK = 'SearchBank'
export const SERVICE_CANCELLATION_SEARCH_BIB = 'SearchBIB'
export const SERVICE_CANCELLATION_SEARCH_CREATOR = 'SearchCreator'
export const SERVICE_CANCELLATION_SEARCH_EVENT = 'SearchEvent'
export const SERVICE_CANCELLATION_SEARCH_FARMER = 'SearchFarmer'
export const SERVICE_CANCELLATION_SEARCH_FOTOTREE = 'SearchFototree'
export const SERVICE_CANCELLATION_SEARCH_MESSAGE = 'SearchMessage'
