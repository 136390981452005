import React from 'react'
import styled from 'styled-components'
import {GIFTSHOP_UPLOAD_VIDEO_LIMIT, GIFT_SHOP_UPLOAD_VIDEO_TYPE} from 'consts'
import {useTranslation} from 'i18n'
import {getBorder} from 'utils'
import {DragDrop} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopUploadDropzoneVideoProps} from './GiftShopUploadDropzoneProps'

const StyledContainer = styled.div`
  ${({theme}) => ({
    border: getBorder(2, 'dashed', theme.gray_3),
    ':hover': {
      borderColor: theme.primary_5,
    },
  })}
  height: 100%;
  width: 100%;
  border-radius: ${convertUnit(16)};
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
`

export default function GiftShopUploadDropzoneVideo({
  onUploadVideo,
  videoValidator,
  onClickVideo,
}: GiftShopUploadDropzoneVideoProps) {
  const {translate} = useTranslation()

  return (
    <StyledContainer onClick={onClickVideo}>
      <DragDrop
        icon="video-view"
        header={translate('giftShop:uploadModeVideoHeader')}
        description={translate('giftShop:uploadModeVideoDescription', {
          max: GIFTSHOP_UPLOAD_VIDEO_LIMIT,
        })}
        onDrop={(files) => onUploadVideo(files)}
        validator={videoValidator}
        accept={GIFT_SHOP_UPLOAD_VIDEO_TYPE.join(',')}
      />
    </StyledContainer>
  )
}
