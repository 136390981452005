import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import Lottie from 'lottie-react'
import {requestData} from 'services'
import {LIST_MASONRY_PAGINATION_LOAD_LIMIT} from 'consts'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {GiftShopContentData, LayoutView} from 'types'
import {
  clearUserCache,
  showPrompt,
  useDebounce,
  useDefaultState,
  useDidMount,
  useHistory,
  useLocation,
} from 'utils'
import {ListLazy, ListNotLazy, ModalLoading, View} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {GiftShopTemplateDetailContentProps} from './GiftShopTemplateDetailContentProps'
import {GiftShopTemplateDetailContentItem} from '../DetailContentItem'
import {GiftShopTemplateDetailContentEdit} from '../DetailContentEdit'
import {GiftShopTemplateDetailContentEditVideo} from '../DetailContentEditVideo'
import {GiftShopTemplateListPagination} from '../Pagination'
import animationData from '../../../../../assets/lottie/activity_indicator.json'

interface StyledLoadingContainerProps {
  width?: number
  height?: number
}

const StyledLoadingContainer = styled.div<StyledLoadingContainerProps>`
  ${({height, width}) => ({
    width: width ?? '100%',
    height: height ?? '100%',
  })}
  display: flex;
  padding: ${convertUnit(10)} 0;
  align-items: center;
  justify-content: center;
`

const StyledLottie = styled(Lottie)`
  width: ${convertUnit(100)};
  height: ${convertUnit(100)};
`

const StyledContainer = styled(View)`
  display: flex;
  flex: 1;
  width: 100%;
`

export default function GiftShopTemplateDetailContent({
  hideOverlay,
  hideOverlayWhenExpand,
  isHost,
  showActionButton = true,
  showReportButton,
  showResBadge = true,
  showPreviewInfo = true,
  showImageBadge = false,
  data,
  refresh,
  stateEdit: stateEditBase,
  showSkipButton,
  showPreviewBadge,
  selectedItemId,
  loadData,
  requestType,
  showWishlistButton,
  showPurchaseStatusBadge,
  showUnwishlistBadge,
  isWishlistPage = false,
  showFoundConfirmed,
  listEmptyElement,
  showDownloadButton,
  showResBadgeOverlay,
  showBuyNowButton,
  showSaveButton = false,
  onSendDM,
  isRemoved,
  isEditable,
  resendSource,
  showFullOverlay,
  showOverlayPrice = true,
  fetchOnToggle,
  darkBackground = false,
  darkBackgroundImage = false,
  showDownscaledImage = false,
  removeAfterUnfavorite,
  onBuyNow,
  isPaginated,
  page,
  stateCount,
  maxPage,
  setMaxPage,
  limitContent,
  showFavorite,
  isArchived,
  showDescriptionButton = true,
  showTextShadow,
  showArchiveButton,
  redirectCreator,
  fromCreation,
}: GiftShopTemplateDetailContentProps) {
  const location = useLocation('giftshop_profile_creation_detail')
  const {username} = useSelector('user') || {}
  const {translate} = useTranslation()
  const stateData = useState<ReadonlyArray<GiftShopContentData>>(data || [])
  const setData = stateData[1]
  const stateEdit = useDefaultState(undefined, stateEditBase)
  const [edit, setEdit] = stateEdit
  const [layout, setLayout] = useState<LayoutView>()
  const hasScrolled = useRef(false)
  const [loading, setLoading] = useState(false)
  const [listLoading, setListLoading] = useState(false)
  const debounce = useDebounce()

  const history = useHistory()
  const [rawPage, setRawPage] = useState(page)

  const handleUpdatePage = useCallback(
    (newPage: number, force?: boolean) => {
      if (page === newPage && !force) return

      history.replaceQuery({
        ...location,
        path: 'giftshop_profile_creation_detail',
        queryObject: {
          ...location.query,
          page: newPage,
        },
        recursivePath: username,
      })
    },
    [history, location, page, username],
  )

  useEffect(() => {
    if (!rawPage) return

    const timer = setTimeout(() => {
      handleUpdatePage(rawPage)
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [handleUpdatePage, rawPage])

  const handleUpdatePageAfterDeletion = useCallback(
    async (safeNewPage: number) => {
      const currentPageNewData = await loadData(
        safeNewPage,
        LIST_MASONRY_PAGINATION_LOAD_LIMIT,
      )
      if (currentPageNewData) setData(currentPageNewData)

      setListLoading(false)

      return currentPageNewData
    },
    [loadData, setData],
  )

  const handleDealDeletion = useCallback(() => {
    if (!stateCount || !page) return

    const [count, setCount] = stateCount

    const newDataCount = count - 1

    const newMaxPage = Math.ceil(
      newDataCount / LIST_MASONRY_PAGINATION_LOAD_LIMIT,
    )

    const safePage = page > newMaxPage ? newMaxPage : page

    if (newDataCount > 0) {
      setCount(newDataCount)
      setMaxPage && setMaxPage(newMaxPage)
      setListLoading(true)
      setRawPage(safePage)
      handleUpdatePageAfterDeletion(safePage)
    } else {
      debounce(
        () => history.replace('giftshop_profile', {self: true}, username),
        500,
      )
    }
  }, [
    stateCount,
    page,
    setMaxPage,
    handleUpdatePageAfterDeletion,
    debounce,
    history,
    username,
  ])

  const handleDelete = useCallback(
    (item: GiftShopContentData) => {
      requestData(requestType, {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {content_ids: [item.content_id]},
        onRequestSuccess: () => {
          if (!edit) {
            setData((previous) =>
              previous.filter((value) => value.content_id !== item.content_id),
            )
            handleDealDeletion()
          }
        },
      })
    },
    [edit, handleDealDeletion, requestType, setData],
  )

  const handleDeleteCreation = useCallback(() => {
    if (edit) {
      setLoading(true)
      requestData('giftshop_delete_creation', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {
          content_ids: [edit.content_id],
          is_selected_all: false,
        },
        onRequestReceived: () => setLoading(false),
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setData((previous) =>
              previous.filter(
                (object) => object.content_id !== edit.content_id,
              ),
            )
            setEdit(undefined)
            setLoading(false)
            setListLoading(false)
            handleDealDeletion()
            clearUserCache('creation')
          }
        },
      })
    }
  }, [edit, handleDealDeletion, setData, setEdit])

  const handleKeyExtractor = useCallback(
    (item: GiftShopContentData) => item.content_id + item.quality,
    [],
  )

  const selectedComponentRef = useCallback((node: HTMLDivElement) => {
    node &&
      node.scrollIntoView({
        block: 'start',
        inline: 'nearest',
      })
    hasScrolled.current = true
  }, [])

  const handleLogicShowDescriptionButton = useCallback(
    (item: GiftShopContentData) =>
      typeof showDescriptionButton === 'function'
        ? showDescriptionButton(item)
        : showDescriptionButton,
    [showDescriptionButton],
  )

  const handleRenderItem = useCallback(
    (item: GiftShopContentData) =>
      layout ? (
        <GiftShopTemplateDetailContentItem
          componentRef={
            item.content_id === selectedItemId ? selectedComponentRef : null
          }
          showActionButton={showActionButton}
          hideOverlay={hideOverlay}
          hideOverlayWhenExpand={hideOverlayWhenExpand}
          isHost={isHost}
          showReportButton={showReportButton}
          showResBadge={showResBadge}
          showPreviewInfo={showPreviewInfo}
          showImageBadge={showImageBadge}
          data={item}
          layout={layout}
          showSkipButton={showSkipButton}
          showPreviewBadge={showPreviewBadge}
          showWishlistButton={showWishlistButton}
          stateData={stateData}
          isWishlistPage={isWishlistPage}
          showPurchaseStatusBadge={showPurchaseStatusBadge}
          showUnwishlistBadge={showUnwishlistBadge}
          showFoundConfirmed={showFoundConfirmed}
          showDownloadButton={showDownloadButton}
          showResBadgeOverlay={showResBadgeOverlay}
          showBuyNowButton={showBuyNowButton}
          showSaveButton={showSaveButton}
          resendSource={resendSource}
          isRemoved={isRemoved}
          isEditable={isEditable}
          darkBackground={darkBackground}
          darkBackgroundImage={darkBackgroundImage}
          showFullOverlay={showFullOverlay}
          showOverlayPrice={showOverlayPrice}
          removeAfterUnfavorite={removeAfterUnfavorite}
          showFavorite={showFavorite}
          isArchived={isArchived}
          showArchiveButton={showArchiveButton}
          onSendDM={onSendDM ? () => onSendDM(item) : undefined}
          onBuyNow={onBuyNow ? () => onBuyNow(item) : undefined}
          onDelete={() => handleDelete(item)}
          onEdit={() => setEdit(item)}
          onResendpologyDelete={handleDealDeletion}
          fetchOnToggle={fetchOnToggle}
          showDownscaledImage={showDownscaledImage}
          showTextShadow={showTextShadow}
          showDescriptionButton={handleLogicShowDescriptionButton(item)}
          redirectCreator={redirectCreator}
          fromCreation={fromCreation}
        />
      ) : (
        <></>
      ),
    [
      layout,
      selectedItemId,
      selectedComponentRef,
      showActionButton,
      hideOverlay,
      hideOverlayWhenExpand,
      isHost,
      showReportButton,
      showResBadge,
      showPreviewInfo,
      showImageBadge,
      showSkipButton,
      showPreviewBadge,
      showWishlistButton,
      stateData,
      isWishlistPage,
      showPurchaseStatusBadge,
      showUnwishlistBadge,
      showFoundConfirmed,
      showDownloadButton,
      showResBadgeOverlay,
      showBuyNowButton,
      showSaveButton,
      resendSource,
      isRemoved,
      isEditable,
      darkBackground,
      darkBackgroundImage,
      showFullOverlay,
      showOverlayPrice,
      removeAfterUnfavorite,
      showFavorite,
      isArchived,
      showArchiveButton,
      onSendDM,
      onBuyNow,
      handleDealDeletion,
      fetchOnToggle,
      showDownscaledImage,
      showTextShadow,
      handleLogicShowDescriptionButton,
      redirectCreator,
      fromCreation,
      handleDelete,
      setEdit,
    ],
  )

  const handleRenderEdit = useMemo(
    () =>
      edit ? (
        edit.content_type === 'photo' ? (
          <GiftShopTemplateDetailContentEdit
            stateData={stateData}
            stateEdit={stateEdit}
            onDeleteCreation={handleDeleteCreation}
          />
        ) : (
          <GiftShopTemplateDetailContentEditVideo
            onDeleteCreation={handleDeleteCreation}
            stateData={stateData}
            stateEdit={stateEdit}
          />
        )
      ) : (
        <></>
      ),
    [edit, handleDeleteCreation, stateData, stateEdit],
  )

  const handleRenderLoading = useMemo(
    () => (
      <StyledLoadingContainer>
        <StyledLottie
          animationData={animationData}
          height={convertUnit(100)}
          width={convertUnit(100)}
        />
      </StyledLoadingContainer>
    ),
    [],
  )

  const handleRenderList = useMemo(() => {
    if (listLoading) return handleRenderLoading

    if (isPaginated && page)
      return (
        <GiftShopTemplateListPagination
          stateData={stateData}
          dataCount={stateCount ? stateCount[0] : 0}
          page={page}
          defaultPage={page}
          loadData={loadData}
          setPage={(newPage: number) => {
            setRawPage(newPage)
          }}
          movePage={(difference: number) => {
            setRawPage((prev) => (prev ?? 1) + difference)
          }}
          renderItem={handleRenderItem}
        />
      )

    if (limitContent)
      return (
        <ListNotLazy
          stateData={stateData}
          refresh={refresh}
          keyExtractor={handleKeyExtractor}
          renderItem={handleRenderItem}
          listEmptyElement={listEmptyElement}
          loadData={loadData}
          backgroundColor={darkBackground ? 'black' : 'white_2'}
          contentContainerStyle={{
            width: '100%',
            padding: `0px ${convertUnit(15)}`,
          }}
          page={1}
          defaultPage={1}
          pageCount={1}
          limitContent
        />
      )

    return (
      <ListLazy
        stateData={stateData}
        refresh={refresh}
        keyExtractor={handleKeyExtractor}
        renderItem={handleRenderItem}
        listEmptyElement={listEmptyElement}
        loadData={loadData}
        backgroundColor={darkBackground ? 'black' : 'white_2'}
        contentContainerStyle={{
          width: '100%',
          padding: `0px ${convertUnit(15)}`,
        }}
      />
    )
  }, [
    listLoading,
    handleRenderLoading,
    isPaginated,
    page,
    stateData,
    stateCount,
    loadData,
    handleRenderItem,
    limitContent,
    refresh,
    handleKeyExtractor,
    listEmptyElement,
    darkBackground,
  ])

  const handleRenderModalLoading = useMemo(
    () => <ModalLoading visible={loading} />,
    [loading],
  )

  useDidMount(() => {
    if (page) setRawPage(page)
  })

  useEffect(() => {
    if (typeof maxPage !== 'number' || !rawPage) return

    if ((maxPage > -1 && rawPage > maxPage) || rawPage < 1) {
      const safe = rawPage > maxPage ? maxPage : 1
      setRawPage(safe)
      handleUpdatePage(safe, true)
    }
  }, [handleUpdatePage, maxPage, rawPage])

  useEffect(() => {
    showPrompt(
      edit !== undefined,
      translate('giftShop:contentDetailEditModalMessage'),
    )
  }, [edit, translate])

  return (
    <StyledContainer onLayout={setLayout}>
      {handleRenderModalLoading}
      {handleRenderEdit}
      {handleRenderList}
    </StyledContainer>
  )
}
