import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_ROOT_HEADER_PADDING, GIFT_SHOP_ROOT_MENU_WIDTH} from 'consts'
import {useWindowMode} from 'windows'
import {requestData} from 'services'
import {WindowModeType} from 'types'
import {
  showModalLimitedAccess,
  showModalReprocessKyc,
  useDidMount,
  useHistory,
  useNewMessageIndicator,
} from 'utils'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {Icon, LanguageToggle, Paragraph} from 'common/components'
import {GiftShopRootHeaderProps} from './GiftShopRootHeaderProps'

interface StyledContainerProps {
  mode: WindowModeType
}

interface StyledSectionButtonProps {
  darkMode: boolean
}

interface StyledSectionTitleProps extends StyledContainerProps {
  showBackIcon: boolean
  hideSideNavbar: boolean
  darkMode: boolean
}

interface StyledBadgeProps {
  count: number
}

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
`

const StyledSectionTitle = styled.div<StyledSectionTitleProps>`
  ${({showBackIcon, mode, hideSideNavbar, darkMode, theme}) => ({
    padding: `0 ${
      mode === 'website' && !hideSideNavbar
        ? convertUnit(
            GIFT_SHOP_ROOT_HEADER_PADDING / (showBackIcon ? 2 : 1) +
              GIFT_SHOP_ROOT_MENU_WIDTH,
          )
        : convertUnit(GIFT_SHOP_ROOT_HEADER_PADDING / (showBackIcon ? 2 : 1))
    }`,
    marginLeft: showBackIcon || mode === 'website' ? 0 : convertUnit(8),
    backgroundColor: darkMode ? theme.black : undefined,
  })}
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  align-items: center;
`

const StyledSectionButton = styled.div<StyledSectionButtonProps>`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0 ${convertUnit(GIFT_SHOP_ROOT_HEADER_PADDING)};
  align-items: center;
  ${({darkMode, theme}) => ({
    backgroundColor: darkMode ? theme.black : undefined,
  })}
`

const StyledLeftContainer = styled.div<StyledContainerProps>`
  padding: ${({mode}) =>
    mode === 'website' ? convertUnit(12) : convertUnit(20)};
  margin-right: ${convertUnit(GIFT_SHOP_ROOT_HEADER_PADDING / 2)};
  cursor: pointer;
`

const StyledBadgeContainer = styled.div`
  ${({theme}) => ({
    color: theme.primary_5,
    margin: `0 ${convertUnit(4)}`,
    ':hover': {color: theme.primary_4},
  })}
  padding-top: ${convertUnit(12)};
  padding-bottom: ${convertUnit(12)};
  padding-right: ${convertUnit(7)};
  padding-left: ${convertUnit(12)};
  position: relative;
  transition: all 0.15s ease-out;
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`

const StyledIconBadge = styled(Icon)`
  position: relative;
`

const StyledBadge = styled.div<StyledBadgeProps>`
  ${({theme}) => ({backgroundColor: theme.warning_5})}
  position: absolute;
  border-radius: ${convertUnit(100)};
  width: ${({count}) => (count < 10 ? convertUnit(18) : convertUnit(26))};
  height: ${convertUnit(18)};
  bottom: ${convertUnit(27)};
  left: ${convertUnit(27)};
  border: ${convertUnit(1)} solid ${({theme}) => theme.white_1};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledMessageIconContainer = styled.div`
  ${({theme}) => ({
    color: theme.primary_5,
    margin: `0 ${convertUnit(4)}`,
    ':hover': {color: theme.primary_4},
  })}
  padding-top: ${convertUnit(12)};
  padding-bottom: ${convertUnit(12)};
  padding-right: ${convertUnit(12)};
  padding-left: ${convertUnit(7)};
  position: relative;
  transition: all 0.15s ease-out;
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledIndicator = styled.div`
  width: ${convertUnit(8)};
  height: ${convertUnit(8)};
  background-color: ${({theme}) => theme.danger_5};
  position: absolute;
  right: ${convertUnit(14)};
  border-radius: ${convertUnit(8)};
`

export default function GiftShopRootHeader({
  darkMode = false,
  headerTitle,
  headerSubtitle,
  buttons,
  rightButtons,
  overlayHeader = false,
  opaqueHeader = false,
  showBackIcon = true,
  showBadgeIcon = true,
  hideSideNavbar = false,
  badgeIconType = 'cart',
  showLeftBtn,
  showMessageIcon = true,
  leftButton,
  leftElement,
  showLanguageToggle = false,
  onBackButtonClicked,
  onClickHeader,
  onClickLeftBtn,
}: GiftShopRootHeaderProps) {
  const history = useHistory()
  const {update} = useDispatch()
  const mode = useWindowMode()
  const {cart_count} = useSelector('cart')
  const user = useSelector('user')
  const hasNewChat = useNewMessageIndicator()
  const {kycStatus} = useSelector('yuserActivationState')
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const {status: childStatus} = useSelector('parentalConsentState')

  const handleLoadCartCount = useCallback(() => {
    if (user) {
      requestData('giftshop_get_cart_count', {
        actionType: 'fetch',
        onRequestSuccess: ({data: response, status}) => {
          if (status === 200) {
            update('cart', {cart_count: response.result.total_item})
          }
        },
      })
    }
  }, [update, user])

  const handleFeatureDisabled = useCallback(() => {
    if (childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [childStatus, history, kycStatus, similarStatus])

  const handleRenderBack = useMemo(
    () =>
      showBackIcon && (
        <StyledLeftContainer
          mode={mode}
          onClick={onBackButtonClicked || history.goBack}>
          <Icon
            type="back"
            color={
              !opaqueHeader && (darkMode || overlayHeader)
                ? 'white_1'
                : 'primary_5'
            }
            size={convertUnit(20)}
          />
        </StyledLeftContainer>
      ),
    [
      darkMode,
      history.goBack,
      mode,
      onBackButtonClicked,
      opaqueHeader,
      overlayHeader,
      showBackIcon,
    ],
  )

  const handleRenderLeftIcon = useMemo(
    () =>
      showLeftBtn && leftButton ? (
        <StyledLeftContainer mode={mode} onClick={onClickLeftBtn}>
          <Icon
            type={leftButton}
            color={darkMode ? 'white_1' : 'primary_5'}
            size={convertUnit(20)}
          />
        </StyledLeftContainer>
      ) : (
        leftElement
      ),
    [darkMode, leftButton, leftElement, mode, onClickLeftBtn, showLeftBtn],
  )

  const handleRenderTitle = useMemo(
    () => (
      <StyledTitleContainer>
        <Paragraph
          fontSize="l"
          fontWeight="medium"
          color={
            !opaqueHeader && (darkMode || overlayHeader) ? 'white_1' : 'black'
          }
          className={onClickHeader && 'pressable'}
          onClick={onClickHeader}>
          {headerTitle}
        </Paragraph>
        {headerSubtitle && (
          <Paragraph
            color={darkMode ? 'gray_3' : 'gray_5'}
            className={onClickHeader && 'pressable'}
            onClick={onClickHeader}>
            {headerSubtitle}
          </Paragraph>
        )}
      </StyledTitleContainer>
    ),
    [
      darkMode,
      headerSubtitle,
      headerTitle,
      onClickHeader,
      opaqueHeader,
      overlayHeader,
    ],
  )

  const handleRenderBadgeIcon = useMemo(
    () =>
      showBadgeIcon && (
        <>
          <div
            onClick={() =>
              !(kycStatus === 'verified' || kycStatus === 'verified_check')
                ? handleFeatureDisabled()
                : history.push('giftshop_cart', {})
            }>
            <StyledBadgeContainer>
              {badgeIconType && (
                <StyledIconBadge
                  type={badgeIconType}
                  size={convertUnit(20)}
                  color="inherit"
                />
              )}
              {badgeIconType === 'cart' &&
                cart_count !== undefined &&
                cart_count > 0 && (
                  <StyledBadge count={cart_count}>
                    <StyledParagraph
                      fontSize="xs"
                      fontWeight="bold"
                      color="white_1">
                      {cart_count}
                    </StyledParagraph>
                  </StyledBadge>
                )}
            </StyledBadgeContainer>
          </div>
          {showMessageIcon && (
            <StyledMessageIconContainer>
              {hasNewChat && <StyledIndicator />}
              <Icon
                type="message"
                size={convertUnit(20)}
                color="primary_5"
                onClick={() =>
                  childStatus === 'UNDERAGE' ||
                  !(
                    kycStatus === 'verified' ||
                    kycStatus === 'verified_check' ||
                    kycStatus === 'onhold'
                  )
                    ? handleFeatureDisabled()
                    : history.push('giftshop_message', {})
                }
              />
            </StyledMessageIconContainer>
          )}
        </>
      ),
    [
      badgeIconType,
      cart_count,
      childStatus,
      handleFeatureDisabled,
      hasNewChat,
      history,
      kycStatus,
      showBadgeIcon,
      showMessageIcon,
    ],
  )

  const handleRenderLanguageToggle = useMemo(
    () => (showLanguageToggle ? <LanguageToggle /> : <></>),
    [showLanguageToggle],
  )

  useDidMount(() => {
    showBadgeIcon && handleLoadCartCount()
  })

  return (
    <StyledContainer mode={mode}>
      <StyledSectionTitle
        darkMode={darkMode}
        mode={mode}
        showBackIcon={showBackIcon}
        hideSideNavbar={hideSideNavbar}>
        {handleRenderLeftIcon}
        {handleRenderBack}
        {handleRenderTitle}
      </StyledSectionTitle>
      <StyledSectionButton darkMode={darkMode}>
        {buttons}
        {handleRenderBadgeIcon}
        {handleRenderLanguageToggle}
        {rightButtons}
      </StyledSectionButton>
    </StyledContainer>
  )
}
