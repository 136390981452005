import {lazy} from 'react'

const GiftShopRoboPetScreen = lazy(
  () => import(/* webpackChunkName: 'RoboPet' */ './GiftShopRoboPetScreen'),
)

const GiftShopRobopetActivationSelfieScreen = lazy(
  () => import('./GiftShopRobopetActivationSelfieScreen'),
)
const GiftShopRobopetRetakeScreen = lazy(
  () => import('./GiftShopRobopetRetakeScreen'),
)
const GiftShopRobopetRetakeSelfieScreen = lazy(
  () => import('./GiftShopRobopetRetakeSelfieScreen'),
)
export * from './GiftShopRobopetActivationSelfieScreenParam'
export * from './GiftShopRobopetScreenParam'

export {GiftShopRoboPetScreen, GiftShopRobopetActivationSelfieScreen, GiftShopRobopetRetakeScreen, GiftShopRobopetRetakeSelfieScreen}
