import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {GiftShopContentData} from 'types'
import {
  clearUserCache,
  formatExploreFilterDate,
  showModalReprocessKyc,
  useDidMount,
  useFirestoreExploreRefresh,
  useHistory,
  useLocation,
} from 'utils'
import {Button} from 'common/components'
import history from 'lib/history'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'
import {GiftShopTemplateDetailContent} from '../../../template'
import {GiftShopRootEmpty} from '../../../root'
import {GiftShopExploreContentDetailProps} from './GiftShopExploreContentDetailProps'

const StyledScreenContainer = styled.div`
  position: absolute;
  top: 8;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledButtonContainer = styled.div`
  margin-top: ${convertUnit(20)};
`

export default function GiftShopExploreContentDetail({
  isFilter,
}: GiftShopExploreContentDetailProps) {
  const {data, selectedItemId} = useLocation('giftshop_explore_detail').state
  const emptyImage = IMAGE_ASSET(
    'giftshop',
    isFilter ? 'roboyu-filter.png' : 'robopet-confused.png',
  )
  const {translate} = useTranslation()
  const exploreLastUpdate = useFirestoreExploreRefresh()
  const {update, reset} = useDispatch()
  const [exploreLastUpdateLocal, setExploreLastUpdateLocal] = useState<number>(
    Math.floor(new Date().getTime() / 1000),
  )
  const [newDate, setNewDate] = useState<Date>(new Date())
  const shouldRenderButton = useMemo(
    () => exploreLastUpdate && exploreLastUpdate > exploreLastUpdateLocal,
    [exploreLastUpdate, exploreLastUpdateLocal],
  )
  const filter = useSelector('exploreFilter')
  const locationHistory = useHistory()
  const {kycStatus} = useSelector('yuserActivationState')
  const {id} = useSelector('user') || {}
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const {sensitivityLevel} = useSelector('roboyuSensitivityLevel')

  useDidMount(() => {
    if (history.location.state && history.location.state.data) {
      const state = {...history.location.state}
      state.data = []
      history.replace({...history.location, state})
    }
  })

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const {
        startDate,
        endDate,
        latitude,
        longitude,
        tags,
        creators,
        filterSensitivity,
      } = filter
      const {startTimestamp, endTimestamp} = formatExploreFilterDate(
        startDate,
        endDate,
      )
      const tagIds = tags.map((item) => item.id)
      const lat = parseFloat(latitude)
      const long = parseFloat(longitude)
      const creatorIds = creators
        ? creators.map((creator) => creator.creator_id)
        : []
      const response = await requestData('giftshop_post_explore', {
        actionType: 'execute',
        data: {
          tags: tagIds,
          page,
          limit,
          is_filter: isFilter,
          is_detail: true,
          latitude: lat,
          longitude: long,
          type: 'all',
          from: startTimestamp,
          to: endTimestamp,
          creator_ids: creatorIds,
          sensitivity_level: isFilter ? filterSensitivity : sensitivityLevel,
        },
      })

      return typeof response !== 'string' && response.status === 200
        ? response.data.result.filter((item) => item.is_reported !== true)
        : []
    },
    [filter, isFilter, sensitivityLevel],
  )

  const handleClearFilter = useCallback(() => {
    reset('exploreFilter')
    location.reload()
  }, [reset])

  const handleClearFilterButton = useMemo(
    () =>
      isFilter && (
        <StyledButtonContainer>
          <Button
            onClick={handleClearFilter}
            label={translate('giftShop:isThisYouFilterEmptyButton')}
          />
        </StyledButtonContainer>
      ),
    [handleClearFilter, isFilter, translate],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <GiftShopRootEmpty
        image={emptyImage}
        imageAlt={emptyImage}
        title={translate(
          isFilter
            ? 'giftShop:isThisYouFilterEmptyTitle'
            : 'giftShop:isThisYouEmptyTitle',
        )}
        message={translate(
          isFilter
            ? 'giftShop:isThisYouFilterEmptyMessage'
            : 'giftShop:isThisYouEmptyDesc',
        )}
        messageAlt={
          !isFilter
            ? translate('giftShop:isThisYouEmptyDesc', {
                context: 'Alt',
              })
            : undefined
        }
        bottomElement={handleClearFilterButton}
      />
    ),
    [emptyImage, handleClearFilterButton, isFilter, translate],
  )

  const handleRefresh = useCallback(() => {
    exploreLastUpdate && setExploreLastUpdateLocal(exploreLastUpdate)
    setNewDate(new Date())
    update('giftshopState', {
      exploreRefresher: new Date(),
    })
  }, [exploreLastUpdate, update])

  const handleRenderFloatingButton = useMemo(
    () =>
      shouldRenderButton && (
        <StyledScreenContainer>
          <Button
            label={translate('giftShop:exploreFloatingButtonTitle')}
            backgroundColor="white_3"
            color="primary_5"
            onClick={handleRefresh}
          />
        </StyledScreenContainer>
      ),
    [handleRefresh, shouldRenderButton, translate],
  )

  const handleFeatureDisabled = useCallback(() => {
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return locationHistory.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      locationHistory.push('giftshop_robopet_activation_selfie', {})
      return
    }
    locationHistory.push('giftshop_robopet', {})
  }, [kycStatus, locationHistory, similarStatus])

  const handleOnBuyNow = useCallback(
    (item: GiftShopContentData) => {
      if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
        handleFeatureDisabled()
        return
      }
      const {url, type, price} = item
      const par: GiftShopContentData = {
        ...item,
        url: type ? url[type] : url,
      }
      clearUserCache('cart')
      locationHistory.push('payment_checkout', {
        data: [par],
        firstPurchase: [],
        totalPrice: price,
      })
    },
    [handleFeatureDisabled, kycStatus, locationHistory],
  )

  const handleLogicShowDescriptionActionButtons = useCallback(
    (item: GiftShopContentData) => item.member_id !== id,
    [id],
  )

  return (
    <>
      <GiftShopTemplateDetailContent
        data={data}
        showSkipButton
        showPreviewBadge
        showBuyNowButton
        showFavorite
        showTextShadow
        showArchiveButton
        redirectCreator
        refresh={newDate}
        selectedItemId={selectedItemId}
        requestType="giftshop_delete_explore"
        loadData={handleLoadData}
        listEmptyElement={handleRenderEmpty}
        onBuyNow={handleOnBuyNow}
        showFullOverlay
        showPrice
        darkBackgroundImage
        showDescriptionButton={handleLogicShowDescriptionActionButtons}
      />
      {handleRenderFloatingButton}
    </>
  )
}
