import React, {CSSProperties, useCallback} from 'react'
import styled from 'styled-components'
import {
  isListMasonryHasEmptySpace,
  useListMasonryData,
  useListMasonryPaginationAction,
} from 'utils'
import {ListMasonryBatchData} from 'types'
import {LIST_MASONRY_PAGINATION_LOAD_LIMIT} from 'consts'
import {ListMasonryPaginationProps} from './ListMasonryPaginationProps'
import {ListPagination} from '../ListPagination'

interface StyledItemProps {
  flex: CSSProperties['flex']
}

interface StyledSectionRowBaseProps {
  aspectRatio: number
}

const StyledItem = styled.div<StyledItemProps>`
  ${({flex}) => ({flex})}
  display: flex;
  box-sizing: border-box;
  height: 100%;
`

const StyledSectionRowBase = styled.div<StyledSectionRowBaseProps>`
  ${({aspectRatio}) => ({
    paddingTop: `${(1 / aspectRatio) * 100}%`,
  })}
  position: relative;
  width: 100%;
`

const StyledSectionRow = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export default function ListMasonryPagination<ItemT, S>({
  data,
  limitPerPage = LIST_MASONRY_PAGINATION_LOAD_LIMIT,
  refreshing,
  refreshDefaultEnabled,
  search,
  cache,
  stateData,
  keyExtractor,
  onRefresh,
  onLayoutDimension,
  onContentSizeChange,
  renderItem,
  loadLayout,
  loadData,
  page,
  defaultPage,
  width,
  height,
  pageCount,
  ...props
}: ListMasonryPaginationProps<ItemT, S>) {
  const {
    maxItem,
    maxRatio,
    maxRatioPerItem,
    minItem,
    minRatioPerItem,
  } = useListMasonryData({width})

  const {
    batch,
    status,
    handleLayoutDimension,
    handleContentSizeChange,
  } = useListMasonryPaginationAction<ItemT, S>({
    data,
    stateData,
    refreshDefaultEnabled,
    refreshing,
    search,
    cache,
    limitPerPage,
    minItem,
    maxItem,
    maxRatio,
    minRatioPerItem,
    maxRatioPerItem,
    loadData,
    loadLayout,
    onRefresh,
    onLayoutDimension,
    onContentSizeChange,
    assertPage: page,
    pageCount,
    defaultPage,
  })

  const handleKeyExtractor = useCallback(
    ({items}: ListMasonryBatchData<ItemT>) =>
      items.map((col) => keyExtractor(col.item)).join(','),
    [keyExtractor],
  )

  const handleRenderContentSpace = useCallback(
    (row: ListMasonryBatchData<ItemT>, index: number) =>
      isListMasonryHasEmptySpace({
        batch,
        index,
        maxItem,
        maxRatio,
      }) && <StyledItem flex={maxRatio - row.ratio} />,
    [batch, maxRatio, maxItem],
  )

  const handleRenderContentItems = useCallback(
    (row: ListMasonryBatchData<ItemT>, index: number) =>
      row.items.map(({item, ratio}) => (
        <StyledItem key={keyExtractor(item)} flex={ratio}>
          {renderItem(item, index)}
        </StyledItem>
      )),
    [keyExtractor, renderItem],
  )

  const handleRenderRow = useCallback(
    (row: ListMasonryBatchData<ItemT>, index: number) => (
      <StyledSectionRowBase
        aspectRatio={
          isListMasonryHasEmptySpace({
            batch,
            index,
            maxItem,
            maxRatio,
          })
            ? maxRatio
            : row.ratio
        }>
        <StyledSectionRow>
          {handleRenderContentItems(row, index)}
          {handleRenderContentSpace(row, index)}
        </StyledSectionRow>
      </StyledSectionRowBase>
    ),
    [
      batch,
      maxItem,
      maxRatio,
      handleRenderContentItems,
      handleRenderContentSpace,
    ],
  )

  return (
    <ListPagination
      {...props}
      width={width}
      height={height}
      data={batch}
      status={status}
      keyExtractor={handleKeyExtractor}
      renderItem={handleRenderRow}
      onLayoutDimension={handleLayoutDimension}
      onContentSizeChange={handleContentSizeChange}
    />
  )
}
