import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {ButtonIcon, Icon, Input, List} from 'common/components'
import {FirebaseMessageListItem, MessageMode, ObjectState} from 'types'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {useDebounce, useFirebaseMessageList} from 'utils'
import {translate} from 'i18n'
import GiftShopMessageListItem from './GiftShopMessageListItem'
import GiftShopMessageRoomEmpty from './GiftShopMessageRoomEmpty'

const StyledSearchBar = styled.div`
  padding-left: ${convertUnit(20)};
  padding-right: ${convertUnit(20)};
  padding-top: ${convertUnit(16)};
  padding-bottom: ${convertUnit(16)};
`

const StyledIcon = styled(ButtonIcon)`
  cursor: pointer;
  width: ${convertUnit(44)};
  height: ${convertUnit(44)};
  border-radius: ${convertUnit(50)};
  position: absolute;
  bottom: ${convertUnit(20)};
  right: ${convertUnit(35)};
`

export interface GiftShopMessageListHomeProps {
  stateMode: ObjectState<MessageMode>;
}

export default function GiftShopMessageList({
  stateMode,
}: GiftShopMessageListHomeProps) {
  const {id: userId} = useSelector('user') || {}
  const {data, initialize} = useFirebaseMessageList(userId || '')
  const setMode = stateMode[1]
  const [search, setSearch] = useState('')
  const debounce = useDebounce()
  
  const handleKeyExtractor = useCallback(
    (item: FirebaseMessageListItem) => item.id,
    [],
  )

  const handleRenderItem = useCallback(
    (item: FirebaseMessageListItem) => (
      <GiftShopMessageListItem
        data={item}
        search={search}
      />
    ),
    [search]
  )

  const handleRenderEmpty = useMemo(
    () => (
      initialize ? <GiftShopMessageRoomEmpty /> : undefined
    ), [initialize])

  const handleRenderHeader = useMemo(
    () => (
      <StyledSearchBar>
        <Input
          placeholder={translate('global:search')}
          leftIcon={(<Icon type="search" color="gray_3" />)}
          onChangeText={(text) => debounce(() => setSearch(text))}
        />
      </StyledSearchBar>
    ), [debounce])

  return (
    <>
      <List
        listHeaderElement={handleRenderHeader}
        data={data}
        keyExtractor={handleKeyExtractor}
        renderItem={handleRenderItem}
        listEmptyElement={handleRenderEmpty}
      />
      <StyledIcon
        iconType="pencil"
        iconColor="white_1"
        onClick={() => setMode('new-message')}
      />
    </>
  )
}
