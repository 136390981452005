import {lazy} from 'react'

const AuthEasySignupScreen = lazy(
  () => import('./AuthEasySignupScreen'),
)

const AuthEasySignupSelfieScreen = lazy(
  () => import('./AuthEasySignupSelfieScreen'),
)

const AuthEasySignupSSOScreen = lazy(
  () => import('./AuthEasySignupSSOScreen'),
)

const AuthEasySignupVerificationScreen = lazy(
  () => import('./AuthEasySignupVerificationScreen'),
)

const AuthEasySignupWelcomeScreen = lazy(
  () => import('./AuthEasySignupWelcomeScreen'),
)

export {AuthEasySignupScreen, AuthEasySignupSelfieScreen, AuthEasySignupSSOScreen, AuthEasySignupVerificationScreen, AuthEasySignupWelcomeScreen}

export * from './AuthEasySignupScreenParam'
