import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  GIFTSHOP_SEARCH_LIMIT,
  SERVICE_CANCELLATION_SEARCH_CREATOR,
  SERVICE_CANCELLATION_SEARCH_EVENT,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {
  GiftShopGetExploreCreatorResponse,
  TreeSearchEventsResponse,
} from 'types'
import {useDidMount} from 'utils'
import {ActivityIndicator, List, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateSearchCreatorAndTreeProps} from './GiftShopTemplateSearchCreatorAndTreeProps'
import GiftShopTemplateSearchCreatorItem from './GiftShopTemplateSearchCreatorItem'
import GiftShopTemplateSearchTreeItem from './GiftShopTemplateSearchTreeItem'

const StyledEmptyContainer = styled.div`
  padding: ${convertUnit(20)};
  text-align: center;
`

export default function GiftShopTemplateSearchCreatorAndTree({
  search,
  searchOnEmpty = false,
}: GiftShopTemplateSearchCreatorAndTreeProps) {
  const {translate} = useTranslation()
  const [data, setData] = useState<
    ReadonlyArray<TreeSearchEventsResponse | GiftShopGetExploreCreatorResponse>
  >([])
  const [loading, setLoading] = useState(false)

  const handleLoadSearchData = useCallback(async () => {
    const temp: Array<
      TreeSearchEventsResponse | GiftShopGetExploreCreatorResponse
    > = []
    setLoading(true)

    await requestData('tree_search_events', {
      params: {
        page: 1,
        limit: GIFTSHOP_SEARCH_LIMIT,
        q: search,
      },
      cancelId: SERVICE_CANCELLATION_SEARCH_EVENT,
      onRequestSuccess: ({status, data: {result}}) => {
        status === 200 && result.forEach((item) => temp.push(item))
      },
    })

    await requestData('giftshop_get_explore_creator', {
      params: {
        page: 1,
        limit: GIFTSHOP_SEARCH_LIMIT,
        q: search,
      },
      cancelId: SERVICE_CANCELLATION_SEARCH_CREATOR,
      onRequestSuccess: ({status, data: {result}}) => {
        status === 200 && result.forEach((item) => temp.push(item))
      },
    })

    setData(temp)
    setLoading(false)
  }, [search, setLoading])

  const handleKeyExtractor = useCallback(
    (item: TreeSearchEventsResponse | GiftShopGetExploreCreatorResponse) =>
      'creator_id' in item ? item.creator_id : item.tag_id + item.tree_id,
    [],
  )

  const handleRenderItem = useCallback(
    (item: TreeSearchEventsResponse | GiftShopGetExploreCreatorResponse) =>
      'creator_id' in item ? (
        <GiftShopTemplateSearchCreatorItem item={item} />
      ) : (
        <GiftShopTemplateSearchTreeItem item={item} />
      ),
    [],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <StyledEmptyContainer>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Paragraph fontSize="m" color="gray_5">
            {translate('giftShop:searchCreatorAndFotoTreeNotFound')}
          </Paragraph>
        )}
      </StyledEmptyContainer>
    ),
    [loading, translate],
  )

  useDidMount(() => {
    searchOnEmpty && handleLoadSearchData()
  })

  useEffect(() => {
    handleLoadSearchData()
  }, [handleLoadSearchData, search])

  return (
    <List
      data={data}
      keyExtractor={handleKeyExtractor}
      renderItem={handleRenderItem}
      listEmptyElement={handleRenderEmpty}
    />
  )
}
