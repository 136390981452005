import React, {useCallback} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {getBorder, useHistory} from 'utils'
import {Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateSearchTreeItemProps} from './GiftShopTemplateSearchTreeItemProps'

const StyledContainer = styled.div`
  ${({theme}) => ({
    ':hover': {
      color: theme.white_1,
      backgroundColor: theme.primary_1,
    },
  })}
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: ${convertUnit(8)} ${convertUnit(12)};
  gap: ${convertUnit(12)};
`

const StyledImage = styled(Image)`
  width: ${convertUnit(32)};
  aspect-ratio: 3/4;
  border: ${({theme}) => getBorder(1, 'solid', theme.gray_1)};
  border-radius: ${convertUnit(6)};
  box-sizing: border-box;
  object-fit: contain;
  background-color: ${({theme}) => theme.white_1};
`

const StyledTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(4)};
  align-items: center;
  justify-content: flex-start;
`

const StyledFotoTreeImage = styled(Image)`
  width: ${convertUnit(12)};
  aspect-ratio: 1;
  object-fit: contain;
`

export default function GiftShopTemplateSearchTreeItem({
  item,
}: GiftShopTemplateSearchTreeItemProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const fototreeIcon = IMAGE_ASSET('tree', 'icons/pinpoint-community.png')

  const handleClickItem = useCallback(() => {
    history.pushQuery({
      path: 'tree_events_bib_masonry',
      state: {
        tree_url: item.url || '',
        tree_name: item.tree_name || '',
        fromScan: false,
      },
      queryObject: {
        tree_id: item.tree_id,
        tag_id: item.tag_id,
        search: '',
      },
    })
  }, [history, item])

  return (
    <StyledContainer onClick={handleClickItem}>
      <StyledImage src={item.url} alt={item.tree_name || ''} />
      <div>
        <Paragraph fontSize="m" fontWeight="bold">
          {item.tree_name}
        </Paragraph>
        <StyledTypeContainer>
          <StyledFotoTreeImage
            src={fototreeIcon}
            alt={translate('global:fototree')}
          />
          <Paragraph>{translate('global:fototree')}</Paragraph>
        </StyledTypeContainer>
      </div>
    </StyledContainer>
  )
}
