import {TranslationKeyTree} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_TREE_EN: TranslationResource<TranslationKeyTree> = {
  translation: {
    'tree:addFarmer': 'Add Farmer',
    'tree:addFarmerExceedLimit':
      'Maximum farmer that can be added is {{count}}',
    'tree:addFarmerInputPlaceholder': 'Search by username',
    'tree:addFarmerSuccess': 'Success!',
    'tree:addFarmerSuccessDescription':
      'Your invitation has been sent successfully.',
    'tree:addFarmerNotFound': 'Username is not found',
    'tree:addHostInfoCreatorPriceTitle': 'Set Creator Price',
    'tree:addHostInfoCreatorPriceSubtitle':
      'You can change this later in the manage menu.',
    'tree:addHostInfoErrorHashtagContainSpecialChar':
      'Host name cannot contains special characters except (_) underscore',
    'tree:addHostInfoErrorHashtagAlreadyExist':
      'This name has been used by another host, try using other name',
    'tree:addHostInfoErrorHostNameContainSpecialChar':
      'Host name cannot contains special characters except (.) dot',
    'tree:addHostInfoErrorInvalidFileFormat':
      'Invalid file format. Please try again',
    'tree:addHostInfoErrorMaxFee': 'Maximum fee is {{fee}}%',
    'tree:addHostInfoErrorMaxFileSize': 'Maximum size is 50 MB',
    'tree:addHostInfoErrorMaxPrice': 'Max price is {{price}}',
    'tree:addHostInfoErrorMaxPriceMoreThanMinPrice':
      'Maximum price must be more than minimum price',
    'tree:addHostInfoErrorMinPriceLessThanMaxPrice':
      'Minimum price must not exceed maximum price',
    'tree:addHostInfoFirstBuyChecked': 'First buy content is now Free',
    'tree:addHostInfoFirstBuyDescription': 'Free price for First Buy Content ',
    'tree:addHostInfoFirstBuySubtitle':
      'You can change this later in the manage menu.',
    'tree:addHostInfoFirstBuyTitle': 'First Buy Content',
    'tree:addHostInfoFirstBuyUnchecked':
      'First buy content is now normal price',
    'tree:addHostInfoHostNameLabel': 'Host Name',
    'tree:addHostInfoHostNamePlaceholder': 'Input your host name',
    'tree:addHostInfoHostNameSubtitle':
      'You can change this later in the manage menu.',
    'tree:addHostInfoHostNameTitle': 'Create Host Name',
    'tree:addHostInfoHeader': 'Add Host Information',
    'tree:addHostInfoHostFeeIndependentLabel':
      'Host Fee for INDEPENDENT Creator',
    'tree:addHostInfoHostFeeInternalLabel': 'Host Fee for INTERNAL Creator',
    'tree:addHostInfoHostFeeLabel': 'Host Fee',
    'tree:addHostInfoHostFeePlaceholder': 'Input your host fee',
    'tree:addHostInfoHostFeeSubtitle':
      'You can change this later in the manage menu.',
    'tree:addHostInfoHostFeeTitle': 'Set Host Fee',
    'tree:addHostInfoLogoChange': 'Change Logo',
    'tree:addHostInfoLogoSubtitle': 'Add your company logo here.',
    'tree:addHostInfoLogoTitle': 'Add Logo Picture',
    'tree:addHostInfoMaxPriceLabel': 'Max. Price',
    'tree:addHostInfoMaxPricePlaceholder': 'Input maximum price',
    'tree:addHostInfoMinPriceLabel': 'Min. Price',
    'tree:addHostInfoMinPricePlaceholder': 'Input minimum price',
    'tree:availableBalanceLabel': 'Available #Host Balance',
    'tree:beAHost': 'Be a Host',
    'tree:beAHostModalHashtagDesc': 'Claim your own #Host_Name_Business_Name',
    'tree:beAHostModalPlaceDesc':
      'Hosts can be tourism sites, parks, malls, hotels, ballrooms, cafes, restaurants, sportclubs, or events',
    'tree:beAHostModalRevenueDesc':
      'Create a new revenue stream with your current Business',
    'tree:beAHostNotCreatorSnackbarMessage': 'You need to be a creator first.',
    'tree:bio': 'Tree Bio',
    'tree:bioPlaceholder': 'Tree description',
    'tree:changePhotoButton': 'Change Photo',
    'tree:changeBioPlaceholder': 'Tell about your FotoTree',
    'tree:changeLocationButton': 'Change Location',
    'tree:chooseLocationButton': 'Choose Location',
    'tree:creatorViewCameraLive': 'Camera Live',
    'tree:creatorViewCameraLiveAction':
      'Camera Live until <date>{{date}}</date>',
    'tree:creatorViewCameraLiveDescription':
      'Set your shooting duration at this location',
    'tree:creatorViewCameraLiveInputError': 'Max. time is 24 hours.',
    'tree:creatorViewCameraLiveSnackbarMessage':
      'To use "Camera Live" feature, please upgrade your account into verified creator.',
    'tree:deleteVideoConfirmation': 'Are you sure you want to remove video?',
    'tree:editHostChangeBioHeader': 'Change Bio',
    'tree:editHostChangeHostFeeHeader': 'Change Host Fee',
    'tree:editHostChangeHostNameHeader': 'Change Host Name',
    'tree:editHostChangeMaxPriceHeader': 'Change Maximum Price',
    'tree:editHostChangeMinPriceHeader': 'Change Minimum Price',
    'tree:editHostFeeDisclaimer': 'The default value is 20% from Creator Price',
    'tree:editHostFirstBuyModalDescription':
      'Are you sure you want to make changes?',
    'tree:editHostFormLabelBio': 'Bio',
    'tree:editHostFormLabelHostFee': 'Host Fee',
    'tree:editHostFormLabelHostFeeIndependent':
      'Host Fee for INDEPENDENT Creator',
    'tree:editHostFormLabelHostFeeInternal': 'Host Fee for INTERNAL Creator',
    'tree:editHostFormLabelHostName': 'Host Name',
    'tree:editHostFormLabelMaxPrice': 'Max. Price',
    'tree:editHostFormLabelMinPrice': 'Min. Price',
    'tree:editHostFormPlaceholderBio': 'Describe yourself',
    'tree:editHostFormPlaceholderHostFee': 'Input your host fee',
    'tree:editHostFormPlaceholderMaxPrice': 'Input your maximum price',
    'tree:editHostFormPlaceholderMinPrice': 'Input minimum price',
    'tree:editHostImageChangePicture': 'Change Picture',
    'tree:editHostIndependentOfficialCreatorTitle': 'Independent Creator',
    'tree:editHostInternalOfficialCreatorTitle': 'Internal Creator',
    'tree:editHostProfileBioEmpty': 'No Bio',
    'tree:editHostProfileBioTitle': 'Bio (Optional)',
    'tree:editHostProfileHeader': 'Edit Host',
    'tree:editHostProfileHostFeeTitle': 'Host Fee',
    'tree:editHostFormPlaceholderHostName': 'Input your host name',
    'tree:editHostProfileMaxPriceTitle': 'Max. Price',
    'tree:editHostProfileMinPriceTitle': 'Min. Price',
    'tree:editHostProfileSettingPriceTitle': 'Setting Creator Price',
    'tree:editTree': 'Edit Bio FotoTree',
    'tree:enableGPS': 'Enable GPS',
    'tree:eventBIBContentSaveSuccessMessage': 'File saved on the "FotoYu" tab.',
    'tree:eventBIBEmptyMessageFirstHalf':
      'Yes Boss, RoboYu is searching for your content.',
    'tree:eventBIBEmptyMessageSecondHalf':
      'RoboYu will send a notification after finding it!',
    'tree:eventBIBSearchErrorNumeric': 'Numeric only.',
    'tree:eventBIBSearchPlaceholder': 'Type your BIB number',
    'tree:eventDate': 'Tree Event Date',
    'tree:eventDateEnd': 'End Date',
    'tree:eventDateEndErrorLessThanStartDate':
      'End date must not be set before the start date.',
    'tree:eventDateEndErrorMoreThanMaxDate':
      'End date must not exceed {{max}} days from the start date.',
    'tree:eventDateStart': 'Start Date',
    'tree:eventSearchEmptySubtitle': 'Please try again using another keyword.',
    'tree:eventSearchEmptyTitle':
      'Sorry! We cannot find the Event / FotoTree you are looking for',
    'tree:eventSearchPlaceholder': 'Search by event name',
    'tree:footprints': 'Footprints',
    'tree:fototreeFootprints': 'FotoTree Footprints',
    'tree:fototreeFootprintsTreeCount': '{{count}} tree',
    'tree:fototreeFootprintsTreeCount_plural': '{{count}} trees',
    'tree:fototreeFootprintsLeafCount': '{{count}} memory leaves',
    'tree:fototreeName': 'FotoTree Name',
    'tree:history': 'History',
    'tree:historyDescription':
      'Check all of host transaction history in one place',
    'tree:hostCreatorType': '',
    'tree:hostCreatorType_independent': 'Independent',
    'tree:hostCreatorType_internal': 'Internal',
    'tree:hostCancelInvitationDescription':
      'Are you sure you want to cancel this invitation?',
    'tree:hostCancelInvitationSuccess': 'Invitation cancelled.',
    'tree:hostCancelInvitationTitle': 'Cancel Invitation',
    'tree:hostManageAdminAddAdmin': 'Add Admin',
    'tree:hostManageAdminAddAdminErrorAlreadyInAnotherHost':
      'User already registered as host admin by another host.',
    'tree:hostManageAdminCaution':
      'Maximum host admin that you can promote is 5',
    'tree:hostManageAdminDemoteAdmin': 'Demote Admin',
    'tree:hostManageAdminDemoteAdminContent':
      'Are you sure you want to demote this admin?',
    'tree:hostManageAdminNoAdmin': "You don't have admin yet",
    'tree:hostManageAdminUsernameNotFound': 'Username not found',
    'tree:hostManageAdminSuccessInviteSubtitle':
      'Your promotion has been sent successfully.',
    'tree:hostManageAdminPeopleSelected': '{{qty}} People Selected',
    'tree:hostManageCreatorCaution':
      'You can only invite 5 internal creators at once',
    'tree:hostManageCreatorAddCreator': 'Add Internal Creator',
    'tree:hostManageCreatorAddCreatorErrorAlreadyInAnotherHost':
      'User already registered as internal creator by another host.',
    'tree:hostManageCreatorAddCreatorIndependent': 'Add Independent Creator',
    'tree:hostManageCreatorAddCreatorIndependentHeader':
      'Add Independen Creators',
    'tree:hostManageCreatorAddCreatorInternal': 'Add Internal Creator',
    'tree:hostManageCreatorAddCreatorInternalHeader': 'Add Internal Creators',
    'tree:hostManageCreatorDemoteCreator': 'Demote Creator',
    'tree:hostManageCreatorDemoteCreatorContent':
      'Are you sure you want to demote this internal creator?',
    'tree:hostManageCreatorSuccessInviteSubtitle':
      'Your invitation has been sent successfully.',
    'tree:hostManageCreatorNoCreator': "You don't have internal creator yet",
    'tree:hostNotification': 'Host Notification',
    'tree:hostNotificationEmptyInfo': 'See notifications from host here.',
    'tree:hostNotificationAdmin': 'host admin.',
    'tree:hostNotificationAdminInvitation': 'Wants to promote you as a ',
    'tree:hostNotificationInvitationConfirmationAccept':
      'Are you sure you want to accept the invitation? You can accept one invitation only.',
    'tree:hostNotificationInvitationConfirmationReject':
      'Are you sure you want to reject the invitation?',
    'tree:hostNotificationOfficialCreatorIndependent': 'independent creator.',
    'tree:hostNotificationOfficialCreatorIndependentInvitation':
      'Wants to promote you as an ',
    'tree:hostNotificationOfficialCreatorInternal': 'internal creator.',
    'tree:hostNotificationOfficialCreatorInternalInvitation':
      'Wants to promote you as an ',
    'tree:hostProfileAdminCount': 'Admins',
    'tree:hostProfileCreatorCount': 'Creators',
    'tree:hostProfileSellCount': 'Sell',
    'tree:hostRegistrationFormAccountHolderLabel': 'Account Holder Name',
    'tree:hostRegistrationFormAccountHolderPlaceholder':
      'Add Account Holder Name',
    'tree:hostRegistrationFormAccountNumberLabel': 'Account Number',
    'tree:hostRegistrationFormAccountNumberPlaceholder': 'Add Account Number',
    'tree:hostRegistrationFormAddressNumberLabel': 'Number',
    'tree:hostRegistrationFormAddressNumberPlaceholder':
      'Add Your Address Number',
    'tree:hostRegistrationFormBankNameLabel': 'Bank Name',
    'tree:hostRegistrationFormBankNamePlaceholder': 'Add Bank Name',
    'tree:hostRegistrationFormBusinessFieldLabel': 'Business Fields',
    'tree:hostRegistrationFormBusinessFieldPlaceholder': 'Add Business Field',
    'tree:hostRegistrationFormCompanyLabel': 'Company Name',
    'tree:hostRegistrationFormCompanyPlaceholder': 'PT. Your Company',
    'tree:hostRegistrationFormCountryLabel': 'Country',
    'tree:hostRegistrationFormCountryPlaceholder': 'Indonesia',
    'tree:hostRegistrationFormDistrictLabel': 'District',
    'tree:hostRegistrationFormDistrictPlaceholder': 'Add your District',
    'tree:hostRegistrationFormPostalCodeLabel': 'Postal Code',
    'tree:hostRegistrationFormPostalCodePlaceholder': 'Add your Postal Code',
    'tree:hostRegistrationFormProvinceLabel': 'Province',
    'tree:hostRegistrationFormProvincePlaceholder': 'Add your Province',
    'tree:hostRegistrationFormRegencyLabel': 'Regency/City',
    'tree:hostRegistrationFormRegencyPlaceholder': 'Add your Regency/City',
    'tree:hostRegistrationFormStreetLabel': 'Street',
    'tree:hostRegistrationFormStreetPlaceholder': 'Add your Street Name',
    'tree:hostRegistrationFormSubDistrictLabel': 'Sub district',
    'tree:hostRegistrationFormSubDistrictPlaceholder': 'Add your Sub district',
    'tree:hostRegistrationFormSubtitle':
      "Fill the form below with your company information, so we can verify your company's identity",
    'tree:hostRegistrationFormTitle': 'Registration Form',
    'tree:hostRegistrationHeader': 'Host Registration',
    'tree:hostRegistrationInvalidAccountHolderMustAlphabet':
      'Account holder name must be alphabet',
    'tree:hostRegistrationInvalidCompanyAlreadyExist':
      'Company name already exists',
    'tree:hostRegistrationInvalidCompanyNameSpecialChar':
      'Company name cannot contains special characters except (.) dot',
    'tree:hostRegistrationInvalidDocumentSizeErrorMessage':
      'Maximum file size is 5 Mb',
    'tree:hostRegistrationInvalidDocumentTypeErrorMessage':
      'File format should be .pdf or .docx',
    'tree:hostRegistrationProcessModalMessage':
      'Please wait while we checking your information. We will notify you after the process is done.',
    'tree:hostRegistrationProcessModalTitle': 'Thank You!',
    'tree:hostRegistrationSelectLocationButton': 'Select the Location',
    'tree:hostRegistrationSelectLocationDescription': 'Selected Location:',
    'tree:hostRegistrationUploadDocumentButton': 'Upload a Document',
    'tree:hostRegistrationUploadDocumentDescription': 'Uploaded document',
    'tree:hostRegistrationUploadDocumentPlaceholder': 'No file uploaded yet',
    'tree:hostRegistrationUploadDocumentSubtitle':
      '.pdf or .docx file, maximum size is 5MB',
    'tree:hostRegistrationUploadDocumentTitle': 'Upload Company Documents',
    'tree:hostValidateMaxCharacter': 'Maximum character is {{count}}',
    'tree:hostValidateMinCharacter': 'Minimum character is {{count}}',
    'tree:independent': 'Independent',
    'tree:internal': 'Internal',
    'tree:invisible': 'Only visible to you',
    'tree:leafAMemory': 'Leaf a Memory',
    'tree:leafAMemoryHeader': 'Leaf a Memory',
    'tree:leafCountIncludePrivate': 'Leaf count including private leaves.',
    'tree:leafEmpty': 'No content yet.',
    'tree:locPermissionModalContent':
      'You will need to enable the GPS service to continue.',
    'tree:location': 'Tree Location',
    'tree:locationPlaceholder': 'Please choose a location',
    'tree:manageFarmer': 'Manage Farmer',
    'tree:manageFarmerInfo': 'Maximum farmer that you can add is 5',
    'tree:manageFarmerEmpty': "You don't have farmer yet",
    'tree:messageError_alreadyRegister':
      'User already registered as internal creator or host admin by another host',
    'tree:name': 'Tree Name',
    'tree:namePlaceholder': 'Input the tree name',
    'tree:nearestFototree': 'Nearest FotoTree',
    'tree:plantFototree': 'Plant a FotoTree',
    'tree:plantFototreeInvalid':
      'You already own a FotoTree, can’t plant more than 1 FotoTree.',
    'tree:plantFototreeInvalid_verified':
      'To plant a FotoTree, please upgrade your account into verified creator.',
    'tree:plantFototreeSuccess':
      'Thank you for planting the FotoTree seed! Please wait for the root to emerge.',
    'tree:plantFototreeVideoExtension': 'MP4, MPEG4, MOV, HEIV',
    'tree:plantFototreeVideoDuration':
      'Video duration must be between 3s - 15s.',
    'tree:plantFototreeVideoInvalidFormat':
      'The video file is invalid or not allowed. Only files with these following extensions are allowed: MP4, MPEG4, MOV, HEIV.',
    'tree:plantFototreeVideoMaxSize': 'Max size 30MB',
    'tree:plantFototreeVideoMaxWidth': 'Max width 1080',
    'tree:postMemory': 'Post your memory',
    'tree:previewQrCode': 'Preview QR Code',
    'tree:previewQrCodeDownload': 'Download QR Code',
    'tree:previewQrCodeTitle': 'Scan Your Photo',
    'tree:previewQrCodeDescription': '',
    'tree:previewQrCodeDescription_1': 'Open your camera.',
    'tree:previewQrCodeDescription_2': 'Direct your camera to the QR Code.',
    'tree:previewQrCodeDescription_3': 'Open the link that appears.',
    'tree:previewQrCodeDescription_4': 'Register account and get your photo.',
    'tree:profileEmptyBio': 'No bio yet.',
    'tree:profileHeader': '#Host',
    'tree:profileManageAdmin': 'Manage Admin',
    'tree:profileManageButton': 'Manage',
    'tree:profileManageEditHost': 'Edit Host',
    'tree:profileManageHostBalance': 'Host Balance',
    'tree:profileManageOfficialCreator': 'Manage Internal Creators',
    'tree:profilePicture': 'Tree Profile Picture',
    'tree:profilePictureEmpty': 'No file uploaded yet.',
    'tree:readFurther': 'Read further',
    'tree:removeFarmer': 'Remove Farmer',
    'tree:removeFarmerDescription':
      'Are you sure you want to remove this farmer?',
    'tree:searchFototree': 'Search FotoTree',
    'tree:searchFototreeEmptyMessage':
      'Oops, there’s no FotoTree with that name, please check the keyword, or try again with a different keyword.',
    'tree:searchFototreeInputPlaceholder': 'Input the name of a FotoTree',
    'tree:searchTransactionInputPlaceholder': 'Search Transaction History',
    'tree:selected': 'Selected ({{count}})',
    'tree:shareFototree': 'Share FotoTree',
    'tree:similarFototreeName': 'Similar Tree Name:',
    'tree:selectedFarmer': '{{count}} Farmer Selected',
    'tree:tallestTree': 'Tallest Tree',
    'tree:tellYourStory': 'Tell your story',
    'tree:treeName': 'Tree Name',
    'tree:treeTreatment': 'Tree Treatment',
    'tree:treeType_community': 'Community',
    'tree:treeType_event': 'Event',
    'tree:treeType_place': 'Place',
    'tree:trimmingLeaf': 'Trimming the Leaves',
    'tree:trimmingLeafDescription':
      'Are you sure you want to trim these leaves? Content deletion cannot be undone',
    'tree:type': 'Tree Type',
    'tree:uploadRequirement': 'JPG, JPEG, PNG, HEIC. (Max. 15MB)',
    'tree:validationRequired': 'This field is required',
    'tree:whereToPlantFotoTree': 'Where do you want to plant your FotoTree?',
    'tree:withdraw': 'Withdraw',
    'tree:withdrawConfirmationDisclaimer':
      'Withdrawals will be processed within 7 days.',
    'tree:withdrawDisclaimer': 'You can only withdraw 1x/month',
    'tree:withdrawMaximumWarning':
      'You have reached the withdrawal limit. (Withdrawals can be made 1x on each month)',
    'tree:withdrawDescription': 'Withdraw your balance to your bank account',
    'tree:validationWithdrawField': 'Please input a valid amount of withdrawal',
    'tree:viewLocation': 'View Location',
    'tree:visitedFototree': 'You have visited <name>{{name}}</name> FotoTree.',
  },
}
