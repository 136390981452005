import React, {useMemo, useRef} from 'react'
import ReactHlsPlayer from '@gumlet/react-hls-player'
import styled from 'styled-components'
import {
  GIFT_SHOP_UPLOAD_VIDEO_SEND_TO_FACE_TYPE,
  WINDOW_MODE_MOBILE_WIDTH,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {WindowModeType} from 'types'
import {getFileSize} from 'utils'
import {useWindowMode} from 'windows'
import {Button, Icon, Image, InputPicker, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopUploadContentItemVideoImageProps} from './GiftShopUploadContentItemVideoImageProps'

interface StyledContentContainerProps {
  mode: WindowModeType
}

const StyledContentContainer = styled.div<StyledContentContainerProps>`
  display: flex;
  padding-top: ${convertUnit(20)};
  padding-bottom: ${convertUnit(20)};
  ${({mode}) => ({
    flexDirection: mode === 'tablet' ? 'row' : 'column',
    gap: mode === 'tablet' ? convertUnit(20) : convertUnit(12),
    maxWidth: mode === 'website' ? convertUnit(226) : '100%',
    justifyContent: mode === 'tablet' ? 'space-between' : 'unset',
    paddingLeft: mode === 'mobile' ? 0 : convertUnit(20),
    paddingRight: mode === 'tablet' ? convertUnit(20) : 0,
  })}}
`

const StyledVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(4)};
`

const StyledRelativeContainer = styled.div`
  position: relative;
`

const StyledVideoIconContainer = styled.div`
  position: absolute;
  bottom: ${convertUnit(12)};
  left: ${convertUnit(12)};
  z-index: 3;
`

const StyledVideo = styled.video`
  object-fit: contain;
  border-radius: ${convertUnit(8)};
  width: ${convertUnit(226)};
  height: ${convertUnit(226)};
  aspect-ratio: 1;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
    height: auto;
    aspect-ratio: auto;
  }
`

const StyledHls = styled(ReactHlsPlayer)`
  object-fit: contain;
  border-radius: ${convertUnit(8)};
  width: ${convertUnit(226)};
  height: ${convertUnit(226)};
  aspect-ratio: 1;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
    height: auto;
    aspect-ratio: auto;
  }
`

const StyledVideoInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledVideoNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(4)};
  justify-content: flex-start;
  align-items: flex-start;
`

const StyledVideoName = styled(Paragraph)`
  word-break: break-all;
`

const StyledLine = styled.hr`
  height: 1px;
  border: none;
  background-color: ${({theme}) => theme.white_4};
  margin: 0;
`

const StyledSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(12)};
  flex: 1;
`

const StyledSendToFacePlaceHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(12)};
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: ${convertUnit(8)};
  width: ${convertUnit(226)};
  height: ${convertUnit(226)};
  background-color: ${({theme}) => theme.white_3};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
    height: auto;
    aspect-ratio: auto 1;
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: 100%;
    height: auto;
    aspect-ratio: auto;
    padding: ${convertUnit(25)} 0;
  }
`

const StyledSendToFaceImage = styled(Image)`
  width: 100%;
  object-fit: contain;
  border-radius: ${convertUnit(8)};
`

const StyledUploadSendToFaceButton = styled(Button)`
  width: 100%;
`

export default function GiftShopUploadContentItemVideoImage({
  videoHeight,
  videoSize,
  videoTitle,
  videoSrc,
  videoWidth,
  sendToFaceSrc,
  isEdit = false,
  onLoadFaceFile,
}: GiftShopUploadContentItemVideoImageProps) {
  const {translate} = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)
  const mode = useWindowMode()
  const videoRef = useRef<HTMLVideoElement>(null)

  const handleRenderVideo = useMemo(
    () => (
      <StyledVideoContainer>
        <StyledRelativeContainer>
          <StyledVideoIconContainer>
            <Icon type="video-view" size={convertUnit(24)} color="white_1" />
          </StyledVideoIconContainer>
          {isEdit ? (
            <StyledHls playerRef={videoRef} src={videoSrc} />
          ) : (
            <StyledVideo autoPlay={false}>
              <source src={videoSrc} type="video/mp4" />
            </StyledVideo>
          )}
        </StyledRelativeContainer>
        <Paragraph fontWeight="medium" color="gray_3">
          {videoWidth} x {videoHeight}, {getFileSize(videoSize)}
        </Paragraph>
      </StyledVideoContainer>
    ),
    [isEdit, videoHeight, videoSize, videoSrc, videoWidth],
  )

  const handleRenderVideoFileName = useMemo(
    () => (
      <StyledVideoInfoContainer>
        <Paragraph fontSize="l" fontWeight="bold">
          {translate('giftShop:uploadTitleLabel')}
        </Paragraph>
        <StyledVideoNameContainer>
          <Icon
            type="video-view"
            size={convertUnit(20)}
            color="gray_4"
            style={{alignSelf: 'flex-start'}}
          />
          <StyledVideoName fontSize="m" color="gray_4">
            {videoTitle}
          </StyledVideoName>
        </StyledVideoNameContainer>
      </StyledVideoInfoContainer>
    ),
    [translate, videoTitle],
  )

  const handleRenderSendToFacePlaceHolder = useMemo(
    () => (
      <StyledSendToFacePlaceHolder>
        <Icon type="face-scan" color="gray_5" size={convertUnit(32)} />
        <Paragraph fontSize="m" fontWeight="medium" color="gray_5">
          {translate('giftShop:videoUploadSendToFacePlaceholder')}
        </Paragraph>
      </StyledSendToFacePlaceHolder>
    ),
    [translate],
  )

  const handleRenderUploadSendToFaceButton = useMemo(
    () => (
      <StyledUploadSendToFaceButton
        label={translate(
          sendToFaceSrc ? 'giftShop:changePhotoButton' : 'giftShop:uploadPhoto',
        )}
        backgroundColor="white_2"
        color="primary_5"
        backgroundHoverColor="white_4"
        onClick={() => {
          inputRef.current && inputRef.current.click()
        }}
      />
    ),
    [sendToFaceSrc, translate],
  )

  const handleRenderSendToFaceSection = useMemo(
    () => (
      <StyledSectionContainer>
        <Paragraph fontSize="l" fontWeight="bold">
          {translate('giftShop:sendToFace')}
        </Paragraph>
        {sendToFaceSrc ? (
          <StyledSendToFaceImage src={sendToFaceSrc} alt={videoTitle} />
        ) : (
          handleRenderSendToFacePlaceHolder
        )}
        {handleRenderUploadSendToFaceButton}
      </StyledSectionContainer>
    ),
    [
      handleRenderSendToFacePlaceHolder,
      handleRenderUploadSendToFaceButton,
      sendToFaceSrc,
      translate,
      videoTitle,
    ],
  )

  const handleRenderInputPicker = useMemo(
    () => (
      <InputPicker
        inputRef={inputRef}
        accept={GIFT_SHOP_UPLOAD_VIDEO_SEND_TO_FACE_TYPE.join(',')}
        onLoadFiles={(files) => onLoadFaceFile(files[0])}
      />
    ),
    [onLoadFaceFile],
  )

  return (
    <StyledContentContainer mode={mode}>
      {handleRenderInputPicker}
      <StyledSectionContainer>
        <Paragraph fontSize="l" fontWeight="bold">
          {translate('giftShop:videoPreview')}
        </Paragraph>
        {handleRenderVideo}
        {handleRenderVideoFileName}
      </StyledSectionContainer>
      {mode !== 'tablet' && <StyledLine />}
      {handleRenderSendToFaceSection}
    </StyledContentContainer>
  )
}
