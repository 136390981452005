import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {
  ForkygramGetCommentResponse,
  ForkygramReportCategoriesResponse,
  ForkygramSelectedComment,
} from 'types'
import {showSnackbar} from 'utils'
import {ConfirmationModal, ListLazy, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import {ForkygramCommentContentProps} from './ForkygramCommentModalContentProps'
import {ForkygramCommentComment} from '../ForkygramComment'
import {ForkygramReportContent} from '../../ReportModal'

const StyledEmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default function ForkygramCommentContent({
  postId,
  ownerId,
  stateCommentCount,
  stateRecipient,
  stateSearch,
}: ForkygramCommentContentProps) {
  const {translate} = useTranslation()
  const stateOpenModalDelete = useState(false)
  const stateOpenModalReport = useState(false)
  const stateSelectedComment = useState<ForkygramSelectedComment>({
    member_id: '',
    comment_id: '',
  })
  const {access_token} = useSelector('user') || {}
  const [openModalDelete, setOpenModalDelete] = stateOpenModalDelete
  const [openModalReport, setOpenModalReport] = stateOpenModalReport
  const selectedComment = stateSelectedComment[0]
  const setCommentCount = stateCommentCount[1]
  const [search, setSearch] = stateSearch

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('forkygram_get_comment', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {page, limit, post_id: postId},
      })
      return typeof response !== 'string' && response.status === 200
        ? response.data.result
        : null
    },
    [postId],
  )

  const handleDeleteComment = useCallback(() => {
    requestData('forkygram_delete_comment', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {
        comment_id: selectedComment.comment_id,
      },
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          setCommentCount((prev) => prev - 1)
          const date = new Date().getTime()
          setSearch(date)
        }
      },
    })
  }, [selectedComment.comment_id, setCommentCount, setSearch])

  const handleKeyExtractor = useCallback(
    (item: ForkygramGetCommentResponse) => item.comment_id,
    [],
  )

  const handleRenderItem = useCallback(
    (item: ForkygramGetCommentResponse) => (
      <ForkygramCommentComment
        data={item}
        ownerId={ownerId}
        stateSelectedComment={stateSelectedComment}
        stateOpenModalDelete={stateOpenModalDelete}
        stateRecipient={stateRecipient}
        stateOpenModalReport={stateOpenModalReport}
      />
    ),
    [
      ownerId,
      stateOpenModalDelete,
      stateOpenModalReport,
      stateRecipient,
      stateSelectedComment,
    ],
  )

  const handleRenderContent = useMemo(
    () => (
      <ListLazy
        loadData={handleLoadData}
        keyExtractor={handleKeyExtractor}
        renderItem={handleRenderItem}
        search={search}
        listEmptyElement={
          <StyledEmptyContainer>
            <Paragraph fontSize="s" color="gray_3">
              {translate('forkygram:emptyComment')}
            </Paragraph>
          </StyledEmptyContainer>
        }
      />
    ),
    [handleKeyExtractor, handleLoadData, handleRenderItem, search, translate],
  )

  const handleRenderDeleteConfirmation = useMemo(
    () => (
      <ConfirmationModal
        visible={openModalDelete}
        title={translate('forkygram:deletePostComment')}
        text={translate('forkygram:deletePostCommentMessage')}
        onConfirm={() => handleDeleteComment()}
        toggleModal={() => setOpenModalDelete(!openModalDelete)}
        cancelText={translate('global:cancel')}
        confirmText={translate('global:yesImSure')}
      />
    ),
    [handleDeleteComment, openModalDelete, setOpenModalDelete, translate],
  )

  const handleReportComment = useCallback(
    (category: ForkygramReportCategoriesResponse, description?: string) => {
      if (!access_token) {
        return
      }

      requestData('forkygram_post_report_comments', {
        data: {
          category_id: category.id,
          description,
          reported_id: selectedComment.comment_id,
          reported_member_id: selectedComment.member_id,
        },
        onRequestReceived: () => setOpenModalReport(!openModalReport),
        onRequestFailed: () =>
          showSnackbar(translate('forkygram:reportCommentFailed')),
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            showSnackbar(translate('forkygram:reportCommentSuccess'))
          }

          if (status === 422) {
            showSnackbar(translate('forkygram:reportCommentAlreadyReported'))
          }
        },
      })
    },
    [
      access_token,
      selectedComment.comment_id,
      selectedComment.member_id,
      setOpenModalReport,
      openModalReport,
      translate,
    ],
  )

  const handleRenderReportModal = useMemo(
    () => (
      <ForkygramReportContent
        visible={openModalReport}
        onSubmit={handleReportComment}
        toggleModal={() => setOpenModalReport(!openModalReport)}
      />
    ),

    [openModalReport, setOpenModalReport, handleReportComment],
  )

  return (
    <>
      {handleRenderDeleteConfirmation}
      {handleRenderReportModal}
      {handleRenderContent}
    </>
  )
}
