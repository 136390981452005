import React, {useState, useCallback, useMemo} from 'react'
import {useParams} from 'react-router'
import {useTranslation} from 'i18n'
import {GiftShopRootScreen} from 'pages'
import {requestData} from 'services'
import {TreeSettingMenus, TreeEditFotoTreeData} from 'types'
import {useDidMount, useLocation} from 'utils'
import {TreeEditTreeForm, TreeFototreeBioForm} from '../containers'

export default function TreeFototreeEditScreen() {
  const {translate} = useTranslation()
  const {alias}: {alias: string} = useParams()
  const stateActiveContent = useState<TreeSettingMenus>('profile')
  const activeContent = stateActiveContent[0]
  const {data, treeId} = useLocation('tree_fototree_edit').state
  const stateTreeInfo = useState<TreeEditFotoTreeData>(data)
  const setTreeInfo = stateTreeInfo[1]

  const handleLoadData = useCallback(() => {
    requestData('tree_get_fototree_detail', {
      useDefaultMessage: true,
      actionType: 'fetch',
      params: {tree_id: treeId},
      onRequestSuccess: ({data: {result}, status}) => {
        if (status === 200) {
          setTreeInfo({
            alias,
            bio: result.bio,
            id: treeId,
            name: result.name,
            url: result.url,
            video_intro_url: result.video_intro_url,
            event_end_date: result.event_end_date,
            event_start_date: result.event_start_date,
            category_name: result.category_name,
          })
        }
      },
    })
  }, [alias, setTreeInfo, treeId])

  const handleRenderContent = useMemo(() => {
    switch (activeContent) {
      case 'profile':
        return (
          <TreeEditTreeForm
            stateTreeInfo={stateTreeInfo}
            stateActiveContent={stateActiveContent}
          />
        )
      case 'bio':
        return (
          <TreeFototreeBioForm
            stateTreeInfo={stateTreeInfo}
            stateActiveContent={stateActiveContent}
          />
        )
    }
  }, [activeContent, stateActiveContent, stateTreeInfo])

  useDidMount(handleLoadData)

  return (
    <GiftShopRootScreen
      headerTitle={translate('tree:editTree')}
      showBackIcon
      showBadgeIcon={false}
      onBackButtonClicked={() =>
        history.go(activeContent === 'profile' ? -1 : 0)
      }>
      {handleRenderContent}
    </GiftShopRootScreen>
  )
}
