import React, {CSSProperties, useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {Icon, ListLazy, Paragraph} from 'common/components'
import {FORKYGRAM_COMMENT_LIMIT_DAY, WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {getThemeColor, showSnackbar, useHistory} from 'utils'
import {ForkygramRecipientData, TreeFototreeCommentResponse} from 'types'
import {FototreeGlobalChatProps} from './FototreeGlobalChatProps'
import FototreeGlobalChatInput from './FototreeGlobalChatInput'
import FototreeGlobalChatItem from './FototreeGlobalChatItem'

interface StyledPreviewContainerProps {
  display: CSSProperties['display']
}

const StyledContainer = styled.div`
  position: fixed;
  background-color: ${({theme}) => getThemeColor(theme, 'black', 0.95)};
  height: 100%;
  width: 100%;
  max-width: ${convertUnit(620)};
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
`

const StyledPreviewContainer = styled.div<StyledPreviewContainerProps>`
  position: fixed;
  background-color: ${({theme}) => getThemeColor(theme, 'black', 0.5)};
  width: calc(100% - ${convertUnit(40)});
  max-width: calc((2 / 3) * ${convertUnit(596)});
  border-radius: ${convertUnit(8)};
  bottom: ${convertUnit(78)};
  padding: ${convertUnit(4)} 0;
  cursor: pointer;
  display: ${({display}) => display};

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: calc((2 / 3) * 100%);
  }
`

const StyledContentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

const StyledEmptyContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const StyledIconBackWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: ${convertUnit(42)};
  height: ${convertUnit(42)};
  padding: ${convertUnit(20)} ${convertUnit(12)};
  cursor: pointer;

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: ${convertUnit(20)} ${convertUnit(22)};
  }
`

export default function FototreeGlobalChat({
  stateData,
  stateOverlay,
  stateLoginModal,
  userType,
  treeId,
  isUserScroll,
}: FototreeGlobalChatProps) {
  const history = useHistory()
  const user = useSelector('user')
  const {translate} = useTranslation()
  const {kycStatus} = useSelector('yuserActivationState')
  const stateRecipient = useState<ForkygramRecipientData>({
    comment_id: '',
    username: '',
  })
  const stateNewReply = useState<TreeFototreeCommentResponse>()
  const stateMessage = useState('')
  const stateSearch = useState(0)
  const stateLoading = useState(false)
  const [recipient, setRecipient] = stateRecipient
  const [overlay, setOverlay] = stateOverlay
  const [message, setMessage] = stateMessage
  const [search, setSearch] = stateSearch
  const data = stateData[0]
  const setNewReply = stateNewReply[1]
  const setLoading = stateLoading[1]

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      if (isUserScroll && overlay === 'hidden') return
      const response = await requestData('tree_get_fototree_comment', {
        useDefaultMessage: true,
        params: {
          page,
          limit,
          tree_id: treeId,
        },
      })
      return typeof response !== 'string' && response.status === 200
        ? response.data.result
        : []
    },
    [isUserScroll, overlay, treeId],
  )

  const handleFeatureDisabled = useCallback(() => {
    if (kycStatus === 'onhold') {
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [history, kycStatus])

  const handleNewReply = useCallback(
    (id: string) => {
      user &&
        setNewReply({
          id,
          comment: message,
          created_at: new Date().toISOString(),
          reply_count: 0,
          member: {
            id: user.id,
            username: user.username,
            photo: user.photo,
            creator_status: user.creator_status,
          },
        })
    },
    [message, setNewReply, user],
  )

  const handleSendMessage = useCallback(() => {
    if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
      handleFeatureDisabled()
      return
    }
    setLoading(true)
    if (!recipient.username) {
      requestData('tree_post_fototree_comment', {
        useDefaultMessage: true,
        data: {
          tree_id: treeId,
          comment: message,
        },
        onRequestReceived: () => setLoading(false),
        onRequestSuccess: ({status}) => {
          if (status === 201) {
            setSearch(new Date().getTime())
            setMessage('')
          }
        },
      })
    } else {
      requestData('tree_post_fototree_comment_reply', {
        useDefaultMessage: true,
        data: {
          tree_id: treeId,
          comment_id: recipient.comment_id,
          comment: message,
        },
        onRequestReceived: () => setLoading(false),
        onRequestSuccess: ({status, data: {result}}) => {
          if (status === 201) {
            handleNewReply(result.id)
            setSearch(new Date().getTime())
            setMessage('')
            setRecipient({
              comment_id: '',
              username: '',
            })
          } else if (status === 429) {
            showSnackbar(
              translate('forkygram:commentLimitPerDay', {
                comment_limit: FORKYGRAM_COMMENT_LIMIT_DAY,
              }),
            )
          }
        },
      })
    }
  }, [
    handleFeatureDisabled,
    handleNewReply,
    kycStatus,
    message,
    recipient.comment_id,
    recipient.username,
    setLoading,
    setMessage,
    setRecipient,
    setSearch,
    translate,
    treeId,
  ])

  const handleRenderItem = useCallback(
    (item: TreeFototreeCommentResponse) => (
      <FototreeGlobalChatItem
        treeId={treeId}
        data={item}
        overlay={overlay}
        userType={userType}
        stateRecipient={stateRecipient}
        stateSearch={stateSearch}
        stateNewReply={stateNewReply}
      />
    ),
    [overlay, stateNewReply, stateRecipient, stateSearch, treeId, userType],
  )

  const handleRenderList = useMemo(
    () => (
      <ListLazy
        scrollbar={false}
        keyExtractor={(item) => item.id}
        stateData={stateData}
        renderItem={handleRenderItem}
        loadData={handleLoadData}
        search={search}
        listEmptyElement={
          <StyledEmptyContainer>
            <Paragraph color="gray_6">
              {translate('giftShop:emptyDefaultMessage')}
            </Paragraph>
          </StyledEmptyContainer>
        }
      />
    ),
    [handleLoadData, handleRenderItem, search, stateData, translate],
  )

  const handleRenderInputBox = useMemo(
    () => (
      <FototreeGlobalChatInput
        treeId={treeId}
        stateLoading={stateLoading}
        stateMessage={stateMessage}
        stateLoginModal={stateLoginModal}
        stateRecipient={stateRecipient}
        onSend={handleSendMessage}
      />
    ),
    [
      handleSendMessage,
      stateLoading,
      stateLoginModal,
      stateMessage,
      stateRecipient,
      treeId,
    ],
  )

  const handleRenderBack = useMemo(
    () => (
      <StyledIconBackWrapper
        onClick={() => setOverlay(isUserScroll ? 'hidden' : 'preview')}>
        <Icon type="back" size={convertUnit(20)} color="white_1" />,
      </StyledIconBackWrapper>
    ),
    [isUserScroll, setOverlay],
  )

  const handleRenderContent = useMemo(() => {
    switch (overlay) {
      case 'preview':
        return (
          <StyledPreviewContainer
            display={!data.length ? 'none' : undefined}
            onClick={() => setOverlay('full')}>
            {data.slice(0, 2).map((item) => (
              <FototreeGlobalChatItem
                key={item.id}
                data={item}
                treeId={treeId}
                overlay={overlay}
                stateRecipient={stateRecipient}
                stateSearch={stateSearch}
                stateNewReply={stateNewReply}
              />
            ))}
          </StyledPreviewContainer>
        )
      case 'full':
        return (
          <StyledContainer>
            <StyledContentContainer>
              {handleRenderBack}
              {handleRenderList}
              {handleRenderInputBox}
            </StyledContentContainer>
          </StyledContainer>
        )
      default:
        return handleRenderList
    }
  }, [
    data,
    handleRenderBack,
    handleRenderInputBox,
    handleRenderList,
    overlay,
    setOverlay,
    stateNewReply,
    stateRecipient,
    stateSearch,
    treeId,
  ])

  return handleRenderContent
}
