import React, {useCallback, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {WINDOW_MODE_MOBILE_WIDTH, TREE_MAX_BIO_CHARACTER} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {TreeEditBioForm, TreeEditFotoTreeData} from 'types'
import {useDidMount, useHistory} from 'utils'
import {Button, InputArea, ModalLoading} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeFototreeBioFormProps} from './TreeEditFormProps'

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledScreenContainer = styled.div`
  display: flex;
  flex: 1;
  margin: ${convertUnit(25)};
  padding: ${convertUnit(50)};
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_1};
  flex-direction: column;
  max-width: ${convertUnit(770)};
  height: max-content;

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: 0;
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(25)};
`

export default function TreeFototreeBioForm({
  stateTreeInfo,
  stateActiveContent,
}: TreeFototreeBioFormProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const setActiveContent = stateActiveContent[1]
  const [treeInfo, setTreeInfo] = stateTreeInfo
  const [loading, setLoading] = useState(false)
  const form = useForm<TreeEditBioForm>({
    defaultValues: {bio: treeInfo.bio},
  })
  const {setValue, register, watch, handleSubmit} = form
  const {bio} = watch()

  const handlePatchBio = useCallback(() => {
    setLoading(true)
    requestData('tree_patch_fototree', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {
        tree_bio: bio,
        tree_id: treeInfo.id,
      },
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status}) => {
        const data: TreeEditFotoTreeData = {...treeInfo, bio}
        if (status === 200) {
          setTreeInfo(data)
          history.replace(
            'tree_fototree_edit',
            {treeId: treeInfo.id, data},
            treeInfo.alias,
          )
          setActiveContent('profile')
        }
      },
    })
  }, [bio, history, setActiveContent, setTreeInfo, treeInfo])

  useDidMount(() => register('bio'))

  return (
    <>
      <ModalLoading visible={loading} />
      <StyledContainer>
        <StyledScreenContainer>
          <InputArea
            name="bio"
            form={form}
            placeholder={translate('tree:changeBioPlaceholder')}
            label={translate('giftShop:bio')}
            labelDescription={translate('global:charCount', {
              count: bio.length,
              max: TREE_MAX_BIO_CHARACTER,
            })}
            labelDescriptionColor={
              bio && bio.length === TREE_MAX_BIO_CHARACTER
                ? 'danger_5'
                : undefined
            }
            inputStyle={{height: convertUnit(89), resize: 'none'}}
            maxLength={TREE_MAX_BIO_CHARACTER}
            multiline
            onChangeText={(text) => setValue('bio', text)}
          />
          <StyledButton
            disabled={bio === treeInfo.bio || bio === '' || loading}
            label={translate('global:save')}
            onClick={handleSubmit(handlePatchBio)}
            isLoading={loading}
          />
        </StyledScreenContainer>
      </StyledContainer>
    </>
  )
}
