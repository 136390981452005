import React from 'react'
import styled, {CSSProperties} from 'styled-components'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopSettingItemsProps} from './GiftShopSettingItemsProps'

interface StyledContainerProps {
  cursor: CSSProperties['cursor']
  isBorder?: boolean
}

const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${convertUnit(16)} 0;
  cursor: ${({cursor}) => cursor};
  box-sizing: border-box;
  background-color: ${({theme}) => theme.white_1};
  border-bottom: ${convertUnit(1)} solid
    ${({theme, isBorder}) => (isBorder ? theme.white_1 : theme.white_4)};
`

const StyledLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

const StyledIconContainer = styled.div`
  margin-right: ${convertUnit(20)};
`

const StyledParagraph = styled(Paragraph)`
  padding-top: ${convertUnit(5)};
`

export default function GiftShopSettingItems({
  icon,
  label,
  showArrow = true,
  onClickItem,
  primaryColor = false,
  cursor = 'pointer',
  storage = false,
}: GiftShopSettingItemsProps) {
  return (
    <StyledContainer onClick={onClickItem} cursor={cursor} isBorder={storage}>
      <StyledLabelContainer>
        <StyledIconContainer>
          <Icon
            type={icon}
            size={convertUnit(24)}
            color={primaryColor ? 'primary_5' : 'black'}
          />
        </StyledIconContainer>

        <StyledParagraph
          fontSize="m"
          fontWeight="medium"
          color={primaryColor ? 'primary_5' : 'black'}>
          {label}
        </StyledParagraph>
      </StyledLabelContainer>

      {showArrow && !storage && (
        <Icon type="chevron-right" size={convertUnit(16)} color="gray_3" />
      )}
    </StyledContainer>
  )
}
