import React from 'react'
import styled from 'styled-components'
import {Button} from 'common/components'
import {ButtonOutlineProps} from './ButtonOutlineProps'

const StyledButtonOutline = styled(Button)<ButtonOutlineProps>`
  ${({
    borderColor,
    theme,
    disabled,
    disabledBorderColor = 'gray_3',
    borderWidth = 1,
    backgroundColor,
  }) => ({
    backgroundColor: backgroundColor ?? 'transparent',
    ...(!disabled && !disabledBorderColor
      ? {border: `${borderWidth}px solid ${theme[borderColor]}`}
      : {border: `${borderWidth}px solid ${theme[disabledBorderColor]}`}),
  })};
`

export default function ({...props}: ButtonOutlineProps) {
  return <StyledButtonOutline {...props} />
}
