import React, {
  CSSProperties,
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react'
import styled from 'styled-components'
import ReactHlsPlayer from '@gumlet/react-hls-player'
import {
  FORKYGRAM_CAPTION_LINE_HEIGHT,
  IMAGE_ASSET,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {ForkygramTemplatePostButtons, TemplateAuthAccessModal} from 'pages'
import {
  getTextShadow,
  getThemeColor,
  showModalLimitedAccess,
  useDidUpdate,
  useHistory,
} from 'utils'
import {useWindowLayout} from 'windows'
import {
  Icon,
  Image,
  IconVerifiedBadge,
  Paragraph,
  ProgressBar,
} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {AssetImageFotoTree} from 'types'
import {FototreeMemoryContentItemProps} from './FototreeContentItemProps'

interface StyledContentProps {
  height: number
}

interface StyledButtonPlayPauseProps {
  visibility: CSSProperties['visibility']
}

interface StyledParagraphProps {
  collapse: boolean
}

interface StyledProfileProps {
  shouldUpdateGap: boolean
}

const StyledContentImage = styled(Image)<StyledContentProps>`
  max-width: 100%;
  width: 100%;
  object-fit: scale-down;
  max-height: ${({height}) => convertUnit(height)};
  box-sizing: border-box;
`

const StyledMobileCaptionContainer = styled.div`
  position: absolute;
  left: ${convertUnit(12)};
  bottom: ${convertUnit(12)};
  display: flex;
  flex-direction: column;
  width: calc(100% - (${convertUnit(70)}));
  max-width: ${WINDOW_MODE_MOBILE_WIDTH}px;
`

const StyledButtonPlayPause = styled.div<StyledButtonPlayPauseProps>`
  ${({visibility}) => ({visibility})}
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  height: ${convertUnit(72)};
  width: ${convertUnit(72)};
  align-items: center;
  justify-content: center;
  display: flex;
`

const StyledVideo = styled(ReactHlsPlayer)<StyledContentProps>`
  max-width: 100%;
  object-fit: scale-down;
  max-height: ${({height}) => convertUnit(height)};
  box-sizing: border-box;
`

const StyledCaptionContainer = styled.div`
  display: flex;
  flex: 1;
  width: fill-available;
  flex-direction: column;
`

const StyledUsernameContainer = styled.div<StyledParagraphProps>`
  display: flex-end;
`

const StyledCaptionContentContainer = styled.div<StyledParagraphProps>`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
`

const StyledParagraphContainer = styled.div<StyledParagraphProps>`
  height: fit-content;
  max-height: ${({collapse}) => (collapse ? convertUnit(30) : 'fit-content')};
  overflow: hidden;
`

const StyledParagraph = styled(Paragraph)`
  text-shadow: ${({theme}) => getTextShadow(theme)};
`
const StyledIconWrapper = styled.div`
  display: inline;
`

const StyledCollapsibleParagraph = styled(Paragraph)<StyledParagraphProps>`
  ${({collapse}) =>
    collapse && {
      overflow: 'hidden',
    }};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: auto;
  word-break: break-word;
  text-shadow: ${({theme}) => getTextShadow(theme)};
`
const StyledAvatarContainer = styled.div`
  position: relative;
`
const StyledAvatar = styled(Image)`
  ${({theme}) => ({
    border: `solid ${convertUnit(1)} ${theme.white_1}`,
  })}
  border-radius: ${convertUnit(5)};
  object-fit: contain;
  cursor: pointer;
  width: ${convertUnit(45)};
  height: ${convertUnit(60)};
  background-color: ${({theme}) => theme.black};
  box-sizing: border-box;
`

const StyledProgressBarContainer = styled.div`
  bottom: ${convertUnit(0)};
  position: absolute;
  display: flex;
  padding: 0 ${convertUnit(8)};
  justify-content: center;
  align-items: center;
  width: inherit;
  max-width: ${convertUnit(600)};
`

const StyledContentContainer = styled.div<StyledContentProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({height}) => convertUnit(height)};
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  max-width: ${convertUnit(600)};
  margin: auto;
`

const StyledProfileContainer = styled.div<StyledProfileProps>`
  ${({shouldUpdateGap}) => ({gap: convertUnit(shouldUpdateGap ? 16 : 8)})};
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: fit-content;
`

const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  margin-right: ${convertUnit(4)};
  line-height: ${convertUnit(16)};
  margin-bottom: ${convertUnit(-4)};
`

const StyledInvisibleContainer = styled.div<StyledParagraphProps>`
  display: flex;
  column-gap: ${convertUnit(4)};
  background-color: ${({theme, collapse}) =>
    getThemeColor(theme, 'black', collapse ? 0.4 : 0.5)};
  width: fit-content;
`

const StyledFollowButton = styled.div`
  position: absolute;
  top: ${convertUnit(4)};
  left: ${convertUnit(36)};
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(4)};
  padding: ${convertUnit(2)};
  padding-top: 0;
  cursor: pointer;
`

const StyledInlineParagraph = styled(StyledParagraph)`
  display: inline;
  word-break: break-word;
  margin-right: ${convertUnit(4)};
`

const StyledFotoTreeTag = styled.div<StyledParagraphProps>`
  display: flex;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  gap: ${convertUnit(4)};
`

const StyledFotoTreePinContainer = styled.div`
  width: fit-content;
`

const StyledFotoTreePin = styled(Image)`
  height: ${convertUnit(16)};
`

export default function FototreeMemoryContentItem({
  data,
  dataTag,
  active,
  focused,
  stateMute,
  statePause,
  toggleTimeout = 500,
  onClickMore,
  onFollow,
  stateFollowButton,
}: FototreeMemoryContentItemProps) {
  const {translate} = useTranslation()
  const {content_type, is_hidden} = data
  const {id, username, photo, creator_status, is_followed} = data.member
  const {access_token, id: userId} = useSelector('user') || {}
  const {status: childStatus} = useSelector('parentalConsentState')
  const [pause, setPause] = statePause
  const [mute, setMute] = stateMute
  const history = useHistory()
  const {height} = useWindowLayout()
  const ref = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const timestamp = useRef(new Date().getTime())
  const [showButton, setShowButton] = useState(false)
  const [collapse, setCollapse] = useState(true)
  const [progress, setProgress] = useState(0)
  const [captionClientHeight, setClientHeight] = useState(0)
  const [captionScrollHeight, setScrollHeight] = useState(0)
  const [loginModal, setLoginModal] = useState(false)
  const [followButton, setFollowButton] = stateFollowButton
  const [shouldUpdateGap, setShouldUpdateGap] = useState(false)
  const usernameRef = useRef<HTMLDivElement>(null)

  const isOverflow = useMemo(() => captionScrollHeight > captionClientHeight, [
    captionClientHeight,
    captionScrollHeight,
  ])

  const locationName = useMemo(() => {
    const name = dataTag?.location?.name
    return name && !name.startsWith('-') ? name : null
  }, [dataTag?.location?.name])

  const handleHideButton = useCallback(() => {
    const now = new Date().getTime()
    const dif = now - timestamp.current

    if (dif >= toggleTimeout) {
      setShowButton(false)
    }
  }, [toggleTimeout])

  const handleToggleVideo = useCallback(() => {
    timestamp.current = new Date().getTime()

    setPause((previous) => !previous)
    setShowButton(true)

    setTimeout(handleHideButton, toggleTimeout)
  }, [toggleTimeout, setPause, handleHideButton])

  const handleVideoTimeUpdate = useCallback<
    React.ReactEventHandler<HTMLVideoElement>
  >((event) => {
    const {currentTime, duration} = event.currentTarget
    const extra = (currentTime / duration) * 1

    setProgress(Math.min(100, (currentTime + extra) / duration) * 100)
  }, [])

  const handleBlur = useCallback(() => {
    videoRef.current && videoRef.current.pause()
  }, [])

  const handleFocus = useCallback(() => {
    videoRef.current && videoRef.current.play()
  }, [])

  const handleFocusEvent = useCallback(() => {
    if (content_type === 'video' && videoRef.current && active) {
      window.addEventListener('blur', handleBlur)
      window.addEventListener('focus', handleFocus)

      return () => {
        window.removeEventListener('blur', handleBlur)
        window.removeEventListener('focus', handleFocus)
      }
    }

    return undefined
  }, [active, content_type, handleBlur, handleFocus])

  const handleClickMore = useCallback(() => {
    if (!access_token) {
      setLoginModal(true)
      return
    }
    onClickMore()
  }, [access_token, onClickMore])

  const handleNavigateProfile = useCallback(() => {
    if (childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    history.push(
      'giftshop_profile',
      {
        self: id === userId,
        postData: {member_id: id, username, photo},
      },
      username,
    )
  }, [childStatus, history, id, userId, username, photo])

  const handleNavigateFotoTreeDetail = useCallback(() => {
    if (!dataTag) return
    history.replace(
      'tree_fototree_detail',
      {
        treeId: dataTag.tree_id,
        treeAlias: dataTag.alias,
      },
      dataTag.alias,
    )
  }, [dataTag, history])

  const handleRenderContent = useMemo(
    () =>
      content_type === 'photo' ? (
        <StyledContentImage src={data.url} alt="" height={height} />
      ) : (
        <>
          <StyledVideo
            id={data.post_id}
            loop
            src={data.url}
            height={height}
            playerRef={videoRef}
            onClick={handleToggleVideo}
            muted={mute}
            onTimeUpdate={handleVideoTimeUpdate}
          />
          <StyledButtonPlayPause visibility={showButton ? 'visible' : 'hidden'}>
            <Icon
              type={pause ? 'pause' : 'play'}
              size={convertUnit(56)}
              color="white_1"
            />
          </StyledButtonPlayPause>
        </>
      ),
    [
      content_type,
      data.url,
      data.post_id,
      height,
      handleToggleVideo,
      mute,
      handleVideoTimeUpdate,
      showButton,
      pause,
    ],
  )

  const handleRenderProgressBar = useMemo(
    () => (
      <StyledProgressBarContainer>
        <ProgressBar progress={progress} />
      </StyledProgressBarContainer>
    ),
    [progress],
  )

  const handleRenderBadge = useMemo(
    () => creator_status === 'verified' && <StyledIconVerifiedBadge />,
    [creator_status],
  )

  const handleRenderUsername = useMemo(
    () => (
      <StyledUsernameContainer collapse={collapse} ref={usernameRef}>
        <StyledInlineParagraph
          color="white_1"
          fontWeight="bold"
          onClick={handleNavigateProfile}>
          @{username}
        </StyledInlineParagraph>
        <StyledIconWrapper>{handleRenderBadge}</StyledIconWrapper>
      </StyledUsernameContainer>
    ),
    [collapse, handleNavigateProfile, handleRenderBadge, username],
  )

  const handleRenderAvatar = useMemo(
    () => (
      <StyledProfileContainer shouldUpdateGap={shouldUpdateGap}>
        <StyledAvatarContainer>
          {!is_followed && followButton && id !== userId ? (
            <StyledFollowButton
              onClick={() => {
                if (access_token) {
                  setFollowButton(false)
                  onFollow()
                } else {
                  setLoginModal(true)
                }
              }}>
              <Icon type="plus" size={12} color="primary_5" />
            </StyledFollowButton>
          ) : (
            <></>
          )}
          <StyledAvatar alt="" src={photo} onClick={handleNavigateProfile} />
        </StyledAvatarContainer>
        {handleRenderUsername}
      </StyledProfileContainer>
    ),
    [
      access_token,
      followButton,
      handleNavigateProfile,
      handleRenderUsername,
      id,
      is_followed,
      onFollow,
      photo,
      setFollowButton,
      shouldUpdateGap,
      userId,
    ],
  )

  const handleRenderFotoTreeTag = useMemo(
    () =>
      dataTag?.name &&
      dataTag?.icon &&
      locationName && (
        <StyledFotoTreeTag
          collapse={collapse}
          onClick={handleNavigateFotoTreeDetail}>
          <StyledFotoTreePinContainer>
            <StyledFotoTreePin
              src={IMAGE_ASSET(
                'tree',
                `icons/pinpoint-${dataTag.icon}.png` as AssetImageFotoTree,
              )}
              alt={dataTag.icon}
            />
          </StyledFotoTreePinContainer>
          <StyledInlineParagraph
            fontWeight="medium"
            color="white_1"
            lineHeight={14}>
            {`${dataTag.name}${locationName && ` • ${locationName}`}`}
          </StyledInlineParagraph>
        </StyledFotoTreeTag>
      ),
    [
      collapse,
      dataTag?.icon,
      dataTag?.name,
      handleNavigateFotoTreeDetail,
      locationName,
    ],
  )

  const handleRenderMobileCaption = useMemo(
    () => (
      <StyledMobileCaptionContainer>
        {handleRenderAvatar}
        {handleRenderFotoTreeTag}
        {data.caption ? (
          <StyledCaptionContainer>
            {is_hidden && (
              <StyledInvisibleContainer collapse={collapse}>
                <Icon
                  type="invisible"
                  size={convertUnit(12)}
                  color="white_1"
                  hasOpacity
                  hasShadow
                />
                <Paragraph color="white_1" fontWeight="medium">
                  {translate('tree:invisible')}
                </Paragraph>
              </StyledInvisibleContainer>
            )}
            <StyledCaptionContentContainer
              collapse={collapse}
              onClick={() => setCollapse((prev) => !prev)}>
              <StyledParagraphContainer collapse={collapse} ref={ref}>
                <StyledCollapsibleParagraph
                  lineHeight={14}
                  color="white_1"
                  collapse={collapse}>
                  {data.caption}
                </StyledCollapsibleParagraph>
              </StyledParagraphContainer>
              {isOverflow && (
                <StyledParagraph
                  lineHeight={14}
                  color="white_1"
                  fontWeight="bold">
                  {'...'}
                  {collapse
                    ? translate('global:more')
                    : translate('global:less')}
                </StyledParagraph>
              )}
            </StyledCaptionContentContainer>
          </StyledCaptionContainer>
        ) : (
          <></>
        )}
      </StyledMobileCaptionContainer>
    ),
    [
      collapse,
      data.caption,
      handleRenderAvatar,
      handleRenderFotoTreeTag,
      isOverflow,
      is_hidden,
      translate,
    ],
  )

  const handleRenderRightButtons = useMemo(
    () => (
      <ForkygramTemplatePostButtons
        ownerId={data.member.id}
        postId={data.post_id}
        photo={data.member.photo}
        username={data.member.username}
        member_id={data.member.id}
        creator_status={data.member.creator_status}
        isFeed
        data={data}
        mute={mute}
        handleShowModal={() => setLoginModal(true)}
        onPressMute={() => setMute((prev) => !prev)}
        onClickMore={handleClickMore}
      />
    ),
    [data, handleClickMore, mute, setMute],
  )

  const handleRenderModalLogin = useMemo(
    () => (
      <TemplateAuthAccessModal
        toggleModal={() => setLoginModal((prev) => !prev)}
        visible={loginModal}
      />
    ),
    [loginModal, setLoginModal],
  )

  useEffect(() => handleFocusEvent(), [handleFocusEvent])

  useEffect(() => {
    if (videoRef.current) {
      if (active) {
        videoRef.current.play()
      } else {
        videoRef.current.pause()
      }
    }
  }, [active])

  useEffect(() => {
    if (videoRef.current && !focused) {
      videoRef.current.currentTime = 0
    }
  }, [focused])

  useEffect(() => {
    ref.current?.clientHeight && setClientHeight(ref.current.clientHeight)
    ref.current?.scrollHeight && setScrollHeight(ref.current.scrollHeight)
  }, [])

  useDidUpdate(() => {
    setShouldUpdateGap(
      !is_followed && followButton && usernameRef.current?.offsetHeight
        ? usernameRef.current.offsetHeight > FORKYGRAM_CAPTION_LINE_HEIGHT
        : false,
    )
  }, [followButton, is_followed, usernameRef.current?.offsetHeight])

  return (
    <StyledContentContainer height={height}>
      {handleRenderModalLogin}
      {handleRenderContent}
      {handleRenderMobileCaption}
      {handleRenderRightButtons}
      {handleRenderProgressBar}
    </StyledContentContainer>
  )
}
