import storage from 'redux-persist/lib/storage'
import {ReduxReducerPersistConfig} from '../../lib/redux/ReduxReducer'

export const REDUX_PERSIST_CONFIG: ReduxReducerPersistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'user',
    'lastUserState',
    'lastTreeState',
    'lastUploadStatePhoto',
    'lastUploadStateVideo',
    'userBanStatus',
    'exploreFilter',
    'verificationStatus',
    'userNewPhone',
    'userNewEmail',
    'hostState',
    'bannerClosed',
    'hostRegistrationState',
    'lastPasswordOTPTime',
    'yuserActivationState',
    'messageRoomState',
    'forgotPasswordState',
    'similarAccountStatus',
    'roboyuSensitivityLevel',
    'giftshopState',
    'giftShopTooltipState',
    'languageState',
    'easySignupDuplicateState',
    'userDevice',
    'parentalConsentState',
  ],
}
