import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  IMAGE_ASSET,
  SERVICE_CANCELLATION_SEARCH_EVENT,
  SERVICE_CANCELLED_RESPONSE,
} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopRootEmpty} from 'pages'
import {requestData} from 'services'
import {TreeSearchEventsResponse} from 'types'
import {useDebounce, useHistory} from 'utils'
import {useWindowLayout, useWindowMode} from 'windows'
import {Icon, Input, ListLazy, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeEventsContentItem} from '../ContentItem'

const StyledSearchContainer = styled.div`
  width: 100%;
  margin-bottom: ${convertUnit(8)};
`

export default function TreeEventsContent() {
  const {translate} = useTranslation()
  const [search, setSearch] = useState('')
  const mode = useWindowMode()
  const {width} = useWindowLayout()
  const emptyImg = IMAGE_ASSET('giftshop', 'not-found.png')
  const history = useHistory()
  const debounce = useDebounce()

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('tree_search_events', {
        cancelId: SERVICE_CANCELLATION_SEARCH_EVENT,
        params: {
          page,
          limit,
          q: search,
        },
      })

      if (
        typeof response === 'string' &&
        response === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      return typeof response !== 'string' && response.status === 200
        ? response.data.result
        : []
    },
    [search],
  )

  const handleClickItem = useCallback(
    (item: TreeSearchEventsResponse) => {
      history.pushQuery({
        path: 'tree_events_bib_masonry',
        state: {
          tree_url: item.url || '',
          tree_name: item.tree_name || '',
          fromScan: false,
        },
        queryObject: {
          tree_id: item.tree_id,
          tag_id: item.tag_id,
          search: '',
        },
      })
    },
    [history],
  )

  const handleRenderSearch = useMemo(
    () => (
      <StyledSearchContainer>
        <Input
          placeholder={translate('tree:eventSearchPlaceholder')}
          placeholderColor="gray_3"
          leftIcon={<Icon type="search" color="gray_3" size={16} />}
          containerStyle={{
            margin: `${convertUnit(12)} ${convertUnit(20)}`,
          }}
          defaultValue={search}
          onChangeText={(text) => debounce(() => setSearch(text))}
          rightIcon="delete"
          onRightIconClick={() => setSearch('')}
        />
      </StyledSearchContainer>
    ),
    [debounce, search, translate],
  )

  const handleRenderItem = useCallback(
    (item: TreeSearchEventsResponse) => (
      <TreeEventsContentItem
        data={item}
        onClickItem={() => handleClickItem(item)}
      />
    ),
    [handleClickItem],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <GiftShopRootEmpty
        image={emptyImg}
        imageAlt={emptyImg}
        bottomElement={
          <>
            <Paragraph fontSize="l" fontWeight="bold">
              {translate('tree:eventSearchEmptyTitle')}
            </Paragraph>
            <Paragraph color="gray_5">
              {translate('tree:eventSearchEmptySubtitle')}
            </Paragraph>
          </>
        }
      />
    ),
    [emptyImg, translate],
  )

  return (
    <ListLazy
      loadData={handleLoadData}
      keyExtractor={(item) => `${item.tag_id}${item.tree_id}`}
      renderItem={handleRenderItem}
      search={search}
      listHeaderElement={handleRenderSearch}
      listEmptyElement={handleRenderEmpty}
      numColumns={mode === 'website' ? Math.floor(width / 450) : 1}
    />
  )
}
