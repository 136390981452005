import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useHistory} from 'utils'
import {GiftShopRobopetActivationTemplate} from '../ActivationTemplate'

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(20)};
`

export default function GiftShopRobopetActivationIncomplete() {
  const {translate} = useTranslation()
  const history = useHistory()

  return (
    <GiftShopRobopetActivationTemplate
      backgroundColor="primary_1"
      robopetImg="robopet-confused.png"
      title={translate('giftShop:roboyuActivationIncompleteHeader')}
      titleColor="primary_5">
      <Paragraph fontSize="m" fontWeight="regular" color="primary_5">
        {translate('giftShop:roboyuActivationIncompleteDescription')}
      </Paragraph>
      <StyledButton
        label={translate('global:tryAgain')}
        onClick={() =>
          history.push('giftshop_robopet_activation_selfie', {
            isReapply: true,
          })
        }
      />
    </GiftShopRobopetActivationTemplate>
  )
}
