import React, {useCallback, useMemo, useState} from 'react'
import {gpsPermissionState} from 'types'
import {getLocationPermission, useDidMount} from 'utils'
import {useDispatch} from 'lib/redux'
import {TreeFototreeModal, TreeLocPermissionModal} from '../containers'

export default function TreeFototreeScreen() {
  const permissionModalState = useState<boolean>(false)
  const {update} = useDispatch()
  const [modal, setModal] = permissionModalState
  const [permission, setPermission] = useState<gpsPermissionState>('denied')

  const handleErrorLocation = useCallback(() => {
    setModal(true)
  }, [setModal])

  const handleSuccessLocation = useCallback(
    // eslint-disable-next-line no-undef
    (position: GeolocationPosition) => {
      setModal(false)
      update('user', {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      })
    },
    [setModal, update],
  )

  const handlePermission = useCallback(
    (res: PermissionStatus) => {
      setPermission(res.state)
      if (res.state === 'prompt') {
        setModal(true)
      } else if (res.state === 'denied') {
        setModal(true)
      } else {
        navigator.geolocation.getCurrentPosition(
          handleSuccessLocation,
          handleErrorLocation,
          {
            enableHighAccuracy: true,
          },
        )
      }
    },
    [handleErrorLocation, handleSuccessLocation, setModal],
  )

  useDidMount(() => {
    getLocationPermission(handlePermission, () => {})
  })

  const handleRenderPermissionModal = useMemo(
    () => (
      <TreeLocPermissionModal
        visible={modal}
        toggleModal={() => setModal(!modal)}
      />
    ),
    [modal, setModal],
  )

  return (
    <>
      <TreeFototreeModal
        permissionModal={permissionModalState}
        gpsEnabled={permission === 'granted'}
      />
      {handleRenderPermissionModal}
    </>
  )
}
