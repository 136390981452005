import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import AvatarEditor from 'react-avatar-editor'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  HOST_IMAGE_TYPE,
  HOST_LOGO_MAX_SIZE,
  WINDOW_MODE_MOBILE_WIDTH,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {TreeHostPatchPictureForm, TreeUploadImageExtensionType} from 'types'
import {
  cropImage,
  handleUploadSingleFileToWasabi,
  showSnackbar,
  useDidMount,
} from 'utils'
import {useWindowMode} from 'windows'
import {Icon, Image, ImageCropper, ModalLoading} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeEditProfileCoverProps} from './TreeEditProfileCoverProps'
import {TreeEditProfileLogo} from '../Logo'
import {TreeEditProfileModalImageOptions} from '../Modal'

const StyledContainer = styled.div`
  height: ${convertUnit(200)};
  width: 100%;
  position: relative;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    height: ${convertUnit(120)};
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    height: ${convertUnit(100)};
  }
`

const StyledCover = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`

const StyledCoverPlaceholder = styled.div`
  background-color: ${({theme}) => theme.gray_1};
  height: inherit;
  width: 100%;
`

const StyledEditContainer = styled.div`
  position: absolute;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({theme}) => theme.white_1};
  width: ${convertUnit(32)};
  height: ${convertUnit(32)};
  border: ${convertUnit(1.89)} solid ${({theme}) => theme.white_3};
  right: ${convertUnit(12)};
  top: ${convertUnit(12)};
`

const StyledInput = styled.input`
  display: none;
`

export default function TreeEditProfileCover({
  coverPhoto,
  logoPhoto,
}: TreeEditProfileCoverProps) {
  const {translate} = useTranslation()
  const [modal, setModal] = useState(false)
  const [crop, setCrop] = useState(false)
  const [loading, setLoading] = useState(false)
  const canvas = useRef<AvatarEditor>(null)
  const coverRef = useRef<HTMLInputElement>(null)
  const mode = useWindowMode()

  const form = useForm<TreeHostPatchPictureForm>()
  const {register, setValue, watch} = form
  const {imgExtension, imgFile, imgHeight, imgMime, imgSize, imgWidth} = watch()

  const handleCheckCover = useCallback(() => {
    setModal(false)
    if (coverRef.current?.files?.length) {
      const file = coverRef.current.files[0]
      const filename = file.name
      const extension = filename.substring(
        filename.lastIndexOf('.') + 1,
      ) as TreeUploadImageExtensionType
      const {type, size} = file
      if (!HOST_IMAGE_TYPE.includes(type)) {
        showSnackbar(translate('tree:addHostInfoErrorInvalidFileFormat'))
        return false
      }
      if (size > HOST_LOGO_MAX_SIZE) {
        showSnackbar(translate('tree:addHostInfoErrorMaxFileSize'))
        return false
      }

      setValue('imgMime', type)
      setValue('imgExtension', extension)
      return true
    }
    return false
  }, [setValue, translate])

  const handleGenerateCoverLink = useCallback(async () => {
    setLoading(true)
    const response = await requestData('tree_post_cover_link', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {
        cover: {
          extension: imgExtension,
          height: imgHeight,
          size: imgSize,
          width: imgWidth,
        },
      },
      onRequestSuccess: ({status}) => status !== 200 && setLoading(false),
      onRequestFailed: () => setLoading(false),
    })
    return typeof response !== 'string' && response.status === 200
      ? response.data.result.cover
      : null
  }, [imgExtension, imgHeight, imgSize, imgWidth])

  const handlePublishToWasabi = useCallback(
    async (link: string) => {
      try {
        return handleUploadSingleFileToWasabi(imgFile, link, imgMime, {
          'Content-Type': imgMime,
          'x-amz-meta-imagewidth': imgWidth.toString(),
          'x-amz-meta-imageheight': imgHeight.toString(),
        })
      } catch (error) {
        return false
      }
    },
    [imgFile, imgHeight, imgMime, imgWidth],
  )

  const handleChangeCover = useCallback(
    async (link: string) => {
      const response = await requestData('tree_patch_hosts', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {
          cover_link: link,
          cover_size: imgSize,
        },
      })
      return typeof response !== 'string' && response.status === 200
    },
    [imgSize],
  )

  const handlePublishCover = useCallback(async () => {
    const link = await handleGenerateCoverLink()
    if (link) {
      const wasabiStatus = await handlePublishToWasabi(link)
      if (wasabiStatus) {
        const uploadStatus = await handleChangeCover(link)
        if (uploadStatus) {
          setLoading(false)
          setCrop(false)
          location.reload()
        }
      }
    }
  }, [handleChangeCover, handleGenerateCoverLink, handlePublishToWasabi])

  const handleCrop = useCallback(
    (img?: File) => {
      if (img) {
        const reader = new FileReader()
        reader.readAsDataURL(img)
        reader.onload = () => {
          const url = reader.result?.toString()
          const image = document.createElement('img')
          if (url) {
            image.src = url
            image.onload = () => {
              const {width, height} = image
              setValue('imgFile', img)
              setValue('imgHeight', height)
              setValue('imgWidth', width)
              setValue('imgSize', img.size)
            }
          }
        }
      }
    },
    [setValue],
  )

  const handleRenderCover = useMemo(
    () => (
      <>
        {coverPhoto ? (
          <StyledCover src={coverPhoto} alt={coverPhoto} />
        ) : (
          <StyledCoverPlaceholder />
        )}
        <StyledEditContainer onClick={() => setModal(true)}>
          <Icon type="pencil" size={convertUnit(12)} />
        </StyledEditContainer>
      </>
    ),
    [coverPhoto],
  )

  const handleRenderChangePicOPtions = useMemo(
    () => (
      <TreeEditProfileModalImageOptions
        visible={modal}
        onClickOption={() => {
          coverRef.current && coverRef.current.click()
        }}
        toggleModal={() => setModal((prev) => !prev)}
      />
    ),
    [modal],
  )

  const handleRenderLogoCropper = useMemo(
    () =>
      coverRef.current?.files?.length ? (
        <ImageCropper
          visible={crop}
          borderRadius={0}
          border={mode === 'website' ? [0, 120] : [0, 100]}
          componentRef={canvas}
          shape="landscape"
          file={coverRef.current.files[0]}
          onBackdropPress={() => setCrop(false)}
          onClickSave={() => cropImage(canvas, 'cover', handleCrop)}
        />
      ) : (
        <></>
      ),
    [crop, handleCrop, mode],
  )

  useDidMount(() => {
    register('imgFile')
    register('imgExtension')
    register('imgHeight')
    register('imgSize')
    register('imgWidth')
    register('imgMime')
  })

  useEffect(() => {
    if (imgHeight && imgWidth && imgSize) {
      handlePublishCover()
    }
  }, [handlePublishCover, imgHeight, imgSize, imgWidth])

  return (
    <StyledContainer>
      {handleRenderChangePicOPtions}
      {handleRenderLogoCropper}
      <ModalLoading visible={loading} />
      <StyledInput
        ref={coverRef}
        type="file"
        onChange={() => {
          const check = handleCheckCover()
          return check ? setCrop(true) : undefined
        }}
        accept={HOST_IMAGE_TYPE.join(',')}
      />
      <TreeEditProfileLogo logoPhoto={logoPhoto} />
      {handleRenderCover}
    </StyledContainer>
  )
}
