import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload, GiftShopContentData} from 'types'
import {requestData} from 'services'
import {useDidMount, useHistory, parseDate} from 'utils'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import GiftShopMessageRoomContentList from './GiftShopMessageRoomContentItemList'

export interface GiftShopMessageRoomContentItemReminderWaitingHiresProps {
  payload: FirebaseMessageRoomItemPayload['reminder_waiting_hires_content']
}

const StyledExtraText = styled(Paragraph)`
  margin-top: ${convertUnit(20)};
`

export default function GiftShopMessageRoomContentItemReminderWaitingHires({
  payload: {contents, creator_username, username, purchased_at},
}: GiftShopMessageRoomContentItemReminderWaitingHiresProps) {
  const {translate} = useTranslation()
  const [data, setData] = useState<GiftShopContentData[]>([])
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  const handleLoadData = useCallback(
    () =>
      requestData('giftshop_get_contents_detail', {
        params: {content_ids: contents.toString()},
        onRequestSuccess: ({status, data: {result}}) => {
          status === 200 && setData(result)
          setLoading(false)
        },
      }),
    [contents],
  )

  const handleRenderMessage = useMemo(
    () => (
      <>
        <Paragraph fontSize="m">
          {translate('messages:reminderWaitingHiresGreet', {
            username,
          })}
        </Paragraph>
        <StyledExtraText fontSize="m">
          {translate('messages:reminderWaitingHires', {
            creator_username,
          })}
        </StyledExtraText>
      </>
    ),
    [creator_username, translate, username],
  )

  const handleRenderContent = useMemo(
    () => (
      <GiftShopMessageRoomContentList
        data={data}
        onClick={(item) =>
          history.push('giftshop_collection_detail', {
            data,
            selectedItemId: item.content_id,
            limitContent: true,
          })
        }
        dataCount={contents.length}
        loading={loading}
      />
    ),
    [contents.length, data, history, loading],
  )

  const handleRenderExtra = useMemo(
    () => (
      <>
        {purchased_at && (
          <Paragraph>
            {parseDate(purchased_at, 'DD/MM/YYYY, HH:mm:ss')}
          </Paragraph>
        )}
        <StyledExtraText fontSize="m">
          {translate('messages:reminderWaitingHiresExtra')}
        </StyledExtraText>
      </>
    ),
    [purchased_at, translate],
  )

  useDidMount(() => {
    handleLoadData()
  })

  return (
    <>
      {handleRenderMessage}
      {handleRenderContent}
      {handleRenderExtra}
    </>
  )
}
