import React from 'react'
import styled from 'styled-components'
import {
  LIST_MASONRY_PAGINATION_BUTTON_SIZE,
  LIST_MASONRY_PAGINATION_BUTTON_SIZE_MOBILE,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import convertUnit from 'lib/unit'
import {Button} from '../Button'
import {
  ListMasonryPaginationButtonControlsProps,
  ListMasonryPaginationButtonPageProps,
} from './ListMasonryPaginationPageButtonProps'

interface StyledPaginationButtonProps {
  active?: boolean
}

const StyledPaginationPageButton = styled(Button)<StyledPaginationButtonProps>`
  ${({theme, active}) =>
    active
      ? {
          color: theme.white_1,
        }
      : {
          color: theme.gray_5,
          backgroundColor: theme.white_1,
          border: `${convertUnit(1)} solid ${theme.gray_1}`,
        }}
  width: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_SIZE)};
  height: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_SIZE)};
  font-family: 'Roboto-Medium';
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    padding: 0;
    width: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_SIZE_MOBILE)};
    height: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_SIZE_MOBILE)};
    font-size: 12px;
  }
`

const StyledPaginationGapMarker = styled.div`
  ${({theme}) => ({
    color: theme.gray_5,
  })}
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_SIZE)};
  height: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_SIZE)};
  font-family: 'Roboto-Medium';
  letter-spacing: ${convertUnit(1)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    padding: 0;
    width: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_SIZE_MOBILE)};
    height: ${convertUnit(LIST_MASONRY_PAGINATION_BUTTON_SIZE_MOBILE)};
    font-size: 12px;
  }
`

export function ListMasonryPaginationButtonPage({
  destination,
  page,
  setPage,
}: ListMasonryPaginationButtonPageProps) {
  return (
    <StyledPaginationPageButton
      active={page === destination}
      onClick={() => setPage(destination)}>
      {destination}
    </StyledPaginationPageButton>
  )
}

export function ListMasonryPaginationButtonControls({
  onClick,
  children,
}: ListMasonryPaginationButtonControlsProps) {
  return (
    <StyledPaginationPageButton onClick={onClick}>
      {children}
    </StyledPaginationPageButton>
  )
}

export function ListMasonryPaginationGapMarker() {
  return <StyledPaginationGapMarker>...</StyledPaginationGapMarker>
}
