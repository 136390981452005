import React, {useMemo} from 'react'
import styled from 'styled-components'
import {ForkygramListGridItemType} from 'types'
import {getBorder, getThemeColor} from 'utils'
import {useWindowLayout, useWindowMode} from 'windows'
import {Icon, Image, Paragraph, Tooltip} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {ThemeColor} from 'themes'
import {ForkygramListGridItemProps} from './ForkygramListGridItemProps'

interface StyledContentContainerProps {
  width: number
  borderColor?: ThemeColor
}

const StyledContentContainer = styled.div<StyledContentContainerProps>`
  ${({theme, width, borderColor = 'white_1'}) => ({
    width: convertUnit(width),
    height: convertUnit(width),
    border: getBorder(1, 'solid', theme[borderColor]),
  })}
  position: relative;
  box-sizing: border-box;
`

const StyledImage = styled(Image)`
  object-fit: cover;
  width: inherit;
  height: inherit;
  background-color: ${({theme}) => theme.gray_2};
`

const StyledVideoIconContainer = styled.div`
  position: absolute;
  top: ${convertUnit(4)};
  left: ${convertUnit(4)};
`

const StyledThumbnail = styled(Image)`
  width: inherit;
  height: inherit;
  object-fit: cover;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledCaptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${convertUnit(8)};
  height: ${convertUnit(28)};
`

const StyledInvisibleContainer = styled.div`
  position: absolute;
  bottom: ${convertUnit(4)};
  left: ${convertUnit(4)};
  background-color: ${({theme}) => getThemeColor(theme, 'primary_5', 0.6)};
  border-radius: ${convertUnit(20)};
  padding: 0 ${convertUnit(4)};
`

export default function ForkygramListGridItem<
  ItemT extends ForkygramListGridItemType
>({data, column, borderColor, onClickItem}: ForkygramListGridItemProps<ItemT>) {
  const {translate} = useTranslation()
  const {width} = useWindowLayout()
  const mode = useWindowMode()

  const handleRenderInvisibleIcon = useMemo(
    () =>
      data.isHidden && (
        <StyledInvisibleContainer
          data-tooltip-id={`tooltip-invisible-${data.url}`}>
          <Icon type="invisible" size={convertUnit(12)} color="white_1" />
          <Tooltip
            id={`tooltip-invisible-${data.url}`}
            content={translate('tree:invisible')}
          />
        </StyledInvisibleContainer>
      ),
    [data.isHidden, data.url, translate],
  )

  return (
    <StyledContainer>
      <StyledContentContainer
        borderColor={borderColor}
        style={{cursor: onClickItem ? 'pointer' : 'default'}}
        onClick={() => onClickItem && onClickItem(data)}
        width={mode === 'website' ? (width - 97) / column : width / column}>
        {data.contentType === 'photo' ? (
          <StyledImage src={data.url} alt="" />
        ) : (
          <>
            <StyledVideoIconContainer>
              <Icon type="video-view" size={convertUnit(22)} color="white_1" />
            </StyledVideoIconContainer>
            <StyledThumbnail src={data.thumbnail} alt="thumbnail" />
          </>
        )}
        {handleRenderInvisibleIcon}
      </StyledContentContainer>
      {data.caption && (
        <StyledCaptionContainer>
          <Paragraph fontSize="xs" fontWeight="medium" color="white_1">
            {data.caption}
          </Paragraph>
        </StyledCaptionContainer>
      )}
    </StyledContainer>
  )
}
